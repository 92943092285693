import React from 'react'
import ReactStars from 'react-stars'
import ReviewSlider from "./ReviewSlider"

const ReviewSection = ({ type, changeView, data }) => {
    return (
        <div className='text-white w-full px-6 lg:px-8 xl:px-0 flex lg:flex-row flex-col lg:gap-16 gap-6'>
            <div className={`lg:w-2/5 w-full order-1 ${type === 1 ? 'lg:order-2' : 'lg:order-1'} flex items-center justify-center`}>
                <div className='flex flex-col items-center lg:items-start'>
                    <h1 className='lg:text-4xl text-2xl font-semibold'>{data.heading}</h1>
                    <div className='bg-brand lg:h-[10px] h-[5px] rounded-lg w-[50%] my-2' />
                    <p className='lg:text-lg text-center lg:text-left text-md'>{data.description}</p>
                    <ReactStars
                        count={5}
                        value={5}
                        size={40}
                        color1={'#D3D3D3'}
                        color2={'#ffd700'}
                        edit={false}
                        half={true} />
                </div>
            </div>
            <div className={`lg:w-3/5 w-full order-2 ${type === 1 ? 'lg:order-1' : 'lg:order-2'}`}>
                <ReviewSlider changeView={changeView} reviews={data.reviews} />
            </div>
        </div>

    )
}

export default ReviewSection