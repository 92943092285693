import React, { useEffect, useState } from 'react'
import { Link, Navigate } from 'react-router-dom'
import NavigationDesktop from '../components-desktop/Navigation/NavigationDesktop';
import NavigationBar from './Navigation/navigationBar/NavigationBar';

import deskBg from '../images/backgroundError.png';
import mobileBg from '../images/backgroundErrorM.png';

import vector from '../images/VectError.png';
import vectorM from '../images/VectErrorM.png';

function Redirect() {

    const [changeView, setChangeView] = useState(true)

    useEffect(() => {
        if (window.innerWidth <= 1000) {
            setChangeView(false);
        } else {
            setChangeView(true);
        }
    }, []);

    return (
        <div className="w-screen h-screen relative overflow-y-auto" style={{ backgroundImage: `url(${changeView ? deskBg : mobileBg})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}>
            {changeView ? (
                <NavigationDesktop
                    submissionType={"none"}
                    submissionText={"Download Our App"} />
            ) : (
                <NavigationBar btn={"training"} homePage={true} page={"white"} />
            )}
            {changeView ?
                (
                    <div className='mx-auto max-w-7xl xl:px-0 px-[60px]  flex justify-between items-center '>
                        <div className='flex-2/3 gap-12 flex flex-col'>
                            <h1 className='text-[65px] font-semibold text-white'>Ooops No Page Found!!</h1>
                            <div className='desktop-navigation-booking-button'>
                                <div className="">
                                    <Link to="/">
                                        <button className="booksession-navigation-desktop">{"Back to Home"}</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className='flex-1/3'>
                            <img className='h-full' alt={'404'} src={vector} style={{ height: '500px' }} />
                        </div>
                    </div>
                ) :
                <div className='px-[60px] flex flex-col justify-center items-center gap-10 mt-10'>
                    <h1 className='text-[36px] font-semibold text-white text-center'>Ooops No Page Found!!</h1>
                    <img alt={'404'} src={vectorM} style={{ width: '300px', height: 'auto' }} />
                    <div className='desktop-navigation-booking-button'>
                        <div className="">
                            <Link to="/">
                                <button className="booksession-navigation-desktop">{"Back to Home"}</button>
                            </Link>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
export default Redirect