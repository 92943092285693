import { BsArrowUp } from "@react-icons/all-files/bs/BsArrowUp";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import LazyLoad from "react-lazyload";
import { Link } from "react-router-dom";
import NavigationDesktop from "../components-desktop/Navigation/NavigationDesktop";
import "./Adoption.css";
import GoToTop from "./GoToTop";
import NavigationBar from "./Navigation/navigationBar/NavigationBar";
import PopupAdoption from "./PopupAdoption";
import TimelineComponent from "./TimelineComponent";
import ConnectWithUs from "./ConnectWithUs";

function Adoption(props) {
  const data = [
    {
      title: "Why should we choose Petmojo?",
      content:
        "Our expert trainers have up to 20+ years of training experience. Moreover, we are super empathetic towards your furry friend using only positive reinforcement to re-model behavior. Our mission is to improve your lifelong relationship with your pet by making it more harmonious. We are not money-driven and care deeply about overall animal welfare. ",
    },
    {
      title: "Who will be my pet’s Trainer?",
      content:
        "PetMojo’s in-house trainer is experienced, reliable, and verified through a comprehensive background check. Our trainers are focused on delivering results with positive and reward-based training. ",
    },
    {
      title: "In what areas do you provide services?",
      content:
        "We provide services in Delhi, Gurgaon, Noida, Greater Noida, Ghaziabad, Chandigarh, Mumbai, Bangalore, Pune, Kolkata, Chennai, and Bhopal at the moment. ",
    },
    {
      title: "What Breeds of Dogs do you Train?",
      content:
        "We have trained all breeds of dogs. You would be hard-pressed to find a breed that we have not trained – from Chihuahua to Great Dane, big or small, we train them all! ",
    },
    // {
    //   title: "What is the Cancellation and Refund Policy",
    //   content:
    //     "We will refund 100% of the remaining sessions – no questions asked. To claim the refund please contact our customer support team.",
    // },
  ];
  const timelineData = [
    {
      title: "Choose the Puppy",
      text: "Choose a pet from available breeds ",
      //   route: "/services",
    },
    {
      title: "Meet at our Boarding Center",
      text: "Choose the date of your convenience and visit our Boarding Center",
      //   route: "/grooming",
      //   type: "grooming",
    },
    {
      title: "Register and Pay the amount",
      text: "Fill the registration form and go through the Payment procedure.",
      //   link: "https://play.google.com/store/apps/details?id=in.tamely.user",
    },
  ];
  const [navDropdown, setNavDropdown] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [changeView, setChangeView] = useState(false);

  const dropdownRef = useRef(null);

  function goToTopHandler() {
    return window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  const scrollToTop = () => {
    return window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const path = window.location.pathname;
  useEffect(() => {
    if (window.innerWidth < 1000) {
      setChangeView(false);
    } else {
      setChangeView(true);
    }
  }, []);
  const showModal = () => {
    // Get the modal
    var modal = document.getElementById("myModal");
    modal.style.display = "block"
    // Get the button that opens the modal
    var btn = document.getElementById("myBtn");

    // Get the <span> element that closes the modal
    var span = document.getElementsByClassName("close")[0];

    // // When the user clicks the button, open the modal 
    // btn.onclick = function() {
    //   modal.style.display = "block";
    // }

    // When the user clicks on <span> (x), close the modal
    span.onclick = function () {
      modal.style.display = "none";
    }

    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function (event) {
      if (event.target == modal) {
        modal.style.display = "none";
      }
    }
  }
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setNavDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const sectionOneRef = useRef(null);
  const sectionTwoRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // console.log('Section is now visible');
          const ele1 = document.getElementById("section1");
          const ele2 = document.getElementById("section2");
          // console.log(ele);
          ele1.classList.add("appstore");
          ele2.classList.add("playstore");
          // Trigger the section action
        }
      },
      { rootMargin: "0px 0px -50% 0px" }
    );
    if (sectionOneRef.current) {
      observer.observe(sectionOneRef.current);
    }
    return () => {
      if (sectionOneRef.current) {
        observer.unobserve(sectionOneRef.current);
      }
    };
  }, []);
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        // console.log('Section is now visible');
        const ele1 = document.getElementById("section3");
        const ele2 = document.getElementById("section4");
        // console.log(ele);
        ele1.classList.add("appstore");
        ele2.classList.add("playstore");
        // Trigger the section action
      }
    });
    if (sectionTwoRef.current) {
      observer.observe(sectionTwoRef.current);
    }
    return () => {
      if (sectionTwoRef.current) {
        observer.unobserve(sectionTwoRef.current);
      }
    };
  }, []);
  function goToTopHandler() {
    return window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }
  return (
    <div>
      <Helmet>
        <title>Adoption | Petmojo</title>
        <meta
          name="description"
          content="We are here for you if you need any help."
        />
      </Helmet>

      {!changeView ? (
        <div className="help">
          <div className="adoption-page-mobile-view-intro-container">
            <img
              alt="helpbackground"
              className="help-page-mobile-view-background-image"
              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/adoption-bg-image.jpg"
              style={{ objectFit: "cover" }}
            />
            <div class="gradient-overlay"></div>
            <div className="help-page-mobile-view-intro-info-section">
              <NavigationBar
                page={"white"}
                className="home-page-mobile-navigation-container"
              />
              <div className="help-page-mobile-view-intro-info" style={{ marginTop: "20px " }}>
                <div className="help-page-mobile-view-header-container">
                  <div
                    className="help-page-mobile-view-header-1"
                    style={{ fontSize: "30px" }}
                  >
                    Every Pet Deserves <br /> a Loving Home.
                    <br /> <span className="golden-text">Adopt</span> a Pet
                    Today
                  </div>
                </div>

                <div className="help-page-mobile-view-intro-points-container" style={{ marginTop: "10px" }}>
                  <div className="adoption-home-page-intro-mobile-view-point">
                    <div style={{ fontSize: "18px" }}>
                      Browse our available animals and learn more about the
                      adoption process.
                    </div>
                  </div>
                  <div className="adoption-home-page-intro-mobile-view-point">
                    <div style={{ fontSize: "18px" }}>
                      Together, we can{" "}
                      <span className="golden-text">
                        Rescue, Rehabilitate, and Rehome
                      </span>{" "}
                      pets in need.
                    </div>
                  </div>
                  <div className="adoption-home-page-intro-mobile-view-point">
                    <div style={{ fontSize: "18px" }}>
                      Thank you for supporting our mission to bring joy to
                      families through pet adoption.
                    </div>
                  </div>
                </div>

                <div
                  className="help-page-mobile-view-intro-phone-container"
                  style={{ marginTop: "0px", height: "auto", gap: "5px" }}
                >
                  <div className="help-page-desktop-view-intro-bottom-section-container adoption-button-container" style={{ backgroundColor: "rgba(192, 156, 62, 1)", height: "60px", display: "flex", flexDirection: "column", justifyContent: "center", fontSize: "18px" }} onClick={showModal}>
                    <div>
                      Adopt Now
                    </div>
                  </div>
                  <div className="help-page-desktop-view-intro-bottom-section-container">
                    <div>Call us on</div>
                    <div>888 999 18 00</div>
                  </div>
                  <div className="help-page-desktop-view-intro-bottom-section-container" style={{ marginBottom: "10px" }}>
                    <div>Email us on</div>
                    <div>customercare@petmojo.in</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="adoption-page-pets-description-mobile">
            <div
              className="help-page-desktop-view-intro-head"
              style={{ fontSize: "20px", textAlign: "center", color: "white" }}
            >
              Note: <span className="golden-text">KCI Registration</span> will
              be done alongside.
            </div>
            <div className="adoption-page-pets-section-mobile">
              {/* <Link to="/popup-adoption"> */}
              <div className="" style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "10px", alignItems: "center" }}>
                <div className="adoption-page-desktop-view-standard-image-conatiner1-mobile" onClick={showModal}>
                  <div className="adoption-price-tag-out">

                  </div>
                  <div className="adoption-price-tag">
                    <span className="adoption-price">
                      ₹19,980
                    </span>
                  </div>

                  <div className="adoption-page-content">
                    <div className="adoption-text-mobile">
                      +₹5,000 With 1 Year vaccination Protection (If Needed)
                    </div>
                    <div className="adoption-desc">
                      <div className="adoption-pet-name-mobile">
                        Shiz Tzu
                      </div>
                      <div className="adoption-desc-text">
                        <div className="adoption-details-mobile">
                          <div>Gender</div>
                          <div>Age</div>
                          <div>Location</div>
                        </div>
                        <div className="adoption-details-bold-mobile">
                          <div>Male & Female</div>
                          <div>2.5 Months</div>
                          <div>Delhi NCR</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="help-page-desktop-view-intro-bottom-section-container adoption-button-container" style={{ fontSize: "18px", backgroundColor: "rgba(192, 156, 62, 1)", display: "flex", flexDirection: "column", justifyContent: "center", marginRight: "0px" }} onClick={showModal}>
                  <button className="adopt-now-button">Adopt Now</button>
                </div>


              </div>
              <div id="myModal" class="modal">

                <div class="modal-content-mobile">
                  <span class="close">&times;</span>
                  <div style={{ marginTop: "20px", padding: "0px", }}>
                    <PopupAdoption />
                  </div>
                </div>

              </div>
              {/* </Link> */}
              {/* <Link to="/popup-adoption"> */}
              <div className="" style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "10px", alignItems: "center" }}>
                <div className="adoption-page-desktop-view-standard-image-conatiner2-mobile" onClick={showModal}>
                  <div className="adoption-price-tag-out">

                  </div>
                  <div className="adoption-price-tag">
                    <span className="adoption-price">
                      ₹16,680
                    </span>
                  </div>
                  <div className="adoption-page-content">
                    <div className="adoption-text-mobile">
                      +₹5,000 With 1 Year vaccination Protection (If Needed)
                    </div>
                    <div className="adoption-desc">
                      <div className="adoption-pet-name-mobile">
                        Golden Retriever
                      </div>
                      <div className="adoption-desc-text">
                        <div className="adoption-details-mobile">
                          <div>Gender</div>
                          <div>Age</div>
                          <div>Location</div>
                        </div>
                        <div className="adoption-details-bold-mobile">
                          <div>Male</div>
                          <div>3 Months</div>
                          <div>Delhi NCR</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="help-page-desktop-view-intro-bottom-section-container adoption-button-container" style={{ fontSize: "18px", backgroundColor: "rgba(192, 156, 62, 1)", display: "flex", flexDirection: "column", justifyContent: "center", marginRight: "0px" }} onClick={showModal}>
                  <button className="adopt-now-button">Adopt Now</button>
                </div>
              </div>
              {/* </Link> */}
              {/* <Link to="/popup-adoption"> */}
              <div className="" style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "10px", alignItems: "center" }}>
                <div className="adoption-page-desktop-view-standard-image-conatiner3-mobile" onClick={showModal}>
                  <div className="adoption-price-tag-out">

                  </div>
                  <div className="adoption-price-tag">
                    <span className="adoption-price">
                      ₹19,980
                    </span>
                  </div>
                  <div className="adoption-page-content">
                    <div className="adoption-text-mobile">
                      +₹5,000 With 1 Year vaccination Protection (If Needed)
                    </div>
                    <div className="adoption-desc">
                      <div className="adoption-pet-name-mobile">
                        Labrador retriever
                      </div>
                      <div className="adoption-desc-text">
                        <div className="adoption-details-mobile">
                          <div>Gender</div>
                          <div>Age</div>
                          <div>Location</div>
                        </div>
                        <div className="adoption-details-bold-mobile">
                          <div>Male</div>
                          <div>3 Months</div>
                          <div>Delhi NCR</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="help-page-desktop-view-intro-bottom-section-container adoption-button-container" style={{ fontSize: "18px", backgroundColor: "rgba(192, 156, 62, 1)", display: "flex", flexDirection: "column", justifyContent: "center", marginRight: "0px" }} onClick={showModal}>
                  <button className="adopt-now-button">Adopt Now</button>
                </div>
              </div>
              {/* </Link> */}
              {/* <Link to="/popup-adoption"> */}
              <div className="" style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "10px", alignItems: "center" }}>
                <div className="adoption-page-desktop-view-standard-image-conatiner4-mobile" onClick={showModal}>
                  <div className="adoption-price-tag-out">

                  </div>
                  <div className="adoption-price-tag">
                    <span className="adoption-price">
                      At Liberty
                    </span>
                  </div>
                  <div className="adoption-page-content">
                    <div className="adoption-text-mobile">
                      +₹5,000 With 1 Year vaccination Protection (If Needed)
                    </div>
                    <div className="adoption-desc">
                      <div className="adoption-pet-name-mobile">
                        Indie
                      </div>
                      <div className="adoption-desc-text">
                        <div className="adoption-details-mobile">
                          <div>Gender</div>
                          <div>Age</div>
                          <div>Location</div>
                        </div>
                        <div className="adoption-details-bold-mobile">
                          <div>Male</div>
                          <div>4 Months</div>
                          <div>Delhi NCR</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="help-page-desktop-view-intro-bottom-section-container adoption-button-container" style={{ fontSize: "18px", backgroundColor: "rgba(192, 156, 62, 1)", display: "flex", flexDirection: "column", justifyContent: "center", marginRight: "0px" }} onClick={showModal}>
                  <button className="adopt-now-button">Adopt Now</button>
                </div>
              </div>
              {/* </Link>  */}
              <div className="adoption-page-desktop-view-standard-image-conatiner5-mobile">
                <div className="adoption-price-tag-out">

                </div>
                <div className="adoption-price-tag">
                  <span className="adoption-price">
                    Reserved
                  </span>
                </div>
                <div className="adoption-page-content">
                  <div className="adoption-text-mobile">
                    +₹5,000 With 1 Year vaccination Protection (If Needed)
                  </div>
                  <div className="adoption-desc">
                    <div className="adoption-pet-name-mobile">
                      Siberian Husky
                    </div>
                    <div className="adoption-desc-text">
                      <div className="adoption-details-mobile">
                        <div>Gender</div>
                        <div>Age</div>
                        <div>Location</div>
                      </div>
                      <div className="adoption-details-bold-mobile">
                        <div>Male</div>
                        <div>5 Months</div>
                        <div>Delhi NCR</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="adoption-page-desktop-view-standard-image-conatiner6-mobile">
                <div className="adoption-price-tag-out">

                </div>
                <div className="adoption-price-tag">
                  <span className="adoption-price">
                    Sold Out
                  </span>
                </div>
                <div className="adoption-page-content">
                  <div className="adoption-text-mobile">
                    +₹5,000 With 1 Year vaccination Protection (If Needed)
                  </div>
                  <div className="adoption-desc">
                    <div className="adoption-pet-name-mobile">
                      Pug
                    </div>
                    <div className="adoption-desc-text">
                      <div className="adoption-details-mobile">
                        <div>Gender</div>
                        <div>Age</div>
                        <div>Location</div>
                      </div>
                      <div className="adoption-details-bold-mobile">
                        <div>Male</div>
                        <div>3.5 Months</div>
                        <div>Delhi NCR</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="adoption-page-desktop-view-standard-image-container-mobile">
            <div
              className="help-page-desktop-view-intro-head"
              style={{
                fontSize: "30px",
                color: "white",
                textAlign: "center",
                marginBottom: "40px",
              }}
            >
              Why Petmojo?
            </div>
            <div className="adoption-page-why-petmojo-content-mobile">
              <div className="boarding-page-desktop-view-standard-image-conatiner">
                <img
                  className="boarding-standard-desktop-img"
                  alt="imgContainer"
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8992_bhtkeq.png"
                />
                <div className="boarding-page-desktop-view-standard-inner-text1">
                  Ethical Pet Parents as in Caring, Qualified, & Knowledgeable{" "}
                </div>
              </div>
              <div className="boarding-page-desktop-view-standard-image-conatiner">
                <img className="boarding-standard-desktop-img" alt="imgContainer" src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8993_hia6jl.png" />
                <div className="boarding-page-desktop-view-standard-inner-text1">Ethical Breeders Only Provides complete, accurate health records </div>
              </div>
              <div className="boarding-page-desktop-view-standard-image-conatiner">
                <img
                  className="boarding-standard-desktop-img"
                  alt="imgContainer"
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8989_pq4vqi.png"
                />
                <div className="boarding-page-desktop-view-standard-inner-text1">
                  Rigorous background check of litters.{" "}
                </div>
              </div>
              <div className="boarding-page-desktop-view-standard-image-conatiner">
                <img
                  className="boarding-standard-desktop-img"
                  alt="imgContainer"
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8990_ofahvl.png"
                />
                <div className="boarding-page-desktop-view-standard-inner-text1">
                  Available in popular cities Mumbai, Bangalore & Delhi.{" "}
                </div>
              </div>
            </div>

          </div>
          <div className="container-home pink-container-family">
            <div className="textbox2">How it works?</div>
            <hr className="vector1"></hr>
            <div>
              <TimelineComponent
                version={"mobile"}
                timelineData={timelineData}
              />
            </div>
          </div>
          <div className="pink-container" style={{ marginTop: "0px" }}>
            <div className="textbox2" style={{ marginTop: "10px" }}>
              Download our App Now
            </div>
            <hr className="vector2"></hr>
            <div className="iphone-container">
              <div>
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/iphone-icon_cxyipc.png"
                    alt="iphoneicon"
                    className="iphone-icon"
                  ></img>
                </LazyLoad>
              </div>
              <div className="download-btn-container">
                <div className="playstore-icon">
                  <a
                    target="_blank" rel="nofollow"
                    href="https://play.google.com/store/apps/details?id=in.tamely.user"
                  >
                    <LazyLoad>
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/playstore_ordt0r.png"
                        alt="playstore"
                        className="playstore"
                      ></img>
                    </LazyLoad>
                  </a>
                </div>
                <div className="appstore-icon">
                  <a
                    target="_blank" rel="nofollow"
                    href="https://apps.apple.com/in/app/petmojo/id1609861328"
                  >
                    <LazyLoad>
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/appstore_lmgm04.png"
                        alt="appstore"
                        className="appstore"
                      ></img>
                    </LazyLoad>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="textbox2">Connect With Us</div>
            <hr className="vector2"></hr>

            <div className="ellipse-container">
              <div className="ellipse">
                {/* <LazyLoad> */}
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/outer-ring.png"
                  alt="outerring"
                  className="outer-ring"
                ></img>
                {/* </LazyLoad> */}
                {/* <LazyLoad> */}
                <img
                  src={
                    "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/inner-circle.png"
                  }
                  alt="innerring"
                  className="inner-ring"
                ></img>
                {/* </LazyLoad> */}
                <button className="youtube-icon">
                  <a
                    href="https://youtube.com/channel/UCPQrCllizVvTOkSk7_1SIHw"
                    target="_blank"
                    rel="nofollow"
                  >
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/yt-icon.png"
                      alt="youtubeicon"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
                <button className="facebook-icon">
                  <a
                    href="https://www.facebook.com/petmojo.care/"
                    target="_blank"
                    rel="nofollow"
                  >
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/fb-icoc.png"
                      alt="facebookicon"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
                <button className="twitter-icon">
                  <a href="https://twitter.com/Petmojo_App">
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/twitter-icon.png"
                      alt="twittericon"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
                <button className="instagram-icon">
                  <a
                    href="https://www.instagram.com/petmojo.care/"
                    target="_blank"
                    rel="nofollow"
                  >
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/insta-icon.png"
                      alt="instagramicon"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
                <button className="phone-icon">
                  <a href="tel:+91 8889991800" target="_blank" rel="nofollow">
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/phone-icon.png"
                      alt="phoneicon"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
                <button className="gmail-icon">
                  <a
                    href="https://petmojo.care/support/?v=c86ee0d9d7ed"
                    target="_blank"
                    rel="nofollow"
                  >
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/gm-icon.png"
                      alt="gmailicon"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
                <button className="whatsapp-icon">
                  <a href="tel:+91 8889991800" target="_blank" rel="nofollow">
                    {/* <LazyLoad> */}
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/whatsapp-icon.png"
                      alt="whatsappicon"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
              </div>
            </div>
          </div>
          <div>
            <div className="mobile-view-footer-1">
              <div className="mobile-view-footer-1-left">
                <div className="mobile-view-footer-1-left-header-container">
                  <div className="mobile-view-footer-1-header">Need Help</div>
                  <hr className="vector2-footer"></hr>
                </div>
                <div className="mobile-view-footer-1-content-left">
                  <div className="mobile-view-footer-phone-container">
                    <div className="mobile-view-footer-phone-icon">
                      <img
                        alt="telIcon"
                        className="mobile-view-tel-icon"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pink-phone-icon.png"
                      />
                    </div>
                    <div className="">
                      <a
                        className="mobile-view-footer-phone-text"
                        style={{ textDecoration: "none" }}
                        href="tel: +91 8000000445"
                        target="_blank" rel="nofollow"
                      >
                        800 000 0445
                      </a>
                    </div>
                  </div>
                  <div className="mobile-view-footer-mail-container">
                    <div className="mobile-view-footer-mail-icon">
                      <img
                        alt="mailIcon"
                        className="mobile-view-mail-icon"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pink-mail-icon.png"
                      />
                    </div>
                    <div className="">
                      <a
                        className="mobile-view-footer-mail-text"
                        style={{ textDecoration: "none" }}
                        href="mailto: customercare@petmojo.in"
                        target="_blank" rel="nofollow"
                      >
                        customercare@petmojo.in
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mobile-view-footer-1-right">
                <div className="mobile-view-footer-1-header">Services</div>
                <hr className="vector2-footer"></hr>
                <div className="mobile-view-footer-1-right-content">
                  <Link
                    className="mobile-view-footer-page-link"
                    style={{ textDecoration: "none" }}
                    to="/dog-running"
                    state={{ from: "help" }}
                  >
                    Dog Exercise/Walking
                  </Link>
                </div>
                <div className="mobile-view-footer-1-right-content">
                  <Link
                    className="mobile-view-footer-page-link"
                    style={{ textDecoration: "none" }}
                    to="/dog-training"
                    state={{ from: "help" }}
                  >
                    Dog Training
                  </Link>
                </div>
                <div className="mobile-view-footer-1-right-content">
                  <Link
                    className="mobile-view-footer-page-link"
                    style={{ textDecoration: "none" }}
                    to="/grooming"
                    state={{ from: "help" }}
                  >
                    Pet Grooming
                  </Link>
                </div>
                <div className="mobile-view-footer-1-right-content">
                  <Link
                    className="mobile-view-footer-page-link"
                    style={{ textDecoration: "none" }}
                    to="/pet-boarding"
                    state={{ from: "help" }}
                  >
                    Pet Hotel
                  </Link>
                </div>
                {/* <div className='mobile-view-footer-1-right-content'><Link className='mobile-view-footer-page-link' style={{textDecoration: "none"}} to="/styling" state={{from : "help"}}>Pet Styling</Link></div> */}
              </div>
            </div>

            <div className="mobile-view-footer-2">
              <div className="mobile-view-footer-2-left">
                <div className="footerDetailsHeading-mobile">Serving In</div>
                <hr className="footerDetailsLine"></hr>
                <div className="footerDetailsContentContainer-mobile">
                  <div>
                    <div className="footerDetailsLink-mobile">Delhi</div>
                    <div className="footerDetailsLink-mobile">Gurgaon</div>
                    <div className="footerDetailsLink-mobile">Noida</div>
                    <div className="footerDetailsLink-mobile">Ghaziabad</div>
                  </div>
                  <div>
                    <div className="footerDetailsLink-mobile">Faridabad</div>
                    <div className="footerDetailsLink-mobile">Manesar</div>
                    <div className="footerDetailsLink-mobile">Mumbai</div>
                  </div>
                </div>
              </div>
              <div className="mobile-view-footer-2-right">
                <div className="mobile-view-footer-2-right-header">More</div>
                <hr className="vector2-footer"></hr>
                <div className="mobile-view-links-div">
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "rgba(132, 132, 132, 1)",
                    }}
                    to="/terms"
                  >
                    <div className="mobile-view-footer-2-tnc">
                      Terms and Conditions
                    </div>
                  </Link>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "rgba(132, 132, 132, 1)",
                    }}
                    to="/privacy"
                  >
                    <div className="mobile-view-footer-2-tnc">
                      Privacy Policy
                    </div>
                  </Link>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "rgba(132, 132, 132, 1)",
                    }}
                    to="/partner"
                  >
                    <div className="mobile-view-footer-2-left-content">
                      Become a Partner
                    </div>
                  </Link>{" "}
                  <div className="mobile-view-footer-2-left-content">
                    Privacy Policy - Partner
                  </div>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "rgba(132, 132, 132, 1)",
                    }}
                    to="/customer-support-policy"
                  >
                    <div className="mobile-view-footer-2-left-content">
                      Customer Support Policy
                    </div>
                  </Link>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "rgba(132, 132, 132, 1)",
                    }}
                    to="/reviews"
                  >
                    <div className="mobile-view-footer-2-left-content">
                      Petmojo Reviews
                    </div>
                  </Link>
                </div>
              </div>
            </div>

            <div className="mobile-footer-wrap">
              <div className="mobile-view-footer-3" style={{ gap: "5px" }}>
                <div
                  className="mobile-view-footer-3-btn1"
                  style={{ height: "33px" }}
                >
                  <a
                    href="https://apps.apple.com/in/app/petmojo/id1609861328"
                    target="_blank" rel="nofollow"
                  >
                    <img
                      className="mobile-view-footer-appStore-icon appstore"
                      alt="appStore"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/app-store-2.png"
                    />
                  </a>
                </div>
                <div
                  className="mobile-view-footer-3-btn2"
                  style={{ height: "33px" }}
                >
                  <a
                    href=" https://play.google.com/store/apps/details?id=in.tamely.user"
                    target="_blank" rel="nofollow"
                  >
                    <img
                      className="mobile-view-footer-appStore-icon playstore"
                      alt="playStore"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/play-store-2.png"
                    />
                  </a>
                </div>
                <div
                  onClick={() => goToTopHandler()}
                  className="mobile-view-footer-3-btn3"
                  style={{
                    height: "33px",
                    padding: "5px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {/* <div>
                  Go to the top
                </div>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
                  </svg>
                </div> */}
                  <span>Go to the top</span>
                  <BsArrowUp />
                </div>
              </div>
            </div>

            <div
              className="mobile-view-footer-4"
              style={{ marginBottom: "20px" }}
            >
              <div>Copyright 2024 Petmojo</div>
              <div>
                All copyrights are reserved - any infringement will be legally
                pursued.
              </div>
              <div>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="help-page-desktop-view-master-container">
          {/* <img src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/adoption-bg-image.jfif" alt="introImage" className="about-page-main-image-desktop" /> */}
          <div className="adoption-page-container">
            <div className="adoption-page-desktop-view-intro-container">
              <NavigationDesktop
                submissionType={"none"}
                submissionText={"Download Our App"}
              />

              <div className="adoption-page-desktop-view-intro-content-master" >
                <div className="help-page-desktop-view-intro-head-container">
                  <div
                    className="help-page-desktop-view-intro-head"
                    style={{ fontSize: "50px" }}
                  >
                    Every Pet Deserves <br /> a Loving Home.{" "}
                    <span className="golden-text">Adopt</span> a<br /> Pet Today
                  </div>
                </div>

                <div className="help-page-desktop-view-intro-points-container">
                  <div
                    className="intro-desktop-help-page-points-p1"
                    style={{ marginBottom: "10px" }}
                  >
                    <div>
                      Browse our available animals and learn more about the
                      adoption process.
                    </div>
                  </div>
                  <div
                    className="intro-desktop-help-page-points-p1"
                    style={{ marginBottom: "10px" }}
                  >
                    <div>
                      Together, we can{" "}
                      <span className="golden-text">
                        Rescue, Rehabilitate, and Rehome
                      </span>{" "}
                      pets in need.
                    </div>
                  </div>
                  <div className="intro-desktop-help-page-points-p1">
                    <div>
                      Thank you for supporting our mission to bring joy to
                      families through pet adoption.
                    </div>
                  </div>
                </div>

                <div className="adoption-page-desktop-view-intro-bottom-section" style={{ paddingBottom: "40px" }}>
                  <div className="help-page-desktop-view-intro-bottom-section-container adoption-button-container" style={{ fontSize: "18px", backgroundColor: "rgba(192, 156, 62, 1)", display: "flex", flexDirection: "column", justifyContent: "center" }} onClick={showModal}>
                    <button className="adopt-now-button">Adopt Now</button>
                  </div>
                  <div className="help-page-desktop-view-intro-bottom-section-container">
                    <div>Call us on</div>
                    <div>800-000-0445</div>
                  </div>
                  <div className="help-page-desktop-view-intro-bottom-section-container">
                    <div>Email us on</div>
                    <div>customercare@petmojo.in</div>
                  </div>
                </div>
              </div>
              {/* <div className="adoption-page-content">
                
            </div> */}
            </div>
          </div>
          <div className="adoption-page-pets-description" >
            <div
              className="help-page-desktop-view-intro-head"
              style={{ fontSize: "45px", textAlign: "center", color: "white", marginBottom: "80px" }}
            >
              Note: <span className="golden-text">KCI Registration</span> will
              be done alongside.
            </div>
            <div className="adoption-page-pets-section">
              <div className="adoption-page-pets-section-contents">
                {/* <Link to="/popup-adoption"> */}
                <div className="" style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "10px", alignItems: "center" }}>
                  <div className="adoption-page-desktop-view-standard-image-conatiner1" onClick={showModal}
                  >
                    <div className="adoption-price-tag-out">

                    </div>
                    <div className="adoption-price-tag">
                      <span className="adoption-price">
                        ₹19,980
                      </span>
                    </div>

                    <div className="adoption-page-content">
                      <div className="adoption-text">
                        +₹5,000 With 1 Year vaccination Protection (If Needed)
                      </div>
                      <div className="adoption-desc">
                        <div className="adoption-pet-name">
                          Shiz Tzu
                        </div>
                        <div className="adoption-desc-text">
                          <div className="adoption-details">
                            <div>Gender</div>
                            <div>Age</div>
                            <div>Location</div>
                          </div>
                          <div className="adoption-details-bold">
                            <div>Male & Female</div>
                            <div>2.5 Months</div>
                            <div>Delhi NCR</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="help-page-desktop-view-intro-bottom-section-container adoption-button-container" style={{ fontSize: "18px", backgroundColor: "rgba(192, 156, 62, 1)", display: "flex", flexDirection: "column", justifyContent: "center", marginRight: "0px" }} onClick={showModal}>
                    <button className="adopt-now-button">Adopt Now</button>
                  </div>
                </div>


                {/* </Link>  */}
                <div id="myModal" class="modal">

                  <div class="modal-content">
                    <span class="close">&times;</span>
                    <div style={{ marginTop: "10px", padding: "20px 20px", }}>
                      <PopupAdoption />
                    </div>
                  </div>

                </div>
                {/* <Link to="/popup-adoption"> */}
                <div className="" style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "10px", alignItems: "center" }}>
                  <div className="adoption-page-desktop-view-standard-image-conatiner2" onClick={showModal}>
                    <div className="adoption-price-tag-out">

                    </div>
                    <div className="adoption-price-tag">
                      <span className="adoption-price">
                        ₹19,980
                      </span>
                    </div>
                    <div className="adoption-page-content">
                      <div className="adoption-text">
                        +₹5,000 With 1 Year vaccination Protection (If Needed)
                      </div>
                      <div className="adoption-desc">
                        <div className="adoption-pet-name">
                          Labrador retriever
                        </div>
                        <div className="adoption-desc-text">
                          <div className="adoption-details">
                            <div>Gender</div>
                            <div>Age</div>
                            <div>Location</div>
                          </div>
                          <div className="adoption-details-bold">
                            <div>Male</div>
                            <div>3 Months</div>
                            <div>Delhi NCR</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="help-page-desktop-view-intro-bottom-section-container adoption-button-container" style={{ fontSize: "18px", backgroundColor: "rgba(192, 156, 62, 1)", display: "flex", flexDirection: "column", justifyContent: "center", marginRight: "0px" }} onClick={showModal}>
                    <button className="adopt-now-button">Adopt Now</button>
                  </div>
                </div>
                {/* </Link>  */}
                <div className="" style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "10px", alignItems: "center" }}>
                  <div className="adoption-page-desktop-view-standard-image-conatiner3">
                    <div className="adoption-price-tag-out">

                    </div>
                    <div className="adoption-price-tag">
                      <span className="adoption-price">
                        Reserved
                      </span>
                    </div>
                    <div className="adoption-page-content">
                      <div className="adoption-text">
                        +₹5,000 With 1 Year vaccination Protection (If Needed)
                      </div>
                      <div className="adoption-desc">
                        <div className="adoption-pet-name">
                          Siberian Husky
                        </div>
                        <div className="adoption-desc-text">
                          <div className="adoption-details">
                            <div>Gender</div>
                            <div>Age</div>
                            <div>Location</div>
                          </div>
                          <div className="adoption-details-bold">
                            <div>Male</div>
                            <div>5 Months</div>
                            <div>Delhi NCR</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="help-page-desktop-view-intro-bottom-section-container adoption-button-container" style={{fontSize:"18px",backgroundColor:"rgba(192, 156, 62, 1)",display:"flex",flexDirection:"column",justifyContent:"center",marginRight:"0px"}} onClick={showModal}>
                    <div>Adopt Now</div>
                  </div> */}
                </div>
              </div>
              <div className="adoption-page-pets-section-contents">
                {/* <Link  to="/popup-adoption"> */}
                <div className="" style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "10px", alignItems: "center" }}>
                  <div className="adoption-page-desktop-view-standard-image-conatiner4" onClick={showModal}>
                    <div className="adoption-price-tag-out">

                    </div>
                    <div className="adoption-price-tag">
                      <span className="adoption-price">
                        ₹16,680
                      </span>
                    </div>
                    <div className="adoption-page-content">
                      <div className="adoption-text">
                        +₹5,000 With 1 Year vaccination Protection (If Needed)
                      </div>
                      <div className="adoption-desc">
                        <div className="adoption-pet-name">
                          Golden Retriever
                        </div>
                        <div className="adoption-desc-text">
                          <div className="adoption-details">
                            <div>Gender</div>
                            <div>Age</div>
                            <div>Location</div>
                          </div>
                          <div className="adoption-details-bold">
                            <div>Male</div>
                            <div>3 Months</div>
                            <div>Delhi NCR</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="help-page-desktop-view-intro-bottom-section-container adoption-button-container" style={{ fontSize: "18px", backgroundColor: "rgba(192, 156, 62, 1)", display: "flex", flexDirection: "column", justifyContent: "center", marginRight: "0px" }} onClick={showModal}>
                    <button className="adopt-now-button">Adopt Now</button>
                  </div>
                </div>
                {/* </Link> */}
                {/* <Link to="/popup-adoption"> */}
                <div className="" style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "10px", alignItems: "center" }}>
                  <div className="adoption-page-desktop-view-standard-image-conatiner5" onClick={showModal}>
                    <div className="adoption-price-tag-out">

                    </div>
                    <div className="adoption-price-tag">
                      <span className="adoption-price">
                        At Liberty
                      </span>
                    </div>
                    <div className="adoption-page-content">
                      <div className="adoption-text">
                        +₹5,000 With 1 Year vaccination Protection (If Needed)
                      </div>
                      <div className="adoption-desc">
                        <div className="adoption-pet-name">
                          Indie
                        </div>
                        <div className="adoption-desc-text">
                          <div className="adoption-details">
                            <div>Gender</div>
                            <div>Age</div>
                            <div>Location</div>
                          </div>
                          <div className="adoption-details-bold">
                            <div>Male</div>
                            <div>4 Months</div>
                            <div>Delhi NCR</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="help-page-desktop-view-intro-bottom-section-container adoption-button-container" style={{ fontSize: "18px", backgroundColor: "rgba(192, 156, 62, 1)", display: "flex", flexDirection: "column", justifyContent: "center", marginRight: "0px" }} onClick={showModal}>
                    <button className="adopt-now-button">Adopt Now</button>
                  </div>
                </div>
                {/* </Link> */}
                <div className="" style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "10px", alignItems: "center" }}>
                  <div className="adoption-page-desktop-view-standard-image-conatiner6">
                    <div className="adoption-price-tag-out">

                    </div>
                    <div className="adoption-price-tag">
                      <span className="adoption-price">
                        Sold Out
                      </span>
                    </div>
                    <div className="adoption-page-content">
                      <div className="adoption-text">
                        +₹5,000 With 1 Year vaccination Protection (If Needed)
                      </div>
                      <div className="adoption-desc">
                        <div className="adoption-pet-name">
                          Pug
                        </div>
                        <div className="adoption-desc-text">
                          <div className="adoption-details">
                            <div>Gender</div>
                            <div>Age</div>
                            <div>Location</div>
                          </div>
                          <div className="adoption-details-bold">
                            <div>Male</div>
                            <div>3.5 Months</div>
                            <div>Delhi NCR</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="help-page-desktop-view-intro-bottom-section-container adoption-button-container" style={{fontSize:"18px",backgroundColor:"rgba(192, 156, 62, 1)",display:"flex",flexDirection:"column",justifyContent:"center",marginRight:"0px"}} onClick={showModal}>
                    <div>Adopt Now</div>
                  </div> */}
                </div>
              </div>

            </div>
          </div>

          <div className="adoption-page-desktop-view-standard-master-container">
            <div
              className="help-page-desktop-view-intro-head"
              style={{
                fontSize: "50px",
                color: "white",
                textAlign: "center",
                marginBottom: "70px",
              }}
            >
              Why Petmojo?
            </div>
            <div className="adoption-page-desktop-view-standard-upper">
              <div className="boarding-page-desktop-view-standard-image-conatiner">
                <img
                  className="boarding-standard-desktop-img"
                  alt="imgContainer"
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8992_bhtkeq.png"
                />
                <div className="boarding-page-desktop-view-standard-inner-text1">
                  Ethical Pet Parents as in Caring, Qualified, & Knowledgeable{" "}
                </div>
              </div>
              <div className="boarding-page-desktop-view-standard-image-conatiner">
                <img
                  className="boarding-standard-desktop-img"
                  alt="imgContainer"
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8993_hia6jl.png"
                />
                <div className="boarding-page-desktop-view-standard-inner-text1">
                  Ethical Breeders Only Provides complete, accurate health
                  records{" "}
                </div>
              </div>
            </div>
            <div className="adoption-page-desktop-view-standard-lower">
              <div className="boarding-page-desktop-view-standard-image-conatiner">
                <img
                  className="boarding-standard-desktop-img"
                  alt="imgContainer"
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8989_pq4vqi.png"
                />
                <div className="boarding-page-desktop-view-standard-inner-text1">
                  Rigorous background check of litters.{" "}
                </div>
              </div>

              <div className="boarding-page-desktop-view-standard-image-conatiner">
                <img
                  className="boarding-standard-desktop-img"
                  alt="imgContainer"
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8990_ofahvl.png"
                />
                <div className="boarding-page-desktop-view-standard-inner-text1">
                  Available in popular cities Mumbai, Bangalore & Delhi.{" "}
                </div>
              </div>
            </div>
          </div>

          <div>
            <div
              className="container-home-desktop"
              style={{ backgroundColor: "white" }}
            >
              <div>
                <div>
                  <div
                    className="textbox2-desktop"
                    style={{ color: "rgba(41, 49, 60, 1)" }}
                  >
                    How it works?
                  </div>
                </div>
                <div className="line-div">
                  <hr
                    className="live-training-golden-line"
                    style={{ position: "relative", bottom: "0px" }}
                  ></hr>
                </div>

                <div
                  className="get-started-grooming-page-sub-text-container"
                  style={{ position: "relative", bottom: "10px" }}
                >
                  <div
                    className="get-started-grooming-page-sub-text-container1"
                    style={{ color: "rgba(41, 49, 60, 1)" }}
                  >
                    It’s easy as 1-2-3, Follow the steps
                  </div>
                  <div
                    className="get-started-grooming-page-sub-text-container2"
                    style={{ color: "rgba(41, 49, 60, 1)" }}
                  >
                    given and you are good to proceed!
                  </div>
                </div>


              </div>
              <div>
                <TimelineComponent
                  version={"desktop"}
                  timelineData={timelineData}
                />
              </div>
            </div>
          </div>

          <>
            {/* </LazyLoad> */}

            <div className="giveSpace">
              {!changeView ? (
                <div className="pink-container-family">
                  <div className="textbox2">Download Petmojo App</div>
                  <hr className="vector2"></hr>
                </div>
              ) : null}


              <div className="storeContainer">


                <div className="storeContainer_right">
                  <div className="storeContainer_logo_container">
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/petmojo-dark-logo-.png"
                      alt=""
                      style={{ objectFit: "contain" }}
                    />
                  </div>
                  <div
                    className="storeContainer_main_image"
                    style={{ width: "80%" }}
                  >
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/get-petmojo-application.webp"
                      alt=""
                    />
                  </div>

                  <div className="storeContainer_button_container">
                    <a
                      target="_blank" rel="nofollow"
                      href="https://play.google.com/store/apps/details?id=in.tamely.user"
                    >
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/play-store-download.png"
                        alt=""
                      />
                    </a>
                    <a
                      target="_blank" rel="nofollow"
                      href="https://apps.apple.com/in/app/petmojo/id1609861328"
                    >
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/app-store-download.png"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>

            </div>

            <div style={{ margin: "2rem 0rem" }}>

              <ConnectWithUs/>

            </div>


            <div className="footerDetailsContainer">
              <div className="footerDetailsCont1">
                <div className="footerDetailsHeading">Need Help</div>
                <div className="">
                  <hr className="live-training-golden-line"></hr>
                </div>
                <div className="footerDetailsContentContainer">
                  <div className="footer-details-content-call-mail">
                    <div>
                      <img
                        alt="icon"
                        className="icon-desktop-call"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/more-contact-icon.png"
                      />
                    </div>
                    <div>
                      <a
                        className="footerDetailsLink"
                        target="_blank" rel="nofollow"
                        href="tel: 8000000445"
                      >
                        800 000 0445
                      </a>
                    </div>
                  </div>

                  <div className="footer-details-content-call-mail">
                    <div>
                      <img
                        alt="icon"
                        className="icon-desktop-mail"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pink-mail-icon.png"
                      />
                    </div>
                    <div>
                      <a
                        className="footerDetailsLink"
                        target="_blank" rel="nofollow"
                        href="mailto: customercare@petmojo.in"
                      >
                        customercare@petmojo.in
                      </a>
                    </div>
                  </div>
                  <div className="social-icons">
                    <a
                      href="https://www.facebook.com/petmojo.care/"
                      target="_blank" rel="nofollow"
                    >
                      <div>
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/fb.png"
                          alt=""
                        />
                      </div>
                    </a>
                    <a href="https://twitter.com/Petmojo_App" target="_blank" rel="nofollow">
                      <div>
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/tw.png"
                          alt=""
                        />
                      </div>
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCPQrCllizVvTOkSk7_1SIHw"
                      target="_blank" rel="nofollow"
                    >
                      <div>
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/yt.png"
                          alt=""
                        />
                      </div>
                    </a>
                    <a
                      href="https://www.instagram.com/petmojo.care/"
                      target="_blank" rel="nofollow"
                    >
                      <div>
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/ig.png"
                          alt=""
                        />
                      </div>
                    </a>
                    <a
                      href="https://www.linkedin.com/company/petmojo/"
                      target="_blank" rel="nofollow"
                    >
                      <div>
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/linkedin.png"
                          alt=""
                        />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="footerDetailsCont1">
                <div className="footerDetailsHeading">Services</div>
                <div className="">
                  <hr className="live-training-golden-line"></hr>
                </div>
                <div className="footerDetailsContentContainer">
                  <Link
                    className="footerDetailsLink"
                    to="/dog-running"
                    state={{ from: "footer" }}
                  >
                    <div>Daily Dog Exercise/Walking</div>
                  </Link>
                  <Link
                    className="footerDetailsLink"
                    to="/dog-training"
                    state={{ from: "footer" }}
                  >
                    <div>Dog Training</div>
                  </Link>
                  <Link
                    className="footerDetailsLink"
                    to="/grooming"
                    state={{ from: "footer" }}
                  >
                    <div>Pet Grooming</div>
                  </Link>
                  <Link
                    className="footerDetailsLink"
                    to="/grooming-subscription"
                    state={{ from: "footer" }}
                  >
                    <div>Grooming Subscription</div>
                  </Link>
                  <Link
                    className="footerDetailsLink"
                    to="/pet-boarding"
                    state={{ from: "footer" }}
                  >
                    <div>Pet Hotel</div>
                  </Link>
                  {/* <Link
                className="footerDetailsLink"
                to="/styling"
                state={{ from: "footer" }}
              >
                <div>Pet Styling</div>
              </Link> */}
                </div>
              </div>
              <div className="footerDetailsContr1">
                <div className="footerDetailsHeading">Serving In</div>
                <div className="">
                  <hr className="live-training-golden-line"></hr>
                </div>
                <div className="footerDetailsContentContainer">
                  <div className="footerDetailsLink">Delhi</div>
                  <div className="footerDetailsLink">Gurgaon</div>
                  <div className="footerDetailsLink">Noida</div>
                  <div className="footerDetailsLink">Ghaziabad</div>
                  <div className="footerDetailsLink">Faridabad</div>
                  <div className="footerDetailsLink">Manesar</div>
                  <div className="footerDetailsLink">Mumbai</div>
                </div>
              </div>
              <div className="footerDetailsCont1">
                <div className="footerDetailsHeading">More</div>
                <div className="">
                  <hr className="live-training-golden-line"></hr>
                </div>
                <div className="footerDetailsContentContainer">
                  <Link className="footerDetailsLink" to="/partner">
                    <div>Become a Partner</div>
                  </Link>
                  <Link className="footerDetailsLink" to="/terms">
                    <div>Terms and Conditions</div>
                  </Link>
                  <Link className="footerDetailsLink" to="/privacy">
                    <div>Privacy Policy</div>
                  </Link>
                  <Link className="footerDetailsLink" to="/">
                    <div>Privacy Policy - Partner</div>
                  </Link>
                  <Link className="footerDetailsLink" to="/customer-support-policy">
                    <div>Customer Support Policy</div>
                  </Link>
                  <Link className="footerDetailsLink" to="/reviews">
                    <div>Petmojo Reviews</div>
                  </Link>
                </div>
              </div>
            </div>

          </>


          <div>
            <div className="desktop-footer-buttons-container-add" style={{ display: "flex", flexDirection: "column" }}>
              <p style={{ fontWeight: "800" }}>MANGOPAW TECHNOLOGIES AND SOLUTIONS PRIVATE LIMITED</p>
              <p style={{ marginTop: "0rem" }}> Ph no- +918889991800</p>
            </div>
            <div className="desktop-footer-buttons-container">
              <div className="desktop-footer-buttons-left">
                <div className="desktop-footer-buttons-left-btn1">
                  <a
                    href="https://play.google.com/store/apps/details?id=in.tamely.user"
                    target="_blank" rel="nofollow"
                  >
                    <img
                      alt="icon"
                      className="desktop-footer-buttons-left-btn1-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/play-store-download.png"
                    />
                  </a>
                </div>
                <div className="desktop-footer-buttons-left-btn1">
                  <a
                    href="https://apps.apple.com/in/app/petmojo/id1609861328"
                    target="_blank" rel="nofollow"
                  >
                    <img
                      alt="icon"
                      className="desktop-footer-buttons-left-btn1-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/app-store-download.png"
                    />
                  </a>
                </div>
                <div
                  onClick={() => scrollToTop()}
                  className="desktop-footer-buttons-left-btn3"
                >
                  <div>Go at the top</div>

                  <svg
                    className="top-arrow"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-arrow-up"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                    />
                  </svg>
                </div>
              </div>

              <div
                className="desktop-footer-buttons-right"
                style={{ fontSize: "18px" }}
              >
                Copyright 2024 Petmojo
              </div>
            </div>
          </div>
        </div>
      )}

      <GoToTop />
    </div>
  );
}

export default Adoption;
