import React, { useEffect, useRef, useState } from "react";

import { Link } from "react-router-dom";
import "./Grooming.css";
// import 'react-rangeslider/lib/index.css'
import { Helmet } from "react-helmet-async";
import LazyLoad from "react-lazyload";
import { useLocation } from "react-router-dom";
import FAQContainerGrooming from "../components/container/faqContainer/FAQContainerGrooming";
import { default as classes, default as styles } from "../pages/Landing/LandingPage.module.css";
import GoToTop from "./GoToTop";
import TimelineComponent from "./TimelineComponent";
// import IntroContainerDesktopGrooming from "./container/introContainer/IntroContainerDesktopGrooming";

import { BsArrowUp } from "@react-icons/all-files/bs/BsArrowUp";
import { BsChevronRight } from "@react-icons/all-files/bs/BsChevronRight";
import { BsChevronLeft } from "@react-icons/all-files/bs/BsChevronLeft";
import HeartContainer from "./container/heartContainer/HeartContainer";
import IntroContainerDesktopGroomingSubscription from "./container/introContainer/IntroContainerDesktopGroomingSubscription";
import IntroContainerMobileGroomingSubscription from "./container/introContainer/IntroContainerMobileGroomingSubscription";
import ConnectWithUs from "./ConnectWithUs";
const testimonialArray = [
  {
    message:
      "Quality Service from Petmojo Team. Definitely will go for it again 10/10",
    name: "Deepak B",
    img_src:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/testimonial-phone-deepak.webp",
    profession: "Shop Owner",
  },
  {
    message:
      "I recently a rescued an indie-baby and unfortunately he had Tick Issue. Thanks to the team for Gentle Service to my baby. He feels so much better now!",
    name: "Rohan B",
    img_src:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/testimonial-phone-rohan.webp",
    profession: "Business Owner",
  },
  {
    message:
      "I have tried so many local groomers. But they don't understand even E of Empathy for the Poor Baby. Love you guys ♥️",
    name: "Abdul C",
    img_src:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/testimonial-phone-abdul.webp",
    profession: "Pharmacist",
  },
];

function GroomingSubscription() {

  const [popup, setPopup] = useState(false);
  const [navDropdown, setNavDropdown] = useState(false);
  const [changeView, setChangeView] = useState(false);
  const dropdownRef = useRef(null);

  const scrollToTop = () => {
    return window.scrollTo({ top: 200, left: 0, behavior: "smooth" });
  };

  function goToTopHandler() {
    return window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  const location = useLocation();
  const [value, setValue] = useState(10);
  const [value2, setValue2] = useState(10);
  const [valueMobile, setValueMobile] = useState(10);
  const [valueMobile2, setValueMobile2] = useState(10);

  const [isscroll, setIsscroll] = useState(true);
  const [isscroll1, setIsscroll1] = useState(true);
  const handleChange = (value) => {
    setValue(value);
    // setIsscroll(false);
    const scrollableDiv = document.getElementById("scrollable-div");
    scrollableDiv.scrollLeft =
      (value / 100) * (scrollableDiv.scrollWidth - scrollableDiv.clientWidth);
    // setTimeout(()=>{
    //   setIsscroll(true);
    // },1000)
  };
  const handleChangeMobile = (value) => {
    setValueMobile(value);
    setIsscroll1(false);
    const scrollableDiv = document.getElementById("scrollable-div-mobile");
    scrollableDiv.scrollLeft =
      (valueMobile / 100) *
      (scrollableDiv.scrollWidth - scrollableDiv.clientWidth);
  };
  const handleChangeMobile2 = (value) => {
    setValueMobile2(value);
    const scrollableDiv = document.getElementById("scrollable-div2-mobile");
    scrollableDiv.scrollLeft =
      (valueMobile2 / 100) *
      (scrollableDiv.scrollWidth - scrollableDiv.clientWidth);
  };
  const handleChange2 = (value) => {
    setValue2(value);
    const scrollableDiv = document.getElementById("scrollable-div2");
    scrollableDiv.scrollLeft =
      (value2 / 100) * (scrollableDiv.scrollWidth - scrollableDiv.clientWidth);
  };

  const [testmonialData, setTestimonialData] = useState({
    message:
      "My dogs get Walkers from PetMojo since I am busy at office. With minimal cost, both my baby is very happy and playful from everyday session. 🙏",
    name: "Revati B",
    img_src:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/testimonial-phone-revati.webp",
    profession: "Artist",
  });

  let count = 0;

  useEffect(() => {
    const targetDiv = document.querySelector("#testimonialContainer");
    console.log(targetDiv);

    const interval = setInterval(() => {
      count++;
      if (count === 3) {
        count = 0;
      }
      setTestimonialData(testimonialArray[count]);
      //console.log(" The current item is: " +count+ " " + testmonialData.name);
    }, 5000);

    return () => clearInterval(interval);
  }, []);
  // useEffect(()=>{
  //   const divName=document.getElementById('scrollable-div');
  //   let scrollAmount=0;
  //   setInterval(() => {
  //     divName.scrollLeft = scrollAmount;
  //     scrollAmount += 2; // change this value to adjust the scrolling speed
  //   },10);
  // })
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setNavDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if (window.innerWidth <= 1000) {
      setChangeView(false);
    } else {
      setChangeView(true);
    }
  }, []);
  const sectionOneRef = useRef(null);
  const sectionTwoRef = useRef(null);
  const sectionThreeRef = useRef(null);
  // console.log(section1);
  // const mySectionRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // console.log('Section is now visible');
          const ele1 = document.getElementById('section1');
          const ele2 = document.getElementById('section2');
          // console.log(ele);
          ele1.classList.add('appstore')
          ele2.classList.add('playstore')
          // Trigger the section action
        }
      },
      { rootMargin: '0px 0px -50% 0px' }
    );
    if (sectionOneRef.current) {
      observer.observe(sectionOneRef.current);
    }
    return () => {
      if (sectionOneRef.current) {
        observer.unobserve(sectionOneRef.current);
      }
    };
  }, []);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // console.log('Section is now visible');
          const ele1 = document.getElementById('section3');
          const ele2 = document.getElementById('section4');
          // console.log(ele);
          ele1.classList.add('appstore')
          ele2.classList.add('playstore')
          // Trigger the section action
        }
      },
    );
    if (sectionTwoRef.current) {
      observer.observe(sectionTwoRef.current);
    }
    return () => {
      if (sectionTwoRef.current) {
        observer.unobserve(sectionTwoRef.current);
      }
    };
  }, []);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // console.log('Section is now visible');
          const ele1 = document.getElementById('section5');
          const ele2 = document.getElementById('section6');

          ele1.classList.add('appstore')
          ele2.classList.add('playstore')
          // Trigger the section action
        }
      },
    );
    if (sectionThreeRef.current) {
      observer.observe(sectionThreeRef.current);
    }
    return () => {
      if (sectionThreeRef.current) {
        observer.unobserve(sectionThreeRef.current);
      }
    };
  }, []);

  function rightScroll() {
    console.log('right scroll')
    const right = document.querySelector('.slider-training-packages');
    right.scrollBy(200, 0);
  }
  function leftScroll() {
    console.log('right scroll')
    const right = document.querySelector('.slider-training-packages');
    right.scrollBy(-200, 0);
  }
  // useEffect(()=>{
  //   if(value===100){
  //     setValue(0);
  //   }
  //   else{
  //     if(isscroll){
  //       setTimeout(()=>{
  //         setValue(value+1);
  //       },100);
  //     }
  //     else{
  //       setIsscroll(true);
  //       setValue(0);
  //     }
  //   }
  // },[isscroll])
  const changeHandler = () => {
    setIsscroll(true);
  }

  const breakPoints = [
    { width: 1, itemsToShow: 3 },
    { width: 550, itemsToShow: 3 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 3 },
  ];

  const [isActive, setIsActive] = useState(false);
  const [selected, setSelected] = useState("Daily Dog Exercise/Walking");

  const [isActivePopup, setIsActivePopup] = useState(false);
  const [selectedPopup, setSelectedPopup] = useState("BATH & BRUSH");
  const [selectedPrice, setSelectedPrice] = useState("₹749");
  const path = useLocation().pathname;
  const options = [
    "Daily Dog Exercise/Walking",
    "Daily Dog Training",
    "Daily Dog Walking",
    "Daily Pet Grooming",
  ];

  const popupoptions = [
    {
      text: "BATH & BRUSH",
      price: "₹749",
    },
    {
      text: "HAIRCUT & STYLING",
      price: "₹949",
    },
    {
      text: "BATH & FULL HAIRCUT",
      price: "₹1499",
    },
  ];
  // 
  const timelineData = [
    {
      title: "Select Your Package",
      text: "Select the package of your choice for your cuddle buddy from Bath & Brush, Haircut & Styling or Bath & Full Haircut.",
      route: "/services",
    },
    {
      title: "Choose convenient Date & Pay:",
      text: "Choose the Date and Time slot convenient for you and make the payment through our Secure Petmojo App/Payment Link.",
      route: "/grooming",
      type: "grooming",
    },
    {
      title: "We make your Pet look Fabulous",
      text: "We will come to Your Doorstep on the selected date and groom your pet in front of you.",
      link: "https://play.google.com/store/apps/details?id=in.tamely.user",
    },
  ];

  const data = [
    {
      title: "What days and times do you offer for Grooming?",
      content:
        "We offer Grooming 6 days a week. Meeting times will vary by day and season. We usually start at 9:00am and the last meeting of the day at 6:00pm. If you have special needs that do not fall within these times, please let our staff know and we will make every effort to accommodate you.",
    },
    {
      title: "How old should my puppy be before he gets his first groom?",
      content:
        "It is a good idea to introduce your puppy to grooming as soon as possible after the first round of puppy shots, usually between 12-16 weeks of age. This will help him learn at an early age that grooming is a pleasant experience.",
    },
    {
      title: "How long does it take to groom my pet?",
      content:
        "The approximate duration of each treatment can take up to 30 minutes. The time will depend on the condition and length of your dog's coat.",
    },
    {
      title: "Do you use drying cages?",
      content:
        "We have a strict no cage drying policy. All pooch's are finished by hand by our experienced stylists. We choose this for the safety and well-being of our canine clients, and while it increases treatment time, we refuse to compromise the handling of equipment and procedures used by other groomers.",
    },
    {
      title: "How often should my pet be groomed?",
      content:
        "The length and type of your pet's coat will determine how often your pet needs to be groomed.",
    },
    {
      title:
        "What if I am not satisfied with the service my dog ​​is receiving?",
      content:
        "If for any reason you are not completely satisfied with the treatment you received, please let us know within 2 working days so we can do everything we can to make it right. The satisfaction of pet parents is important to us. We take great pride in our services and want pooch parents to too.",
    },
  ];

  useEffect(() => {
    const script1 = document.createElement("script");
    const script2 = document.createElement("script");

    script1.type = "application/ld+json";
    script1.innerText = JSON.stringify({
      "@context": "https://schema.org/",
      "@type": "Review",
      author: {
        "@type": "Person",
        name: "Ritesh Mathur",
      },
      itemReviewed: {
        "@type": "LocalBusiness",
        name: "Petmojo Grooming Service",
        address: "New Delhi, India",
        image:
          "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/petmojo-logo-white.png",
        aggregateRating: {
          "@type": "AggregateRating",
          ratingValue: "4.8",
          ratingCount: "2146",
        },
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: "4.8",
        reviewAspect: "",
      },
      publisher: {
        "@type": "Person",
        name: "Swati Taneja",
      },
      reviewBody:
        "The grooming service is exceptional. The groomers are so professional and well trained. They could easily groom my dog, who is very aggressive, especially while bathing. I’m definitely booking their service again!",
    });

    script2.type = "application/ld+json";
    script2.innerText = JSON.stringify({
      "@context": "https://schema.org/",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "What days and times do you offer for Grooming?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "We offer Grooming 6 days a week. Meeting times will vary by day and season. We usually start at 9:00am and the last meeting of the day at 6:00pm. If you have special needs that do not fall within these times, please let our staff know and we will make every effort to accommodate you.",
          },
        },
        {
          "@type": "Question",
          name: "How old should my puppy be before he gets his first groom?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "It is a good idea to introduce your puppy to grooming as soon as possible after the first round of puppy shots, usually between 12-16 weeks of age. This will help him learn at an early age that grooming is a pleasant experience.",
          },
        },
        {
          "@type": "Question",
          name: "How long does it take to groom my pet?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "The approximate duration of each treatment can take up to 30 minutes. The time will depend on the condition and length of your dog's coat.",
          },
        },
        {
          "@type": "Question",
          name: "Do you use drying cages?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "We have a strict no cage drying policy. All pooch's are groomed by hand by our experienced stylists. We choose this for the safety and well-being of our canine clients, and while it increases treatment time, we refuse to compromise the handling of equipment and procedures.",
          },
        },
        {
          "@type": "Question",
          name: "How often should my pet be groomed?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "The length and type of your pet's coat will determine how often your pet needs to be groomed.",
          },
        },

        {
          "@type": "Question",
          name: "What if I am not satisfied with the service my dog ​​is receiving?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "If for any reason you are not completely satisfied with the treatment you received, please let us know within 2 working days so we can do everything we can to make it right. The satisfaction of pet parents is important to us. We take great pride in our services and want to make sure pooch parents do too.",
          },
        },
      ],
    });
    script1.id = "grooming-page1";
    script2.id = "grooming-page2";
    document.body.appendChild(script1);
    document.body.appendChild(script2);

    return () => {
      document.getElementById("grooming-page1").remove();
      document.getElementById("grooming-page2").remove();
    };
  }, []);

  useEffect(() => {
    document.head.innerHTML += `<meta id="grooming-1" property="og:title" content="Professional Pet Grooming at home - Petmojo"/>
    <meta id="grooming-2" property="og:site_name" content="Petmojo"/>
    <meta id="grooming-3" property="og:url" content="https://petmojo.care"/>
    <meta id="grooming-4" property="og:description" content="Pamper your pets with our carefully selected grooming products. We want your pet babies to look fabulous by trained professionals."/>
    <meta id="grooming-5" property="og:type" content="website"/>
    <meta id="grooming-6" property="og:image" content="https://res.cloudinary.com/tamely-app/image/upload/v1662576550/petmojo-website/Updated_Logo_White_4_drxvnq.png"/>

    
<title id="grooming-id">Professional Pet Grooming at home - Petmojo</title>
<meta id="grooming-desc" name="description" content="Dog Grooming service at home by our trained groomers.  Pamper your pets with our carefully selected grooming products." />
    
    `;

    return () => {
      document.getElementById("grooming-1").remove();
      document.getElementById("grooming-2").remove();
      document.getElementById("grooming-3").remove();
      document.getElementById("grooming-4").remove();
      document.getElementById("grooming-5").remove();
      document.getElementById("grooming-6").remove();
      document.getElementById("grooming-id").remove();
      document.getElementById("grooming-desc").remove();
    };
  }, []);

  return (
    <div className="grooming">
      <Helmet>
        <title>Professional Pet Grooming at home - Petmojo</title>
        <meta
          name="description"
          content="Dog Grooming service at home by our trained groomers.  Pamper your pets with our carefully selected grooming products."
        />
      </Helmet>

      {!changeView ? (
        <IntroContainerMobileGroomingSubscription />
      ) : (
        <IntroContainerDesktopGroomingSubscription />
      )}
      {/* {
          !changeView?
          <div style={{width:"100%",marginTop:"20px",display:"flex",flexDirection:"column",justifyContent:"flex-end"}} className="grooming-page-banner-mobile">
            <div className="download-btn-container" style={{padding:"5px",display:"flex",justifyContent:"space-evenly"}}>
              <div className="playstore-icon">
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=in.tamely.user"
                >
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/playstore_ordt0r.png"
                    alt="playstore"
                    style={{ objectFit: "contain" }}
                    id="section4"
                  ></img>
                </a>
              </div>
              <div className="appstore-icon">
                <a
                  target="_blank"
                  href="https://apps.apple.com/in/app/petmojo/id1609861328"
                >
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/appstore_lmgm04.png"
                    alt="appstore"
                    style={{ objectFit: "contain" }}
                    id="section3"
                  ></img>
                </a>
              </div>
            </div>
          </div>:
          <div style={{width:"100%"}} >
          <img src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/groomingpage-banner-desktop.png" alt="banner-desktop" style={{width:"100%"}}/>
          </div>
        } */}
      {!changeView ? (
        <div className="grooming-page-mobile-view-packages-margin grey-background" style={{ paddingTop: "20px" }}>
          <div className="textbox2">Our Subscription Packages</div>
          <hr className="vector2"></hr>
        </div>
      ) : null}

      {/* ----------------------- the packages issue ---------------------------  */}

      {!changeView ? (
        <div class="grey-background padding-20">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              class="slider-grooming-package-mobile"
              id="scrollable-div-mobile"
              style={{ overflowX: "scroll", marginTop: "0px" }}
            >
              <div className="grooming-page-mobile-view-package-container" style={{ animation: "scroll-div10 2s linear" }} onClick={scrollToTop}>
                <div className="training-page-mobile-view-package-sec-1" style={{ width: "100%", height: "115px", borderRadius: "20px" }}>
                  <img
                    alt="trainingIcon"
                    className="training-page-mobile-view-packageIcon"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-package-bathbrush.webp"
                    style={{ width: "100%", height: "100%", objectFit: "cover", borderTopLeftRadius: "20px", borderTopRightRadius: "20px" }}
                  />
                </div>
                <div className="grooming-page-mobile-view-package-sec-2-one">
                  BATH & BRUSH
                </div>
                <div className="training-page-mobile-view-package-sec-3">
                  <div className="training-page-mobile-view-package-sec-3-1">
                    For general hygiene and a healthy coat
                  </div>
                  <div className="training-page-mobile-view-package-section3">
                    <div className="walking-page-mobile-view-package-point">
                      <div className="">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                          alt="tickIcon"
                          className="walking-page-tick-mobile"
                        />
                      </div>
                      <div>Bath</div>
                    </div>
                    <div className="walking-page-mobile-view-package-point">
                      <div className="">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                          alt="tickIcon"
                          className="walking-page-tick-mobile"
                        />
                      </div>
                      <div>Nail Trim</div>
                    </div>
                    <div className="walking-page-mobile-view-package-point">
                      <div className="">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                          alt="tickIcon"
                          className="walking-page-tick-mobile"
                        />
                      </div>
                      <div>Brushing</div>
                    </div>
                    <div className="walking-page-mobile-view-package-point">
                      <div className="">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                          alt="tickIcon"
                          className="walking-page-tick-mobile"
                        />
                      </div>
                      <div>Shampoo</div>
                    </div>

                    <div className="walking-page-mobile-view-package-point">
                      <div className="">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                          alt="tickIcon"
                          className="walking-page-tick-mobile"
                        />
                      </div>
                      <div>Scrubbing</div>
                    </div>
                    <div className="walking-page-mobile-view-package-point">
                      <div className="">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                          alt="tickIcon"
                          className="walking-page-tick-mobile"
                        />
                      </div>
                      <div>Coat Conditioner</div>
                    </div>
                    {/* <div className="walking-page-mobile-view-package-point">
                      <div className="">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                          alt="tickIcon"
                          className="walking-page-tick-mobile"
                        />
                      </div>
                      <div>Nail Cutting</div>
                    </div> */}
                    <div className="walking-page-mobile-view-package-point">
                      <div className="">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                          alt="tickIcon"
                          className="walking-page-tick-mobile"
                        />
                      </div>
                      <div>Ear Cleaning & Flushing</div>
                    </div>
                    <div className="walking-page-mobile-view-package-point">
                      <div className="">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                          alt="tickIcon"
                          className="walking-page-tick-mobile"
                        />
                      </div>
                      <div>Blow Dry</div>
                    </div>
                    {/* <div className="walking-page-mobile-view-package-point">
                      <div className="">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                          alt="tickIcon"
                          className="walking-page-tick-mobile"
                        />
                      </div>
                      <div>Paw Hair Trimming</div>
                    </div> */}
                  </div>
                </div>


              </div>

              <div className="grooming-page-mobile-view-package-container" style={{ animation: "scroll-div10 2s linear" }} onClick={scrollToTop}>
                <div className="training-page-mobile-view-package-sec-1" style={{ width: "100%", height: "115px", borderRadius: "20px" }}>
                  <img
                    alt="trainingIcon"
                    className="training-page-mobile-view-packageIcon"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-package-haircutstyling.webp"
                    style={{ width: "100%", height: "100%", objectFit: "cover", borderTopLeftRadius: "20px", borderTopRightRadius: "20px" }}
                  />
                </div>
                <div className="grooming-page-mobile-view-package-sec-2-three">
                  HAIRCUT & STYLING
                </div>
                <div className="training-page-mobile-view-package-sec-3">
                  <div className="training-page-mobile-view-package-sec-3-1">
                    For full haircut and complete styling
                  </div>
                  <div className="training-page-mobile-view-package-section3">
                    <div className="walking-page-mobile-view-package-point">
                      <div className="">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                          alt="tickIcon"
                          className="walking-page-tick-mobile"
                        />
                      </div>
                      <div>Full Haircut</div>
                    </div>

                    <div className="walking-page-mobile-view-package-point">
                      <div className="">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                          alt="tickIcon"
                          className="walking-page-tick-mobile"
                        />
                      </div>
                      <div>Breed Specific Haircut</div>
                    </div>

                    <div className="walking-page-mobile-view-package-point">
                      <div className="">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                          alt="tickIcon"
                          className="walking-page-tick-mobile"
                        />
                      </div>
                      <div>Fanny Hair Trim</div>
                    </div>
                    <div className="walking-page-mobile-view-package-point">
                      <div className="">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                          alt="tickIcon"
                          className="walking-page-tick-mobile"
                        />
                      </div>
                      <div>Paw Hair Trim</div>
                    </div>
                    <div className="walking-page-mobile-view-package-point">
                      <div className="">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                          alt="tickIcon"
                          className="walking-page-tick-mobile"
                        />
                      </div>
                      <div>Tail Hair Shaping</div>
                    </div>
                    <div className="walking-page-mobile-view-package-point">
                      <div className="">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                          alt="tickIcon"
                          className="walking-page-tick-mobile"
                        />
                      </div>
                      <div>Brushing</div>
                    </div>
                  </div>
                </div>


              </div>

              <div className="grooming-page-mobile-view-package-container" style={{ animation: "scroll-div10 2s linear" }} onClick={scrollToTop}>
                {/* <img
                  alt="trainingIcon"
                  className="grooming-page-mobile-view-packageIcon"
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/bath%26fullhaircut.png"
                  style={{width:"100%",height:"100%",objectFit:"cover"}}
                    /> */}
                <div className="training-page-mobile-view-package-sec-1" style={{ width: "100%", height: "115px", borderRadius: "20px" }}>
                  <img
                    alt="trainingIcon"
                    className="training-page-mobile-view-packageIcon"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-package-bathfullhaircut.webp"
                    style={{ width: "100%", height: "100%", objectFit: "cover", borderTopLeftRadius: "20px", borderTopRightRadius: "20px" }}
                  />
                </div>

                <div className="grooming-page-mobile-view-package-sec-2-three" style={{ background: "linear-gradient(180deg, #00BAA4 0%, #006D70 100%)" }}>
                  BATH & FULL HAIRCUT
                </div>
                <div className="training-page-mobile-view-package-sec-3">
                  <div className="training-page-mobile-view-package-sec-3-1">
                    Complete hygiene and styling
                  </div>
                  <div className="training-page-mobile-view-package-section3">
                    <div className="walking-page-mobile-view-package-point">
                      <div className="">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                          alt="tickIcon"
                          className="walking-page-tick-mobile"
                        />
                      </div>
                      <div> Bath</div>
                    </div>
                    <div className="walking-page-mobile-view-package-point">
                      <div className="">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                          alt="tickIcon"
                          className="walking-page-tick-mobile"
                        />
                      </div>
                      <div>Nail Trim</div>
                    </div>
                    <div className="walking-page-mobile-view-package-point">
                      <div className="">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                          alt="tickIcon"
                          className="walking-page-tick-mobile"
                        />
                      </div>
                      <div>Brushing</div>
                    </div>

                    <div className="walking-page-mobile-view-package-point">
                      <div className="">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                          alt="tickIcon"
                          className="walking-page-tick-mobile"
                        />
                      </div>
                      <div>Shampoo</div>
                    </div>

                    <div className="walking-page-mobile-view-package-point">
                      <div className="">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                          alt="tickIcon"
                          className="walking-page-tick-mobile"
                        />
                      </div>
                      <div>Scrubbing</div>
                    </div>
                    <div className="walking-page-mobile-view-package-point">
                      <div className="">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                          alt="tickIcon"
                          className="walking-page-tick-mobile"
                        />
                      </div>
                      <div>Blow Dry </div>
                    </div>
                    <div className="walking-page-mobile-view-package-point">
                      <div className="">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                          alt="tickIcon"
                          className="walking-page-tick-mobile"
                        />
                      </div>
                      <div>Coat Conditioner</div>
                    </div>
                    <div className="walking-page-mobile-view-package-point">
                      <div className="">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                          alt="tickIcon"
                          className="walking-page-tick-mobile"
                        />
                      </div>
                      <div>Ear Cleaning & Flushing</div>
                    </div>

                  </div>
                </div>
                <div className="grooming-page-mobile-view-tick-container">
                  <div className="walking-page-mobile-view-package-point">
                    <div className="">
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                        alt="tickIcon"
                        className="walking-page-tick-mobile"
                      />
                    </div>
                    <div style={{ fontSize: "14px", color: "grey" }}>
                      Full Haircut
                    </div>
                  </div>
                  <div className="walking-page-mobile-view-package-point">
                    <div className="">
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                        alt="tickIcon"
                        className="walking-page-tick-mobile"
                      />
                    </div>
                    <div style={{ fontSize: "14px", color: "grey" }}>
                      Breed Specific Haircut
                    </div>
                  </div>
                  <div className="walking-page-mobile-view-package-point">
                    <div className="">
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                        alt="tickIcon"
                        className="walking-page-tick-mobile"
                      />
                    </div>
                    <div style={{ fontSize: "14px", color: "grey" }}>
                      Fanny Hair Trim
                    </div>
                  </div>
                  <div className="walking-page-mobile-view-package-point">
                    <div className="">
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                        alt="tickIcon"
                        className="walking-page-tick-mobile"
                      />
                    </div>
                    <div style={{ fontSize: "14px", color: "grey" }}>
                      Paw Hair Trim
                    </div>
                  </div>

                  <div className="walking-page-mobile-view-package-point">
                    <div className="">
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                        alt="tickIcon"
                        className="walking-page-tick-mobile"
                      />
                    </div>
                    <div style={{ fontSize: "14px", color: "grey" }}>
                      Tail Hair Shaping
                    </div>
                  </div>
                </div>

              </div>


            </div>
            <div
              style={{ width: "100%", padding: "10px", textAlign: "center" }}
            >
              {/* <Slider
                min={0}
                max={100}
                value={valueMobile}
                
                onChange={handleChangeMobile}
                onChangeComplete={()=>setIsscroll1(true)}
                tooltip={false}
              /> */}
              {/* <center style={{}}>
                <Loader color="#ff5e95" />
                </center> */}
            </div>
          </div>
          <div className="bookingbutton" style={{ padding: "10px", marginTop: "0px", position: "relative", bottom: "50px" }}>
            <div
              onClick={() => scrollToTop()}
              state={{ type: "grooming" }}
              style={{ width: "100%" }}
            >
              <button
                className="booksession-mobile-view-grooming "
                style={{ width: "100%", fontSize: "17px" }}
              >
                Book a Professional Groomer
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="slider-container-desktop-grooming margin-for-desktop"
          style={{ position: "relative", justifyContent: "space-between" }}
        >
          <div className="slide-grooming-package grooming-package-1-desktop">
            <div className="grooming-page-packages-heading">
              <div className="grooming-page-packages-head" style={{ fontSize: "3rem" }}>Our Subscription</div>
              <div className="grooming-page-packages-head">Packages</div>

              <div className="line-div">
                <hr
                  className="live-training-pink-line"
                  style={{ position: "relative", bottom: "10px" }}
                ></hr>
              </div>
            </div>
            <div className="grooming-page-packages-head-bottom">
              <div className="grooming-page-packages-cta2">
                <div
                  className="bookingbutton"
                  style={{ position: "relative", top: "20px" }}
                >
                  <div
                    onClick={() => scrollToTop()}
                    state={{ type: "grooming" }}
                  >
                    <button className="booksession" >
                      Book a Professional Groomer
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button style={{ height: "150px", borderRadius: "5px", padding: "10px", display: "flex", flexDirection: "column", justifyContent: "center", borderColor: "rgba(254, 119, 152, 1)", border: "2px solid rgba(254,119,152,1)", cursor: "pointer", background: "none" }} onClick={leftScroll}>
            <BsChevronLeft color="rgba(254,119,152,1)" size={20} />
          </button>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              className="slider-training-packages"
              id="scrollable-div"
              style={{ overflow: "auto", width: "50vw", scrollBehavior: "smooth" }}
            >
              <div className="grooming-page-mobile-view-package-container" style={{ animation: "scroll-div10 2s linear" }} onClick={scrollToTop}>
                <div className="training-page-mobile-view-package-sec-1" style={{ width: "100%", height: "115px", borderRadius: "20px" }}>
                  <img
                    alt="trainingIcon"
                    className="training-page-mobile-view-packageIcon"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-package-bathbrush.webp"
                    style={{ width: "100%", height: "100%", objectFit: "cover", borderTopLeftRadius: "20px", borderTopRightRadius: "20px" }}

                  />
                </div>
                <div className="grooming-page-mobile-view-package-sec-2-one">
                  BATH & BRUSH
                </div>
                <div className="training-page-mobile-view-package-section3">
                  <div className="walking-page-mobile-view-package-point">
                    <div className="">
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                        alt="tickIcon"
                        className="walking-page-tick-mobile"
                      />
                    </div>
                    <div>Bath</div>
                  </div>
                  <div className="walking-page-mobile-view-package-point">
                    <div className="">
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                        alt="tickIcon"
                        className="walking-page-tick-mobile"
                      />
                    </div>
                    <div>Nail Trim</div>
                  </div>
                  <div className="walking-page-mobile-view-package-point">
                    <div className="">
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                        alt="tickIcon"
                        className="walking-page-tick-mobile"
                      />
                    </div>
                    <div>Brushing</div>
                  </div>
                  <div className="walking-page-mobile-view-package-point">
                    <div className="">
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                        alt="tickIcon"
                        className="walking-page-tick-mobile"
                      />
                    </div>
                    <div>Shampoo</div>
                  </div>

                  <div className="walking-page-mobile-view-package-point">
                    <div className="">
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                        alt="tickIcon"
                        className="walking-page-tick-mobile"
                      />
                    </div>
                    <div>Scrubbing</div>
                  </div>
                  <div className="walking-page-mobile-view-package-point">
                    <div className="">
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                        alt="tickIcon"
                        className="walking-page-tick-mobile"
                      />
                    </div>
                    <div>Coat Conditioner</div>
                  </div>
                  {/* <div className="walking-page-mobile-view-package-point">
                      <div className="">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                          alt="tickIcon"
                          className="walking-page-tick-mobile"
                        />
                      </div>
                      <div>Nail Cutting</div>
                    </div> */}
                  <div className="walking-page-mobile-view-package-point">
                    <div className="">
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                        alt="tickIcon"
                        className="walking-page-tick-mobile"
                      />
                    </div>
                    <div>Ear Cleaning & Flushing</div>
                  </div>
                  <div className="walking-page-mobile-view-package-point">
                    <div className="">
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                        alt="tickIcon"
                        className="walking-page-tick-mobile"
                      />
                    </div>
                    <div>Blow Dry</div>
                  </div>
                  {/* <div className="walking-page-mobile-view-package-point">
                      <div className="">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                          alt="tickIcon"
                          className="walking-page-tick-mobile"
                        />
                      </div>
                      <div>Paw Hair Trimming</div>
                    </div> */}
                </div>

                {/* <div className="training-page-mobile-view-package-sec-4">
                  <div className="training-card-mobile-view-discounted-price">
                    Discounted Price
                  </div>
                  <div className="walking-card-mobile-view-price-btn">
                    <div className="training-card-mobile-view-og-price">
                      <div className="diagonal-line-mobile-view-training">
                        <div className="diagonal-line-mobile-view-text">
                          ₹1200
                        </div>
                      </div>{" "}
                    </div>
                    <div className="grooming-1-card-mobile-view-disc-price">
                      ₹799
                    </div>
                  </div>
                  
                </div> */}
              </div>

              <div className="grooming-page-mobile-view-package-container" style={{ animation: "scroll-div10 2s linear" }} onClick={scrollToTop}>
                <div className="training-page-mobile-view-package-sec-1" style={{ width: "100%", height: "115px", borderRadius: "20px" }}>
                  <img
                    alt="trainingIcon"
                    className="training-page-mobile-view-packageIcon"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-package-haircutstyling.webp"
                    style={{ width: "100%", height: "100%", objectFit: "cover", borderTopLeftRadius: "20px", borderTopRightRadius: "20px" }}
                  />
                </div>
                <div className="grooming-page-mobile-view-package-sec-2-three">
                  HAIRCUT & STYLING
                </div>
                <div className="training-page-mobile-view-package-sec-3">
                  <div className="training-page-mobile-view-package-sec-3-1">
                    For full haircut and complete styling
                  </div>
                  <div className="training-page-mobile-view-package-section3">
                    <div className="walking-page-mobile-view-package-point">
                      <div className="">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                          alt="tickIcon"
                          className="walking-page-tick-mobile"
                        />
                      </div>
                      <div>Full Haircut</div>
                    </div>

                    <div className="walking-page-mobile-view-package-point">
                      <div className="">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                          alt="tickIcon"
                          className="walking-page-tick-mobile"
                        />
                      </div>
                      <div>Breed Specific Haircut</div>
                    </div>

                    <div className="walking-page-mobile-view-package-point">
                      <div className="">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                          alt="tickIcon"
                          className="walking-page-tick-mobile"
                        />
                      </div>
                      <div>Fanny Hair Trim</div>
                    </div>
                    <div className="walking-page-mobile-view-package-point">
                      <div className="">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                          alt="tickIcon"
                          className="walking-page-tick-mobile"
                        />
                      </div>
                      <div>Paw Hair Trim</div>
                    </div>
                    <div className="walking-page-mobile-view-package-point">
                      <div className="">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                          alt="tickIcon"
                          className="walking-page-tick-mobile"
                        />
                      </div>
                      <div>Tail Hair Shaping</div>
                    </div>
                    <div className="walking-page-mobile-view-package-point">
                      <div className="">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                          alt="tickIcon"
                          className="walking-page-tick-mobile"
                        />
                      </div>
                      <div>Brushing</div>
                    </div>
                  </div>
                </div>

                {/* <div className="training-page-mobile-view-package-sec-4">
                  <div className="training-card-mobile-view-discounted-price">
                    Discounted Price
                  </div>
                  <div className="walking-card-mobile-view-price-btn">
                    <div className="training-card-mobile-view-og-price">
                      <div className="diagonal-line-mobile-view-training">
                        <div className="diagonal-line-mobile-view-text">
                          ₹1600
                        </div>
                      </div>{" "}
                    </div>
                    <div className="grooming-1-card-mobile-view-disc-price">
                      ₹1249
                    </div>
                  </div>
                 
                </div> */}
              </div>

              <div className="grooming-page-mobile-view-package-container" style={{ animation: "scroll-div10 2s linear" }} onClick={scrollToTop}>
                <div className="training-page-mobile-view-package-sec-1" style={{ width: "100%", height: "115px", borderRadius: "20px" }}>
                  <img
                    alt="trainingIcon"
                    className="training-page-mobile-view-packageIcon"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-package-bathfullhaircut.webp"
                    style={{ width: "100%", height: "100%", objectFit: "cover", borderTopLeftRadius: "20px", borderTopRightRadius: "20px" }}
                  />
                </div>

                <div className="grooming-page-mobile-view-package-sec-2-three" style={{ background: "linear-gradient(180deg, #00baa4 0%, #006d70 100%)" }}>
                  BATH & FULL HAIRCUT
                </div>
                <div className="training-page-mobile-view-package-sec-3">
                  <div className="training-page-mobile-view-package-sec-3-1">
                    Complete hygiene and styling
                  </div>
                  <div className="training-page-mobile-view-package-section3">
                    <div className="walking-page-mobile-view-package-point">
                      <div className="">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                          alt="tickIcon"
                          className="walking-page-tick-mobile"
                        />
                      </div>
                      <div> Bath</div>
                    </div>
                    <div className="walking-page-mobile-view-package-point">
                      <div className="">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                          alt="tickIcon"
                          className="walking-page-tick-mobile"
                        />
                      </div>
                      <div>Nail Trim</div>
                    </div>
                    <div className="walking-page-mobile-view-package-point">
                      <div className="">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                          alt="tickIcon"
                          className="walking-page-tick-mobile"
                        />
                      </div>
                      <div>Brushing</div>
                    </div>

                    <div className="walking-page-mobile-view-package-point">
                      <div className="">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                          alt="tickIcon"
                          className="walking-page-tick-mobile"
                        />
                      </div>
                      <div>Shampoo</div>
                    </div>

                    <div className="walking-page-mobile-view-package-point">
                      <div className="">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                          alt="tickIcon"
                          className="walking-page-tick-mobile"
                        />
                      </div>
                      <div>Scrubbing</div>
                    </div>
                    <div className="walking-page-mobile-view-package-point">
                      <div className="">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                          alt="tickIcon"
                          className="walking-page-tick-mobile"
                        />
                      </div>
                      <div>Blow Dry </div>
                    </div>
                    <div className="walking-page-mobile-view-package-point">
                      <div className="">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                          alt="tickIcon"
                          className="walking-page-tick-mobile"
                        />
                      </div>
                      <div>Coat Conditioner</div>
                    </div>
                    <div className="walking-page-mobile-view-package-point">
                      <div className="">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                          alt="tickIcon"
                          className="walking-page-tick-mobile"
                        />
                      </div>
                      <div>Ear Cleaning & Flushing</div>
                    </div>

                  </div>
                </div>
                <div className="grooming-page-mobile-view-tick-container">
                  <div className="walking-page-mobile-view-package-point">
                    <div className="">
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                        alt="tickIcon"
                        className="walking-page-tick-mobile"
                      />
                    </div>
                    <div style={{ fontSize: "14px", color: "grey" }}>
                      Full Haircut
                    </div>
                  </div>
                  <div className="walking-page-mobile-view-package-point">
                    <div className="">
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                        alt="tickIcon"
                        className="walking-page-tick-mobile"
                      />
                    </div>
                    <div style={{ fontSize: "14px", color: "grey" }}>
                      Breed Specific Haircut
                    </div>
                  </div>
                  <div className="walking-page-mobile-view-package-point">
                    <div className="">
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                        alt="tickIcon"
                        className="walking-page-tick-mobile"
                      />
                    </div>
                    <div style={{ fontSize: "14px", color: "grey" }}>
                      Fanny Hair Trim
                    </div>
                  </div>
                  <div className="walking-page-mobile-view-package-point">
                    <div className="">
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                        alt="tickIcon"
                        className="walking-page-tick-mobile"
                      />
                    </div>
                    <div style={{ fontSize: "14px", color: "grey" }}>
                      Paw Hair Trim
                    </div>
                  </div>

                  <div className="walking-page-mobile-view-package-point">
                    <div className="">
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8749_rvhrk2.png"
                        alt="tickIcon"
                        className="walking-page-tick-mobile"
                      />
                    </div>
                    <div style={{ fontSize: "14px", color: "grey" }}>
                      Tail Hair Shaping
                    </div>
                  </div>
                </div>

              </div>

            </div>
            {/* <div
              style={{ width:"100%"}}
            >
              <Slider
                min={0}
                max={100}
                value={value}
                
                onChange={handleChange}
                tooltip={false}
                
              />
              
            </div> */}
          </div>
          <button style={{ height: "150px", borderRadius: "5px", padding: "10px", display: "flex", flexDirection: "column", justifyContent: "center", borderColor: "rgba(254, 119, 152, 1)", border: "2px solid rgba(254,119,152,1)", cursor: "pointer", background: "none" }} onClick={rightScroll}>
            <BsChevronRight color="rgba(254,119,152,1)" size={20} />
          </button>
        </div>
      )}


      {!changeView ? (
        <div className="groomer-meet" style={{ marginBottom: "20px" }}>
          <div className="groomer-meet-section-1">
            Look <span className="pink">Fabulous</span> Gurantee
          </div>
          <hr className="vector2"></hr>

          <div className="groomer-meet-section-2">
            <div className="groomer-meet-section-2-subtext">
              Expert and Empathetic Pet Groomers
            </div>
            <div>

              <div style={{ display: "flex" }}>
                <div>
                  <li></li>
                </div>
                <div>Expert in variety of Pet Hairstyles for every breed</div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <li></li>
                </div>
                <div>Carefully Selected Non Allergic, Animal Friendly Products</div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <li></li>
                </div>
                <div>Verified Groomers by a rigorous background check</div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <li></li>
                </div>
                <div>500+ monthly pet grooming sessions</div>
              </div>
            </div>
          </div>
          <div className="groomer-meet-section-3" style={{ width: "100%" }}>
            <div className="bookingbutton" style={{ padding: "10px" }}>
              <div
                onClick={() => scrollToTop()}
                state={{ type: "grooming" }}
                style={{ width: "100%" }}
              >
                <button
                  className="booksession-mobile-view-grooming"
                  style={{ width: "100%", fontSize: "17px" }}
                >
                  Book a Professional Groomer
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="grooming-page-trainer-meet">
          <div className="grooming-page-trainer-meet-left">
            <div className="grooming-page-trainer-meet-head3">
              <h2 className="fifty-font-size bold-none">
                Look{" "}
                <span className="grooming-page-trainer-meet-pink">
                  Fabulous
                </span>{" "}
                Guarantee
              </h2>
            </div>
            <div className="line-div ">
              <hr
                className="live-training-pink-line"
                style={{ position: "relative", bottom: "50px" }}
              ></hr>
            </div>
            <div className="bookingbutton" style={{ marginTop: "0px" }}>
              <div
                onClick={() => scrollToTop()}
                state={{ type: "grooming" }}
                style={{ position: "relative", bottom: "80px" }}
              >
                <button
                  className="booksession-mobile-view"
                  style={{ width: "max-content" }}
                >
                  Book a Professional Groomer
                </button>
              </div>
            </div>
          </div>

          <div className="grooming-page-trainer-meet-right">
            <div
              className="grooming-page-trainer-meet-right-head"
              style={{ marginBottom: "0px" }}
            >
              <h3
                className="bold-none grooming-page-trainer-meet-right-head"
                style={{ fontSize: "45px" }}
              >
                Expert and Empathetic Pet Groomers
              </h3>
            </div>

            <div
              className="grooming-page-trainer-meet-subtext"
              style={{ marginTop: "0px", fontSize: "20px" }}
            >
              {/* Expert in variety of Pet Hairstyles for every breed
Carefully Selected Non Allergic, Animal Friendly Products
Verified Groomers by a rigorous background check
500+ monthly pet grooming sessions */}
              <div style={{ display: "flex" }}>
                <div>
                  <li></li>
                </div>
                <div>Expert in variety of Pet Hairstyles for every breed</div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <li></li>
                </div>
                <div>Carefully Selected Non Allergic, Animal Friendly Products</div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <li></li>
                </div>
                <div>Verified Groomers by a rigorous background check</div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <li></li>
                </div>
                <div>500+ monthly pet grooming sessions</div>
              </div>

              {/* <li>Trained Professionals & Quality Services</li>
                <li>550+ At home Grooming Sessions done</li>
                <li>Variety of Grooming Styles</li>
                <li>Carefully Selected Non Allergic Products</li> */}
            </div>
          </div>
        </div>
      )}
      {!changeView ? <HeartContainer pathname={path} /> : null}
      {!changeView ? null : (
        <div className={classes.heartSection}>
          <div className={classes.heartSectionTextContainer}>
            <div className={classes.heartSectionTextHeading}>
              <h2 className="fifty-font-size bold-none">
                Your Pet, Our Family 💖
              </h2>
              <div className="line-div relative-line">
                <hr className="live-training-pink-line"></hr>
              </div>
            </div>

            <div
              className={classes.heartSectionText}
              style={{ marginTop: "0px", position: "relative", bottom: "25px" }}
            >
              <div style={{}}>
                <h4 className="bold-none">
                  <span className="pink">16000+</span> monthly happy pet parents
                  on our platform!
                </h4>
              </div>
            </div>

            <div
              className="bookingbutton"
              style={{ marginTop: "0px", position: "relative", bottom: "30px" }}
            >
              <div
                onClick={() => scrollToTop()}
                state={{ type: "dog-running" }}
              >
                <button className="booksession">
                  Book a Professional Groomer
                </button>
              </div>
            </div>
          </div>
          <div className={classes.heartSectionImage}>
            <img
              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/desktop-heart-container.webp"
              alt="heart"
              className="hearticon"
            ></img>
          </div>
        </div>
      )}
      {!changeView ? (
        <div className="container-home pink-container-family" >
          <div className="textbox2-get-pet-grooming">
            How to Book Pet Grooming Service?
          </div>
          <hr className="vector1"></hr>
          <div>
            <TimelineComponent version={"mobile"} timelineData={timelineData} />
          </div>
        </div>
      ) : (
        <div>
          <div className="container-home-desktop">
            <div className={styles.getStartedHeading}>
              <div className={styles.howToStart}>
                <div className="textbox2-desktop">How to Book Pet Grooming Service?</div>
              </div>
              <div className="line-div">
                <hr
                  className="live-training-pink-line"
                  style={{ position: "relative", bottom: "20px" }}
                ></hr>
              </div>

              <div
                className="get-started-grooming-page-sub-text-container"
                style={{ position: "relative", bottom: "30px" }}
              >
                <div className="get-started-grooming-page-sub-text-container1">
                  Book Pet Grooming Services with Ease
                </div>
                <div className="get-started-grooming-page-sub-text-container2">
                  - Follow These Simple Steps!
                </div>
              </div>

              <div
                className="bookingbutton"
                style={{
                  marginTop: "20px",
                  position: "relative",
                  bottom: "20px",
                }}
              >
                <div onClick={() => scrollToTop()} state={{ type: "grooming" }}>
                  <button className="booksession">
                    Book a Professional Groomer
                  </button>
                </div>
              </div>
            </div>
            <div>
              <TimelineComponent
                version={"desktop"}
                timelineData={timelineData}
              />
            </div>
          </div>
        </div>
      )}
      {!changeView ? (
        <div
          className={
            !changeView
              ? "container white-background"
              : "container-desktop-walking grey-background"
          }
          style={{ marginTop: "0px" }}
        >
          {!changeView ? (
            <div>
              <div className="textbox2">Our Customers</div>
              <hr className="vector2"></hr>
            </div>
          ) : null}

          <div class="review-container" style={{ marginBottom: "0px" }}>
            <div class={!changeView ? "review-box" : "review-box-desktop"}>
              {!changeView ? null : (
                <div className="home-page-customers-head-walking">
                  <div className="home-page-customer-heading">
                    <div>Our Customers</div>
                    <div className="line-div">
                      <hr className="live-training-pink-line"></hr>
                    </div>
                  </div>

                  <div className="home-page-customers-contents">
                    <div>Our customers trust us for having</div>
                    <div>
                      <span className="pink">Joyful</span> and{" "}
                      <span className="pink">Positive</span> Reinforcements
                    </div>
                    <div>only, "Your Pet is Our Family!"</div>
                  </div>
                </div>
              )}
              <div
                id="testimonialContainer"
                className={!changeView ? "review-box" : "review-box-desktop-3"}
              >
                <div
                  className={
                    !changeView
                      ? "testimonial-card-mastercontainer-mobile-view"
                      : "testimonial-card-mastercontainer-2"
                  }
                >
                  <div className="testimonial-card-upper-container">
                    <div className="testimonial-card-quote-image">
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/quote-icon.png"
                        alt="quoteIcon"
                        className="quote-icon"
                      ></img>
                    </div>
                    <div className="testimonial-card-review-content">
                      {testmonialData.message}
                    </div>
                    <hr className="grey-line"></hr>
                  </div>
                  <div className="testimonial-card-lower-container">
                    <div className="testimonial-card-reviewer-image">
                      <img
                        alt="rudrangImage"
                        className="testimonial-user-image"
                        src={testmonialData.img_src}
                      />
                    </div>
                    <div className="testimonial-card-reviewer-name">
                      {testmonialData.name}
                    </div>
                    <div className="testimonial-card-reviewer-profession">
                      {testmonialData.profession}
                    </div>
                    <div className="testimonial-card-review-star-box">
                      <div>
                        <img
                          alt="starIcon"
                          className="star-icon-desktop-image"
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png"
                        />
                      </div>
                      <div>
                        <img
                          alt="starIcon"
                          className="star-icon-desktop-image"
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png"
                        />
                      </div>
                      <div>
                        <img
                          alt="starIcon"
                          className="star-icon-desktop-image"
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png"
                        />
                      </div>
                      <div>
                        <img
                          alt="starIcon"
                          className="star-icon-desktop-image"
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png"
                        />
                      </div>
                      <div>
                        <img
                          alt="starIcon"
                          className="star-icon-desktop-image"
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {!changeView ? (
        <div
          className="bookingbutton"
          style={{ paddingLeft: "30px", paddingRight: "30px", marginTop: "0px", position: "relative", bottom: "30px" }}
        >
          <div
            onClick={() => scrollToTop()}
            state={{ type: "grooming" }}
            style={{ width: "100%" }}
          >
            <button
              className="booksession-mobile-view-grooming"
              style={{ width: "100%", fontSize: "17px" }}
            >
              Book a Professional Groomer
            </button>
          </div>
        </div>
      ) : null}



      {!changeView ? null : (
        <div className="container-desktop-walking" style={{}}>
          <div className="review-container">
            <div className="review-box-desktop">
              {/* <div
                className={!changeView ? "review-box" : "review-box-desktop-3"} 
              >
                <div
                  className={
                    !changeView
                      ? "testimonial-card-mastercontainer-mobile-view"
                      : "testimonial-card-mastercontainer-2"
                  }
                >
                  <div className="testimonial-card-upper-container">
                    <div className="testimonial-card-quote-image">
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/quote-icon.png"
                        alt="quoteIcon"
                        className="quote-icon"
                      ></img>
                    </div>
                    <div className="testimonial-card-review-content">
                      {testmonialData.message}
                    </div>
                    <hr className="grey-line"></hr>
                  </div>
                  <div className="testimonial-card-lower-container">
                    <div className="testimonial-card-reviewer-image">
                      <img
                        alt="rudrangImage"
                        className="testimonial-user-image"
                        src={testmonialData.img_src}
                      />
                    </div>
                    <div className="testimonial-card-reviewer-name">
                      {testmonialData.name}
                    </div>
                    <div className="testimonial-card-reviewer-profession">
                      {testmonialData.profession}
                    </div>
                    <div className="testimonial-card-review-star-box">
                      <div>
                        <img
                          alt="starIcon"
                          className="star-icon-desktop-image"
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png"
                        />
                      </div>
                      <div>
                        <img
                          alt="starIcon"
                          className="star-icon-desktop-image"
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png"
                        />
                      </div>
                      <div>
                        <img
                          alt="starIcon"
                          className="star-icon-desktop-image"
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png"
                        />
                      </div>
                      <div>
                        <img
                          alt="starIcon"
                          className="star-icon-desktop-image"
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png"
                        />
                      </div>
                      <div>
                        <img
                          alt="starIcon"
                          className="star-icon-desktop-image"
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="home-page-customers-head-grooming" >
                <div className="home-page-customer-heading" >
                  <div>
                    <h2 className="fifty-font-size bold-none">Our Customers</h2>
                  </div>
                  <div className=" relative-line" >
                    {/* <div> */}
                    <hr className="live-training-pink-line" ></hr>
                    {/* </div> */}
                  </div>
                </div>

                <div
                  className="home-page-customers-contents"
                  style={{
                    marginTop: "0px",
                    position: "relative",
                    bottom: "25px",
                  }}
                >
                  <h4 className="bold-none">
                    <div>Our customers trust us for having</div>
                    <div>
                      <span className="pink">Joyful</span> and{" "}
                      <span className="pink">Positive</span> Reinforcements
                    </div>
                    <div>only, "Your Pet is Our Family!"</div>
                  </h4>
                </div>

                <div
                  className="bookingbutton"
                  style={{ position: "relative", bottom: "30px" }}
                >
                  <div
                    onClick={() => scrollToTop()}
                    state={{ type: "grooming" }}
                  >
                    <button className="booksession">
                      Book a Professional Groomer
                    </button>
                  </div>
                </div>
              </div>

              <div
                className={!changeView ? "review-box" : "review-box-desktop-3"} style={{ justifyContent: !changeView ? "center" : "flex-end" }}
              >
                <div
                  className={
                    !changeView
                      ? "testimonial-card-mastercontainer-mobile-view"
                      : "testimonial-card-mastercontainer-2"
                  }
                >
                  <div className="testimonial-card-upper-container">
                    <div className="testimonial-card-quote-image">
                      <img
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/quote-icon.png"
                        alt="quoteIcon"
                        className="quote-icon"
                      ></img>
                    </div>
                    <div className="testimonial-card-review-content">
                      {testmonialData.message}
                    </div>
                    <hr className="grey-line"></hr>
                  </div>
                  <div className="testimonial-card-lower-container">
                    <div className="testimonial-card-reviewer-image">
                      <img
                        alt="rudrangImage"
                        className="testimonial-user-image"
                        src={testmonialData.img_src}
                      />
                    </div>
                    <div className="testimonial-card-reviewer-name">
                      {testmonialData.name}
                    </div>
                    <div className="testimonial-card-reviewer-profession">
                      {testmonialData.profession}
                    </div>
                    <div className="testimonial-card-review-star-box">
                      <div>
                        <img
                          alt="starIcon"
                          className="star-icon-desktop-image"
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png"
                        />
                      </div>
                      <div>
                        <img
                          alt="starIcon"
                          className="star-icon-desktop-image"
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png"
                        />
                      </div>
                      <div>
                        <img
                          alt="starIcon"
                          className="star-icon-desktop-image"
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png"
                        />
                      </div>
                      <div>
                        <img
                          alt="starIcon"
                          className="star-icon-desktop-image"
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png"
                        />
                      </div>
                      <div>
                        <img
                          alt="starIcon"
                          className="star-icon-desktop-image"
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}





      {!changeView ? (
        <div className="">
          <div className="textbox2 pink-container-family">
            Prepare Your Pet for Grooming Visits{" "}
          </div>
          <hr className="vector2"></hr>
          <p style={{ fontWeight: "", fontSize: "20px", fontFamily: "DM Sans", textAlign: "center" }}>We can <span className="pink-bold">Help!</span></p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="dog-container">
              <div
                className="dog-box-2"
                id="scrollable-div2-mobile"
                style={{ overflowX: "scroll" }}
              >
                <div className="social-page-mobile-view-card-container">
                  <div className="social-page-mobile-view-card-image">
                    <img
                      alt="cardImage"
                      className="social-page-mobile-view-card-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-blog-1.webp"
                    />
                  </div>
                  <div className="social-page-mobile-blog-text"></div>
                  <div className="social-page-mobile-view-card-title">
                    How to Brush Your Dog’s teeth?
                  </div>
                  <div>
                    <Link
                      className="social-page-mobile-view-card-read"
                      to="/blog/how-to-brush-your-dog’s-teeth-60"
                    >
                      Read More
                    </Link>
                  </div>
                </div>

                <div className="social-page-mobile-view-card-container">
                  <div className="social-page-mobile-view-card-image">
                    <img
                      alt="cardImage"
                      className="social-page-mobile-view-card-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-blog-2.webp"
                    />
                  </div>
                  <div className="social-page-mobile-blog-text"></div>
                  <div className="social-page-mobile-view-card-title">
                    4 Signs that your Dog has ticks
                  </div>
                  <div>
                    <Link
                      className="social-page-mobile-view-card-read"
                      to="/blog/4-signs-your-dog-has-ticks"
                    >
                      Read More
                    </Link>
                  </div>
                </div>

                <div className="social-page-mobile-view-card-container">
                  <div className="social-page-mobile-view-card-image">
                    <img
                      alt="cardImage"
                      className="social-page-mobile-view-card-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-blog-3.webp"
                    />
                  </div>
                  <div className="social-page-mobile-blog-text"></div>
                  <div className="social-page-mobile-view-card-title">
                    Reasons you should groom your Dog
                  </div>
                  <div>
                    <Link
                      className="social-page-mobile-view-card-read"
                      to="/blog/reasons-you-should-groom-your-dog-29"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{ width: "100%", padding: "10px", textAlign: "center" }}
            >
              {/* <input type="range" min="0" max="100" step="15" onChange={handleRangeChange} style={{width:"100%"}} /> */}
              {/* <Slider
                min={0}
                max={100}
                value={valueMobile2}
                
                onChange={handleChangeMobile2}
                tooltip={false}
              /> */}
              {/* <center style={{}}>
                <Loader color="#ff5e95" />
                </center> */}
            </div>
          </div>
        </div>
      ) : null}

      {!changeView ? null : (
        <div className="dog-container-desktop" >
          <div className="dog-slide-desktop-grooming" >
            <div className="grooming-page-behave-box1">
              <div>Prepare Your Pet</div>
              <div>for Grooming Visits</div>
            </div>
            <div
              className="line-div"
              style={{ position: "relative", bottom: "20px" }}
            >
              <hr className="live-training-pink-line"></hr>
            </div>
            <div
              className="grooming-page-behave-box2"
              style={{ position: "relative", bottom: "30px" }}
            >
              <div>Give your pet the pampering they deserve.</div>
              <div>Read our blog posts to learn more.</div>
            </div>
            <div
              className="training-page-behave-box3"
              style={{ position: "relative", bottom: "20px" }}
            >
              We Can <span className="pink">Help!</span>
            </div>
          </div>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          > */}
          <div
            className="dog-box-desktop-training"
            id="scrollable-div2"
          // style={{ overflowX: "scroll" }}
          >
            <div className="educational-content-card-training">
              <div className="educational-content-card-training-upper">
                <img
                  className="educational-content-card-training-image"
                  alt="eduCard"
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-blog-1.webp"
                />
                <div className="educational-content-card-training-text">
                  Grooming
                </div>
                <div className="educational-content-card-training-head">
                  How to Brush Your Dog’s teeth?
                </div>
              </div>
              <div className="educational-content-card-training-lower">
                <div className="">
                  <Link
                    className="educational-content-card-training-link"
                    to="/blog/how-to-brush-your-dog’s-teeth-60"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>

            <div className="educational-content-card-training">
              <div className="educational-content-card-training-upper">
                <img
                  className="educational-content-card-training-image"
                  alt="eduCard"
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-blog-2.webp"
                />
                <div className="educational-content-card-training-text">
                  Grooming
                </div>
                <div className="educational-content-card-training-head">
                  4 Signs that your Dog has ticks
                </div>
              </div>
              <div className="educational-content-card-training-lower">
                <div className="">
                  <Link
                    className="educational-content-card-training-link"
                    to="/blog/4-signs-your-dog-has-ticks"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>

            <div className="educational-content-card-training">
              <div className="educational-content-card-training-upper">
                <img
                  className="educational-content-card-training-image"
                  alt="eduCard"
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-blog-3.webp"
                />
                <div className="educational-content-card-training-text">
                  Grooming
                </div>
                <div className="educational-content-card-training-head">
                  Reasons you should groom your Dog
                </div>
              </div>
              <div className="educational-content-card-training-lower">
                <div className="">
                  <Link
                    className="educational-content-card-training-link"
                    to="/blog/reasons-you-should-groom-your-dog-29"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* </div> */}
        </div>
      )}

      {/* {!changeView ? (
        <div className="training-page-mobile-view-why-container">
          <div className="services-container2">
            <div className="service-box">
              <div
                className="textbox2"
                style={{ color: "#5D5D5D", fontWeight: 700 }}
              >
                Why Choose Petmojo?
              </div>
              <hr className="vector1"></hr>
              <div className="service-1">
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/trust-icon.png"
                    alt="trust icon"
                    className="trust-icon"
                  ></img>
                </LazyLoad>
                <div
                  className="service-info"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  Trusted & Caring Trainers
                </div>
              </div>
              <div className="service-2">
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/door-icon.png"
                    alt="door icon"
                    className="door-icon"
                  ></img>
                </LazyLoad>
                <div
                  className="service-info"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  Service at Your Door-Step{" "}
                </div>
              </div>
              <div className="service-3">
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/refund-icon.png"
                    alt="refund icon"
                    className="refund-icon"
                  ></img>
                </LazyLoad>
                <div
                  className="service-info"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  Guaranteed Satisfaction{" "}
                </div>
              </div>
              <div className="service-4">
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/team-icon.png"
                    alt="team icon"
                    className="team-icon"
                  ></img>
                </LazyLoad>
                <div
                  className="service-info"
                  style={{ color: "white", fontWeight: 700 }}
                >
                  Local Onground Support
                </div>
              </div>
              
            </div>
          </div>
        </div>
      ) : (
        <div className="home-page-why-choose-container">
          <div className="home-page-why-choose-container1">
            <div className="home-page-why-choose-container1-heading">
              <div>Why Choose</div>
              <div>Petmojo?</div>
            </div>

            <div className="line-div">
              <hr className="live-training-pink-line"></hr>
            </div>

            <div className="home-page-why-choose-container1-content">
              <div>We are worlds most empathetic</div>
              <div>Pet Care Services.</div>
            </div>

            <div className="bookingbutton" style={{ marginTop: "20px" }}>
              <div onClick={() => scrollToTop()} state={{ type: "grooming" }}>
                <button className="booksession">
                  Book a Professional Groomer
                </button>
              </div>
            </div>
          </div>

          <div className="home-page-why-choose-container2">
            <div className="service-1">
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/trust-icon.png"
                  alt="trust icon"
                  className="trust-icon"
                ></img>
              </LazyLoad>
              <div
                className="service-info"
                style={{ color: "white", fontWeight: 700 }}
              >
                Trusted & Caring Trainers
              </div>
            </div>
            <div className="service-2">
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/door-icon.png"
                  alt="door icon"
                  className="door-icon"
                ></img>
              </LazyLoad>
              <div
                className="service-info"
                style={{ color: "white", fontWeight: 700 }}
              >
                Service at Your Door-Step{" "}
              </div>
            </div>
            <div className="service-3">
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/refund-icon.png"
                  alt="refund icon"
                  className="refund-icon"
                ></img>
              </LazyLoad>
              <div
                className="service-info"
                style={{ color: "white", fontWeight: 700 }}
              >
                Guaranteed Satisfaction{" "}
              </div>
            </div>
            <div className="service-4">
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/team-icon.png"
                  alt="team icon"
                  className="team-icon"
                ></img>
              </LazyLoad>
              <div
                className="service-info"
                style={{ color: "white", fontWeight: 700 }}
              >
                Local Onground Support
              </div>
            </div>
          </div>

          <div className="home-page-why-choose-container3">
            <img
              className="home-page-why-choose-container3-img"
              alt="heroImg"
              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/desktop-bg-dog.webp"
            />
          </div>
        </div>
      )} */}

      {!changeView ? (
        <div className="pink-container-family" style={{ paddingTop: "0px", marginTop: "20px", marginBottom: "0px" }}>
          <div className="textbox2">We are Family of Pet Lovers</div>
          <hr className="vector2"></hr>

          <div className="textbox3">
            Fully Trained and Certified Trainers/Groomers.
            <br /> <span className="pinktext">Top 1% </span>
            <span className="grey-text">
              of all Trainers/Groomers in India. Rigorous quality and audit
              checks
            </span>
          </div>

          <div className="trainer-container">
            <div className="trainer-cards-mobile ">
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/rectangle-box.png"
                  alt="trainercardborder"
                  className="trainer-card-border-mobile"
                ></img>
              </LazyLoad>
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/trainee-testimonials-mobile-1.webp"
                  alt="trainercard1"
                  className="trainer-card-mobile"
                ></img>
              </LazyLoad>
            </div>
            <div className="trainer-cards-mobile">
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/rectangle-box.png"
                  alt="trainercardborder"
                  className="trainer-card-border-mobile"
                ></img>
              </LazyLoad>
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/trainee-testimonials-mobile-2.webp"
                  alt="trainercard2"
                  className="trainer-card-mobile"
                ></img>
              </LazyLoad>
            </div>
            <div className="trainer-cards-mobile">
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/rectangle-box.png"
                  alt="trainercardborder"
                  className="trainer-card-border-mobile"
                ></img>
              </LazyLoad>
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/trainee-testimonials-mobile-3.webp"
                  alt="trainercard2"
                  className="trainer-card-mobile"
                ></img>
              </LazyLoad>
            </div>
          </div>
          <div className="bookingbutton" style={{ padding: "30px", position: "relative", bottom: "30px" }}>
            <div
              onClick={() => scrollToTop()}
              state={{ type: "grooming" }}
              style={{ width: "100%" }}
            >
              <button
                className="booksession-mobile-view-grooming"
                style={{ width: "100%", fontSize: "17px" }}
              >
                Book a Professional Groomer
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="pink-container-desktop">
          <div className="pink-container-left">
            <div className="pink-container-left-box1">
              <div className="textbox2-desktop">
                We are Family of Pet Lovers
              </div>
            </div>

            <div className="line-div2">
              <hr
                className="live-training-pink-line"
                style={{ position: "relative", bottom: "20px" }}
              ></hr>
            </div>

            <div
              className="pink-container-left-box2"
              style={{ position: "relative", bottom: "15px" }}
            >
              <div>Fully Trained and Certified</div>
              <div>
                Trainers. <span className="pink-bold">Top 1%</span> of all
                trainers in
              </div>
              <div>India. Rigorous quality and audit</div>
              <div>checks.</div>
            </div>
            <div
              className="bookingbutton"
              style={{
                marginTop: "20px",
                position: "relative",
                bottom: "20px",
              }}
            >
              <div onClick={() => scrollToTop()} state={{ type: "grooming" }}>
                <button className="booksession">
                  Book a Professional Groomer
                </button>
              </div>
            </div>
            {/* <div className="trainer-container">
              <div className="trainer-cards">
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/rectangle-box.png"
                    alt="trainercardborder"
                    className="trainer-card-border-desktop"
                  ></img>
                </LazyLoad>
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8926_criqhc.png"
                    alt="trainercard1"
                    className="trainer-card-desktop"
                  ></img>
                </LazyLoad>
              </div>
              <div className="trainer-cards">
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/rectangle-box.png"
                    alt="trainercardborder"
                    className="trainer-card-border-desktop"
                  ></img>
                </LazyLoad>
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8928_rtscxb.png"
                    alt="trainercard2"
                    className="trainer-card-desktop"
                  ></img>
                </LazyLoad>
              </div>

              <div className="trainer-cards">
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/rectangle-box.png"
                    alt="trainercardborder"
                    className="trainer-card-border-desktop"
                  ></img>
                </LazyLoad>
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8930_nsgnok.png"
                    alt="trainercard3"
                    className="trainer-card-desktop"
                  ></img>
                </LazyLoad>
              </div>
            </div> */}
          </div>

          <div className="pink-container-right">
            <div className="pink-container-right-head">
              Some of Our Top Trainers
            </div>
            <div className="trainer-container">
              <div className="trainer-cards">
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/rectangle-box.png"
                    alt="trainercardborder"
                    className="trainer-card-border-desktop"
                  ></img>
                </LazyLoad>
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8926_criqhc.png"
                    alt="trainercard1"
                    className="trainer-card-desktop"
                  ></img>
                </LazyLoad>
              </div>
              <div className="trainer-cards">
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/rectangle-box.png"
                    alt="trainercardborder"
                    className="trainer-card-border-desktop"
                  ></img>
                </LazyLoad>
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8928_rtscxb.png"
                    alt="trainercard2"
                    className="trainer-card-desktop"
                  ></img>
                </LazyLoad>
              </div>

              <div className="trainer-cards">
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/rectangle-box.png"
                    alt="trainercardborder"
                    className="trainer-card-border-desktop"
                  ></img>
                </LazyLoad>
                <LazyLoad>
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8930_nsgnok.png"
                    alt="trainercard3"
                    className="trainer-card-desktop"
                  ></img>
                </LazyLoad>
              </div>
            </div>
            {/* <div className="pink-container-left-box1">
              <div className="textbox2-desktop" style={{textAlign:"right"}}>
                We are Family of Pet Lovers
              </div>
            </div>

            <div className="line-div2" style={{display:"flex",justifyContent:"flex-end",width:"100%"}}>
              <div>
              <hr
                className="live-training-pink-line"
                style={{ position: "relative", bottom: "15px" }}
              ></hr>
              </div>
            </div>

            <div
              className="pink-container-left-box2"
              style={{ position: "relative", bottom: "5px",alignItems:"flex-end" }}
            >
              <div>Fully Trained and Certified</div>
              <div>
                Trainers. <span className="pink-bold">Top 1%</span> of all
                trainers in
              </div>
              <div>India. Rigorous quality and audit</div>
              <div>checks.</div>
            </div>
            <div
              className="bookingbutton"
              style={{
                marginTop: "20px",
                position: "relative",
                bottom: "10px",
                alignItems:"flex-end"
              }}
            >
              <div onClick={() => scrollToTop()} state={{ type: "grooming" }}>
                <button className="booksession">
                Book a Professional Groomer
                </button>
              </div>
            </div> */}
          </div>
        </div>
      )}
      {!changeView ? (
        <div className="" style={{ marginBottom: "20px", backgroundImage: "linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(https://websitefilesbucket.s3.ap-south-1.amazonaws.com/new-image.jfif)", backgroundRepeat: "no-repeat", display: "flex", flexDirection: "column", alignItems: "center", fontFamily: "DM Sans", color: "white", padding: "20px 20px", fontSize: "20px", backgroundSize: "cover" }}>
          <div className="" style={{ textAlign: "center", padding: "0px 30px", lineHeight: "32px" }}>
            All groomers at Petmojo have a minimum of three years' professional grooming experience.
          </div>
          <div style={{ textAlign: "center", padding: "0px 30px", marginTop: "20px", lineHeight: "32px" }}>
            We assure that your pet is in capable hands and highly trusted company. You can blindly trust our services and service providers.
          </div>
          {/* Expert in variety of Pet Hairstyles for every breed
Carefully Selected Non Allergic, Animal Friendly Products
Verified Groomers by a rigorous background check
500+ monthly pet grooming sessions */}
          <div className="groomer-meet-section-2" style={{ textAlign: "center", padding: "0px 30px", marginTop: "20px", marginBottom: "20px" }}>
            <div className="groomer-meet-section-2-subtext" style={{ fontSize: "14px", display: "flex", gap: "5px" }}>
              <div >
                <img src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/secure-tick.png" alt="" style={{ height: "40px", width: "40px" }} />
              </div>
              <div style={{ display: "flex", alignItems: "center", lineHeight: "18px" }}>
                Your pet is our family, We do Rigorous quality and audit checks
              </div>
            </div>

          </div>
          {/* <div className="groomer-meet-section-3" style={{ width: "100%" }}>
            <div className="bookingbutton" style={{ padding: "10px" }}>
              <div
                onClick={() => scrollToTop()}
                state={{ type: "grooming" }}
                style={{ width: "100%" }}
              >
                <button
                  className="booksession-mobile-view-grooming"
                  style={{ width: "100%",fontSize:"17px" }}
                >
                  Book a Professional Groomer
                </button>
              </div>
            </div>
          </div> */}
        </div>
      ) : (
        <div className="" style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "150px 120px",
          alignItems: "center",
          fontFamily: "DM Sans",
          color: "white",
          backgroundImage: "linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(https://websitefilesbucket.s3.ap-south-1.amazonaws.com/new-image.jfif)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "70vh",
          flexDirection: "column", fontFamily: "DM Sans"
        }}>
          {/* <div className="grooming-page-trainer-meet-left">
            <div className="grooming-page-trainer-meet-head3">
              <h2 className="fifty-font-size bold-none">
                Look{" "}
                <span className="grooming-page-trainer-meet-pink">
                  Fabulous
                </span>{" "}
                Guarantee
              </h2>
            </div>
            <div className="line-div ">
              <hr
                className="live-training-pink-line"
                style={{ position: "relative", bottom: "50px" }}
              ></hr>
            </div>
            <div className="bookingbutton" style={{ marginTop: "0px" }}>
              <div
                onClick={() => scrollToTop()}
                state={{ type: "grooming" }}
                style={{ position: "relative", bottom: "80px" }}
              >
                <button
                  className="booksession-mobile-view"
                  style={{ width: "max-content" }}
                >
                 Book a Professional Groomer
                </button>
              </div>
            </div>
          </div>

          <div className="grooming-page-trainer-meet-right">
            <div
              className="grooming-page-trainer-meet-right-head"
              style={{ marginBottom: "0px" }}
            >
              <h3
                className="bold-none grooming-page-trainer-meet-right-head"
                style={{ fontSize: "45px" }}
              >
                Expert and Empathetic Pet Groomers
              </h3>
            </div>

            <div
              className="grooming-page-trainer-meet-subtext"
              style={{ marginTop: "0px", fontSize: "20px" }}
            >
              
              <div style={{ display: "flex" }}>
                <div>
                  <li></li>
                </div>
                <div>Expert in variety of Pet Hairstyles for every breed</div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <li></li>
                </div>
                <div>Carefully Selected Non Allergic, Animal Friendly Products</div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <li></li>
                </div>
                <div>Verified Groomers by a rigorous background check</div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <li></li>
                </div>
                <div>500+ monthly pet grooming sessions</div>
              </div>

            </div>
          </div> */}
          <div style={{ textAlign: "center", fontSize: "35px", lineHeight: "45px" }}>
            All groomers at Petmojo have a minimum of three years' professional grooming experience. We assure that your pet is in capable hands and highly trusted company. You can blindly trust our services and service providers.
          </div>
          <div style={{ textAlign: "center", fontSize: "20px", lineHeight: "22px", marginTop: "50px", display: "flex", gap: "10px" }}>
            <div>
              <img src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/secure-tick.png" alt="" style={{ height: "54px", width: "54px" }} />

            </div>
            <div style={{ display: "flex", alignItems: "center" }}>Your pet is our family, We do Rigorous quality and audit checks</div>
          </div>
        </div>
      )}
      {!changeView ? (
        <FAQContainerGrooming view={"mobile"} />
      ) : (
        <FAQContainerGrooming view={"desktop"} />
      )}

      {!changeView ? (
        <div
          className="bookingbutton grey-background"
          style={{ paddingLeft: "30px", paddingRight: "30px", position: "relative", bottom: "60px" }}
        >
          <div
            onClick={() => scrollToTop()}
            state={{ type: "grooming" }}
            style={{ width: "100%" }}
          >
            <button
              className="booksession-mobile-view-grooming"
              style={{ width: "100%", fontSize: "17px" }}
            >
              Book a Professional Groomer
            </button>
          </div>
        </div>
      ) : null}

      {/* {!changeView ? (
        <div className="pink-container-family" style={{paddingTop:"0px",position:"relative",bottom:"20px"}}>
          <div className="textbox2">We’ll Make Your Pet Smile</div>
          <hr className="vector2"></hr>

          <div className="heroImage1Container">
            <div className="heroImage1">
              <img
                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Group_8580_hjildd.png"
                alt="makepetsmile"
                className="make-pet-smile"
              ></img>
              <div className="petmojo-partner-mobile-view-name">
                Petmojo Partner -{" "}
                <span className="petmojo-partner-mobile-view-name-cursive">
                  Kartik Rai
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {!changeView ? null : (
        <div className="hero-image-container-desktop">
          <div className="hero-image-container-desktop-left">
            <div className="pet-smile-home-set1">
              <div>We'll Make Your</div>
              <div>Pet Smile</div>
              <div className="line-div">
                <hr className="live-training-pink-line"></hr>
              </div>
            </div>

            <div className="pet-smile-home-set2">Your Pet Our Family!</div>
          </div>

          <div className="hero-image-container-desktop-right">
            <img
              className="pet-smile-img"
              alt="heroImg"
              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/desktop-petmojo-partner.webp"
            />
            <div className="petmojo-partner-name-desktop">
              Petmojo Partner -{" "}
              <span className="petmojo-partner-name-desktop-cursive">
                Kartik Rai
              </span>
            </div>
          </div>
        </div>
      )} */}

      <div className="giveSpace" style={{ marginTop: "0px" }}>
        {!changeView ? (
          <div className="pink-container-family" style={{ paddingTop: "0px", position: "relative", bottom: "20px" }}>
            <div className="textbox2">Download Our App Now</div>
            <hr className="vector2"></hr>
          </div>
        ) : null}

        {!changeView ? (
          <div className="iphone-container">
            <div className="storeContainer_logo_container_mobile">
              <img
                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/petmojo-dark-logo-.png"
                alt=""
                style={{ objectFit: "contain" }}
              />
            </div>
            <div>
              <img
                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/get-petmojo-application-mobile.webp"
                alt="iphoneicon"
                className="iphone-icon"
              ></img>
            </div>

            <div className="download-btn-container" ref={sectionTwoRef}>
              <div className="playstore-icon">
                <a
                  target="_blank" rel="nofollow"
                  href="https://play.google.com/store/apps/details?id=in.tamely.user"
                >
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/playstore_ordt0r.png"
                    alt="playstore"
                    style={{ objectFit: "contain" }}
                    id="section4"
                  ></img>
                </a>
              </div>
              <div className="appstore-icon">
                <a
                  target="_blank" rel="nofollow"
                  href="https://apps.apple.com/in/app/petmojo/id1609861328"
                >
                  <img
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/appstore_lmgm04.png"
                    alt="appstore"
                    style={{ objectFit: "contain" }}
                    id="section3"
                  ></img>
                </a>
              </div>
            </div>
          </div>
        ) : null}
      </div>

      {!changeView ? null : (
        <div className="storeContainer">
          <div className="storeContainer_left">
            <h2 className="storeContainer_heading" >
              Get Petmojo Application
            </h2>

            <hr className="live-training-pink-line"></hr>

            <p className="storeContainer_text">
              Download our mobile application to get exclusive offers and discounts on Pet Grooming.
            </p>
            <div className="pink-container-left-box3" style={{ marginTop: "10px" }} >
              <div className="bookingbutton">
                {/* <Link to="/dog-running" state={{ type: "dog-running" }}> */}
                <button className="booksession" onClick={scrollToTop}>Book a Professional Groomer!</button>
                {/* </Link> */}
                <div className="limitedslots">
                  Hurry Up, Limited slots left!
                </div>
              </div>
            </div>
          </div>

          <div className="storeContainer_right">
            <div className="storeContainer_logo_container">
              <img
                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/petmojo-dark-logo-.png"
                alt=""
                style={{ objectFit: "contain" }}
              />
            </div>
            <div className="storeContainer_main_image">
              <img
                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/get-petmojo-application.webp"
                alt=""
              />
            </div>

            <div className="storeContainer_button_container">
              <a
                target="_blank" rel="nofollow"
                href="https://play.google.com/store/apps/details?id=in.tamely.user"
              >
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/play-store-download.png"
                  alt=""
                />
              </a>
              <a
                target="_blank" rel="nofollow"
                href="https://apps.apple.com/in/app/petmojo/id1609861328"
              >
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/app-store-download.png"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      )}

      <div style={{ margin: "2rem 0rem" }}>
        {!changeView ? (
          <div>
            <div className="textbox2">Connect With Us</div>
            <hr className="vector2"></hr>

            <div className="ellipse-container">
              <div className="ellipse">
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/outer-ring.png"
                  alt="outerring"
                  className="outer-ring"
                ></img>

                <img
                  src={
                    "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/inner-circle.png"
                  }
                  alt="innerring"
                  className="inner-ring"
                ></img>

                <button className="youtube-icon">
                  <a
                    href="https://youtube.com/channel/UCPQrCllizVvTOkSk7_1SIHw"
                    target="_blank"
                    rel="nofollow"
                  >
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/yt-icon.png"
                      alt="youtubeicon"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
                <button className="facebook-icon">
                  <a
                    href="https://www.facebook.com/petmojo.care/"
                    target="_blank"
                    rel="nofollow"
                  >
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/fb-icoc.png"
                      alt="facebookicon"
                    ></img>
                  </a>
                </button>
                <button className="twitter-icon">
                  <a href="https://twitter.com/Petmojo_App" target="_blank" rel="nofollow">
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/twitter-icon.png"
                      alt="twittericon"
                    ></img>
                  </a>
                </button>
                <button className="instagram-icon">
                  <a
                    href="https://www.instagram.com/petmojo.care/"
                    target="_blank"
                    rel="nofollow"
                  >
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/insta-icon.png"
                      alt="instagramicon"
                    ></img>
                  </a>
                </button>
                <button className="phone-icon">
                  <a href="tel:+91 8889991800" target="_blank" rel="nofollow">
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/phone-icon.png"
                      alt="phoneicon"
                    ></img>
                  </a>
                </button>
                <button className="gmail-icon">
                  <a
                    href="mailto: customercare@petmojo.in"
                    target="_blank"
                    rel="nofollow"
                  >
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/gm-icon.png"
                      alt="gmailicon"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
                <button className="whatsapp-icon">
                  <a href="tel:+91 8889991800" target="_blank" rel="nofollow">
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/whatsapp-icon.png"
                      alt="whatsappicon"
                    ></img>
                    {/* </LazyLoad> */}
                  </a>
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>

      {!changeView ? null : (
        <ConnectWithUs />
      )}

      {!changeView ? null : (
        <div className="footerDetailsContainer">
          <div className="footerDetailsCont1">
            <div className="footerDetailsHeading">Need Help</div>
            <hr className="footerDetailsLine"></hr>
            <div className="footerDetailsContentContainer">
              <div className="footer-details-content-call-mail">
                <div>
                  <img
                    alt="icon"
                    className="icon-desktop-call"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/more-contact-icon.png"
                  />
                </div>
                <div>
                  <a
                    className="footerDetailsLink"
                    target="_blank"
                    href="tel: 8889991800" rel="nofollow"
                  >
                    8889991800
                  </a>
                </div>
              </div>

              <div className="footer-details-content-call-mail">
                <div>
                  <img
                    alt="icon"
                    className="icon-desktop-mail"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pink-mail-icon.png"
                  />
                </div>
                <div>
                  <a
                    className="footerDetailsLink"
                    target="_blank"
                    href="mailto: customercare@petmojo.in" rel="nofollow"
                  >
                    customercare@petmojo.in
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="footerDetailsCont1">
            <div className="footerDetailsHeading">Services</div>
            <hr className="footerDetailsLine"></hr>
            <div className="footerDetailsContentContainer">
              <Link
                className="footerDetailsLink"
                to="/dog-running"
                state={{ from: "grooming" }}
              >
                <div>Daily Dog Exercise/Walking</div>
              </Link>
              <Link
                className="footerDetailsLink"
                to="/dog-training"
                state={{ from: "grooming" }}
              >
                <div>Dog Training</div>
              </Link>
              <Link
                className="footerDetailsLink"
                to="/cat-training"
                state={{ from: "training" }}
              >
                <div>Cat Training</div>
              </Link>
              <Link
                className="footerDetailsLink"
                to="/grooming"
                state={{ from: "grooming" }}
              >
                <div>Pet Grooming</div>
              </Link>
              <Link
                className="footerDetailsLink"
                to="/grooming-subscription"
                state={{ from: "footer" }}
              >
                <div>Grooming Subscription</div>
              </Link>
              <Link
                className="footerDetailsLink"
                to="/pet-boarding"
                state={{ from: "grooming" }}
              >
                <div>Pet Hotel</div>
              </Link>
              {/* <Link
                className="footerDetailsLink"
                to="/styling"
                state={{ from: "grooming" }}
              >
                <div>Pet Styling</div>
              </Link> */}
            </div>
          </div>
          <div className="footerDetailsContr1">
            <div className="footerDetailsHeading">Serving In</div>
            <hr className="footerDetailsLine"></hr>
            <div className="footerDetailsContentContainer">
              <div className="footerDetailsLink">Delhi</div>
              <div className="footerDetailsLink">Gurgaon</div>
              <div className="footerDetailsLink">Noida</div>
              <div className="footerDetailsLink">Ghaziabad</div>
              <div className="footerDetailsLink">Faridabad</div>
              <div className="footerDetailsLink">Manesar</div>
              <div className="footerDetailsLink">Mumbai</div>
            </div>
          </div>
          <div className="footerDetailsCont1">
            <div className="footerDetailsHeading">More</div>
            <hr className="footerDetailsLine"></hr>
            <div className="footerDetailsContentContainer">
              <Link className="footerDetailsLink" to="/partner">
                <div>Become a Partner</div>
              </Link>
              <Link className="footerDetailsLink" to="/terms">
                <div>Terms and Conditions</div>
              </Link>
              <Link className="footerDetailsLink" to="/privacy">
                <div>Privacy Policy</div>
              </Link>
              <Link className="footerDetailsLink" to="/">
                <div>Privacy Policy - Partner</div>
              </Link>
              <Link className="footerDetailsLink" to="/customer-support-policy">
                <div>Customer Support Policy</div>
              </Link>
              <Link className="footerDetailsLink" to="/reviews">
                                    <div>Petmojo Reviews</div>
                                </Link>
              <Link className="footerDetailsLink" to="/about">
                <div>About Us</div>
              </Link>
            </div>
          </div>
        </div>
      )}

      {!changeView ? (
        <div>
          <div className="mobile-view-footer-1">
            <div className="mobile-view-footer-1-left">
              <div className="mobile-view-footer-1-left-header-container">
                <div className="mobile-view-footer-1-header">Need Help</div>
                <hr className="vector2-footer"></hr>
              </div>
              <div className="mobile-view-footer-1-content-left">
                <div className="mobile-view-footer-phone-container">
                  <div className="mobile-view-footer-phone-icon">
                    <img
                      alt="telIcon"
                      className="mobile-view-tel-icon"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pink-phone-icon.png"
                    />
                  </div>
                  <div className="">
                    <a
                      className="mobile-view-footer-phone-text"
                      style={{ textDecoration: "none" }}
                      href="tel: +91 8889991800"
                      target="_blank" rel="nofollow"
                    >
                      8889991800
                    </a>
                  </div>
                </div>
                <div className="mobile-view-footer-mail-container">
                  <div className="mobile-view-footer-mail-icon">
                    <img
                      alt="mailIcon"
                      className="mobile-view-mail-icon"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pink-mail-icon.png"
                    />
                  </div>
                  <div className="">
                    <a
                      className="mobile-view-footer-mail-text"
                      style={{ textDecoration: "none" }}
                      href="mailto: customercare@petmojo.in"
                      target="_blank" rel="nofollow"
                    >
                      customercare@petmojo.in
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="mobile-view-footer-1-right">
              <div className="mobile-view-footer-1-header">Services</div>
              <hr className="vector2-footer"></hr>
              <div className="mobile-view-footer-1-right-content">
                <Link
                  className="mobile-view-footer-page-link"
                  style={{ textDecoration: "none" }}
                  to="/dog-running"
                  state={{ from: "grooming" }}
                >
                  Dog Exercise/Walking
                </Link>
              </div>
              <div className="mobile-view-footer-1-right-content">
                <Link
                  className="mobile-view-footer-page-link"
                  style={{ textDecoration: "none" }}
                  to="/dog-training"
                  state={{ from: "grooming" }}
                >
                  Dog Training
                </Link>
              </div>
              <div className="mobile-view-footer-1-right-content">
                <Link
                  className="mobile-view-footer-page-link"
                  style={{ textDecoration: "none" }}
                  to="/grooming"
                  state={{ from: "grooming" }}
                >
                  Pet Grooming
                </Link>
              </div>
              <div className="mobile-view-footer-1-right-content">
                <Link
                  className="mobile-view-footer-page-link"
                  style={{ textDecoration: "none" }}
                  to="/pet-boarding"
                  state={{ from: "grooming" }}
                >
                  Pet Hotel
                </Link>
              </div>
              {/* <div className="mobile-view-footer-1-right-content">
                <Link
                  className="mobile-view-footer-page-link"
                  style={{ textDecoration: "none" }}
                  to="/styling"
                  state={{ from: "grooming" }}
                >
                  Pet Styling
                </Link>
              </div> */}
            </div>
          </div>

          <div className="mobile-view-footer-2">
            <div className="mobile-view-footer-2-left">
              <div className="footerDetailsHeading-mobile">Serving In</div>
              <hr className="footerDetailsLine"></hr>
              <div className="footerDetailsContentContainer-mobile">
                <div>
                  <div className="footerDetailsLink-mobile">Delhi</div>
                  <div className="footerDetailsLink-mobile">Gurgaon</div>
                  <div className="footerDetailsLink-mobile">Noida</div>
                  <div className="footerDetailsLink-mobile">Ghaziabad</div>
                </div>
                <div>
                  <div className="footerDetailsLink-mobile">Faridabad</div>
                  <div className="footerDetailsLink-mobile">Manesar</div>
                  <div className="footerDetailsLink-mobile">Mumbai</div>
                </div>
              </div>
            </div>
            <div className="mobile-view-footer-2-right">
              <div className="mobile-view-footer-2-right-header">More</div>
              <hr className="vector2-footer"></hr>
              <div className="mobile-view-links-div">
                <Link
                  style={{
                    textDecoration: "none",
                    color: "rgba(132, 132, 132, 1)",
                  }}
                  to="/terms"
                >
                  <div className="mobile-view-footer-2-tnc">
                    Terms and Conditions
                  </div>
                </Link>
                <Link
                  style={{
                    textDecoration: "none",
                    color: "rgba(132, 132, 132, 1)",
                  }}
                  to="/privacy"
                >
                  <div className="mobile-view-footer-2-tnc">Privacy Policy</div>
                </Link>
                <Link
                  style={{
                    textDecoration: "none",
                    color: "rgba(132, 132, 132, 1)",
                  }}
                  to="/partner"
                >
                  <div className="mobile-view-footer-2-left-content">
                    Become a Partner
                  </div>
                </Link>{" "}
                <div className="mobile-view-footer-2-left-content">
                  Privacy Policy - Partner
                </div>
                <Link
                  style={{
                    textDecoration: "none",
                    color: "rgba(132, 132, 132, 1)",
                  }}
                  to="/customer-support-policy"
                >
                  <div className="mobile-view-footer-2-left-content">
                    Customer Support Policy
                  </div>
                </Link>
                <Link
                  style={{
                    textDecoration: "none",
                    color: "rgba(132, 132, 132, 1)",
                  }}
                  to="/reviews"
                >
                  <div className="mobile-view-footer-2-left-content">
                    Petmojo Reviews
                  </div>
                </Link>
                <Link
                  style={{
                    textDecoration: "none",
                    color: "rgba(132, 132, 132, 1)",
                  }}
                  to="/about"
                >
                  <div className="mobile-view-footer-2-left-content">
                    About Us
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <div className="mobile-footer-wrap" ref={sectionThreeRef}>
            <div className="mobile-view-footer-3" style={{ gap: "5px" }}>
              <div
                className="mobile-view-footer-3-btn1"
                style={{ height: "33px" }}
              >
                <a href="https://apps.apple.com/in/app/petmojo/id1609861328" target="_blank" rel="nofollow">
                  <img
                    className="mobile-view-footer-appStore-icon "
                    alt="appStore"
                    id="section5"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/app-store-2.png"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                </a>

              </div>
              <div
                className="mobile-view-footer-3-btn2"
                style={{ height: "33px" }}
              >
                <a href="https://play.google.com/store/apps/details?id=in.tamely.user" target="_blank" rel="nofollow">
                  <img
                    className="mobile-view-footer-appStore-icon "
                    id="section6"
                    alt="playStore"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/play-store-2.png"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                </a>

              </div>
              <div
                onClick={() => goToTopHandler()}
                className="mobile-view-footer-3-btn3"
                style={{
                  height: "33px",
                  padding: "5px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <span>Go to the top</span>
                <BsArrowUp />
                {/* <div>Go to the top</div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-arrow-up"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                    />
                  </svg>
                </div> */}
              </div>
            </div>
          </div>

          <div className="mobile-view-footer-4">
            <div>Copyright 2024 Petmojo</div>
            <div>
              All copyrights are reserved - any infringement will be legally
              pursued.
            </div>
            <div>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </div>
          </div>
        </div>
      ) : null}

      {!changeView ? null : (
        <div>
          <div className="desktop-footer-buttons-container-add" style={{ display: "flex", flexDirection: "column" }}>
            <p style={{ fontWeight: "800" }}>MANGOPAW TECHNOLOGIES AND SOLUTIONS PRIVATE LIMITED</p>
            <p style={{ marginTop: "0rem" }}> Ph no- +918889991800</p>
          </div>
          <div className="desktop-footer-buttons-container">
            <div className="desktop-footer-buttons-left">
              <div className="desktop-footer-buttons-left-btn1">
                <a href="https://play.google.com/store/apps/details?id=in.tamely.user" target="_blank" rel="nofollow">

                  <img
                    alt="icon"
                    className="desktop-footer-buttons-left-btn1-img"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/play-store-download.png"
                  />
                </a>
              </div>
              <div className="desktop-footer-buttons-left-btn1">
                <a href="https://apps.apple.com/in/app/petmojo/id1609861328" target="_blank" rel="nofollow">

                  <img
                    alt="icon"
                    className="desktop-footer-buttons-left-btn1-img"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/app-store-download.png"
                  />
                </a>
              </div>
              <div
                onClick={scrollToTop}
                className="desktop-footer-buttons-left-btn3"
              >
                <div>Go at the top</div>

                <svg
                  className="top-arrow"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-arrow-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                  />
                </svg>
              </div>
            </div>

            <div className="desktop-footer-buttons-right">
              Copyright 2024 Petmojo
            </div>
          </div>
        </div>
      )}
      <GoToTop />
    </div>
  );
}

export default GroomingSubscription;
