import { BsArrowUp } from "@react-icons/all-files/bs/BsArrowUp";
import Helmet from "helmet";
import React, { useEffect, useRef, useState } from "react";
import LazyLoad from "react-lazyload";
import { Link } from "react-router-dom";
import NavigationDesktop from "../components-desktop/Navigation/NavigationDesktop";
import classes from "../pages/Landing/LandingPage.module.css";
import "./About.css";
import NavigationBar from "./Navigation/navigationBar/NavigationBar";
import ConnectWithUs from "./ConnectWithUs";

const testimonialArray = [
  {
    message:
      "Got myself Advanced Training Package for both my dog, German & Golden. I never realised what all they could learn if trained properly 🙏 Thank you so much",
    name: "Rudrang",
    img_src:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/rudrang.png",
    profession: "Tattoo Artist",
  },
  {
    message:
      "Petmojo has been a great partner in my new pet parenting journey. I worry less knowing my dog is getting trained and walks everyday.",
    name: "Ronak T",
    img_src:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/ronak-homepage.png",
    profession: "Shop Owner",
  },
  {
    message:
      "Quality Service from Petmojo Team. Definitely will go for it again 10/10",
    name: "Deepak B",
    img_src:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/deepak.png",
    profession: "Shop Owner",
  },
  {
    message:
      "The grooming process has been extremly easy and pocket-friendly! My pup looked amazing and didn’t seem distressed at all. I book their home service regurly.",
    name: "Vishal",
    img_src:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/sanket.png",
    profession: "Software Engineer",
  },
  {
    message:
      "Third time the charm. After trying 2 training from my local area, I got a very good trainer on this app. Really liked the defined approach.",
    name: "Jay Bhosle",
    img_src:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/jay.png",
    profession: "Proctor",
  },
];

function About() {
  const [showad, setShowad] = useState(true);
  const [isAndroid, setIsAndroid] = useState(false);
  const [isiOS, setIsiOS] = useState(false);
  // const removeAdd = () => {
  //   const ele = document.getElementById("grooming-popup-ad");
  //   ele.style.display = "none";
  //   setShowad(false);
  // };
  // setTimeout(() => {
  //   const ele = document.getElementById("grooming-popup-ad");
  //   ele.style.display = "flex";
  // }, 5000);
  let url;

  const userAgent = navigator.userAgent.toLowerCase();
  useEffect(() => {
    setIsAndroid(userAgent.indexOf("android") > -1);
    setIsiOS(
      userAgent.indexOf("iphone") > -1 ||
      userAgent.indexOf("ipad") > -1 ||
      userAgent.indexOf("ipod") > -1
    );
    // console.log(userAgent.indexOf("iphone"))
  }, [userAgent]);

  if (isAndroid) {
    url = "https://play.google.com/store/apps/details?id=in.tamely.user";
  } else if (isiOS) {
    url = "https://apps.apple.com/in/app/petmojo/id1609861328";
  }
  const [navDropdown, setNavDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const [changeView, setChangeView] = useState(false);

  function goToTopHandler() {
    console.log("happed");
    return window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  const scrollToTop = () => {
    console.log("to the top");
    return window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const goAtTheTop = () => {
    console.log("to the top");
    const ele = document.getElementById("scrolltop");
    ele.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setNavDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const [testmonialData, setTestimonialData] = useState({
    message:
      "Got myself Advanced Training Package for both my dog, German & Golden. I never realised what all they could learn if trained properly 🙏 Thank you so much",
    name: "Rudrang",
    img_src:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/rudrang.png",
    profession: "Tattoo Artist",
  });

  let count = 0;

  useEffect(() => {
    const targetDiv = document.querySelector("#testimonialContainer");
    console.log(targetDiv);

    const interval = setInterval(() => {
      count++;
      if (count === 5) {
        count = 0;
      }
      setTestimonialData(testimonialArray[count]);
      //console.log(" The current item is: " +count+ " " + testmonialData.name);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 1000) {
      setChangeView(false);
    } else {
      setChangeView(true);
    }
  });

  return (
    <div id="scrolltop">
      <Helmet>
        <title>Get to know more about Petmojo, our mission and vision</title>
        <meta
          name="description"
          content="Read to find out more about our passion towards providing the best services for your pets, our mission and vision"
        />
      </Helmet>
      {!changeView ? (
        <div className="about">
          <img
            className="about-page-mobile-view-intro-image"
            alt="backImage"
            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/boardingabout.webp"
          />
          <div className="about-page-mobile-view-intro-section">
            {/* {showad && (
        <div
          style={{
            width: "100%",
            backgroundColor: "white",
            height: "60px",
            display: "none",
            gap: "5px",
            alignItems: "center",
            padding: "5px",
            justifyContent: "space-evenly",
          }}
          id="grooming-popup-ad"
        >
          <div onClick={removeAdd}>
            <RxCross2 color="#5E5E5E" />
          </div>
          <div style={{ height: "100%" }}>
            <img
              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/grooming-ad-logo.png"
              alt="logo"
              style={{ height: "100%" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontFamily: "DM Sans",
              height: "100%",
            }}
          >
            <div
              style={{
                fontSize: "14px",
                color: "rgba(94, 94, 94, 1)",
                fontWeight: "700",
              }}
            >
              Petmojo
            </div>
            <div
              style={{
                fontSize: "12px",
                color: "rgba(94, 94, 94, 1)",
                fontWeight: "400",
              }}
            >
              Download our app & get <br />{" "}
              <span style={{ color: "rgba(35, 175, 0, 1)" }}>₹1000</span> in
              your Wallet{" "}
            </div>
          </div>
          <a
            href={url}
            target="_blank"
            style={{
              textDecoration: "none",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="button-group" style={{ height: "80%" }}>
              <button
                className=""
                style={{
                  textAlign: "center",
                  borderRadius: "5px",
                  outline: "none",
                  border: "2px solid #DEC269",
                  color: "#DEC269",
                  fontFamily: "DM Sans",
                  backgroundColor: "white",
                  fontWeight: "600",
                  fontSize: "16px",
                }}
              >
                DOWNLOAD
              </button>
            </div>
          </a>
        </div>
      )} */}
            <NavigationBar
              page={"white"}
              className="home-page-mobile-navigation-container"
            />

            <div className="about-page-mobile-view-intro-subsection">
              <div className="about-page-mobile-view-intro-subsection-heading">
                Who We Are
              </div>
              <div className="about-page-mobile-view-intro-subsection-text">
                Based in Delhi with Clients and Team members all over INDIA. We
                exist for Pets and to provide them Care & Training they deserve.
              </div>
              <div className="about-page-mobile-view-intro-subsection-text">
                We have 150+ Service providers who are Fully Trained and
                Certified Trainers. Top 1% of all trainers in India. Rigorous
                quality and audit checks.
              </div>
              <div className="about-page-mobile-view-intro-subsection-btn">
                <a
                  target="_blank"
                  rel="nofollow"
                  href="https://play.google.com/store/apps/details?id=in.tamely.user"
                >
                  <button className="booksession-navigation-desktop">
                    Download Our App
                  </button>
                </a>
              </div>
            </div>
          </div>
          <LazyLoad>
            <img
              src={
                "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/about-bg-1_ulxert.png"
              }
              alt="about-bg"
              className="about-bg"
            ></img>
          </LazyLoad>
          <div className="petmojo-aim">
            <div className="mission">MISSION</div>
            <div className="mission-subtitle">
              3 Billion animal lovers across the globe by 2027
            </div>
            <div className="mission-subtitle">
              {" "}
              30 Million service providers across the globe.
            </div>
          </div>
          <LazyLoad>
            <img
              src={
                "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/about-bg-2_mdg77h.png"
              }
              alt="about-bg"
              className="about-bg"
            ></img>
          </LazyLoad>
          <div className="petmojo-aim">
            <div className="mission">VISION</div>
            <div className="mission-subtitle">
              Building a community for all pet lovers & are willing to spend
              time helping other pet parents.
            </div>
          </div>

          <div className="statistics">
            <div className="stat-box">
              <div className="stat-number">08+</div>
              <div className="stat-info">
                Years Experience for Training, Walking and Grooming
              </div>
            </div>
            <div className="stat-box">
              <div className="stat-number">60+</div>
              <div className="stat-info">
                Certified, Trained and Passionate Dog Trainers
              </div>
            </div>
            <div className="stat-box">
              <div className="stat-number">500+</div>
              <div className="stat-info">
                Live Tracking’s done from remote location
              </div>
            </div>
            <div className="stat-box">
              <div className="stat-number">800+</div>
              <div className="stat-info">
                Photos and Videos Shared to our users
              </div>
            </div>
          </div>

          {!changeView ? (
            <div
              className={
                !changeView
                  ? "container grey-background"
                  : "container-desktop-walking grey-background"
              }
            >
              {!changeView ? (
                <div>
                  <div className="textbox2">Words From Pet Parents</div>
                  <hr className="vector2"></hr>
                </div>
              ) : null}

              <div class="review-container">
                <div class={!changeView ? "review-box" : "review-box-desktop"}>
                  {!changeView ? null : (
                    <div className="home-page-customers-head-walking">
                      <div className="home-page-customer-heading">
                        <div>Our Customers</div>
                        <div className="line-div">
                          <hr className="live-training-pink-line"></hr>
                        </div>
                      </div>

                      <div className="home-page-customers-contents">
                        <div>Our customers trust us for having</div>
                        <div>
                          <span className="pink">Joyful</span> and{" "}
                          <span className="pink">Positive</span> Reinforcements
                        </div>
                        <div>only, "Your Pet is Our Family!"</div>
                      </div>
                    </div>
                  )}
                  <div
                    id="testimonialContainer"
                    className={
                      !changeView ? "review-box" : "review-box-desktop-3"
                    }
                  >
                    <div
                      className={
                        !changeView
                          ? "testimonial-card-mastercontainer-mobile-view"
                          : "testimonial-card-mastercontainer-2"
                      }
                    >
                      <div className="testimonial-card-upper-container">
                        <div className="testimonial-card-quote-image">
                          <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/quote-icon.png"
                            alt="quoteIcon"
                            className="quote-icon"
                          ></img>
                        </div>
                        <div className="testimonial-card-review-content">
                          {testmonialData.message}
                        </div>
                        <hr className="grey-line"></hr>
                      </div>
                      <div className="testimonial-card-lower-container">
                        <div className="testimonial-card-reviewer-image">
                          <img
                            alt="rudrangImage"
                            className="testimonial-user-image"
                            src={testmonialData.img_src}
                          />
                        </div>
                        <div className="testimonial-card-reviewer-name">
                          {testmonialData.name}
                        </div>
                        <div className="testimonial-card-reviewer-profession">
                          {testmonialData.profession}
                        </div>
                        <div className="testimonial-card-review-star-box">
                          <div>
                            <img
                              alt="starIcon"
                              className="star-icon-desktop-image"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png"
                            />
                          </div>
                          <div>
                            <img
                              alt="starIcon"
                              className="star-icon-desktop-image"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png"
                            />
                          </div>
                          <div>
                            <img
                              alt="starIcon"
                              className="star-icon-desktop-image"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png"
                            />
                          </div>
                          <div>
                            <img
                              alt="starIcon"
                              className="star-icon-desktop-image"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png"
                            />
                          </div>
                          <div>
                            <img
                              alt="starIcon"
                              className="star-icon-desktop-image"
                              src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <div className="textbox2">Your Pet - Our Family!</div>
          <hr className="vector1"></hr>
          <div className="heartimg">
            <LazyLoad>
              <img
                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/desktop-heart-container.webp"
                alt="heart image"
                className="hearticon"
              ></img>
            </LazyLoad>
          </div>

          <div className="pink-container">
            <div className="textbox tracking-text">
              <span>
                <span className="pinktext">Track every session on our </span>{" "}
                <span className="pet-bold-text">pet</span>
                <span className="pinktext">mojo Application</span>
              </span>
            </div>

            <div className="tracking-container">
              <div className="tracking-info">
                <ul className="tracking-points">
                  <li>Report card of sessions </li>
                  <li>Tracking progress</li>
                  <li>Photos from session</li>
                  <li>Agenda for sessions</li>
                  <li>Trainers feedback</li>
                  <li>Highlights from today</li>
                </ul>
                <LazyLoad>
                  <a
                    href="https://apps.apple.com/in/app/petmojo/id1609861328"
                    rel="nofollow"
                    target="_blank"
                  >
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/appstore_lmgm04.png"
                      alt="appstore-training"
                      className="appstore"
                    ></img>
                  </a>
                </LazyLoad>
                <LazyLoad>
                  <a
                    href="https://play.google.com/store/apps/details?id=in.tamely.user"
                    target="_blank"
                    rel="nofollow"
                  >
                    <img
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/playstore_ordt0r.png"
                      alt="playstore"
                      className="playstore"
                    ></img>
                  </a>
                </LazyLoad>
              </div>
              <LazyLoad>
                <img
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/girlwithmobile.webp"
                  alt="trackingicon"
                  className="tracking-icon"
                ></img>
              </LazyLoad>
            </div>

            <div className="bookingbutton">
              <Link to="/popup" state={{ type: "dog-training" }}>
                <button className="booksession">Book a FREE Session</button>
              </Link>
              <div className="limitedslots">Hurry Up, Limited slots left!</div>
            </div>
          </div>

          <div>
            <div className="mobile-view-footer-1">
              <div className="mobile-view-footer-1-left">
                <div className="mobile-view-footer-1-left-header-container">
                  <div className="mobile-view-footer-1-header">Need Help</div>
                  <hr className="vector2-footer"></hr>
                </div>
                <div className="mobile-view-footer-1-content-left">
                  <div className="mobile-view-footer-phone-container">
                    <div className="mobile-view-footer-phone-icon">
                      <img
                        alt="telIcon"
                        className="mobile-view-tel-icon"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pink-phone-icon.png"
                      />
                    </div>
                    <div className="">
                      <a
                        className="mobile-view-footer-phone-text"
                        style={{ textDecoration: "none" }}
                        href="tel: +91 8889991800"
                        target="_blank"
                      >
                        8889991800
                      </a>
                    </div>
                  </div>
                  <div className="mobile-view-footer-mail-container">
                    <div className="mobile-view-footer-mail-icon">
                      <img
                        alt="mailIcon"
                        className="mobile-view-mail-icon"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pink-mail-icon.png"
                      />
                    </div>
                    <div className="">
                      <a
                        className="mobile-view-footer-mail-text"
                        style={{ textDecoration: "none" }}
                        href="mailto: customercare@petmojo.in"
                        target="_blank"
                        rel="nofollow"
                      >
                        customercare@petmojo.in
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mobile-view-footer-1-right">
                <div className="mobile-view-footer-1-header">Services</div>
                <hr className="vector2-footer"></hr>
                <div className="mobile-view-footer-1-right-content">
                  <Link
                    className="mobile-view-footer-page-link"
                    style={{ textDecoration: "none" }}
                    to="/dog-running"
                    state={{ from: "about" }}
                  >
                    Dog Exercise/Walking
                  </Link>
                </div>
                <div className="mobile-view-footer-1-right-content">
                  <Link
                    className="mobile-view-footer-page-link"
                    style={{ textDecoration: "none" }}
                    to="/dog-training"
                    state={{ from: "about" }}
                  >
                    Dog Training
                  </Link>
                </div>
                <div className="mobile-view-footer-1-right-content">
                  <Link
                    className="mobile-view-footer-page-link"
                    style={{ textDecoration: "none" }}
                    to="/grooming"
                    state={{ from: "about" }}
                  >
                    Pet Grooming
                  </Link>
                </div>
                <div className="mobile-view-footer-1-right-content">
                  <Link
                    className="mobile-view-footer-page-link"
                    style={{ textDecoration: "none" }}
                    to="/grooming-subscription"
                    state={{ from: "about" }}
                  >
                    Grooming Subscription
                  </Link>
                </div>
                <div className="mobile-view-footer-1-right-content">
                  <Link
                    className="mobile-view-footer-page-link"
                    style={{ textDecoration: "none" }}
                    to="/pet-boarding"
                    state={{ from: "about" }}
                  >
                    Pet Hotel
                  </Link>
                </div>
                {/* <div className='mobile-view-footer-1-right-content'><Link className='mobile-view-footer-page-link' style={{textDecoration: "none"}} to="/styling" state={{from : "about"}}>Pet Styling</Link></div> */}
              </div>
            </div>

            <div className="mobile-view-footer-2">
              <div className="mobile-view-footer-2-left">
                <div className="footerDetailsHeading-mobile">Serving In</div>
                <hr className="footerDetailsLine"></hr>
                <div className="footerDetailsContentContainer-mobile">
                  <div>
                    <div className="footerDetailsLink-mobile">Delhi</div>
                    <div className="footerDetailsLink-mobile">Gurgaon</div>
                    <div className="footerDetailsLink-mobile">Noida</div>
                    <div className="footerDetailsLink-mobile">Ghaziabad</div>
                  </div>
                  <div>
                    <div className="footerDetailsLink-mobile">Faridabad</div>
                    <div className="footerDetailsLink-mobile">Manesar</div>
                    <div className="footerDetailsLink-mobile">Mumbai</div>
                  </div>
                </div>
              </div>
              <div className="mobile-view-footer-2-right">
                <div className="mobile-view-footer-2-right-header">More</div>
                <hr className="vector2-footer"></hr>
                <div className="mobile-view-links-div">
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "rgba(132, 132, 132, 1)",
                    }}
                    to="/terms"
                  >
                    <div className="mobile-view-footer-2-tnc">
                      Terms and Conditions
                    </div>
                  </Link>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "rgba(132, 132, 132, 1)",
                    }}
                    to="/privacy"
                  >
                    <div className="mobile-view-footer-2-tnc">
                      Privacy Policy
                    </div>
                  </Link>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "rgba(132, 132, 132, 1)",
                    }}
                    to="/partner"
                  >
                    <div className="mobile-view-footer-2-left-content">
                      Become a Partner
                    </div>
                  </Link>
                  <div className="mobile-view-footer-2-left-content">
                    Privacy Policy - Partner
                  </div>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "rgba(132, 132, 132, 1)",
                    }}
                    to="/customer-support-policy"
                  >
                    <div className="mobile-view-footer-2-left-content">
                      Customer Support Policy
                    </div>
                  </Link>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "rgba(132, 132, 132, 1)",
                    }}
                    to="/reviews"
                  >
                    <div className="mobile-view-footer-2-left-content">
                      Petmojo Reviews
                    </div>
                  </Link>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "rgba(132, 132, 132, 1)",
                    }}
                    to="/about"
                  >
                    <div className="mobile-view-footer-2-left-content">
                      About Us
                    </div>
                  </Link>
                </div>
              </div>
            </div>

            <div className="mobile-footer-wrap">
              <div className="mobile-view-footer-3" style={{ gap: "5px" }}>
                <div
                  className="mobile-view-footer-3-btn1"
                  style={{ height: "33px" }}
                >
                  <a
                    href="https://apps.apple.com/in/app/petmojo/id1609861328"
                    rel="nofollow"
                    target="_blank"
                  >
                    <img
                      className="mobile-view-footer-appStore-icon appstore"
                      alt="appStore"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/app-store-2.png"
                    />
                  </a>
                </div>
                <div
                  className="mobile-view-footer-3-btn2"
                  style={{ height: "33px" }}
                >
                  <a
                    href="https://play.google.com/store/apps/details?id=in.tamely.user"
                    rel="nofollow"
                    target="_blank"
                  >
                    <img
                      className="mobile-view-footer-appStore-icon playstore"
                      alt="playStore"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/play-store-2.png"
                    />
                  </a>
                </div>
                <div
                  onClick={scrollToTop}
                  //  onClick={() => goToTopHandler()}
                  className="mobile-view-footer-3-btn3"
                  style={{
                    height: "33px",
                    padding: "5px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {/* <div>
                  Go to the top
                </div>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
                  </svg>
                </div> */}
                  <span>Go to the top</span>
                  <BsArrowUp />
                </div>
              </div>
            </div>

            <div className="mobile-view-footer-4">
              <div>Copyright 2024 Petmojo</div>
              <div>
                All copyrights are reserved - any infringement will be legally
                pursued.
              </div>
              <div>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="about-page-desktop-view-master-container">
          <img
            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/aboutwebsite.webp"
            alt="introImage"
            className="about-page-main-image-desktop"
          />

          <div className="about-page-desktop-view-intro-container">
            <NavigationDesktop
              submissionType={"none"}
              submissionText={"Download Our App"}
            />
            <div
              className="about-page-desktop-view-intro-main"
              style={{
                paddingLeft: "120px",
                paddingRight: "120px",
                paddingTop: "0px",
                paddingBottom: "0px",
              }}
            >
              <div className="about-page-desktop-view-intro-left">
                <div className="about-page-desktop-view-intro-left-head">
                  Who We Are
                </div>
                <div className="about-page-desktop-view-intro-left-content-1">
                  Based in Delhi with Clients and Team members all over INDIA.
                  We exist for Pets and to provide them Care & Training they
                  deserve.
                </div>
                <div className="about-page-desktop-view-intro-left-content-1">
                  We have 50,000+ Service providers who are Fully Trained and
                  Certified Trainers. Top 1% of all trainers in India. Rigorous
                  quality and audit checks are done.
                </div>
                <div className="about-page-desktop-view-intro-left-button">
                  <div className="desktop-navigation-booking-button">
                    <div className="">
                      <button className="booksession-navigation-desktop">
                        Download Our App
                      </button>
                    </div>
                  </div>
                </div>
                <div className="socials-page-available-text">
                  Available on Playstore and Appstore!
                </div>
              </div>
              <div className="about-page-desktop-view-intro-right">
                <img
                  className="about-page-desktop-view-mobile-image"
                  alt="mobileImage"
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/AboutPageMockupgreen.png"
                />
              </div>
            </div>
          </div>

          <div className="about-page-desktop-view-mission-container">
            <div className="about-page-desktop-view-mission-left">
              <div className="about-page-desktop-view-mission-head">
                MISSION
              </div>
              <div className="about-page-desktop-view-mission-content">
                3 Billion animal lovers across the globe by 2027
              </div>
              <div className="about-page-desktop-view-mission-content">
                30 Million service providers across the globe.
              </div>
            </div>
            <div className="about-page-desktop-view-mission-right">
              <img
                alt="aboutImage"
                className="about-page-mission-image"
                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Construction_costs-amico_1_zm4n6v.png"
              />
            </div>
          </div>

          <div className="about-page-desktop-view-mission-container">
            <div className="about-page-desktop-view-mission-right">
              <img
                alt="aboutImage"
                className="about-page-mission-image"
                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/social-ideas-desktop.png"
              />
            </div>
            <div className="about-page-desktop-view-mission-left">
              <div className="about-page-desktop-view-mission-head">VISION</div>
              <div className="about-page-desktop-view-mission-content">
                Building a community for all pet lovers & are willing to spend
                time helping other pet parents.
              </div>
            </div>
          </div>

          <div className="about-page-desktop-view-plus-container">
            <div className="plus-1-outer-container">
              <div className="plus-1-inner-container">
                <div className="number-container">08+</div>
                <div className="plus-1-text">
                  Years Experience for Training, Walking and Grooming
                </div>
              </div>
            </div>
            <div className="plus-1-outer-container">
              <div className="plus-1-inner-container">
                <div className="number-container">60+</div>
                <div className="plus-1-text">
                  Certified, Trained and Passionate Dog Trainers
                </div>
              </div>
            </div>
            <div className="plus-1-outer-container">
              <div className="plus-1-inner-container">
                <div className="number-container">500+</div>
                <div className="plus-1-text">
                  Live Tracking’s done from remote location
                </div>
              </div>
            </div>
            <div className="plus-1-outer-container">
              <div className="plus-1-inner-container">
                <div className="number-container">800+</div>
                <div className="plus-1-text">
                  Photos and Videos Shared to our users
                </div>
              </div>
            </div>
          </div>

          <div className={classes.heartSectionAboutPage}>
            <div className={classes.heartSectionTextContainer}>
              <div className={classes.heartSectionTextHeading}>
                Your Pet, Our Family 💖
              </div>
              <div className="line-div">
                <hr className="live-training-pink-line"></hr>
              </div>
              <div className={classes.heartSectionText}>
                <div>
                  <span className="pink">16000+</span> monthly happy pet
                </div>
                <div>parents on our platform!</div>
              </div>
            </div>
            <div className={classes.heartSectionImage}>
              <img
                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/desktop-heart-container.webp"
                alt="heart"
                className="hearticon"
              ></img>
            </div>
          </div>

          <div
            className={
              !changeView
                ? "container pink-container-family"
                : "container-desktop"
            }
          >
            {!changeView ? (
              <div>
                <div className="textbox2">Words From Pet Parents</div>
                <hr className="vector2"></hr>
              </div>
            ) : null}

            <div class="review-container">
              <div class={!changeView ? "review-box" : "review-box-desktop"}>
                {!changeView ? null : (
                  <div className="home-page-customers-head">
                    <div className="home-page-customer-heading">
                      <div>Our Customers</div>
                      <div className="line-div">
                        <hr className="live-training-pink-line"></hr>
                      </div>
                    </div>

                    <div className="home-page-customers-contents">
                      <div>Our customers trust us for having</div>
                      <div>
                        <span className="pink">Joyful</span> and{" "}
                        <span className="pink">Positive</span> Reinforcements
                      </div>
                      <div>only, "Your Pet is Our Family!"</div>
                    </div>
                  </div>
                )}

                <div
                  id="testimonialContainer"
                  className={
                    !changeView ? "review-box" : "review-box-desktop-3"
                  }
                >
                  <div className="testimonial-card-mastercontainer-2">
                    <div className="testimonial-card-upper-container">
                      <div className="testimonial-card-quote-image">
                        <img
                          src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/quote-icon.png"
                          alt="quoteIcon"
                          className="quote-icon"
                        ></img>
                      </div>
                      <div className="testimonial-card-review-content">
                        {testmonialData.message}
                      </div>
                      <hr className="grey-line"></hr>
                    </div>
                    <div className="testimonial-card-lower-container">
                      <div className="testimonial-card-reviewer-image">
                        <img
                          alt="rudrangImage"
                          className="testimonial-user-image"
                          src={testmonialData.img_src}
                        />
                      </div>
                      <div className="testimonial-card-reviewer-name">
                        {testmonialData.name}
                      </div>
                      <div className="testimonial-card-reviewer-profession">
                        {testmonialData.profession}
                      </div>
                      <div className="testimonial-card-review-star-box">
                        <div>
                          <img
                            alt="starIcon"
                            className="star-icon-desktop-image"
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png"
                          />
                        </div>
                        <div>
                          <img
                            alt="starIcon"
                            className="star-icon-desktop-image"
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png"
                          />
                        </div>
                        <div>
                          <img
                            alt="starIcon"
                            className="star-icon-desktop-image"
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png"
                          />
                        </div>
                        <div>
                          <img
                            alt="starIcon"
                            className="star-icon-desktop-image"
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png"
                          />
                        </div>
                        <div>
                          <img
                            alt="starIcon"
                            className="star-icon-desktop-image"
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/star-icon.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="walking-page-track-container">
            <div className="walking-page-track-right">
              <div className="walking-page-track-left-heading">
                <div className="walking-page-track-line1 pink">
                  <h2
                    className="fifty-font-size bold-none"
                    style={{ fontFamily: "DM Sans" }}
                  >
                    Track every session on our{" "}
                    <span className="dark-grey-text fifty-font-size">pet</span>
                    <span className="pink">mojo</span>{" "}
                    <span className="pink">Application</span>
                  </h2>
                </div>
                {/* <hr
                className=""
                style={{ position: "relative",bottom:"0px",width:"150px" ,borderRadius:"10px",border:"3px solid rgba(254, 119, 152, 1)"}}
              ></hr> */}
                <div className="line-div relative-line">
                  <hr className="live-training-pink-line"></hr>
                </div>
              </div>
              <div
                className="walking-page-track-left-content"
                style={{ fontFamily: "DM Sans" }}
              >
                <div className="track-session-point">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div>Report card of sessions</div>
                </div>
                <div className="track-session-point">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div>Tracking progress</div>
                </div>
                <div className="track-session-point">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div>Photos from session</div>
                </div>
                <div className="track-session-point">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div>Agenda for sessions</div>
                </div>
                <div className="track-session-point">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div>Trainers feedback</div>
                </div>
                <div className="track-session-point">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div>Highlights from today</div>
                </div>
                <div className="track-session-point">
                  <div>
                    <img
                      alt="tick"
                      className="tick-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/check-mark_3_wxvkxm.png"
                    />
                  </div>
                  <div>Photo/Video Update</div>
                </div>
              </div>
              <div className="" style={{ marginTop: "40px" }}>
                {/* <Link to="/popup" state={{ type: "dog-running" }}> */}
                <button
                  className="booksession-track"
                  onClick={() => scrollToTop()}
                  style={{ width: "" }}
                >
                  Book 1st FREE Walk
                </button>
                {/* </Link> */}
              </div>
            </div>
            <div
              className=""
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div
                className="walking-page-track-left-buttons"
                style={{
                  height: "80%",
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <div className="icons-store-section-float-2">
                  <div>
                    <a
                      target="_blank"
                      rel="nofollow"
                      href="https://apps.apple.com/in/app/petmojo/id1609861328"
                    >
                      <img
                        alt="appStore"
                        className="walking-page-track-left-buttons-store"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/image_17_txwsw4.png"
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      target="_blank"
                      rel="nofollow"
                      href="https://play.google.com/store/apps/details?id=in.tamely.user"
                    >
                      <img
                        alt="playStore"
                        className="walking-page-track-left-buttons-store"
                        src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/play-store-download.png"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="walking-page-track-left"
              style={{ display: "flex", alignItems: "flex-end" }}
            >
              <div
                style={{
                  height: "100%",
                  width: "full",
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <img
                  alt="petmojoImg"
                  className="walking-page-track-image"
                  src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/girlwithmobile.webp"
                  style={{
                    height: "524px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
              </div>
            </div>
          </div>

          <ConnectWithUs/>

          <div className="footerDetailsContainer">
            <div className="footerDetailsCont1">
              <div className="footerDetailsHeading">Need Help</div>
              <hr className="footerDetailsLine"></hr>
              <div className="footerDetailsContentContainer">
                <div className="footer-details-content-call-mail">
                  <div>
                    <img
                      alt="icon"
                      className="icon-desktop-call"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/more-contact-icon.png"
                    />
                  </div>
                  <div>
                    <a
                      className="footerDetailsLink"
                      target="_blank"
                      href="tel: 8889991800"
                      rel="nofollow"
                    >
                      8889991800
                    </a>
                  </div>
                </div>

                <div className="footer-details-content-call-mail">
                  <div>
                    <img
                      alt="icon"
                      className="icon-desktop-mail"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pink-mail-icon.png"
                    />
                  </div>
                  <div>
                    <a
                      className="footerDetailsLink"
                      target="_blank"
                      href="mailto: customercare@petmojo.in"
                      rel="nofollow"
                    >
                      customercare@petmojo.in
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="footerDetailsCont1">
              <div className="footerDetailsHeading">Services</div>
              <hr className="footerDetailsLine"></hr>
              <div className="footerDetailsContentContainer">
                <Link
                  className="footerDetailsLink"
                  to="/dog-running"
                  state={{ from: "about" }}
                >
                  <div>Daily Dog Exercise/Walking</div>
                </Link>
                <Link
                  className="footerDetailsLink"
                  to="/dog-training"
                  state={{ from: "about" }}
                >
                  <div>Dog Training</div>
                </Link>
                <Link
                  className="footerDetailsLink"
                  to="/cat-training"
                  state={{ from: "about" }}
                >
                  <div>Cat Training</div>
                </Link>
                <Link
                  className="footerDetailsLink"
                  to="/grooming"
                  state={{ from: "about" }}
                >
                  <div>Pet Grooming</div>
                </Link>
                <Link
                  className="footerDetailsLink"
                  to="/grooming-subscription"
                  state={{ from: "about" }}
                >
                  <div>Grooming Subscription</div>
                </Link>
                <Link
                  className="footerDetailsLink"
                  to="/pet-boarding"
                  state={{ from: "about" }}
                >
                  <div>Pet Hotel</div>
                </Link>
                {/* <Link className="footerDetailsLink" to="/styling" state={{from : "about"}}><div>Pet Styling</div></Link> */}
              </div>
            </div>
            <div className="footerDetailsContr1">
              <div className="footerDetailsHeading">Serving In</div>
              <hr className="footerDetailsLine"></hr>
              <div className="footerDetailsContentContainer">
                <div className="footerDetailsLink">Delhi</div>
                <div className="footerDetailsLink">Gurgaon</div>
                <div className="footerDetailsLink">Noida</div>
                <div className="footerDetailsLink">Ghaziabad</div>
                <div className="footerDetailsLink">Faridabad</div>
                <div className="footerDetailsLink">Manesar</div>
                <div className="footerDetailsLink">Mumbai</div>
              </div>
            </div>
            <div className="footerDetailsCont1">
              <div className="footerDetailsHeading">More</div>
              <hr className="footerDetailsLine"></hr>
              <div className="footerDetailsContentContainer">
                <Link className="footerDetailsLink" to="/partner">
                  <div>Become a Partner</div>
                </Link>
                <Link className="footerDetailsLink" to="/terms">
                  <div>Terms and Conditions</div>
                </Link>
                <Link className="footerDetailsLink" to="/privacy">
                  <div>Privacy Policy</div>
                </Link>
                <Link className="footerDetailsLink" to="/">
                  <div>Privacy Policy - Partner</div>
                </Link>
                <Link className="footerDetailsLink" to="/customer-support-policy">
                  <div>Customer Support Policy</div>
                </Link>
                <Link className="footerDetailsLink" to="/reviews">
                  <div>Petmojo Reviews</div>
                </Link>
              </div>
            </div>
          </div>
          <div>
            <div className="desktop-footer-buttons-container-add" style={{ display: "flex", flexDirection: "column" }}>
              <p style={{ fontWeight: "800" }}>MANGOPAW TECHNOLOGIES AND SOLUTIONS PRIVATE LIMITED</p>
              <p style={{ marginTop: "0rem" }}> Ph no- +918889991800</p>
            </div>
            <div className="desktop-footer-buttons-container">
              <div className="desktop-footer-buttons-left">
                <div className="desktop-footer-buttons-left-btn1">
                  <a
                    href="https://play.google.com/store/apps/details?id=in.tamely.user"
                    target="_blank"
                    rel="nofollow"
                  >
                    <img
                      alt="icon"
                      className="desktop-footer-buttons-left-btn1-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/play-store-download.png"
                    />
                  </a>
                </div>
                <div className="desktop-footer-buttons-left-btn1">
                  <a
                    href="https://apps.apple.com/in/app/petmojo/id1609861328"
                    target="_blank"
                    rel="nofollow"
                  >
                    <img
                      alt="icon"
                      className="desktop-footer-buttons-left-btn1-img"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/app-store-download.png"
                    />
                  </a>
                </div>
                <div
                  onClick={goAtTheTop}
                  className="desktop-footer-buttons-left-btn3"
                >
                  <span>Go at the top</span>

                  <svg
                    className="top-arrow"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-arrow-up"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                    />
                  </svg>
                </div>
              </div>

              <div className="desktop-footer-buttons-right">
                Copyright 2024 Petmojo
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
} //

export default About;
