import React, { useEffect, useRef, useState } from "react";

import { Link } from "react-router-dom";
import "./Grooming.css";
// import 'react-rangeslider/lib/index.css'
import { BsArrowUp } from "@react-icons/all-files/bs/BsArrowUp";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import IntroContainerDesktopGrooming from "./container/introContainer/IntroContainerDesktopGroomingUC";
import IntroContainerMobileGrooming from "./container/introContainer/IntroContainerMobileGroomingUC";
import GoToTop from "./GoToTop";

const testimonialArray = [
  {
    message:
      "Quality Service from Petmojo Team. Definitely will go for it again 10/10",
    name: "Deepak B",
    img_src:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/testimonial-phone-deepak.webp",
    profession: "Shop Owner",
  },
  {
    message:
      "I recently a rescued an indie-baby and unfortunately he had Tick Issue. Thanks to the team for Gentle Service to my baby. He feels so much better now!",
    name: "Rohan B",
    img_src:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/testimonial-phone-rohan.webp",
    profession: "Business Owner",
  },
  {
    message:
      "I have tried so many local groomers. But they don't understand even E of Empathy for the Poor Baby. Love you guys ♥️",
    name: "Abdul C",
    img_src:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/testimonial-phone-abdul.webp",
    profession: "Pharmacist",
  },
];

function Grooming() {

  const [popup, setPopup] = useState(false);
  const [navDropdown, setNavDropdown] = useState(false);
  const [changeView, setChangeView] = useState(false);
  const dropdownRef = useRef(null);

  const scrollToTop = () => {
    return window.scrollTo({ top: 200, left: 0, behavior: "smooth" });
  };

  function goToTopHandler() {
    return window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  const location = useLocation();
  const [value, setValue] = useState(10);
  const [value2, setValue2] = useState(10);
  const [valueMobile, setValueMobile] = useState(10);
  const [valueMobile2, setValueMobile2] = useState(10);

  const [isscroll, setIsscroll] = useState(true);
  const [isscroll1, setIsscroll1] = useState(true);
  const handleChange = (value) => {
    setValue(value);
    // setIsscroll(false);
    const scrollableDiv = document.getElementById("scrollable-div");
    scrollableDiv.scrollLeft =
      (value / 100) * (scrollableDiv.scrollWidth - scrollableDiv.clientWidth);
    // setTimeout(()=>{
    //   setIsscroll(true);
    // },1000)
  };
  const handleChangeMobile = (value) => {
    setValueMobile(value);
    setIsscroll1(false);
    const scrollableDiv = document.getElementById("scrollable-div-mobile");
    scrollableDiv.scrollLeft =
      (valueMobile / 100) *
      (scrollableDiv.scrollWidth - scrollableDiv.clientWidth);
  };
  const handleChangeMobile2 = (value) => {
    setValueMobile2(value);
    const scrollableDiv = document.getElementById("scrollable-div2-mobile");
    scrollableDiv.scrollLeft =
      (valueMobile2 / 100) *
      (scrollableDiv.scrollWidth - scrollableDiv.clientWidth);
  };
  const handleChange2 = (value) => {
    setValue2(value);
    const scrollableDiv = document.getElementById("scrollable-div2");
    scrollableDiv.scrollLeft =
      (value2 / 100) * (scrollableDiv.scrollWidth - scrollableDiv.clientWidth);
  };

  const [testmonialData, setTestimonialData] = useState({
    message:
      "My dogs get Walkers from PetMojo since I am busy at office. With minimal cost, both my baby is very happy and playful from everyday session. 🙏",
    name: "Revati B",
    img_src:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/testimonial-phone-revati.webp",
    profession: "Artist",
  });

  let count = 0;

  useEffect(() => {
    const targetDiv = document.querySelector("#testimonialContainer");
    console.log(targetDiv);

    const interval = setInterval(() => {
      count++;
      if (count === 3) {
        count = 0;
      }
      setTestimonialData(testimonialArray[count]);
      //console.log(" The current item is: " +count+ " " + testmonialData.name);
    }, 5000);

    return () => clearInterval(interval);
  }, []);
  // useEffect(()=>{
  //   const divName=document.getElementById('scrollable-div');
  //   let scrollAmount=0;
  //   setInterval(() => {
  //     divName.scrollLeft = scrollAmount;
  //     scrollAmount += 2; // change this value to adjust the scrolling speed
  //   },10);
  // })
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setNavDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if (window.innerWidth <= 1000) {
      setChangeView(false);
    } else {
      setChangeView(true);
    }
  }, []);
  const sectionOneRef = useRef(null);
  const sectionTwoRef = useRef(null);
  const sectionThreeRef = useRef(null);
  // console.log(section1);
  // const mySectionRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // console.log('Section is now visible');
          const ele1 = document.getElementById('section1');
          const ele2 = document.getElementById('section2');
          // console.log(ele);
          ele1.classList.add('appstore')
          ele2.classList.add('playstore')
          // Trigger the section action
        }
      },
      { rootMargin: '0px 0px -50% 0px' }
    );
    if (sectionOneRef.current) {
      observer.observe(sectionOneRef.current);
    }
    return () => {
      if (sectionOneRef.current) {
        observer.unobserve(sectionOneRef.current);
      }
    };
  }, []);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // console.log('Section is now visible');
          const ele1 = document.getElementById('section3');
          const ele2 = document.getElementById('section4');
          // console.log(ele);
          ele1.classList.add('appstore')
          ele2.classList.add('playstore')
          // Trigger the section action
        }
      },
    );
    if (sectionTwoRef.current) {
      observer.observe(sectionTwoRef.current);
    }
    return () => {
      if (sectionTwoRef.current) {
        observer.unobserve(sectionTwoRef.current);
      }
    };
  }, []);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // console.log('Section is now visible');
          const ele1 = document.getElementById('section5');
          const ele2 = document.getElementById('section6');

          ele1.classList.add('appstore')
          ele2.classList.add('playstore')
          // Trigger the section action
        }
      },
    );
    if (sectionThreeRef.current) {
      observer.observe(sectionThreeRef.current);
    }
    return () => {
      if (sectionThreeRef.current) {
        observer.unobserve(sectionThreeRef.current);
      }
    };
  }, []);

  function rightScroll() {
    console.log('right scroll')
    const right = document.querySelector('.slider-training-packages');
    right.scrollBy(200, 0);
  }
  function leftScroll() {
    console.log('right scroll')
    const right = document.querySelector('.slider-training-packages');
    right.scrollBy(-200, 0);
  }
  // useEffect(()=>{
  //   if(value===100){
  //     setValue(0);
  //   }
  //   else{
  //     if(isscroll){
  //       setTimeout(()=>{
  //         setValue(value+1);
  //       },100);
  //     }
  //     else{
  //       setIsscroll(true);
  //       setValue(0);
  //     }
  //   }
  // },[isscroll])
  const changeHandler = () => {
    setIsscroll(true);
  }

  const breakPoints = [
    { width: 1, itemsToShow: 3 },
    { width: 550, itemsToShow: 3 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 3 },
  ];

  const [isActive, setIsActive] = useState(false);
  const [selected, setSelected] = useState("Daily Dog Exercise/Walking");

  const [isActivePopup, setIsActivePopup] = useState(false);
  const [selectedPopup, setSelectedPopup] = useState("BATH & BRUSH");
  const [selectedPrice, setSelectedPrice] = useState("₹749");
  const path = useLocation().pathname;
  const options = [
    "Daily Dog Exercise/Walking",
    "Daily Dog Training",
    "Daily Dog Walking",
    "Daily Pet Grooming",
  ];

  const popupoptions = [
    {
      text: "BATH & BRUSH",
      price: "₹749",
    },
    {
      text: "HAIRCUT & STYLING",
      price: "₹949",
    },
    {
      text: "BATH & FULL HAIRCUT",
      price: "₹1499",
    },
  ];
  // 
  const timelineData = [
    {
      title: "Select Your Package",
      text: "Select the package of your choice for your cuddle buddy from Bath & Brush, Haircut & Styling or Bath & Full Haircut.",
      route: "/services",
    },
    {
      title: "Choose convenient Date & Pay:",
      text: "Choose the Date and Time slot convenient for you and make the payment through our Secure Petmojo App/Payment Link.",
      route: "/grooming",
      type: "grooming",
    },
    {
      title: "We make your Pet look Fabulous",
      text: "We will come to Your Doorstep on the selected date and groom your pet in front of you.",
      link: "https://play.google.com/store/apps/details?id=in.tamely.user",
    },
  ];

  const data = [
    {
      title: "What days and times do you offer for Grooming?",
      content:
        "We offer Grooming 6 days a week. Meeting times will vary by day and season. We usually start at 9:00am and the last meeting of the day at 6:00pm. If you have special needs that do not fall within these times, please let our staff know and we will make every effort to accommodate you.",
    },
    {
      title: "How old should my puppy be before he gets his first groom?",
      content:
        "It is a good idea to introduce your puppy to grooming as soon as possible after the first round of puppy shots, usually between 12-16 weeks of age. This will help him learn at an early age that grooming is a pleasant experience.",
    },
    {
      title: "How long does it take to groom my pet?",
      content:
        "The approximate duration of each treatment can take up to 30 minutes. The time will depend on the condition and length of your dog's coat.",
    },
    {
      title: "Do you use drying cages?",
      content:
        "We have a strict no cage drying policy. All pooch's are finished by hand by our experienced stylists. We choose this for the safety and well-being of our canine clients, and while it increases treatment time, we refuse to compromise the handling of equipment and procedures used by other groomers.",
    },
    {
      title: "How often should my pet be groomed?",
      content:
        "The length and type of your pet's coat will determine how often your pet needs to be groomed.",
    },
    {
      title:
        "What if I am not satisfied with the service my dog ​​is receiving?",
      content:
        "If for any reason you are not completely satisfied with the treatment you received, please let us know within 2 working days so we can do everything we can to make it right. The satisfaction of pet parents is important to us. We take great pride in our services and want pooch parents to too.",
    },
  ];

  useEffect(() => {
    const script1 = document.createElement("script");
    const script2 = document.createElement("script");

    script1.type = "application/ld+json";
    script1.innerText = JSON.stringify({
      "@context": "https://schema.org/",
      "@type": "Review",
      author: {
        "@type": "Person",
        name: "Ritesh Mathur",
      },
      itemReviewed: {
        "@type": "LocalBusiness",
        name: "Petmojo Grooming Service",
        address: "New Delhi, India",
        image:
          "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/petmojo-logo-white.png",
        aggregateRating: {
          "@type": "AggregateRating",
          ratingValue: "4.8",
          ratingCount: "2146",
        },
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: "4.8",
        reviewAspect: "",
      },
      publisher: {
        "@type": "Person",
        name: "Swati Taneja",
      },
      reviewBody:
        "The grooming service is exceptional. The groomers are so professional and well trained. They could easily groom my dog, who is very aggressive, especially while bathing. I’m definitely booking their service again!",
    });

    script2.type = "application/ld+json";
    script2.innerText = JSON.stringify({
      "@context": "https://schema.org/",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "What days and times do you offer for Grooming?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "We offer Grooming 6 days a week. Meeting times will vary by day and season. We usually start at 9:00am and the last meeting of the day at 6:00pm. If you have special needs that do not fall within these times, please let our staff know and we will make every effort to accommodate you.",
          },
        },
        {
          "@type": "Question",
          name: "How old should my puppy be before he gets his first groom?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "It is a good idea to introduce your puppy to grooming as soon as possible after the first round of puppy shots, usually between 12-16 weeks of age. This will help him learn at an early age that grooming is a pleasant experience.",
          },
        },
        {
          "@type": "Question",
          name: "How long does it take to groom my pet?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "The approximate duration of each treatment can take up to 30 minutes. The time will depend on the condition and length of your dog's coat.",
          },
        },
        {
          "@type": "Question",
          name: "Do you use drying cages?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "We have a strict no cage drying policy. All pooch's are groomed by hand by our experienced stylists. We choose this for the safety and well-being of our canine clients, and while it increases treatment time, we refuse to compromise the handling of equipment and procedures.",
          },
        },
        {
          "@type": "Question",
          name: "How often should my pet be groomed?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "The length and type of your pet's coat will determine how often your pet needs to be groomed.",
          },
        },

        {
          "@type": "Question",
          name: "What if I am not satisfied with the service my dog ​​is receiving?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "If for any reason you are not completely satisfied with the treatment you received, please let us know within 2 working days so we can do everything we can to make it right. The satisfaction of pet parents is important to us. We take great pride in our services and want to make sure pooch parents do too.",
          },
        },
      ],
    });
    script1.id = "grooming-page1";
    script2.id = "grooming-page2";
    document.body.appendChild(script1);
    document.body.appendChild(script2);

    return () => {
      document.getElementById("grooming-page1").remove();
      document.getElementById("grooming-page2").remove();
    };
  }, []);

  useEffect(() => {
    document.head.innerHTML += `<meta id="grooming-1" property="og:title" content="Professional Pet Grooming at home - Petmojo"/>
    <meta id="grooming-2" property="og:site_name" content="Petmojo"/>
    <meta id="grooming-3" property="og:url" content="https://petmojo.care"/>
    <meta id="grooming-4" property="og:description" content="Pamper your pets with our carefully selected grooming products. We want your pet babies to look fabulous by trained professionals."/>
    <meta id="grooming-5" property="og:type" content="website"/>
    <meta id="grooming-6" property="og:image" content="https://res.cloudinary.com/tamely-app/image/upload/v1662576550/petmojo-website/Updated_Logo_White_4_drxvnq.png"/>

    
<title id="grooming-id">Top Pet Grooming Services in Delhi & Mumbai | Petmojo</title>
<meta id="grooming-desc" name="description" content="Pet Grooming Services Mumbai - Find professional pet grooming at home in Delhi and Mumbai. From cats to dogs, we ensure your furry friend looks and feels their best." />
<meta id="grooming-keywords" name="keywords" content="Pet Grooming Services Delhi, Pet Grooming Services Mumbai, Pet Grooming at home, Cat Grooming in Mumbai"/>
    `;

    return () => {
      document.getElementById("grooming-1").remove();
      document.getElementById("grooming-2").remove();
      document.getElementById("grooming-3").remove();
      document.getElementById("grooming-4").remove();
      document.getElementById("grooming-5").remove();
      document.getElementById("grooming-6").remove();
      document.getElementById("grooming-id").remove();
      document.getElementById("grooming-desc").remove();
      document.getElementById("grooming-keywords").remove();
    };
  }, []);

  return (
    <div className="grooming">
      <Helmet>
        <title>Top Pet Grooming Services in Delhi & Mumbai | Petmojo</title>
        <meta
          name="description"
          content="Pet Grooming Services Mumbai - Find professional pet grooming at home in Delhi and Mumbai. From cats to dogs, we ensure your furry friend looks and feels their best."
        />
      </Helmet>

      {!changeView ? (
        <IntroContainerMobileGrooming />
      ) : (
        <IntroContainerDesktopGrooming />
      )}


      {!changeView ? null : (
        <div className="footerDetailsContainer">
          <div className="footerDetailsCont1">
            <div className="footerDetailsHeading">Need Help</div>
            <hr className="footerDetailsLine"></hr>
            <div className="footerDetailsContentContainer">
              <div className="footer-details-content-call-mail">
                <div>
                  <img
                    alt="icon"
                    className="icon-desktop-call"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/more-contact-icon.png"
                  />
                </div>
                <div>
                  <a
                    className="footerDetailsLink"
                    target="_blank"
                    href="tel: 8889991800" rel="nofollow"
                  >
                    8889991800
                  </a>
                </div>
              </div>

              <div className="footer-details-content-call-mail">
                <div>
                  <img
                    alt="icon"
                    className="icon-desktop-mail"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pink-mail-icon.png"
                  />
                </div>
                <div>
                  <a
                    className="footerDetailsLink"
                    target="_blank"
                    href="mailto: customercare@petmojo.in" rel="nofollow"
                  >
                    customercare@petmojo.in
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="footerDetailsCont1">
            <div className="footerDetailsHeading">Services</div>
            <hr className="footerDetailsLine"></hr>
            <div className="footerDetailsContentContainer">
              <Link
                className="footerDetailsLink"
                to="/dog-running"
                state={{ from: "grooming" }}
              >
                <div>Daily Dog Exercise/Walking</div>
              </Link>
              <Link
                className="footerDetailsLink"
                to="/dog-training"
                state={{ from: "grooming" }}
              >
                <div>Dog Training</div>
              </Link>
              <Link
                className="footerDetailsLink"
                to="/cat-training"
                state={{ from: "training" }}
              >
                <div>Cat Training</div>
              </Link>
              <Link
                className="footerDetailsLink"
                to="/grooming"
                state={{ from: "grooming" }}
              >
                <div>Pet Grooming</div>
              </Link>
              <Link
                className="footerDetailsLink"
                to="/grooming-subscription"
                state={{ from: "footer" }}
              >
                <div>Grooming Subscription</div>
              </Link>
              <Link
                className="footerDetailsLink"
                to="/pet-boarding"
                state={{ from: "grooming" }}
              >
                <div>Pet Hotel</div>
              </Link>
              {/* <Link
                className="footerDetailsLink"
                to="/styling"
                state={{ from: "grooming" }}
              >
                <div>Pet Styling</div>
              </Link> */}
            </div>
          </div>
          <div className="footerDetailsContr1">
            <div className="footerDetailsHeading">Serving In</div>
            <hr className="footerDetailsLine"></hr>
            <div className="footerDetailsContentContainer">
              <div className="footerDetailsLink">Delhi</div>
              <div className="footerDetailsLink">Gurgaon</div>
              <div className="footerDetailsLink">Noida</div>
              <div className="footerDetailsLink">Ghaziabad</div>
              <div className="footerDetailsLink">Faridabad</div>
              <div className="footerDetailsLink">Manesar</div>
              <div className="footerDetailsLink">Mumbai</div>
            </div>
          </div>
          <div className="footerDetailsCont1">
            <div className="footerDetailsHeading">More</div>
            <hr className="footerDetailsLine"></hr>
            <div className="footerDetailsContentContainer">
              <Link className="footerDetailsLink" to="/partner">
                <div>Become a Partner</div>
              </Link>
              <Link className="footerDetailsLink" to="/terms">
                <div>Terms and Conditions</div>
              </Link>
              <Link className="footerDetailsLink" to="/privacy">
                <div>Privacy Policy</div>
              </Link>
              <Link className="footerDetailsLink" to="/">
                <div>Privacy Policy - Partner</div>
              </Link>
              <Link className="footerDetailsLink" to="/customer-support-policy">
                <div>Customer Support Policy</div>
              </Link>
              <Link className="footerDetailsLink" to="/reviews">
                                    <div>Petmojo Reviews</div>
                                </Link>
              <Link className="footerDetailsLink" to="/about">
                <div>About Us</div>
              </Link>
            </div>
          </div>
        </div>
      )}

      {!changeView ? (
        <div>
          <div className="mobile-view-footer-1">
            <div className="mobile-view-footer-1-left">
              <div className="mobile-view-footer-1-left-header-container">
                <div className="mobile-view-footer-1-header">Need Help</div>
                <hr className="vector2-footer"></hr>
              </div>
              <div className="mobile-view-footer-1-content-left">
                <div className="mobile-view-footer-phone-container">
                  <div className="mobile-view-footer-phone-icon">
                    <img
                      alt="telIcon"
                      className="mobile-view-tel-icon"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pink-phone-icon.png"
                    />
                  </div>
                  <div className="">
                    <a
                      className="mobile-view-footer-phone-text"
                      style={{ textDecoration: "none" }}
                      href="tel: +91 8889991800"
                      target="_blank" rel="nofollow"
                    >
                      8889991800
                    </a>
                  </div>
                </div>
                <div className="mobile-view-footer-mail-container">
                  <div className="mobile-view-footer-mail-icon">
                    <img
                      alt="mailIcon"
                      className="mobile-view-mail-icon"
                      src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pink-mail-icon.png"
                    />
                  </div>
                  <div className="">
                    <a
                      className="mobile-view-footer-mail-text"
                      style={{ textDecoration: "none" }}
                      href="mailto: customercare@petmojo.in"
                      target="_blank" rel="nofollow"
                    >
                      customercare@petmojo.in
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="mobile-view-footer-1-right">
              <div className="mobile-view-footer-1-header">Services</div>
              <hr className="vector2-footer"></hr>
              <div className="mobile-view-footer-1-right-content">
                <Link
                  className="mobile-view-footer-page-link"
                  style={{ textDecoration: "none" }}
                  to="/dog-running"
                  state={{ from: "grooming" }}
                >
                  Dog Exercise/Walking
                </Link>
              </div>
              <div className="mobile-view-footer-1-right-content">
                <Link
                  className="mobile-view-footer-page-link"
                  style={{ textDecoration: "none" }}
                  to="/dog-training"
                  state={{ from: "grooming" }}
                >
                  Dog Training
                </Link>
              </div>
              <div className="mobile-view-footer-1-right-content">
                <Link
                  className="mobile-view-footer-page-link"
                  style={{ textDecoration: "none" }}
                  to="/grooming"
                  state={{ from: "grooming" }}
                >
                  Pet Grooming
                </Link>
              </div>
              <div className="mobile-view-footer-1-right-content">
                <Link
                  className="mobile-view-footer-page-link"
                  style={{ textDecoration: "none" }}
                  to="/grooming-subscription"
                  state={{ from: "training" }}
                >
                  Grooming Subscription
                </Link>
              </div>
              <div className="mobile-view-footer-1-right-content">
                <Link
                  className="mobile-view-footer-page-link"
                  style={{ textDecoration: "none" }}
                  to="/pet-boarding"
                  state={{ from: "grooming" }}
                >
                  Pet Hotel
                </Link>
              </div>
              {/* <div className="mobile-view-footer-1-right-content">
                <Link
                  className="mobile-view-footer-page-link"
                  style={{ textDecoration: "none" }}
                  to="/styling"
                  state={{ from: "grooming" }}
                >
                  Pet Styling
                </Link>
              </div> */}
            </div>
          </div>

          <div className="mobile-view-footer-2">
            <div className="mobile-view-footer-2-left">
              <div className="footerDetailsHeading-mobile">Serving In</div>
              <hr className="footerDetailsLine"></hr>
              <div className="footerDetailsContentContainer-mobile">
                <div>
                  <div className="footerDetailsLink-mobile">Delhi</div>
                  <div className="footerDetailsLink-mobile">Gurgaon</div>
                  <div className="footerDetailsLink-mobile">Noida</div>
                  <div className="footerDetailsLink-mobile">Ghaziabad</div>
                </div>
                <div>
                  <div className="footerDetailsLink-mobile">Faridabad</div>
                  <div className="footerDetailsLink-mobile">Manesar</div>
                  <div className="footerDetailsLink-mobile">Mumbai</div>
                </div>
              </div>
            </div>
            <div className="mobile-view-footer-2-right">
              <div className="mobile-view-footer-2-right-header">More</div>
              <hr className="vector2-footer"></hr>
              <div className="mobile-view-links-div">
                <Link
                  style={{
                    textDecoration: "none",
                    color: "rgba(132, 132, 132, 1)",
                  }}
                  to="/terms"
                >
                  <div className="mobile-view-footer-2-tnc">
                    Terms and Conditions
                  </div>
                </Link>
                <Link
                  style={{
                    textDecoration: "none",
                    color: "rgba(132, 132, 132, 1)",
                  }}
                  to="/privacy"
                >
                  <div className="mobile-view-footer-2-tnc">Privacy Policy</div>
                </Link>
                <Link
                  style={{
                    textDecoration: "none",
                    color: "rgba(132, 132, 132, 1)",
                  }}
                  to="/partner"
                >
                  <div className="mobile-view-footer-2-left-content">
                    Become a Partner
                  </div>
                </Link>{" "}
                <div className="mobile-view-footer-2-left-content">
                  Privacy Policy - Partner
                </div>
                <Link
                  style={{
                    textDecoration: "none",
                    color: "rgba(132, 132, 132, 1)",
                  }}
                  to="/customer-support-policy"
                >
                  <div className="mobile-view-footer-2-left-content">
                    Customer Support Policy
                  </div>
                </Link>
                <Link
                  style={{
                    textDecoration: "none",
                    color: "rgba(132, 132, 132, 1)",
                  }}
                  to="/reviews"
                >
                  <div className="mobile-view-footer-2-left-content">
                    Petmojo Reviews
                  </div>
                </Link>
                <Link
                  style={{
                    textDecoration: "none",
                    color: "rgba(132, 132, 132, 1)",
                  }}
                  to="/about"
                >
                  <div className="mobile-view-footer-2-left-content">
                    About Us
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <div className="mobile-footer-wrap" ref={sectionThreeRef}>
            <div className="mobile-view-footer-3" style={{ gap: "5px" }}>
              <div
                className="mobile-view-footer-3-btn1"
                style={{ height: "33px" }}
              >
                <a href="https://apps.apple.com/in/app/petmojo/id1609861328" target="_blank" rel="nofollow">
                  <img
                    className="mobile-view-footer-appStore-icon "
                    alt="appStore"
                    id="section5"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/app-store-2.png"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                </a>

              </div>
              <div
                className="mobile-view-footer-3-btn2"
                style={{ height: "33px" }}
              >
                <a href="https://play.google.com/store/apps/details?id=in.tamely.user" target="_blank" rel="nofollow">
                  <img
                    className="mobile-view-footer-appStore-icon "
                    id="section6"
                    alt="playStore"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/play-store-2.png"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                </a>

              </div>
              <div
                onClick={() => goToTopHandler()}
                className="mobile-view-footer-3-btn3"
                style={{
                  height: "33px",
                  padding: "5px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <span>Go to the top</span>
                <BsArrowUp />
                {/* <div>Go to the top</div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-arrow-up"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                    />
                  </svg>
                </div> */}
              </div>
            </div>
          </div>

          <div className="mobile-view-footer-4">
            <div>Copyright 2024 Petmojo</div>
            <div>
              All copyrights are reserved - any infringement will be legally
              pursued.
            </div>
            <div>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </div>
          </div>
        </div>
      ) : null}

      {!changeView ? null : (
        <div>
          <div className="desktop-footer-buttons-container-add" style={{ display: "flex", flexDirection: "column" }}>
            <p style={{ fontWeight: "800" }}>MANGOPAW TECHNOLOGIES AND SOLUTIONS PRIVATE LIMITED</p>
            <p style={{ marginTop: "0rem" }}> Ph no- +918889991800</p>
          </div>
          <div className="desktop-footer-buttons-container">
            <div className="desktop-footer-buttons-left">
              <div className="desktop-footer-buttons-left-btn1">
                <a href="https://play.google.com/store/apps/details?id=in.tamely.user" target="_blank" rel="nofollow">

                  <img
                    alt="icon"
                    className="desktop-footer-buttons-left-btn1-img"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/play-store-download.png"
                  />
                </a>
              </div>
              <div className="desktop-footer-buttons-left-btn1">
                <a href="https://apps.apple.com/in/app/petmojo/id1609861328" target="_blank" rel="nofollow">

                  <img
                    alt="icon"
                    className="desktop-footer-buttons-left-btn1-img"
                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/app-store-download.png"
                  />
                </a>
              </div>
              <div
                onClick={scrollToTop}
                className="desktop-footer-buttons-left-btn3"
              >
                <div>Go at the top</div>

                <svg
                  className="top-arrow"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-arrow-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                  />
                </svg>
              </div>
            </div>

            <div className="desktop-footer-buttons-right">
              Copyright 2024 Petmojo
            </div>
          </div>
        </div>
      )}
      <GoToTop />
    </div>
  );
}

export default Grooming;
