/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { Link } from 'react-router-dom'

const ReviewFooter = ({ changeView, showAddressAndCin = false }) => {
    return (
        <>
            {
                !changeView ? null : (
                    <div className="footerDetailsContainer">
                        <div className="footerDetailsCont1">
                            <div className="footerDetailsHeading">Need Help</div>
                            <hr className="footerDetailsLine"></hr>
                            <div className="footerDetailsContentContainer">
                                <div className="footer-details-content-call-mail">
                                    <div>
                                        <img
                                            alt="icon"
                                            className="icon-desktop-call"
                                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/more-contact-icon.png"
                                        />
                                    </div>
                                    <div>
                                        <a
                                            className="footerDetailsLink"
                                            target="_blank"
                                            href="tel: 8889991800" rel="nofollow"
                                        >
                                            8889991800
                                        </a>
                                    </div>
                                </div>

                                <div className="footer-details-content-call-mail">
                                    <div>
                                        <img
                                            alt="icon"
                                            className="icon-desktop-mail"
                                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pink-mail-icon.png"
                                        />
                                    </div>
                                    <div>
                                        <a
                                            className="footerDetailsLink"
                                            target="_blank"
                                            href="mailto: customercare@petmojo.in" rel="nofollow"
                                        >
                                            customercare@petmojo.in
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footerDetailsCont1">
                            <div className="footerDetailsHeading">Services</div>
                            <hr className="footerDetailsLine"></hr>
                            <div className="footerDetailsContentContainer">
                                <Link
                                    className="footerDetailsLink"
                                    to="/dog-running"
                                    state={{ from: "grooming" }}
                                >
                                    <div>Daily Dog Exercise/Walking</div>
                                </Link>
                                <Link
                                    className="footerDetailsLink"
                                    to="/dog-training"
                                    state={{ from: "grooming" }}
                                >
                                    <div>Dog Training</div>
                                </Link>
                                <Link
                                    className="footerDetailsLink"
                                    to="/cat-training"
                                    state={{ from: "training" }}
                                >
                                    <div>Cat Training</div>
                                </Link>
                                <Link
                                    className="footerDetailsLink"
                                    to="/grooming"
                                    state={{ from: "grooming" }}
                                >
                                    <div>Pet Grooming</div>
                                </Link>
                                <Link
                                    className="footerDetailsLink"
                                    to="/grooming-subscription"
                                    state={{ from: "footer" }}
                                >
                                    <div>Grooming Subscription</div>
                                </Link>
                                <Link
                                    className="footerDetailsLink"
                                    to="/pet-boarding"
                                    state={{ from: "grooming" }}
                                >
                                    <div>Pet Hotel</div>
                                </Link>
                                {/* <Link
                className="footerDetailsLink"
                to="/styling"
                state={{ from: "grooming" }}
              >
                <div>Pet Styling</div>
              </Link> */}
                            </div>
                        </div>
                        <div className="footerDetailsContr1">
                            <div className="footerDetailsHeading">Serving In</div>
                            <hr className="footerDetailsLine"></hr>
                            <div className="footerDetailsContentContainer">
                                <div className="footerDetailsLink">Delhi</div>
                                <div className="footerDetailsLink">Gurgaon</div>
                                <div className="footerDetailsLink">Noida</div>
                                <div className="footerDetailsLink">Ghaziabad</div>
                                <div className="footerDetailsLink">Faridabad</div>
                                <div className="footerDetailsLink">Manesar</div>
                                <div className="footerDetailsLink">Mumbai</div>
                            </div>
                        </div>
                        <div className="footerDetailsCont1">
                            <div className="footerDetailsHeading">More</div>
                            <hr className="footerDetailsLine"></hr>
                            <div className="footerDetailsContentContainer">
                                <Link className="footerDetailsLink" to="/partner">
                                    <div>Become a Partner</div>
                                </Link>
                                <Link className="footerDetailsLink" to="/terms">
                                    <div>Terms and Conditions</div>
                                </Link>
                                <Link className="footerDetailsLink" to="/privacy">
                                    <div>Privacy Policy</div>
                                </Link>
                                <Link className="footerDetailsLink" to="/">
                                    <div>Privacy Policy - Partner</div>
                                </Link>
                                <Link className="footerDetailsLink" to="/customer-support-policy">
                                    <div>Customer Support Policy</div>
                                </Link>
                                <Link className="footerDetailsLink" to="/reviews">
                                    <div>Petmojo Reviews</div>
                                </Link>
                                <Link className="footerDetailsLink" to="/about">
                                    <div>About Us</div>
                                </Link>
                            </div>
                        </div>
                    </div>
                )
            }

            {
                !changeView ? (
                    <div>
                        <div className="mobile-view-footer-1">
                            <div className="mobile-view-footer-1-left">
                                <div className="mobile-view-footer-1-left-header-container">
                                    <div className="mobile-view-footer-1-header">Need Help</div>
                                    <hr className="vector2-footer"></hr>
                                </div>
                                <div className="mobile-view-footer-1-content-left">
                                    <div className="mobile-view-footer-phone-container">
                                        <div className="mobile-view-footer-phone-icon">
                                            <img
                                                alt="telIcon"
                                                className="mobile-view-tel-icon"
                                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pink-phone-icon.png"
                                            />
                                        </div>
                                        <div className="">
                                            <a
                                                className="mobile-view-footer-phone-text"
                                                style={{ textDecoration: "none" }}
                                                href="tel: +91 8889991800"
                                                target="_blank" rel="nofollow"
                                            >
                                                8889991800
                                            </a>
                                        </div>
                                    </div>
                                    <div className="mobile-view-footer-mail-container">
                                        <div className="mobile-view-footer-mail-icon">
                                            <img
                                                alt="mailIcon"
                                                className="mobile-view-mail-icon"
                                                src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/pink-mail-icon.png"
                                            />
                                        </div>
                                        <div className="">
                                            <a
                                                className="mobile-view-footer-mail-text"
                                                style={{ textDecoration: "none" }}
                                                href="mailto: customercare@petmojo.in"
                                                target="_blank" rel="nofollow"
                                            >
                                                customercare@petmojo.in
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mobile-view-footer-1-right">
                                <div className="mobile-view-footer-1-header">Services</div>
                                <hr className="vector2-footer"></hr>
                                <div className="mobile-view-footer-1-right-content">
                                    <Link
                                        className="mobile-view-footer-page-link"
                                        style={{ textDecoration: "none" }}
                                        to="/dog-running"
                                        state={{ from: "grooming" }}
                                    >
                                        Dog Exercise/Walking
                                    </Link>
                                </div>
                                <div className="mobile-view-footer-1-right-content">
                                    <Link
                                        className="mobile-view-footer-page-link"
                                        style={{ textDecoration: "none" }}
                                        to="/dog-training"
                                        state={{ from: "grooming" }}
                                    >
                                        Dog Training
                                    </Link>
                                </div>
                                <div className="mobile-view-footer-1-right-content">
                                    <Link
                                        className="mobile-view-footer-page-link"
                                        style={{ textDecoration: "none" }}
                                        to="/grooming"
                                        state={{ from: "grooming" }}
                                    >
                                        Pet Grooming
                                    </Link>
                                </div>
                                <div className="mobile-view-footer-1-right-content">
                                    <Link
                                        className="mobile-view-footer-page-link"
                                        style={{ textDecoration: "none" }}
                                        to="/grooming-subscription"
                                        state={{ from: "training" }}
                                    >
                                        Grooming Subscription
                                    </Link>
                                </div>
                                <div className="mobile-view-footer-1-right-content">
                                    <Link
                                        className="mobile-view-footer-page-link"
                                        style={{ textDecoration: "none" }}
                                        to="/pet-boarding"
                                        state={{ from: "grooming" }}
                                    >
                                        Pet Hotel
                                    </Link>
                                </div>
                                {/* <div className="mobile-view-footer-1-right-content">
                <Link
                  className="mobile-view-footer-page-link"
                  style={{ textDecoration: "none" }}
                  to="/styling"
                  state={{ from: "grooming" }}
                >
                  Pet Styling
                </Link>
              </div> */}
                            </div>
                        </div>

                        <div className="mobile-view-footer-2">
                            <div className="mobile-view-footer-2-left">
                                <div className="footerDetailsHeading-mobile">Serving In</div>
                                <hr className="footerDetailsLine"></hr>
                                <div className="footerDetailsContentContainer-mobile">
                                    <div>
                                        <div className="footerDetailsLink-mobile">Delhi</div>
                                        <div className="footerDetailsLink-mobile">Gurgaon</div>
                                        <div className="footerDetailsLink-mobile">Noida</div>
                                        <div className="footerDetailsLink-mobile">Ghaziabad</div>
                                    </div>
                                    <div>
                                        <div className="footerDetailsLink-mobile">Faridabad</div>
                                        <div className="footerDetailsLink-mobile">Manesar</div>
                                        <div className="footerDetailsLink-mobile">Mumbai</div>
                                    </div>
                                </div>
                            </div>
                            <div className="mobile-view-footer-2-right">
                                <div className="mobile-view-footer-2-right-header">More</div>
                                <hr className="vector2-footer"></hr>
                                <div className="mobile-view-links-div">
                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: "rgba(132, 132, 132, 1)",
                                        }}
                                        to="/terms"
                                    >
                                        <div className="mobile-view-footer-2-tnc">
                                            Terms and Conditions
                                        </div>
                                    </Link>
                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: "rgba(132, 132, 132, 1)",
                                        }}
                                        to="/privacy"
                                    >
                                        <div className="mobile-view-footer-2-tnc">Privacy Policy</div>
                                    </Link>
                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: "rgba(132, 132, 132, 1)",
                                        }}
                                        to="/partner"
                                    >
                                        <div className="mobile-view-footer-2-left-content">
                                            Become a Partner
                                        </div>
                                    </Link>{" "}
                                    <div className="mobile-view-footer-2-left-content">
                                        Privacy Policy - Partner
                                    </div>
                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: "rgba(132, 132, 132, 1)",
                                        }}
                                        to="/customer-support-policy"
                                    >
                                        <div className="mobile-view-footer-2-left-content">
                                            Customer Support Policy
                                        </div>
                                    </Link>
                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: "rgba(132, 132, 132, 1)",
                                        }}
                                        to="/reviews"
                                    >
                                        <div className="mobile-view-footer-2-left-content">
                                            Petmojo Reviews
                                        </div>
                                    </Link>
                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: "rgba(132, 132, 132, 1)",
                                        }}
                                        to="/about"
                                    >
                                        <div className="mobile-view-footer-2-left-content">
                                            About Us
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="mobile-footer-wrap" >
                            <div className="mobile-view-footer-3" style={{ gap: "5px" }}>
                                <div
                                    className="mobile-view-footer-3-btn1"
                                    style={{ height: "33px" }}
                                >
                                    <a href="https://apps.apple.com/in/app/petmojo/id1609861328" target="_blank" rel="nofollow">
                                        <img
                                            className="mobile-view-footer-appStore-icon "
                                            alt="appStore"
                                            id="section5"
                                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/app-store-2.png"
                                            style={{
                                                height: "100%",
                                                width: "100%",
                                                objectFit: "contain",
                                            }}
                                        />
                                    </a>

                                </div>
                                <div
                                    className="mobile-view-footer-3-btn2"
                                    style={{ height: "33px" }}
                                >
                                    <a href="https://play.google.com/store/apps/details?id=in.tamely.user" target="_blank" rel="nofollow">
                                        <img
                                            className="mobile-view-footer-appStore-icon "
                                            id="section6"
                                            alt="playStore"
                                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/play-store-2.png"
                                            style={{
                                                height: "100%",
                                                width: "100%",
                                                objectFit: "contain",
                                            }}
                                        />
                                    </a>

                                </div>
                            </div>
                        </div>
                        <div className="w-full text-xs flex flex-col items-center justify-center">
                            <p className='font-bold text-center'>MANGOPAW TECHNOLOGIES AND SOLUTIONS PRIVATE LIMITED</p>
                            {showAddressAndCin &&
                                <>
                                    <p >Address- UU 182, Pitampura, New Delhi</p>
                                    <p>CIN- U74999DL2022PTC396055</p>
                                </>
                            }
                        </div>

                        <div className="mobile-view-footer-4">
                            <div>Copyright 2024 Petmojo</div>
                            <div>
                                All copyrights are reserved - any infringement will be legally
                                pursued.
                            </div>
                            <div>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                ) : null
            }

            {
                !changeView ? null : (
                    <div>
                        <div className="desktop-footer-buttons-container-add" style={{ display: "flex", flexDirection: "column" }}>
                            <p style={{ fontWeight: "800" }}>MANGOPAW TECHNOLOGIES AND SOLUTIONS PRIVATE LIMITED</p>
                            {showAddressAndCin &&
                                <>
                                    <p>Address- UU 182, Pitampura, New Delhi</p>
                                    <p>CIN- U74999DL2022PTC396055</p>
                                </>
                            }
                        </div>
                        <div className="desktop-footer-buttons-container">
                            <div className="desktop-footer-buttons-left">
                                <div className="desktop-footer-buttons-left-btn1">
                                    <a href="https://play.google.com/store/apps/details?id=in.tamely.user" target="_blank" rel="nofollow">

                                        <img
                                            alt="icon"
                                            className="desktop-footer-buttons-left-btn1-img"
                                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/play-store-download.png"
                                        />
                                    </a>
                                </div>
                                <div className="desktop-footer-buttons-left-btn1">
                                    <a href="https://apps.apple.com/in/app/petmojo/id1609861328" target="_blank" rel="nofollow">

                                        <img
                                            alt="icon"
                                            className="desktop-footer-buttons-left-btn1-img"
                                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/app-store-download.png"
                                        />
                                    </a>
                                </div>
                            </div>

                            <div className="desktop-footer-buttons-right">
                                Copyright 2024 Petmojo
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default ReviewFooter