import { FaRegPauseCircle } from "@react-icons/all-files/fa/FaRegPauseCircle";
import { FaRegPlayCircle } from "@react-icons/all-files/fa/FaRegPlayCircle";
import React, { useRef, useState } from 'react';
import ReactPlayer from "react-player";
import ReactStars from "react-stars";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, FreeMode } from 'swiper/modules';

const ReviewSlider = ({ changeView, reviews }) => {

    return (
        <Swiper
            modules={[Mousewheel, FreeMode]}
            slidesPerView={changeView ? 2.8 : 1.5}
            spaceBetween={30}
            freeMode={true}
            mousewheel={{
                forceToAxis: true,
                sensitivity: 1,
            }}
            className="py-1 w-full"
        >
            {reviews.map((info, index) => (
                <SwiperSlide key={index}>
                    <div className="flex flex-col gap-6 w-[200px] lg:w-[250px]" >
                        <img src={info.url} alt="Img" className=" lg:w-[250px] w-[200px] object-cover border-2 border-gray-300" />
                        <p className="text-white text-sm">{info.data}</p>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    )
}

export default ReviewSlider