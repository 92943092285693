import React from 'react'

const ConnectWithUs = () => {
    return (
        <div className="social-media-links-container">
            <div className="social-media-links-header">
                <div className="social-media-links-text">
                    Connect With Us
                    <div className="line-div">
                        <hr className="live-training-pink-line"></hr>
                    </div>
                </div>

                <div className="home-page-walking-packages-desktop-container2">
                    <div>Connect on applications you use</div>
                    <div>daily to get Informative/Educational </div>
                    <div>contents on Petmojo.</div>
                </div>
            </div>

            <div className="social-media-links-image">
                <div className="ellipse-container">
                    <div className="ellipse-desktop">
                        {/* <LazyLoad> */}
                        <img
                            src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Ellipse_374_ijrumy.png"
                            alt="outerring"
                            className="outer-ring-desktop"
                        ></img>
                        {/* </LazyLoad> */}
                        {/* <LazyLoad> */}
                        <img
                            src={
                                "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/Ellipse_373_iadrer.png"
                            }
                            alt="innerring"
                            className="inner-ring-desktop"
                        ></img>
                        {/* </LazyLoad> */}
                        <button className="youtube-icon-desktop">
                            <a
                                href="https://youtube.com/channel/UCPQrCllizVvTOkSk7_1SIHw"
                                target="_blank"
                                rel="nofollow"
                            >
                                {/* <LazyLoad> */}
                                <img
                                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/yt-icon.png"
                                    alt="youtubeicon"
                                    width="60px"
                                    height="60px"
                                ></img>
                                {/* </LazyLoad> */}
                            </a>
                        </button>
                        <button className="facebook-icon-desktop">
                            <a
                                href="https://www.facebook.com/petmojo.in/"
                                target="_blank"
                                rel="nofollow"
                            >
                                {/* <LazyLoad> */}
                                <img
                                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/fb-desktop.png"
                                    alt="facebookicon"
                                    width="60px"
                                    height="60px"
                                ></img>
                                {/* </LazyLoad> */}
                            </a>
                        </button>

                        <button className="instagram-icon-desktop">
                            <a
                                href="https://www.instagram.com/petmojo.care/"
                                target="_blank"
                                rel="nofollow"
                            >
                                {/* <LazyLoad> */}
                                <img
                                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/insta-desktop.png"
                                    alt="instagramicon"
                                    width="60px"
                                    height="60px"
                                ></img>
                                {/* </LazyLoad> */}
                            </a>
                        </button>
                        <button className="phone-icon-desktop">
                            <a href="tel:+91 8889991800" target="_blank" rel="nofollow">
                                {/* <LazyLoad> */}
                                <img
                                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/phone-blue-desktop.png"
                                    alt="phoneicon"
                                    width="60px"
                                    height="60px"
                                ></img>
                                {/* </LazyLoad> */}
                            </a>
                        </button>
                        <button className="gmail-icon-desktop">
                            <a
                                href="mailto: customercare@petmojo.in"
                                target="_blank"
                                rel="nofollow"
                            >
                                {/* <LazyLoad> */}
                                <img
                                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/gmail-desktop.png"
                                    alt="gmailicon"
                                    width="60px"
                                    height="60px"
                                ></img>
                                {/* </LazyLoad> */}
                            </a>
                        </button>
                        <button className="whatsapp-icon-desktop">
                            <a href="tel:+91 8889991800" target="_blank" rel="nofollow">
                                {/* <LazyLoad> */}
                                <img
                                    src="https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/whatsapp-desktop.png"
                                    alt="whatsappicon"
                                    width="60px"
                                    height="60px"
                                ></img>
                                {/* </LazyLoad> */}
                            </a>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConnectWithUs