const AllBlogs = [
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/The+Importance+of+Regular+Dog+Training.jpg",
    views: "2418",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/The+Importance+of+Regular+Dog+Training.jpg",
    serial_no: 3,
    date: "17th May, 2024",
    heading: "The Importance of Regular Dog Training",
    full_content: [
      "Welcome to the world of dog training, where every wag of a tail and every bark holds the promise of a stronger bond between you and your furry companion. At Petmojo, we believe that every dog deserves the best care and training, tailored to their unique needs and personalities. With over 50,000 fully trained and certified trainers across India, Petmojo is dedicated to providing top-tier service to ensure your pet's well-being and happiness.",
      "Benefits of Regular Dog Training:",
      "Regular dog training is not just about teaching your pet commands; it's about fostering a deeper connection and understanding between you and your canine friend. By investing time and effort into training, you reap a multitude of benefits that enhance both your lives.",
      "Establishing a Strong Bond:",
      "Training sessions provide opportunities for quality time spent together, strengthening the bond between you and your dog. Through positive interactions and shared experiences, you build trust and mutual respect, laying the foundation for a lifelong partnership.",
      "Ensuring Safety:",
      "A well-trained dog is a safer dog. Basic commands like sit, stay, and come can prevent your pet from wandering into danger or getting into potentially harmful situations. With Petmojo's expert training, you can rest assured knowing that your furry friend will respond promptly to your commands, keeping them safe at all times.",
      "Promoting Good Behavior:",
      "Consistent training instills good behavior in your dog, making them a joy to be around both at home and in public. From leash manners to polite greetings, proper training ensures that your pet is a well-behaved and respectful member of society.",
    ],
    category: "suggestion",
    id: "importance-of-regular-dog-training",
    title:
      "Unleashing the Power of Regular Dog Training with Petmojo OR the Importance of Regular Dog Training",
    description:
      "Some dogs are so hyperactive that it gets difficult to control them. We have 4 tips for you to control and calm them down while making sure their energy is well-utilized.",
  },
  {
    
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_2000.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-124.webp",
    views: "1287",
    date: "30th June, 2023",
    heading: "Types of Cat Personalities",
    full_content: [
      "Cats are fascinating creatures known for their independent nature and unique personalities. From playful and energetic to calm and reserved, cats display a wide range of behaviours and characteristics. Let's explore some of the most common types of cat personalities, shedding light on the delightful diversity that exists within the feline world.",
      "The Social Butterfly:",
      "Social butterflies are cats who thrive on human companionship. They enjoy being in the centre of attention. These cats often seek out interactions, whether it's rubbing against your leg, purring loudly, or curling up next to you while you watch TV.",
      "The Adventurer:",
      "Adventurous cats are always on the lookout for new experiences and thrills. They love exploring their surroundings, both indoors and outdoors. Providing them with interactive toys, scratching posts, and designated play areas will keep them happy and engaged.",
      "The Gentle Observer:",
      "Some cats prefer to take a more laid-back approach to life. These gentle observers are typically calm and serene, content to sit quietly and watch the world go by. These cats may not seek constant attention but appreciate a gentle stroke or a quiet moment of companionship.",
      "The Playful Trickster:",
      "Playful tricksters have an innate mischievous streak and are always ready for a game. Playtime is an essential aspect of their lives, and providing them with a variety of toys and interactive games will keep their minds sharp and their bodies agile.",
    ],
    id: "types-of-cat-personalities-petmojo-124",
    title: "Types of Cat Personalities - Petmojo",
    description:
      "Discover the diverse personalities of cats in our latest blog post! Explore the various types of cat personalities.",
    serial_no: "5",
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1892.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-123.webp",
    views: "1287",
    date: "7th June, 2023",
    heading: "Common Cat Myths",
    full_content: [
      "Cats are beloved pets for many reasons. They are independent, playful, and often very affectionate. However, there are many myths about cats that are not true. In this blog post, we will debunk some of the most common cat myths.",
      "Myth #1: Cats are aloof and independent:",
      "Many people believe that cats are aloof and independent creatures. While it is true that cats are not as needy as dogs, they are still social animals that crave attention and affection. Cats form strong bonds with their owners and enjoy spending time with them.",
      "Myth #2: Cats hate water:",
      "Another common myth about cats is that they hate water. While it is true that some cats do not enjoy getting wet, many cats actually love to play in water. Some cats even enjoy swimming!",
      "Myth #3: Cats only see in black and white:",
      "While cats do not see in color as well as humans, they do see in color. Cats have two types of color receptors in their eyes, while humans have three. This means that cats see the world in shades of blue, green, and yellow.",
      "Myth #4: Cats have nine lives:",
      "This is probably the most famous cat myth of all. The idea that cats have nine lives comes from the fact that they are very good at surviving falls and other dangerous situations. However, cats do not actually have nine lives. They are just very resilient creatures.",
    ],
    id: "common-cat-myths-petmojo-123",
    title: "Common Cat Myths - Petmojo",
    description:
      "Lots of myths surround cats. Cats are highly misunderstood creatures. We are here to bust all cat myths at Petmojo!",
    serial_no: "2",
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1892yu.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-122.webp",
    views: "1287",
    date: "19th May, 2023",
    heading: "Best Dog Breeds for Senior Citizens",
    full_content: [
      "A dog is a wonderful companion for humans, but they also require a lot of work. Often, senior citizens hesitate to get a pet dog due to the responsibilities involved. However, dogs can be great companions during old age. At Petmojo, we understand the difficulties faced by seniors and offer services to help you take care of your dog. In this blog post, we have listed some low-maintenance dog breeds suitable for older people.",
      "Pomeranian:",
      "Pomeranians are active and compact. They make a great choice for senior citizens because they are playful and friendly. Their small size makes them easy to manage.",
      "Pug:",
      "Pugs are small in size, which automatically makes them easier to maintain. They have short fur, so their grooming needs are minimal.",
      "Shih Tzu:",
      "Shih Tzus have long fur, which requires regular grooming. However, grooming is the primary maintenance they need. Their exercise needs are minimal, which is perfect for older people.",
    ],
    id: "best-dog-breeds-for-senior-citizens-petmojo-122",
    title: "Best Dog Breeds for Senior Citizens - Petmojo",
    description:
      "Discover the best dog breeds for senior citizens. Find the perfect companion for older individuals. Explore Petmojo's services to help you take care of your furry friend. Don't let age stop you from enjoying the joys of having a dog!",
    serial_no: "3",
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1856yh.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-121.webp",
    views: "1287",
    date: "24th April, 2023",
    heading: "Dachshund - The Ultimate Guide to Dog Breeds",
    full_content: [
      "These small cute doggos are perfect for the apartment life we all have these days. They were built to sniff out pesky prey. They are known to be around 600 years old.",
      "Characteristics:",
      "Dachshunds have a long body with short legs.They have a smooth fur coat in black or tan colour. They are filled with confidence and are very alert.",
      "Personality:",
      "Even though they are small in size, their spirit is mighty high. They are great watch dogs. They are confident and have a mind of their own. Sometimes they need to be reminded of their limits.",
      "Health and Wellness:",
      "They only need minimal bathing and grooming but regular brushing is highly recommended. You may need to get their eyebrows trimmed every once in a while. They do shed but not excessively. They are prone to ear infections.",
      "Training:",
      "Dachshunds have a strong personality. That means they can get stubborn when you try to train them. But, with some love and positive training, they can get trained.",
      "Life Span:",
      "Dachshunds live from 12 to 16 years depending on their care.",
    ],
    id: "dachshund-the-ultimate-guide-to-dog-breeds-petmojo-121",
    title: "Dachshund - The Ultimate Guide to Dog Breeds - Petmojo",
    description:
      "Dachshund - The Ultimate Guide…Here is a comprehensive guide about everything you need to know about the dog breed Dachshund",
    serial_no: "5",
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1853k.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-120.webp",
    views: "1287",
    date: "19th April, 2023",
    heading: "Secrets To Keep Your Cat Happy And Healthy",
    full_content: [
      "Cats are natural hunters and come from the family of Felines which includes tigers and lions. Knowing this, we can imagine how important it is to keep them happy indoors. We are sharing some deep secrets to keep your cats happy and healthy.",
      "Make it interesting:",
      "Cats do not like monotonous lives. They need something to keep them busy and make it interesting. To keep them occupied, you can seek help from pet toys and some one on one time with your feline.",
      "Feeding your Feline:",
      "Create a feeding schedule and stick to it. Give them only the appropriate amount of food that is required as they tend to overfeed if given in excess.",
      "Give Freedom:",
      "By freedom, I do not mean to let them roam on their own as they tend to leave and are wanderers. But I mean to not be with them all the time. Cats need some me time, just like us humans.",
      "Keep the Litter Box handy and clean:",
      "Keep the litter box in an area which is easy to access for the cat as well as in a corner to avoid the smell and make them feel private about their business.",
    ],
    id: "secrets-to-keep-your-cat-happy-and-healthy-petmojo-120",
    title: "Secrets To Keep Your Cat Happy And Healthy - Petmojo",
    description:
      "Want to keep your cat healthy and happy? Look no further! Whether you're a new cat owner or a seasoned pro, you'll find plenty of helpful information to keep your cat purring with good health.",
    serial_no: "2",
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1844hj.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-119.webp",
    views: "1287",
    date: "15th April, 2023",
    heading: "Pets and their impact on Mental Health",
    full_content: [
      "Pets have long been known to bring joy and companionship to their owners, but did you know that they can also have a positive impact on mental health? According to numerous studies, owning a pet can have a range of mental health benefits. Let’s explore the benefits in detail.",
      "Reduced Stress:",
      "Pets are great stress-busters. They can provide a sense of comfort and companionship, which can help reduce stress levels. Simply petting a dog or cat can help lower blood pressure and promote relaxation.",
      "Increased Socialization:",
      "Pets can also help people become more socially connected. For example, taking a dog for a walk can provide opportunities for social interaction and exercise, which can help reduce feelings of loneliness and depression.",
      "Improved Mood:",
      "Pets can also improve your mood by providing a sense of purpose and responsibility. Caring for a pet can give you a sense of accomplishment and fulfilment, which can boost your self-esteem and overall happiness.",
      "Enhanced Empathy:",
      "Owning a pet can also help develop empathy and compassion. Taking care of an animal’s needs can help people become more aware of others’ emotions and needs, which can lead to better relationships and communication skills.",
      "Increased Physical Activity:",
      "Finally, pets can encourage physical activity, which has been linked to improved mental health. Playing with a pet or taking them for a walk can help boost your energy levels and improve your overall health and wellbeing.",
    ],
    id: "pets-and-their-impact-on-mental-health-119",
    title: "Pets and their impact on Mental Health - Petmojo ",
    description:
      "Pets have a huge impact on improving mental health. A lot of research has been done on this. Read to find the impact pets have on mental health of pet parents.",
    serial_no: "3",
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1844.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-118.webp",
    views: "1287",
    date: "7th April, 2023",
    heading: "Surprising Advantages of Pets for Kids",
    full_content: [
      "Pets are more than just furry companions – they can offer a range of benefits for children of all ages. From teaching responsibility to reducing stress levels, here are some surprising advantages of pets for kids.",
      "Improved social skills:",
      "Children who grow up with pets tend to be more outgoing and sociable. This is because they often have to interact with their pets, which helps them develop communication skills and build confidence.",
      "Increased responsibility:",
      "Owning a pet comes with a great deal of responsibility, which can help teach children valuable life skills. From feeding and grooming to walking and cleaning up after their pet, children can learn about the importance of caring for others.",
      "Reduced stress levels:",
      "Pets can help reduce stress levels in children, especially those with anxiety or ADHD. Studies have shown that interacting with pets can lower cortisol levels (a stress hormone) and increase feelings of happiness and calm.",
      "Boosted immune system:",
      "Growing up with pets can also help strengthen a child’s immune system. Exposure to pet dander and other allergens early in life can actually help build immunity and reduce the risk of allergies and asthma later on.",
      "Emotional support:",
      "Pets can provide children with a source of emotional support and comfort. Whether it’s snuggling with a cat or playing fetch with a dog, pets can help children feel loved and supported.",
      "Increased physical activity:",
      "Pets can also encourage children to be more physically active. Whether it’s playing in the backyard or going for a walk around the block, pets can motivate children to get moving and stay active.<br/>Overall, pets can offer a range of surprising advantages for kids. From improving social skills to reducing stress levels, pets can be a valuable addition to any family. Just remember, owning a pet is a big responsibility, and it’s important to choose a pet that’s right for your family’s lifestyle and needs.",
    ],
    id: "surprising-advantages-of-pets-for-kids-118",
    title: "Surprising Advantages of Pets for Kids - Petmojo ",
    description:
      "Discover the surprising advantages of pets for kids in our latest blog post. From teaching responsibility to reducing stress levels, learn how pets can offer valuable benefits for your child's development and overall wellbeing.",
    serial_no: "5",
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1841.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-117.webp",
    views: "1287",
    date: "4th April, 2023",
    heading: "How to Prepare Your Dog for Boarding?",
    full_content: [
      "Travelling with a dog can be as stressful as it is fun. If you like your travels to be dog free, I’m sure you’ve explored the option of dog boarding. If you are planning to leave your dog at a boarding centre, here are a few ways to prepare for the experience.",
      "Book the Centre Early:",
      "If you have a preferred boarding centre, make sure to book a place as early as possible. Although most boarding centres do have last minute reservations, it's better not to take a chance.",
      "Consider your dog’s preferences:",
      "If your dog is active and social, it is better to choose a centre that offers play time. Just make sure your dog is comfortable and all that he is used to regularly like, he is getting in the centre.",
      "Get a toy along:",
      "Bring your dog’s favourite toy with him to keep him occupied and have something familiar with him at all times.",
    ],
    id: "how-to-prepare-your-dog-for-boarding-petmojo-117",
    title: "How to Prepare Your Dog for Boarding? - Petmojo ",
    description:
      "Are you planning a vacation and are worried about leaving your dog at a boarding centre? Here is a checklist to take care of while boarding your dog.",
    serial_no: "2",
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1828.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-116.webp",
    views: "1287",
    date: "28th March, 2023",
    heading: "Poodle: The Ultimate Guide to Dog Breeds",
    full_content: [
      "Poodles are bright, energetic and friendly dogs. They are known for their curly hairs and bright shining personalities. If you are planning to get a poodle, this is the post for you. Real along to find all about them!",
      "Characteristics & Personality:",
      "Poodles have curly fur coats, long rounded ears, and a balanced look. They come in a variety of colours including, black, silver, apricot, and brown. Their hair is unique. They don’t have a double coat and they don’t shed or shed very rarely",
      "Health and Wellness:",
      "Just like most dog breeds, Poodles are prone to some diseases. Addison’s disease is one such disease. Other health problems include bloat, gastric dilation volvulus (GDV), thyroid issues, epilepsy, sebaceous adenitis, hip dysplasia, and, more rarely, von Willebrand’s disease.",
      "Training Needs:",
      "They are quite intelligent and easy to train. Having said that, it is only advisable to use positive reinforcement methods.",
    ],
    id: "poodle-the-ultimate-guide-to-dog-breeds-116",
    title: "Poodle: The Ultimate Guide to Dog Breeds - Petmojo",
    description:
      "Here is everything you need to know about Poodles. A comprehensive guide to help you decide the right pet for you!",
    serial_no: "3",
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1820f.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-115.webp",
    views: "1287",
    date: "23rd March, 2023",
    heading: "How to Prepare your Dog for Summer",
    full_content: [
      "Indian summers are harsh for everyone, including dogs. It is a known fact that dogs get fatigued during summer. The summer warmth can get harmful for your dog. Here are a few tips to prepare your dogs for a healthy summer.",
      "Prevent Dehydration:",
      "Make sure their water bowl is filled with fresh water at all times. Also, include watery fruits and vegetables in their diet to avoid dehydration like cucumbers.",
      "Prevent Heat Stroke:",
      "To prevent heat stroke, keep a wet towel below their stomach or get cooling vests from the market to keep them cooler. This would reduce the risk of heat stroke.",
      "Prevent Fleas and Ticks:",
      "Beware of ticks and fleas all the time, especially during summer. In summers, they end up multiplying quicker than winters. It is important to get them checked often for ticks and fleas.",
      "Regular Grooming:",
      "During the summer season, increase the frequency of grooming for your dog. Also, another important point to remember is that make sure you do not cut their fur hair less than an inch. The fur keeps them naturally safe from the heat/harmful rays of the sun.",
    ],
    id: "how-to-prepare-your-dog-for-summer-115",
    title: "How to Prepare your Dog for Summer - Petmojo",
    description:
      "Summer is right around the corner and we have shared some tips to make the transition from winter to summer seamless for your dog. Read to find more.",
    serial_no: "5",
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1820.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-114.webp",
    views: "1287",
    date: "18th March, 2023",
    heading: "Healthy Food for Dogs - Recommended by Petmojo",
    full_content: [
      "As a pet parent, it is empirical to provide the most healthy food for dogs but most people are unsure of what is the right superfood for their canines. Here is a list of 5 most healthy superfoods, you can feed your dog to keep them healthy and hearty.",
      "Carrots:",
      "Carrots have beta-carotene which is great for dogs. It also has a lot of fibre. They are great to improve immunity, support eye health, great for digestion and improve a dog's coat and skin.",
      "Green Leafy Vegetables:",
      "Leafy vegetables have antioxidant and anti-inflammatory qualities. They are also high in calcium, iron, potassium, and vitamins A, C, and K, and contain a lot of fibre.",
      "Eggs:",
      "Eggs, as most people know, have a very high amount of protein along with amino acids, essential fatty acids, vitamins, and nutrients. Dogs can easily digest eggs. There are many benefits of eggs for dogs.",
      "Yogurt:",
      "Yogurt is known for its probiotics. It is great for a dog’s gut health. We, at Petmojo, truly believe that gut health is the basis of overall health. It helps keep the dog’s immunity much higher.",
      "Watermelon:",
      "Watermelon is one of the safest fruits for dogs. Watermelon is full of nutrients like amino acids, vitamin A, B vitamins, vitamin C, potassium, and magnesium. It’s great for the intestines, kidney and liver.",
    ],
    id: "healthy-food-for-dogs-recommended-by-petmojo-114",
    title: "Healthy Food for Dogs - Recommended by Petmojo",
    description:
      "Read about the best healthy foods for dogs recommended by Petmojo. From eggs and yogurt to vegetables and fruits, give your furry friend the nutrition they need for a happy, healthy life",
    serial_no: "2",
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1811.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-113.webp",
    views: "1287",
    date: "9th March, 2023",
    heading: "Yorkshire Terrier: The Ultimate Guide to Dog Breeds",
    full_content: [
      "Yorkshire Terriers have been known as pets since the 1800s. They originally are English breeds. They are not very sweet by nature. They have beautiful hair and a bossy personality.",
      "Characteristics & Personality:",
      "They are proportional in looks. They can be of various colours like bluish-grey and tan. They are known for their smooth and straight fur hair. Their coat is hypoallergenic. They are generally confident in nature, in spite of their small size.",
      "Health and Wellness:",
      "Due to their long coat, they require regular <a href='https://petmojo.care/grooming' target='_blank' rel='noopener noreferrer'>grooming</a>. Yorkshire Terriers need brushing daily or twice a day. They are also prone to health issues related to eyes.",
      "Energy Level:",
      "Yorkshire Terriers have a lot of energy. They need steady <a href='https://petmojo.care/dog-running' target='_blank' rel='noopener noreferrer'>walks</a> everyday but occasionally they also require slightly high intensity workouts. It is advised to plan walks with other dogs nearby. ",
      "Training Needs:",
      "The trick to <a href='https://petmojo.care/dog-training' target='_blank' rel='noopener noreferrer'>training</a> a Yorkshire Terrier is to start slow and go gradual. They love to please their humans. So, it is advisable to use positive reinforcement training.",
    ],
    id: "yorkshire-terrier-the-ultimate-guide-to-dog-breeds-113",
    title: "Yorkshire Terrier: The Ultimate Guide to Dog Breeds - Petmojo",
    description:
      "Discover the ultimate guide to Yorkshire Terriers! Learn about their history, temperament, grooming, and training needs. Get expert tips to welcome these charming dogs into your life. Read on to become a Yorkshire Terrier pro!",
    serial_no: "3",
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1805jk.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-112.webp",
    views: "1287",
    date: "21st February, 2023",
    heading: "Quick Tips to make Holi safe for Pets",
    full_content: [
      "Holi is the festival of love, brotherhood, friendship and peace. It is one of the favourite festivals of a lot of Indians. But, unfortunately, it is also a festival that has come to turn into a rowdy affair. Our little pets are also not spared of this and are often exposed to pain, discomfort and health issues in the long run. This Holi, let’s all try to create a peaceful space, especially for pets. Here are some tips to do so.",
      "Take your dog for a walk early morning:",
      "The best way to avoid any exposure to chemical colours, is to take your dog out before the celebration starts. Also, after the celebration, when you take your pooch out for a walk, make sure they are wearing dog shoes to avoid any infections that the residue colour on the ground may cause.",
      "DO NOT use colours on them:",
      "The colours used during the holi celebration are highly toxic, especially for animal skin. Keep safe distance from any chemical colours. If (although not advisable), you absolutely have to play holi with your dog, you can use turmeric, beetroot powder, spinach powder, etc. Make sure these are also organic and free of chemicals.",
      "Use flowers/petals instead of colours:",
      "One of the safest ways to play holi with pets is to use flower petals and shower them with it. But, if your dog is aggressive and not <a href='https://petmojo.care/dog-training' target='_blank' rel='noopener noreferrer'>trained</a> , we won’t suggest this because this can increase aggression in dogs.",
    ],
    id: "quick-tips-to-make-holi-safe-for-pets-112",
    title: "Quick Tips to make Holi safe for Pets - Petmojo",
    description:
      "Learn how to ensure your pup has a happy and healthy Holi experience with these helpful guidelines. Follow the tips and have an animal friendly Holi!",
    serial_no: "5",
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1805.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-111.webp",
    views: "1287",
    date: "17th February, 2023",
    heading: "5 Smallest Dog Breeds in the World",
    full_content: [
      "A lot of new pet parents prefer to get a small dog breed to their home instead of a large one. We have curated a list of top 5 dog breeds which are super small and cute. So, if you are someone in a small apartment or prefer a small breed due to time constraints, these are perfect for you.",
      "Pug:",
      "<a target='_blank' rel='noopener noreferrer' href='https://petmojo.care/blog/pug-the-ultimate-guide-to-dog-breeds-54'>Pugs</a> are small breeds,typically 6 to 9 kg in weight. They are cute and cuddly and totally perfect for families. They have a flat round face with deep wrinkles and large eyes. Their height reaches 14 inches maximum as adults.",
      "Shih Tzu:",
      "<a href='https://petmojo.care/blog/shih-tzu-the-ultimate-guide-to-dog-breed' target='_blank' rel='noopener noreferrer'>Shih Tzu</a> or ‘Little Lion’ are small, affectionate and very lovely dogs. They are very small in size, weighing 4-7.2 kg. They have long silky hair and require <a href='https://petmojo.care/grooming' target='_blank' rel='noopener noreferrer'>grooming</a> and maintenance regularly",
      "Pomerinanian:",
      "They are one of the most popular dog breeds out there. They are often referred to as ‘Poms’ . <a href='https://petmojo.care/blog/pomeranian-the-ultimate-guide-to-dog-breed-66' target='_blank' rel='noopener noreferrer'>Pomeranians</a> are extremely small in size, weighing up to 3.5 kg. They are very cute and great watch dogs as well.",
      "Yorkshire Terrier:",
      "Yorkshire terrier are a toy breed, very popular amongst people these days. They are widely known for having long, silky hair. They weigh less than 3.2 kg. They are petite in stature, with V-shaped ears and medium-sized dark eyes. ",
      "Chihuahua:",
      "The <a href='https://petmojo.care/blog/chihuahua-ultimate-guide-to-dog-breed-98' target='_blank' rel='noopener noreferrer'>Chihuahua</a> is the smallest breed of dog in the world, with a maximum weight limit of 2.7 kg. Mexico is where the Chihuahua breed first appeared. They have round eyes, upright ears, and a short, rounded head. There are Chihuahuas with short hair and those with long hair.",
    ],
    id: "5-smallest-dog-breeds-in-the-world-111",
    title: "5 Smallest Dog Breeds in the World - Petmojo",
    description:
      "Looking for a small dog breed? You’ve come to the right place. We have done the research and present the top 5 smallest dog breeds in the world! ",
    serial_no: "2",
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1799.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-110.webp",
    views: "391",
    date: "8th February, 2023",
    heading: "Getting a new dog? Find the right questions to ask",
    full_content: [
      "All dog owners experience great excitement when getting a new dog. To guarantee a seamless transition and to find the dog breed that is an ideal match for your lifestyle and home, it's important to ask the right questions. You must ensure that you find the breed that is a perfect fit for your lifestyle and home, recognize the limitations you’ll have after getting a dog and how this decision will impact your life. ",
      "Which breed best suits my lifestyle?",
      "Before you start looking for a new dog, it's important to consider the breeds that will best fit your lifestyle. Are you an active person who loves to take long walks or go hiking? If so, you may want to consider a breed that is known for its high energy levels, such as a <a href='https://petmojo.care/blog/labradors-the-ultimate-guide-to-dog-breeds-26' rel='noopener noreferrer' target='_blank'>Labrador</a> Retriever or a Border Collie. Do you live in a <a href='https://petmojo.care/blog/best-dog-breeds-for-a-small-apartment-25' rel='noopener noreferrer' target='_blank'>small apartment</a> and prefer a more low-key lifestyle? Then, you may want to consider a breed that is more suited to apartment living, such as a Bulldog or a <a href='https://petmojo.care/blog/shih-tzu-the-ultimate-guide-to-dog-breed' rel='noopener noreferrer' target='_blank'>Shih Tzu.</a>",
      "Where should I get my new dog?",
      "There are many options when it comes to finding your new furry friend. You can adopt from a shelter, purchase from a breeder, or even rescue a dog from a local rescue group. Each option has its own benefits and drawbacks, so it's important to research and consider which one is best for you and your lifestyle.",
      "What is the dog's history and temperament?",
      "When adopting or getting a dog from rescue, it's important to know its history and temperament. Has the dog been well-socialised and trained? Does it have any behavioural issues or medical conditions that you should be aware of? Asking these questions will help you determine if this dog is what you’re expecting. ",
      "Note: Whenever you are going for adoption or getting a rescue dog, you need to have an open mind and an open heart to accept the dog as is. There may be some baggage that comes with a dog that has a past but there is nothing a little love and support can’t solve. ",
      "What are the steps to be followed after getting the puppy for better development?",
      "Different breeds have different care requirements, so it's important to research and understand what kind of care your new dog will need. A few questions that will help you finalise the action plan include the following. Does my dog need <a href='https://petmojo.care/dog-running' rel='noopener noreferrer' target='_blank'>regular exercise</a>? How essential is dog training for this breed and what are the benefits of getting them trained? How often does a dog require <a href='https://petmojo.care/grooming' rel='noopener noreferrer' target='_blank'>grooming?</a> ",
      "What is the cost of owning a dog?",
      "Owning a dog is not just about the cost of adoption or purchase; it also includes the cost of food, toys, grooming, and veterinary care. Make sure to factor in these costs when making your decision to get a new dog, and to budget accordingly.",
      "Do I need Pet Insurance?",
      "Another important discussion to have with your family is the insurance needs for your dog. This discussion will also help you figure out the consequences, financially and otherwise, of unseen events of a pet.",
    ],
    id: "getting-a-new-dog-find-the-right-questions-to-ask-110",
    title: "Getting a new dog? Find the right questions to ask - Petmojo",
    description:
      "Are you planning to get a pet? There are some questions you must ask yourself before making the decision. Read to find all the important questions.",
    serial_no: 3,
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1796.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-109.webp",
    views: "1287",
    date: "7th February, 2023",
    heading: "Dogs Vs Cats: The Better Pet For You",
    full_content: [
      "Dogs and cats are the two of the most favourite pets amongst people. Even though there are many other options available. When it comes to choosing a pet, many people are torn between getting a dog or a cat. ",
      "Both animals have unique personalities, temperaments, and needs. For example, dogs need regular walks and the owner has to take them out multiple times a day while cats are more self workout. They’ll walk when they need to and you don’t have to take them out most of the time. So, which one makes a better pet? In this blog, we’ll explore the pros and cons of each and help you decide which pet is right for you.",
      "Characteristics of Dogs :",
      "Dogs are often referred to as “man’s best friend”, and for good reason. They are highly social animals and are known for their unwavering loyalty to their owners. They are also great protectors and can be trained to do a variety of tasks, such as providing assistance to those with disabilities, sniffing out drugs or bombs, and performing search and rescue operations and are often used as <a href='https://petmojo.care/blog/all-about-service-dogs-in-india-91' rel='noopener noreferrer' target='_blank'>Service Dogs</a>.",
      "While choosing a dog, you need to consider many things like the breed of the dog, the size, etc as some breeds require much more space than others. If you have a <a href='https://petmojo.care/blog/best-dog-breeds-for-a-small-apartment-25' rel='noopener noreferrer' target='_blank'>small house</a>, it is advisable to choose a breed appropriately. You must also consider the fur of the dog. Longer the fur, more the <a rel='noopener noreferrer' target='_blank' href='https://petmojo.care/grooming'>grooming needs</a> for the dog breed. So, take that into account as well.",
      "Pros of owning a dog:",
      "Loyalty: ",
      "Dogs are highly social animals and are known for their loyalty to their owners. They are the most loyal pets which gives them the nickname, ‘man’s best friend’.",
      "Protection: ",
      "Dogs are natural protectors and can be trained to perform various tasks, such as providing assistance to those with disabilities. They are also great to guard your house of any strange people or thieves. ",
      "Exercise:",
      "Dogs need regular exercise and can be a great motivator to get up and get active.",
      "Socialisation: ",
      "Dogs are social animals and enjoy spending time with their owners and other dogs.",
      "Cons of owning a dog:",
      "High Maintenance:",
      "Dogs require <a rel='noopener noreferrer' target='_blank' href='https://petmojo.care/grooming'></a>regular grooming, feeding, and exercise, which can be time-consuming and expensive.",
      "Training: ",
      "Dogs need to be trained, which can be time-consuming and requires patience and consistency.",
      "Space: ",
      "Dogs need plenty of room to run and play, which may not be possible for those living in apartments.",
      "Noisy:",
      "It is no news that dogs bark! Not just that, they bark, depending on the breed and the kind of training they’ve received, loudly and frequently.",
      "Characteristics of Cats:",
      "Cats are often described as being more independent than dogs, but they are still great pets. They are known for their playful and affectionate personalities, and they are low-maintenance compared to dogs. Cats also tend to be more suitable for people living in apartments, as they do not require as much space as dogs.",
      "Cats can be an indoor cat and an outdoor cat. Just like dogs, cats also come in different breeds. For example, Siamese cats are noisy, intelligent and playful while long haired cats are laid back and short haired cats are even tempered and affectionate. The pros and cons written below are based on an average cat. ",
      "Pros of owning a cat:",
      "Independent: ",
      "Cats are more independent than dogs and do not require as much attention or exercise.",
      "Low maintenance: ",
      "Cats are low-maintenance pets that do not require as much grooming, feeding, or exercise as dogs.",
      "Space: ",
      "Cats are suitable for people living in apartments and do not require as much space as dogs.",
      "Enemies of Pests:",
      "If you have a cat at home, rest assured, your house will be free of all pests. ",
      "Quiet:",
      "Cats are very quiet and calm. They never create a ruckus and are perfect for small apartments. ",
      "Conclusion:",
      "Both dogs and cats have their pros and cons, and the right pet for you will depend on your lifestyle, personality, and preferences. If you are looking for a loyal companion who will always be by your side, a dog may be the right choice for you. However, if you are looking for a low-maintenance pet who is independent, a cat may be the better option. So, do consider your lifestyle, personality, and preferences before making your decision.",
    ],
    id: "dogs-vs-cats-the-better-pet-for-you-109",
    title: "Dogs Vs Cats: The Better Pet For You - Petmojo",
    description:
      "Dogs and Cats are the two most favourite pets in India. We have written a detailed comparison between the two to make the best choice. Read to learn more about it.",
    serial_no: 5,
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1793.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-108.webp",
    views: "453",
    date: "6th February, 2023",
    heading: "Important Vitamins and Minerals for your Dog",
    full_content: [
      "Do you ever wonder if supplements are needed? Or, do we get sufficient nutrition from the food we eat? There are so many health supplements available for humans in all the chemist stores all around us. As pet owners, we all want our furry friends to lead happy and healthy lives. To make that happen, it's essential to provide them with proper nutrition. Just like humans, dogs require a <a href='https://petmojo.care/blog/what-should-i-feed-my-dog' target='_blank' rel='noopener noreferrer'>balanced diet</a> that includes essential vitamins and minerals to support their overall health and well-being. ",
      "With the right vitamins and minerals, your dog will grow to be healthy and live a longer and illness free life. But, we need to be careful while selecting the supplements as too much of these nutrients are also not advisable. In this blog post, we'll take a closer look at the most important vitamins and minerals that your dog needs.",
      "What are Vitamins?",
      "Vitamins can be of two types. The first type is water soluble which includes vitamin C and B complex. The second type is the fat soluble vitamins including vitamin A, D, E and K. ",
      "Let us understand about each of the types of vitamins. The water soluble ones help to build bones, <a ref='noopener noreferrer' target='_blank' href='https://petmojo.care/blog/how-to-brush-your-dog%E2%80%99s-teeth-60'></a>teeth and connective tissues. They are called water soluble because they dissolve in water and hence are excreted by the urine. That is the reason, these vitamins must be more in intake. Moreover, dogs do not need Vitamin C as their bodies produce it themself. They do, however, require vitamin B and its types.",
      "Oil soluble vitamins are found in animal fat. The body absorbs it directly through the intestines. We suggest not to give supplements for these vitamins to your dog. Just a good diet will suffice for all the nutrients they require.",
      "What are Minerals?",
      "Just like humans, minerals are also required for a dog’s well being. Minerals are essential nutrients that play important roles in various bodily functions, including building strong bones and teeth, maintaining normal heart rhythm and muscle function, and supporting the immune system. There are many different minerals that the body needs to function properly, including calcium, phosphorus, potassium, iron, and magnesium. ",
      "When it comes to dogs, their mineral requirements depend on their age, size, and overall health. For example, growing puppies and pregnant dogs have higher mineral requirements compared to adult dogs. Some common minerals that are particularly important for dogs include calcium, phosphorus, and magnesium for bone health, iron for red blood cell production, and potassium for maintaining fluid balance and normal muscle and heart function.",
      "It's essential to provide dogs with a balanced diet that includes adequate amounts of these essential minerals. <a href='https://petmojo.care/blog/can-my-dog-eat-human-food' rel='noopener noreferrer' target='_blank'></a>Commercial dog food products are often formulated to meet the daily mineral requirements of dogs, but it's always a good idea to consult with a veterinarian to determine the right diet for your pet. In some cases, a mineral supplement may be necessary to ensure that your dog is getting all the nutrients they need to maintain good health.",
      "What are the different Vitamins?",
      "Vitamin A: This vitamin is essential for good eyesight, a healthy immune system, and strong bones. It's also necessary for growth and reproduction. You can find vitamin A in animal liver, fish oils, and dairy products.",
      "Vitamin D: This vitamin is important for maintaining healthy bones and teeth, and it helps the body absorb calcium. It can be found in fatty fish, eggs, and dairy products.",
      "Vitamin E: This antioxidant vitamin helps protect cells from damage and supports the immune system. It's also important for maintaining a healthy coat and skin. You can find vitamin E in vegetable oils, nuts, and seeds.",
      "Calcium: Calcium is essential for strong bones and teeth, and it helps maintain normal heart rhythm and muscle function. It can be found in dairy products, bones, and leafy green vegetables.",
      "Phosphorus: Phosphorus works hand-in-hand with calcium to support strong bones and teeth. It also helps the body use carbohydrates and proteins for energy. You can find phosphorus in dairy products, meat, and fish.",
      "Potassium: Potassium is an essential mineral that helps regulate fluid balance, supports normal muscle and heart function, and helps the body use carbohydrates for energy. It can be found in bananas, potatoes, and spinach.",
      "Iron: Iron is necessary for the production of haemoglobin, a protein in red blood cells that carries oxygen throughout the body. Iron can be found in red meat, poultry, and seafood.",
      "Magnesium: Magnesium is important for strong bones and teeth, normal muscle function, and a healthy immune system. It can be found in nuts, seeds, and leafy green vegetables.",
      "Do dogs need supplements for Vitamins or Minerals?",
      "The short answer is that it depends. While a well-rounded diet that includes a variety of wholesome foods can provide dogs with the vitamins and minerals they need, supplements can be beneficial in certain situations. For example, dogs that have health problems, such as digestive issues or a weak immune system, may benefit from additional vitamins and minerals to help support their health. Older dogs may also benefit from vitamin and mineral supplements to maintain their overall health as they age.",
      "However, it's important to remember that not all supplements are created equal, and it's essential to choose a high-quality product that is formulated specifically for dogs. Overdosing on certain vitamins and minerals can be toxic to dogs, so it's important to follow the recommended dosage and consult with a veterinarian before starting any supplement regimen.",
      "In some cases, a dog's dietary needs can be met through the use of specially formulated dog food, rather than through the use of supplements. For example, puppy food typically contains higher levels of certain vitamins and minerals to support growth and development, and senior dog food may contain added vitamins and minerals to help support ageing dogs. It's essential to choose a high-quality product and consult with a veterinarian before starting any supplement regimen.",
      "Providing your dog with a balanced diet that includes these essential vitamins and minerals is crucial for their overall health and well-being. You can ensure that your dog is getting all the nutrients they need by feeding them a well-rounded diet or by supplementing their diet with high-quality dog food. If you're unsure about your dog's dietary needs, it's always best to consult with a veterinarian.",
    ],
    id: "important-vitamins-and-minerals-for-your-dog-108",
    title: "Important Vitamins and Minerals for your Dog - Petmojo",
    description:
      "Minerals and Vitamins are the basic nutrients required by all living things. Read everything about the vitamin and mineral requirements in dogs.",
    serial_no: 2,
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1790.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-107.webp",
    views: "1211",
    date: "4th February, 2023",
    heading: "Indian Spitz: Ultimate Guide to Dog Breed",
    full_content: [
      "The Indian Spitz is one of the most attractive Indian dog breeds there exists. They are quite popular in India and are known for their playful and affectionate character. They are great at adapting to new situations and can thrive in any living situation. This quality is great for being a family dog. ",
      "Indian Spitz are often mistaken as Pomeranians, but they belong to the vast Spitz family. They have been a popular choice for more than 30 years, especially since one of them appeared in the popular hindi movie, Hum Aapke Hain Kaun. ",
      "In this blog post, you will learn everything you need to know about an Indian Spitz before you decide to bring them home. A comprehensive guide to their characteristics, the kind of personality they have and their care and need. ",
      "History:",
      "During British rule in India, in the 19th century, Britishers brought German Spitz to India. That was the first time a dog from the Spitz family was introduced in India. Over years of breeding, came to existence the Indian Spitz, very similar to German Spitz but acclimatised to Indian weather. ",
      "They became popular, as explained, in the early 1990s with the Salman Khan starrer movie. They are small and alert animals that are excellent as indoor guard dogs.",
      "Characteristics:",
      "Indian spitz are medium sized dogs that weigh around 5 to 7 kg. Their height ranges from 35 to 45 cm based on their gender and other factors.",
      "They have a fluffy fur coat and their ears are pointy and generally standing up. They are of various colours like white, brown and black. Some of them may have some spots on their coat. Their coat is what they are known for and makes them look super adorable. To further describe an Indian Spitz, they have short legs as compared to their bodies. Their head is shaped like a wedge and their ears are high and pointy. They have a long, fluffy tail that curves towards their own body.",
      "Personality:",
      "They have a lot of energy and are extremely playful. Communicating with these fur balls is very nice as they are very expressive. Their eyes can say a thousand words. They are also great at understanding and reacting to human emotions appropriately, sometimes better than other humans. That is why Indian Spitz are great with kids as well. ",
      "They are highly intelligent and that makes them great to become house guard dogs. Their care for their family, combined with intelligence is a unique combination. ",
      "Energy Level:",
      "The Indian Spitz is a highly energetic breed that requires plenty of physical and mental stimulation to keep them happy and healthy. This breed was originally bred as a hunting dog and still has a strong desire for exercise and outdoor activities. A daily walk or play session will help keep their energy levels in check and prevent destructive behaviour. It is essential to provide them with plenty of opportunities to run, jump, and play. They love to chase after balls, play fetch, and go for long walks. Additionally, they enjoy playing with their owners and can be trained to perform various tricks and agility exercises. This breed is best suited for families or individuals who are active and enjoy spending time outdoors. ",
      "Due to their wonderful quality of adaptability, they can easily live in apartments as well. But make sure to take them out often as they need plenty of space to run and play. If given the right amount of exercise, the Indian Spitz can make a great pet for families and individuals who are looking for a playful and energetic companion. ",
      "Training Needs:",
      "Being an intelligent breed that is eager to please its owners, makes them highly trainable. Training is an important part of this breed's life as it helps to keep their minds active and prevents boredom. This breed responds best to positive reinforcement methods, such as treats and praise, rather than harsh punishment.",
      "Basic obedience training is a must, as it will help to establish a strong bond between the dog and its owner. Training should start at an early age, and it is important to be consistent and patient throughout the process. Socialisation is also a crucial part of training for the Indian Spitz, as it will help them to become confident and well-behaved around other people and animals. ",
      "The Indian Spitz is an active breed, so it is important to incorporate plenty of physical activity into their training sessions. This can include training for agility and obedience, as well as daily walks and play sessions. It is also important to train the Indian Spitz to respect boundaries and commands, as they have a strong instinct to chase and hunt. Regular training sessions will help to keep this breed physically and mentally stimulated, making them a well-behaved and obedient pet. ",
      "Health and Wellness",
      "The Indian Spitz is a generally healthy breed, but like all dogs, it is important to be aware of potential health concerns to keep them in optimal health. This breed is prone to certain health issues such as eye problems, skin allergies, and hip dysplasia. Regular vet check-ups and vaccinations can help prevent or detect these health issues early on. ",
      "Feeding the Indian Spitz a balanced diet and providing them with regular exercise will help keep them in good physical condition. Keeping their thick, fluffy coat well-groomed will also help to prevent skin irritations and other health issues. The Indian Spitz is an active breed and requires plenty of exercise to stay physically and mentally fit. Regular exercise, such as daily walks and play sessions, will help to keep them in good physical condition.",
      "It is also important to provide them with plenty of mental stimulation, as they are highly intelligent and need plenty of mental stimulation to stay happy and healthy. Regular vet check-ups and a healthy lifestyle will help to ensure that the Indian Spitz remains in good health and wellness throughout its life. ",
      "Life Span:",
      "The life span of an Indian Spitz ranges from 15 to 16 years based on the health regime they’ve been on since the beginning, unless any unforeseen illness or injuries occur.",
    ],
    id: "indian-spitz-ultimate-guide-to-dog-breed-107",
    title: "Indian Spitz: The Ultimate Guide to Dog Breed - Petmojo",
    description:
      "The Ultimate Guide to Indian Spitz Breed. Everything you need to know before you decide to pet them. Read to learn more.",
    serial_no: 3,
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1787yuy.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-106.webp",
    views: "912",
    date: "2nd February, 2023",
    heading: "Fun Tricks To Teach Your Dog",
    full_content: [
      "If you are planning to get a dog, this is the perfect guide for you. Getting a dog is a big decision for a family, especially if you are first time pet parents. If you are sceptical about making the decision, maybe start with a small pet like hamsters. ",
      "But if you have made up your mind about getting a dog and are here to explore the different breeds of dogs, then we will help you with the most exclusive list of best and worst dogs, especially for first time pet parents. For first time owners, we suggest breeds that require less work and are easy to maintain.",
      "Also, while you are finalising the breed you want, try to puppy proof your apartment and prepare yourself and your family members mentally to accept the newest addition to the family.",
      "Let’s dive right into the best and worst dog breeds for you! ",
      "Best Dog Breeds for first time Owners:",
      "Golden Retriever:",
      "Gentle and Sweet",
      "Playful and Affectionate",
      "Good with children and other pets",
      "Active and need daily exercise",
      "Need regular grooming ",
      "This popular breed is known for its friendly and outgoing personality. They make great family pets and are good with children and other pets. They are easy to train for first-time owners. <a href='https://petmojo.care/blog/golden-retriever-the-ultimate-guide-to-dog-breed-36' target='_blank' rel='noopener noreferrer'>Golden Retrievers</a> need daily exercise and have moderate grooming needs. ",
      "Pomeranian:",
      "Sweet Little Lap Dog",
      "Little Stubborn",
      "Get Close to 1 person in the family",
      "Require Obedience Training",
      "Moderately active and need daily exercise",
      "High grooming needs",
      "<a href='https://petmojo.care/blog/pomeranian-the-ultimate-guide-to-dog-breed-66' target='_blank' rel='noopener noreferrer'></a>Pomeranians are a small, lively breed that make great companions. They are friendly and affectionate towards their owners and good with children and other pets. They are also intelligent and easily trainable, making them a good choice for first-time owners. Pomeranians need daily exercise and have high grooming needs, as they have a thick, fluffy coat. Despite their small size, they have a lot of energy and personality, making them a great choice.",
      "Labrador Retriever:",
      "Medium, Large Dogs",
      "Excellent with kids",
      "Loyal and Affectionate",
      "Need plenty of exercise",
      "Minimal grooming needs",
      "<a href='https://petmojo.care/blog/labradors-the-ultimate-guide-to-dog-breeds-26' target='_blank' rel='noopener noreferrer' ></a>Labradors are great dogs for first time dog owners. They are loyal, friendly and playful. They make great family pets and are easy to train. They are also very energetic and need a lot of exercise. If you are considering getting a Lab, it is important to research the types of Labs available to make sure you find the right one for you.",
      "Pugs:",
      "Best Family Dogs",
      "Easily Trained",
      "Low maintenance",
      "Need minimal grooming",
      "Need Ample amount of exercise",
      "<a href='https://petmojo.care/blog/pug-the-ultimate-guide-to-dog-breeds-54' target='_blank' rel='noopener noreferrer' ></a>Pugs are a great choice for beginners because they are gentle and friendly. They are very playful and love to be around people. They make a great companion dog and are good with children. They are not aggressive and are gentle around other dogs. Pugs can be stubborn and require a lot of exercise.",
      "Poodle:",
      "Great with families",
      "Smart",
      "High energy",
      "Need daily Brushing and regular grooming",
      "Respond well to Training",
      "Poodles are smart and easy to train, making them a great choice for first time owners.Their pups are fun and playful. They love a game of fetch and spending time with their family. They are easy to train and require a lot of exercise. ",
      "Worst Dog Breeds for first time Owners:",
      "Border Collie:",
      "Border Collie are not recommended for new pet owners. They are very energetic and highly driven. They need a lot of exercise on an everyday basis, much more than any of the dogs mentioned above. Not just physical stimulation, Border Collie also need ample amounts of mental stimulation. Without enough workout, physical and mental, they develop a lot of behavioural issues.",
      "Siberian Husky:",
      "Generally friendly, happy and affectionate dogs, Siberian Huskies are still one of the worst dogs for new owners only because they are highly enthusiastic and highly peppy. They require a lot of exercise and only walks do not cover for the amount of exercise they need. They need runs, hiking, and many games. They also are predatory in their nature. So, if you have other pets like cats, they are dangerous for them.",
      "German Shepherd:",
      "They are one of the most intelligent breeds of dog to pet. In fact, they are very close to human intelligence, as believed by many. German Shepherd are intelligent but tough to pet due to the amount of stimulation required to function in complete capacity. Also, you need to spend time training them professionally to actually get them to behave properly. So, not just tough, it may get expensive to get them as well. ",
      "Rottweiler:",
      "Rottweilers have a muscular build, they are very heavy, and have a lot of protective instincts. These qualities make them great as service dogs but not that great as a family dog. Don’t get me wrong, Rottweilers are also good family dogs but they are high maintenance and not easy to handle for first time pet parents. ",
      "Dalmatian:",
      "Dalmatians are a high energy breed that require a lot of exercise, making them a challenge for first-time pet owners. They are not recommended for apartment living and may not do well in small spaces. They can also be stubborn and difficult to train, and may not be the best with young children. In addition, they have high grooming needs, as their short, dense coat requires frequent brushing to prevent matting. Overall, Dalmatians may not be the best choice for first-time pet owners who are looking for a low-maintenance companion.",
    ],
    id: "fun-tricks-to-teach-your-dog-106",
    title: "Fun Tricks to Teach your Dog - Petmojo",
    description:
      "We have a list of cool tricks to teach your dog along with the steps to train them. Read to know them and make your dog do the cool tricks!",
    serial_no: 5,
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1778hj.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-105.webp",
    views: "923",
    date: "2nd February, 2023",
    heading: "What to look for in a Good Dog Boarding Centre",
    full_content: [
      "The Dog Boarding facility is the place where you can leave your canines while you have to go out of town. There are also places called doggy daycare where you can leave your dogs while you go for work during the day. In both cases, you need to be sure of the place where you are leaving a piece of your heart. It isn’t easy to make the decision.",
      "When it comes to finding a good dog boarding facility, it's important to look for certain qualities which include the safety and mental peace for the dog, to ensure the best care for your furry friend. Here are the top things to consider when searching for the perfect dog boarding facility.",
      "Experience and Training:",
      "The staff at the boarding facility should have experience handling different breeds and temperaments of dogs. They should also have received proper training on how to handle and care for dogs. Look for certifications and credentials that demonstrate their expertise and dedication to their work. So that when you leave your dog with them, all his problems are taken care of.",
      "Cleanliness and Sanitation:",
      "A clean and sanitary environment is essential for the health and well-being of your dog. Make sure to tour the facilities and observe the cleanliness of the kennels, play areas, and common areas. Check if the lighting is proper, also make sure to meet the boarders taking care of dogs and talking to them may reveal if they are competent enough. By visiting the facility physically, you can identify the problems, if any.",
      "Safety and Security:",
      "Safety should be the foremost concern of pet parents while selecting a boarding facility. The boarding facility should have a secure and safe environment for your dog. This includes secure fencing, locks, and alarms to keep your dog protected from theft or escape.",
      "Plenty of Exercise and Playtime:",
      "Dogs need plenty of exercise and playtime to stay healthy and happy. Make sure the boarding facility offers enough opportunities for your dog to play, run and burn off energy. Boarding centres that have about 10 dogs at a time generally are good. With too many dogs, people cannot give individualised attention to each pet, while too few pets may be bad as your dog won’t get the much deserved socialising opportunity.",
      "Personalised Attention:",
      "It is important that our dogs feel the personal attention that they do at home. Well, it is impossible for them to get the same amount of attention that they do at home but empathy and love can go a long way! A caring and loving dog boarder is what you should look for. A good dog boarder will give your dog individual attention, including regular potty breaks, feedings, and playtime. This will help your dog feel more comfortable and at ease while away from home. ",
      "Clear Communication and Availability:",
      "The boarding facility should have clear and open communication with their clients, including providing updates and pictures of their dog while in their care. The staff should also be available to answer questions or address concerns at any time. This creates a trustworthy bond between the pet parent and boarder. The assurance that your pet is in safe hands is all we pet parents need, isn’t it?",
      "Positive Reinforcement Training:",
      "If you are going for a board and train service by a dog boarding centre, make sure to ask the question about the <a href='https://petmojo.care/blog/methods-to-train-a-dog-1-34' target='_blank' rel='noopener noreferrer'>method of training</a> they will use. The best type of training that you want the boarder to use is the positive reinforcement training. The staff should use positive <a href='https://petmojo.care/dog-training' target='_blank' rel='noopener noreferrer'>reinforcement training</a> methods to reinforce good behaviour and discourage bad behaviour. This will help your dog have a positive experience while away from home.",
      "Medical Care:",
      "We always have to be cautious of our dog’s health when they are with us or we select a boarding facility. In the event of an emergency, the boarding facility should have a plan in place for providing medical care to your dog. Make sure they have a close relationship with a local veterinarian and can provide prompt care if needed.",
      "",
      "In conclusion, these are the top qualities to look for when choosing a dog boarder. A <a href='https://petmojo.care/pet-boarding' target='_blank' rel='noopener noreferrer'>good boarding facility</a> will provide a safe, clean, and loving environment for your dog while you are away. By considering these qualities, you can rest assured that your furry friend is in good hands.",
    ],
    id: "what-to-look-for-in-a-good-dog-boarding-center-105",
    title: "What to look for in a good dog boarding centre - Petmojo",
    description:
      "A good dog boarding centre has many advantages. But, it is important to be cautious while selecting a boarding facility. Read to find the right qualities to look for.",
    serial_no: 2,
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1775juuj.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-104.webp",
    views: "763",
    date: "1st February, 2023",
    heading: "Benefits Of Kids Growing Up With Dogs",
    full_content: [
      "Some people think that dogs or pets in general are not safe for young kids as they are not as hygienic and kids are prone to infections. There is no denying that dogs have a special place in our, the dog lovers hearts. They bring joy, comfort, and unconditional love into our lives, which is why they are often referred to as “man’s best friend”. If your family or your partner needs some convincing, we have a list to help you convince them that dogs can also be incredibly beneficial for children.",
      "Studies have shown that children who grow up with dogs are happier, more confident, and have better social skills than those who don’t. In this blog post, we will explore the many benefits of children and dogs growing up together, and why it is important for parents to consider getting a dog for their family.",
      "Responsible:",
      "Children that grow up with dogs tend to become more responsible as adults than others. The important thing to follow, if you have children as well as dogs, is to make sure to give some responsibilities to your kids. The love kids have for dogs makes them work a little harder for them. For example, you can assign the responsibility of evening walks to the children. It will be an enjoyable time for them along with learning to be responsible.",
      "Healthier Children:",
      "It is scientifically proven that children that grow up with dogs are much more healthy than those without. The reason for this is that living with a dog increases the immunity of a child and this in turn leads to the body becoming better at fighting  infections. ",
      "Immunity",
      "Having a pet exposes children to pet dander and the microbes that pets carry into the home from the outdoors. These dander and microbes improve babies' developing immune systems.",
      "Allergies",
      "Having allergies like asthma or other respiratory allergies are reduced with the presence of a pet dog. The key is to expose children earlier. ",
      "Emotional Intelligence:",
      "One of the biggest benefits of having a dog is the emotional development it provides for children. Dogs are known for their unconditional love and loyalty, which can help children feel safe and secure. Growing up with a dog can also teach children the importance of empathy and compassion, as they learn to care for and nurture their furry friend.",
      "Studies have also shown that children who grow up with dogs have higher levels of self-esteem and confidence, as they learn to take on responsibilities and care for their pet. This can also help children develop their problem-solving skills, as they learn to navigate any challenges that may arise when caring for their dog.",
      "Social Development:",
      "Having a dog can also have a positive impact on a child’s social development. Children who grow up with dogs are often more outgoing and confident, as they learn to interact with other people and animals. This can be especially helpful for children who may be shy or introverted, as they learn to come out of their shell and make new friends.",
      "In addition, children who grow up with dogs are also more likely to develop strong social skills, as they learn to communicate and negotiate with others. This can be especially important as they enter school and start to form relationships with classmates and friends.",
      "Learning about True, Unconditional Love:",
      "Dogs are known for their unwavering love and loyalty, which can be a powerful lesson for children. Growing up with a dog can teach children about the meaning of true, unconditional love. This experience can help children develop a strong sense of self-worth and security, as they learn that they are valued and loved just as they are.",
      "Fights Obesity:",
      "Children who have a dog are more likely to be physically active, as they play and walk with their pet. This daily exercise can help children maintain a healthy weight, as they develop important physical skills and enjoy the great outdoors. In addition, playing with a dog can also help children burn excess energy and reduce stress, making it an ideal form of physical activity for children of all ages.",
      "Physical Growth:",
      "Growing up with a dog can also have a positive impact on a child’s physical development. Dogs need daily exercise and attention, which can encourage children to be more active and get outside to play. This can help children develop important physical skills, such as coordination, balance, and agility, as they play and run around with their dog.",
      "In addition, walking or playing with a dog can also help children develop their cardiovascular fitness, as they get their daily dose of exercise. This can be especially important for children who may struggle with other forms of physical activity, as they can still get their exercise through playing with their dog.",
      "Conclusion:",
      "In conclusion, the benefits of children and dogs growing up together are undeniable. From emotional development to social skills and physical fitness, dogs can have a positive impact on a child’s growth and well-being. So, if you are considering adding a furry friend to your family, don’t hesitate to take the plunge. Your child will thank you for it!",
    ],
    id: "benefits-of-kids-growing-up-with-dogs-104",
    title: "Advantages Of Children Growing Up With Dogs - Petmojo",
    description:
      "There are multiple benefits of a dog in your child’s life. A dog can positively impact a child's development and growth. Read to find more.",
    serial_no: 3,
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1772jh.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-103.webp",
    views: "680",
    date: "31st January, 2023",
    heading: "Caffeine Toxicity in Dogs",
    full_content: [
      "Coffee is one of the most beloved drinks for most adults. A lot of us cannot start our days without a nice cup of warm coffee. I’ve often wondered, if it is an appropriate item for our fur buddies? Well, we have already discussed that <a href='https://petmojo.care/blog/can-my-dog-eat-human-food' target='_blank' rel='noopener noreferrer' >feeding dogs what we eat</a> is not okay. But, it is not just that we shouldn’t feed our dogs what we eat. More often than not, our foods are fatal for canines. ",
      "Let’s now talk about coffee and its effect on dogs.",
      "Scientific Research of the Topic:",
      "It is common knowledge that dogs do not respond well to chocolates and coffee. Not just these 2 items, any food item that has caffeine in it has a negative effect on canines. ",
      "<a href='https://petmojo.care/blog/what-to-do-if-your-dog-eats-chocolate-59' target='_blank' rel='noopener noreferrer'></a>Chocolate poisoning occurs due to the presence of a chemical called theobromine in chocolate but coffee poisoning is different. ",
      "We need to understand the difference between dogs and humans. We need a boost of energy, which we get via coffee while dogs have enough energy by themselves. This is because their bodies cannot process such high stimulants. ",
      "Coffee has caffeine which contains a substance called methylxanthines. These methylxanthines are responsible for causing serious health issues for dogs. ",
      "Why do dogs drink coffee?",
      "Dogs are curious about everything in sight. They have two ways of finding out about anything. The first is to smell and the second is to taste. That is why dogs eat <a href='https://petmojo.care/blog/why-does-my-dog-eat-grass-38' target='_blank' rel='noopener noreferrer'></a>grass, paper and sometimes even other dog’s poop. As they do that, they are obviously curious about your morning brew and would try to steal a taste. ",
      "How to prevent coffee consumption in Dogs:",
      "In larger dogs, just a limited amount of caffeine does not cause a problem but it is best to keep them away from coffee, altogether. Don;t ever give your dog a candy that is meant for humans as a treat. Give them dog-appropriate treats only.",
      "In your house, keep all caffeine products on high shelves. Wash all your coffee utensils right away. Also, if you are someone who brews their own coffee, make sure you seal the trash bags which have coffee grounds in them.",
      "Sources of Caffeine other than Coffee:",
      "In regular life, we believe that caffeine is present in coffee. But, we are unaware of the presence of caffeine in other food items. Here is a list of items in your pantry that contain caffeine and you don’t even know it,",
      "Coffee",
      "Tea",
      "Soda",
      "Aerated Drinks",
      "Energy Drinks or Sports Drinks",
      "Diet Pills",
      "OTC Medication",
      "<a href='https://petmojo.care/blog/why-is-chocolate-bad-for-dogs-53' target='_blank' rel='noopener noreferrer'></a>Chocolate",
      "Coffee Flavoured Ice Creams",
      "Coffee Beans",
      "Coffee Flavoured Liquors",
      "Guide if dog drinks coffee or eats coffee beans:",
      "If your dog has drunk coffee or eaten some coffee beans or even if they went through the trash and congested some coffee grounds, it is essential to seek veterinary attention immediately. When you call the veterinarian, provide them with information on the type of coffee consumed and the amount. Based on the information provided, the veterinarian will instruct you on whether to bring the dog in or not. They will also give you a plan of action if they say not to bring the dog to the clinic.",
      "Wrap-up:",
      "Caffeine is toxic to dogs and can be fatal in large amounts. It is crucial to seek veterinary attention as soon as possible if your dog has consumed caffeine. The earlier the treatment, the better the chance of a full recovery. Make sure to keep coffee and caffeine related items away from your dog’s reach. As soon as you are done making coffee for yourself, clear everything from the kitchen so that the dog doesn’t go and sneak into the kitchen. ",
      "Also inform your guests (especially the ones staying overnight) to keep their coffee mugs and all caffeine related items out of sight of the dog. Sometimes, kids might feed chocolates to dogs for fun, it is always important to let kids know that chocolates are poisonous for dogs. ",
      "Keeping our dogs safe from everything is the sole responsibility of the pet parent. When you bring a dog to your home, you are the one to take care of them. To make it happen, you need to know about everything they love, they hate and everything that's harmful for them. Read more blogs on Petmojo, to stay updated.",
    ],
    id: "caffine-toxicity-in-dogs-103",
    title: "Is Coffee Bad for Dogs? Caffeine Toxicity in Dogs - Petmojo",
    description:
      "It is widely known that Chocolates are toxic for dogs. So is coffee! Find out everything about coffee toxicity and why it is bad for dogs. Read to find more.",
    serial_no: 5,
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1769hj.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-102.webp",
    views: "871",
    date: "31st January, 2023",
    heading: "6 Most Expensive Dog Breeds",
    full_content: [
      "Dogs have been considered man's best friend for centuries now. India, a country of over 1.3 billion people, is no exception to this rule. In fact, according to a recent report, Indians are not far behind in providing homes to the world's priciest dog breeds. Dogs have become increasingly popular in India, with many people willing to pay premium prices for certain breeds.",
      "Here is a list of the ten most expensive dogs in India.",
      "Tibetan Mastiffs:",
      "A Tibetan Mastiff dog can be up to INR 2,00,000–15,00,000! These large canines are famous for their strength and loyalty. These dogs are excellent mountain dogs and they make wonderful companions. The breed is also known for its thick coat and muscular build. Prices may vary depending on the breeder and the sex of the dog. Females are typically less expensive compared to their male counterparts. Overall, the Tibetan Mastiff is a great investment for anyone looking to own a large and powerful dog.",
      "The Tibetan Mastiffs originated in Tibet centuries ago. Their appearance is huge and their coats are of colours like brown, black or blue-black. Tibetan Mastiffs typically grow to be between 63-75 cm in height at the shoulder. They can weigh between 70 and 110 kg. This makes them one of the larger dog breeds.",
      "They have an aura of power and independence about them which makes them a great companion for any family. They also make great watchdogs and will protect the home and its inhabitants from any intruders. They are very intelligent and active by nature. Tibetan Mastiffs are also very protective of their owners and need to be properly trained from a young age to instil good behaviour in them.",
      "Akita Inu:",
      "Akita Inus originated in Japan. They also have been bred in America for a long time. They are known for their huge stature and impressive strength. They can grow to weigh around 30-40kg and reach a height of up to 70cm.",
      "The Akita Inu is a highly intelligent and strong breed of dog. Even though they are huge, they have a certain charm about them. Akita Inu are priced between INR 150000 to 40000.",
      "They have fearless personalities and are fiercely loyal to their owners. They are very obedient. They need to be properly socialised and trained by their owners at an early age to prevent any undesirable behaviours from developing.",
      "Caucasian Mountain Shepherd:",
      "The White Caucasian Shepherd Dog is the most expensive of all shepherd dogs in India costing as much as INR 3000,00!! These massive white dogs are gentle giants who enjoy nothing more than being by their master's side. They love children and form strong bonds with them. They have a calm temperament and are ideal for families.",
      "The dog comes in black and white colours, it has a long muzzle and thick eyebrows and brown eyes that make him look very sharp; it is a medium-sized dog that stands 55 - 70 cm high. They weigh around 40 - 50 kg. It is a very strong, robust and loyal animal; it is not a dangerous dog, but very energetic and lively.",
      "Alaskan Malamute:",
      "The Alaskan Malamute is a large and powerful sled dog that was originally bred to help Inuit people in the Canadian Arctic survive the winter months when temperatures dropped to minus forty. They were used for pulling sleds laden with food and supplies over the snow and ice. The breed has been used for many different purposes over the years, but nowadays they are mainly kept as pets. They are very expensive and can cost INR 200000 to 350000 depending on their age and quality.",
      "The Alaskan Malamute is a large and heavy breed of dog that can reach a height of 60 - 70 cm and a weight of 32 to 48 kg. They have a thick double coat that is black and white in colour and they have heavy fur around their eyes to protect them from the cold and snow. These dogs are supposed to live in the cold. They cannot survive in warm countries like India.",
      "English Mastiff:",
      "The English Mastiff is a very muscular and large breed of dog that can be up to 90 cm in height and weighs more than 100 kg. They have a great temperament and have a protective instinct towards their family, especially kids. They are very courageous and will always stand by the side of their family. They originated in England. They have a large head and can be of different colours like fawn, dark fawn and silver fawn. They can cost anywhere between 500000 to 1000000 INR. ",
      "Red Nose Pitbull Terrier:",
      "Originating in England and Ireland, Red nose pitbull terriers are medium muscular dog breeds that have a great deal of stamina and strength. They are fearless and have a powerful bite. They are great with their owners but it is important to get them trained to make sure their aggression is under control. Even though they are aggressive, they are great with children.",
      "They generally weigh between 16 to 30 kg and have a height of 50cm. They are expensive but less than others on the list. They come in a price range of INR 75000–1500000. ",
      "End Note:",
      "Owning a dog can be a wonderful and fulfilling experience, but it can also come with a hefty price tag. The six dog breeds mentioned in this blog post are among the most expensive in the world and have unique characteristics that make them appealing to many pet owners. ",
      "However, it is important to keep in mind that the cost of owning a dog extends beyond the initial purchase price and includes ongoing expenses such as food, grooming, and veterinary care. When considering adding a dog to your family, it is important to carefully research the breeds and select one that is a good fit for your lifestyle and budget. ",
      "Ultimately, the most important factor in choosing a dog is finding one that brings joy and happiness to your life.",
    ],
    id: "6-most-expensive-dog-breeds-102",
    title: "The most expensive dog breeds - Petmojo",
    description:
      "Some Dog Breeds are so expensive that it is unbelievable. To know more about such breeds, read the blog post.",
    serial_no: 2,
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1766h.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-101.webp",
    views: "966",
    date: "30th January, 2023",
    heading: "A Day In The Life Of A Puppy: Training and Beyond",
    full_content: [
      "A new puppy can be both exciting and overwhelming. From house training to socialising, puppies have a lot to learn. Puppies and even older dogs thrive on routine. That is why it is very important to set a general routine for your puppy. ",
      "Note: We are not saying to follow the exact routine that we suggest in the blog post below, this is just a suggestion. ",
      "If you train your puppy well in the initial months, your life with your dog in the later years will be blissful. To train the puppies, it is better to understand what a typical day looks like for a pup in training can help you better prepare for the journey ahead.",
      "What should be included in Puppy Training?",
      "Puppy training must include all of the following exercises to get the most out of it.",
      "Potty Training",
      "Socialisation",
      "Sleep Training",
      "Separation Anxiety Training",
      "Playing, walking, etc (Physical Stimulation)",
      "Obedience Training",
      "Mental exercises for mental stimulation.",
      "What is the need of a schedule?",
      "Dogs thrive on a routine. Even older dogs get very anxious when their regular routine is disturbed. It is extremely important to set a schedule for puppies as that is the way they are wired. ",
      "If you teach them to get up at 6 in the morning from the very beginning, they will keep doing it till the end. That is why it is advisable to set their routine early on.",
      "I have a dog at home and he is so set on his routine that sometimes I wonder if he can see the clock! For example, at exactly 4 in the evening, he goes to my brother's room, no matter what! That is what he has been doing since he was a puppy and now it is a part of his routine. ",
      "Puppy Training Schedule as per Petmojo?",
      "Morning Routine:",
      "Potty time (6-7 am): ",
      "The first thing to do after your puppy wakes up is to take them outside to potty. As soon as they wake up, you have to get up with them. This is the time to train them to relieve themself in the right place. Take them to the place where you want them to do it. Praise them when they go and reward them with a treat.",
      "After the potty session, see if the pup sleeps again. If he does, you can rest for a while as well or else you also start your day as early as they do.",
      "Breakfast time (7 am):",
      "After potty time, it’s time to feed your puppy their breakfast. Make sure to stick to a consistent feeding schedule for better food habits.",
      "Toilet Time (7.30 am):",
      "Post Breakfast, they might again want to poop.",
      "Playtime (7.45 am): ",
      "Playtime is a great way to bond with your puppy and tire them out for the day ahead. Play fetch, tug-of-war or hide-and-seek.",
      "Nap time (8.30 am to 10.30 am): ",
      "Puppies need a lot of sleep, so make sure to provide them with a quiet and cosy place to nap.",
      "",
      "Afternoon Routine:",
      "Lunch time (12 noon): ",
      "Give the puppy some nutritious meals to enjoy.",
      "Toilet Time (12.30 pm)",
      "Play and Training (1 pm to 2 pm) : ",
      "Review the basic commands from the morning and try new commands like 'roll over' or 'speak'. End the session with some games and fun.",
      "Nap (2 pm to 4pm)",
      "",
      "Evening Routine:",
      "Walk and Potty (4pm to 5pm)",
      "Play and Training session (5pm to 6.30pm):",
      "Engage in more playtime and activities to help your puppy release energy.",
      "Potty time: ",
      "Take your puppy outside for one last potty break before bed.",
      "",
      "Night Routine:",
      "Dinner time: ",
      "Feed your puppy their dinner and make sure to stick to a consistent feeding schedule.",
      "Wind down:",
      "Give your puppy a calm and quiet environment for the night. This can include a comfy bed, a soft blanket and some soothing music.",
      "Tips on Training Them:",
      "Basic commands:",
      "Start with basic commands such as 'sit', 'stay', 'come', and 'heel'. Use positive reinforcement techniques like treats and praise to encourage your puppy.",
      "Socialisation: ",
      "Expose your puppy to new people, places and things. Take them on walks, to the park or to visit friends. This will help them get used to new experiences and reduce anxiety in the future.",
      "Potty training:",
      "Consistency is key when it comes to potty training. Take your puppy outside every hour, after naps and after meals.",
      "If you are having any problem trying to do it all yourself, it is a great idea to hire a professional trainer.",
      "Conclusion:",
      "Training a puppy takes patience, consistency, and lots of love. Remember to take things slow, be patient and have fun with your new furry friend. By following a routine and sticking to a consistent schedule, you and your puppy will have a successful and enjoyable journey.",
    ],
    id: "a-day-in-the-life-of-a-puppy-trainig-and-beyond-101",
    title: "Puppy Life: A day through the eyes of puppy - Petmojo",
    description:
      "A day in the life of a puppy. From daily routines to training tips, learn how to help your puppy grow and thrive. Read the latest blog post. ",
    serial_no: 3,
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1756.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-100.webp",
    views: "878",
    date: "30th January, 2023",
    heading: "How Long Does it Take to Complete Dog Training?",
    full_content: [
      "Training a dog is an important step in ensuring that your pet is well-behaved, obedient, and a joy to be around. But, a lot of pet parents are confused about making this extremely important decision. Here is a blog post that will let you know how long does it take to complete the training process? The answer is not a simple one and depends on several factors.",
      "Factors That Affect Training Duration:",
      "Determining the training duration is not as simple as it sounds. The duration of dog training depends on several factors like age of the dog, breed, the training method being used as well as the owner's or trainer’s dedication. Some of the things that affect the time required to train your dog are as follows. ",
      "Age of the Dog:",
      "It is pretty obvious that puppies are easier to train than adult dogs. But, never believe if someone tells you that we cannot train your dog because he/she is an older dog. It may be more difficult, but it is not impossible. You probably just need a much more trained person to do the job. An expert trainer will not hesitate to train a dog based on its age.",
      "Breed:",
      "Every breed has their own personalities and not just that, every dog is different just like humans. The treatment of each breed and each dog cannot be the same.  Certain breeds of dogs are easier to train than others. For example, retrievers and herding dogs, which are known for their guarding, are often easier to train than hounds and toy breeds. ",
      "Training Method: ",
      "Different training methods will take different amounts of time to complete. We have multiple blog posts discussing the various training methods used for training a dog. For example, positive reinforcement training is often faster than punishment-based training.",
      "Owner's Dedication: ",
      "If you are one of the people that has a routine and follows them properly, your dog can be trained immediately. Dogs can learn much better if they are taught continuously, every day. How much time and effort the owner is willing to put into training will greatly affect the training duration. If you are busy, and cannot take out time every day, it is advisable to hire a professional trainer who can guarantee to maintain a routine. ",
      "Stages of Dog Training:",
      "As everything else, there are various stages involved. Not everyone wants to train their dog till advanced training. Some people just want to make sure their dog is obedient and follows basic commands, others are willing to go all the way. Dog training can be broken down into several stages, each of which takes a different amount of time to complete. These stages include:",
      "Socialisation: ",
      "Socialisation is the first thing that a dog owner should do for them. It helps them to understand different smells, get them various experiences and once they are comfortable with other dogs as well as people, aggression and obedience training becomes easy. Basic socialisation should be done as a puppy. This stage usually takes several months to complete and involves introducing your dog to new people, places, and experiences.",
      "Basic Obedience:",
      "Basic obedience training includes teaching a lot of commands to the dog. It includes commands like sit, stay, etc. These are the commands necessary for controlling the dog and making sure he/she knows that you are the owner. Basic obedience training usually takes 4-6 months to complete and involves teaching your dog commands ",
      "Advanced Training:",
      "If you are looking to get advanced training for your dog, it is a much longer process. Advanced training, such as agility or scent work, can take up to a year to complete, it can be less based on the factors discussed earlier. It requires a high level of dedication and commitment from the owner or the trainer.",
      "Conclusion:",
      "The duration of dog training can vary greatly depending on several factors, including the age of the dog, breed, training method, and owner's dedication. On average, it takes 4-12 months to complete basic obedience training and up to a year to complete advanced training. It is important to remember that training is a lifelong process and that you should continually reinforce good behaviour and obedience throughout your dog's life.",
    ],
    id: "how-long-does-it-take-to-complete-dog-training-100",
    title: "Find out the amount of time required to train your dog - Petmojo",
    description:
      "Training your dog can be a lengthy process, especially if you do not have a good trainer. Here is an estimate of the time required to train your dog. Read to find more.",
    serial_no: 5,
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1750.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-99.webp",
    views: "1134",
    date: "28th January, 2023",
    heading: "The Right Dog Breed for your personality",
    full_content: [
      "Getting a dog is a huge decision. We welcome a new member into our home and that calls for some research before making such a big decision. There are many ways to decide the right breed. Some people select the dog based on looks which is not the right way. Looks can only take you so far. The real thing to match while getting a dog is the personality.",
      "One of them is to decide based on your personality. Most breeds have a few traits which are common amongst all dogs of the breed. We figured we could help you make the decision by decoding the right breed for the right personality. ",
      "Lifestyle Considerations:",
      "One of the first things to consider is your lifestyle and family. If you are single and working, you should think of all the responsibilities a dog has. You need to make sure that you would have enough time to take care of the little fur baby. ",
      "If you are a couple, it is important that both the partners are equally invested in the idea of a canine companion in their lives. Both must be open to handling the responsibilities and neither of them should be scared or wary of a dog.",
      "The third scenario is if you have kids, most children love the idea of having a pet but as a parent, can you manage the dog as well as your kids. In this case also, you must discuss and divide the work with your partner as well as kids. Make sure you give some duties with respect to the new fur buddy to your kids as well.",
      "Asking the right questions will narrow down your decision to the question, which is the ideal breed for you. You should also think of the size of your apartment and availability of a garden, backyard or adequate space for exercise.",
      "Breeds Based on Personality:",
      "Workaholic:",
      "If you and your partner are one of those who are busy with the office. You have a house in a big city which feels empty but you love your job and are not willing to give it up for the responsibility of a family, then the best breed for you is the Bulldog, Basset Hound or Great Dane. They are low energy and low maintenance, so they require much less attention than most breeds. ",
      "Leaving them home alone for all day, every day is not something most dogs can handle. So, you need to figure out a way to either have at least one person stay home all day or get a good doggy daycare near you. You can also use <a href='https://petmojo.care/pet-boarding' target='_blank' rel='noopener noreferrer'>boarding services</a> to board the dog while you are off to work.",
      "The Adventurous Soul:",
      "In your household, if going on adventure trips is the norm, you need a dog that can accept and enjoy the same things you do. You all love camping, trekking, and hiking, the best dog breed for you is <a href='https://petmojo.care/blog/labradors-the-ultimate-guide-to-dog-breeds-26' target='_blank' rel='noopener noreferrer'>Labrador</a> or <a href='https://petmojo.care/blog/german-shepherd-the-ultimate-guide-to-dog-breed-48' target='_blank' rel='noopener noreferrer'></a>German Shepherd.",
      "These dogs have limitless energy and are generally known to be outdoor dogs. In fact, they do require an ample amount of <a href='https://petmojo.care/dog-running' target='_blank' rel='noopener noreferrer'></a>exercise to expel the energy they have. This is important for their physical as well as mental stimulation. With less exercise, they may become lazy and get health problems.",
      "Party Person:",
      "For the party person, a social and outgoing dog breed is the best fit. A social and party person likes to go out as often as possible. If you get a dog who prefers cuddling over spending wild days, you may end up resenting them.",
      "Breeds such as the <a href='https://petmojo.care/blog/golden-retriever-the-ultimate-guide-to-dog-breed-36' target='_blank' rel='noopener noreferrer'>Golden Retriever</a>, or <a href='https://petmojo.care/blog/beagles-the-ultimate-guide-to-dog-breed-61' target='_blank' rel='noopener noreferrer'>Beagle</a> are known for their outgoing personalities and love for attention. They're perfect for those who enjoy hosting parties and socialising with friends.",
      "Introvert:",
      "If you are an introvert, you must not get a super energetic dog as it will be a problem for you to maintain the same energy as them. You need a low maintenance dog that has a similar energy level as you do. Preferable one that does not want to socialise much.",
      "The best breeds for an introvert, the best breeds are Bulldog, Basset Hound, and Great Dane. These dogs are laidback and have low energy. They also prefer a quiet life with the ones they love.",
      "Mushy Romantic:",
      "If you are one of those mushy romantic people who looks for love everywhere and is very emotional, you need a pet that is big and snuggly. ",
      "The best breed for you is Golden Retriever or Bernese Mountain Dog. These are big and snuggly. They are also gentle and sweet. They are perfect for those who are looking for a companion who will be by their side all the time.",
      "Best Comic Timing:",
      "If you feel fun and laughter is a huge part of your life, you must get a dog that will be with you in your goofy plans and becomes your partner in crime. Someone who would make you laugh as well.",
      "The best breeds for you are the Poodle, Australian Terrier and Jack Russell Terrier. These are very playful and super energetic. They always keep doing funny things and will keep you on your toes.",
      "Final thoughts:",
      "Choosing the right dog breed for your personality is essential for ensuring a happy and harmonious relationship with your pet. Take the time to research different breeds and understand their personalities, and also take a moment to understand your own personality. By matching the right dog breed to your lifestyle and personality, you'll be able to enjoy a lifetime of love and companionship with your furry friend.",
    ],
    id: "the-right-dog-breed-for-your-personality-99",
    title: "Find A Dog That Matches Your Personality - Petmojo",
    description:
      "Explore different dog breeds and match them to your unique personality. A guide to help you find the perfect dog breed for you. Read to find more.",
    serial_no: 2,
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1747.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-98.webp",
    views: "1287",
    date: "28th January, 2023",
    heading: "Chihuahua: Ultimate Guide to Dog Breed",
    full_content: [
      "Chihuahuas are one of the smallest dog breeds out there. Even though they are small, they are very energetic. The breed originated in Mexico. They are known for their feisty personalities. A lot of celebrities have gotten Chihuahuas as a pet over the years. That is how they became popular. In this blog post, we will discuss everything you need to know about the Chihuahua, including their history, personality, and care requirements, if you are planning to get them for yourself.",
      "History:",
      "The Chihuahua is believed to have originated in Mexico, with evidence of the breed dating back to the 9th century. They were first discovered by European explorers in the 1800s and were brought back to Europe and America as a new and exotic breed. The Chihuahua quickly became popular in the United States, and the American Kennel Club recognized the breed in 1904.",
      "Characteristics:",
      "When we talk about their physical characteristics, Chihuahuas are extremely small. Their size is as small as 12 cm to 20 cm which is about 5 to 8 inches. Their weight is also too low. They weigh about 3 kg. They have a typical shape of head and have very large eyes. Their eyes are extremely expressive. Their coats can be of various types. Some of them have long fur, others have short fur. The colour range of Chihuahuas also ranges from brown to white to tiger stripes. Their colour and patterns are very diverse.",
      "Personality:",
      "Chihuahuas are known for their energetic and feisty personalities. They are fiercely loyal to their owners and can be protective of them, making them great watchdogs. They are also known to be good with children and other pets, as long as they are socialised properly from a young age. Chihuahuas are also known for their intelligence and trainability, making them easy to train and teach tricks. However, they can also be stubborn at times and may require patience and positive reinforcement during training. They can also be prone to small dog syndrome, where they may display aggressive or dominant behaviour towards other dogs or people. This can be prevented with proper socialisation and training.",
      "Health and Wellness:",
      "Chihuahuas are a small breed and do not require a lot of space, making them suitable for apartment living. They do need regular exercise and mental stimulation, so a daily walk or playtime is important to keep them healthy and happy. They are also known to be prone to dental issues, so regular teeth brushing and dental checkups are recommended. Due to their small size, they are also sensitive to cold temperatures, so they may need a sweater or jacket during colder months. They also have a short coat that requires minimal grooming, making them a low-maintenance breed. Chihuahuas are generally a healthy breed, with a lifespan of about 15 years. However, like all breeds, they can be prone to certain health issues. Some common health issues in Chihuahuas include dental problems, hypoglycemia (low blood sugar), and shivering. They can also be prone to certain genetic conditions such as heart problems and luxating patella (dislocated knee). It is important to purchase a Chihuahua from a reputable breeder who conducts health testing on their breeding dogs. This can help reduce the chances of your Chihuahua developing certain health issues.",
      "Training Needs:",
      "Chihuahuas can be trained using the positive reinforcement <a href='https://petmojo.care/blog/methods-to-train-a-dog-1-34' target='_blank' rel='noopener noreferrer' >method of training</a>. Other methods do not work so well with them as they don’t respond to harsh treatment well. It is highly recommended to train them as they are mostly aggressive, especially as puppies. Due to their high energy level, training them can be quite a fun exercise. The various training that you must get for your Chihuahua are obedience training early on as they are very destructive as puppies, crate training for the same reason, and potty training. These three are a must! If they are properly obedience trained, they can do very well in agility sports.",
      "Some owners of Chihuahuas say that their attitude resembles that of a terrier, even though their size is much smaller. To deal with them, you or the trainer has to be firm but loving.",
      "Energy Level:",
      "Despite the small size, Chihuahuas are highly energetic. They can play for long hours. Their favourite exercises are often playing and running in an open space, digging for something, or walking. While you leave them to play and run in the backyard, don;t ever leave them unsupervised as they can easily go out from small spaces. ",
      "Even though they have a lot of energy and love to play, if they are getting tired, you must immediately pick them up. To know if they are tired, look for signs like panting or a drop in energy level. ",
      "Life Span:",
      "As we have previously discussed, smaller dogs tend to live longer than big ones. Chihuahuas can live between 14 to 16 years, which is the longest as compared to most breeds.",
      "Conclusion:",
      "The Chihuahua is a small and energetic dog breed that is known for its feisty personality and loyalty to its owners. They are easy to train and adapt well to different living situations, making them a popular choice for both city dwellers and rural dwellers. With proper care and training, they make great companion pets for people of all ages.",
      "The Chihuahua is a loving, loyal and fun-loving breed, with a lot of energy and personality packed into a tiny package. They are great companions for both singles and families, and a wonderful addition to any household. With proper care and socialisation, they can be a joy to be around for many years to come.",
    ],
    id: "chihuahua-ultimate-guide-to-dog-breed-98",
    title: "Chihuahua: The Ultimate Guide to Dog Breed - Petmojo",
    description:
      "The Ultimate Guide to Chihuahua Breed. Everything you need to know before you decide to pet them. Read to learn more.",
    serial_no: 3,
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1744.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-97.webp",
    views: "1287",
    date: "27th January, 2023",
    heading: "Do Dogs Get Jealous?",
    full_content: [
      "Jealousy is a common emotion that we all feel at some time or another. These days, due to the advent of social media, unhealthy jealous feelings have become more common than before. In general, jealousy is defined as the feeling of wanting something that you don’t have while feeling resentful of someone who has what you desire. For years, there has been a debate whether dogs can feel such complex emotions. A lot of research has also been done on it, all over the world. We will discuss the research on the subject in detail further. Read on to find out if dogs really get jealous and if they do, how similar is it to human jealousy.",
      "Can Dogs Get Jealous?",
      "As we’ve established, dogs can get jealous. But, we need to understand their emotions via their body language. To understand jealousy in dogs, we must grasp the concept of it in humans as dogs behaviour and feelings are very similar to humans in this matter. Jealousy is a direct result of true, deep love. When you deeply love someone and that person gets close to somebody else, you start feeling jealousy towards the third person. This is exactly what happens in dogs as well. Let’s understand this in detail.",
      "Jealousy towards other dogs:",
      "You may have noticed some changes in body language when you play with another dog. They display the same expressions and behaviour when you come home after mingling with another dog. Depending on the dog, some may show this by being aggressive, others in a different way. To correct this behaviour, especially if they are getting aggressive, you can get them professionally trained or train them yourself by giving a treat or reward to them if their behaviour is proper.",
      "Jealousy towards puppies:",
      "This issue may occur if you already have a dog and get a new puppy as well. The older dog is used to getting full attention from you. The new puppy, when it gets love and attention from you, can trigger feelings of jealousy in the older dog. If you are planning to welcome another dog into your family, it is essential that you make the transition as smooth as possible for your pooch. To do that, make sure that the routine of the older dog is not disturbed. Also, don’t forget to give both of them equal attention.",
      "Jealousy towards people:",
      "The tendency to get jealous does not occur for dogs towards people that you meet occasionally. Most of the time, they won’t get jealous of people. But, if you get too close to someone, for example, a new boyfriend, fiance or spouse and in the process reduce the time spent with your dog, he may get jealous of that person. ",
      "Jealousy towards babies:",
      "Just like dogs can get jealous with a new puppy coming home and hogging your attention, they also have the same feelings, when you have a baby. Obviously, a parent’s attention is not all on the dog. This leaves them feeling bad. Don’t get us wrong, they definitely love your baby but they are also feeling left out. The best way to avoid this is to introduce the dog to the baby. Let them sniff the baby, get used to it. It is important to make sure that you do not leave the baby and the dog in a room unsupervised.",
      "Scientific Research on the topic:",
      "As per a study in Psychological Science, dogs do show signs of jealousy. To come to this conclusion, there have been so many experiments, some of which are described below.Researchers put 18 dogs in different rooms along with their pet parents. Also, a fake dog and an inanimate object were present in each room. The owners were first asked to play and love the fake dog. Then, they were asked to pat the inanimate object. In most of the rooms, the result was the same, the dogs got jealous and started pulling on their leash when the owner loved the fake dog. But they left the leash loose when the owner patted the non living object. This research proved what dog owners already suspected. According to the study, most dogs are jealous when they feel their owner is getting close to somebody else. But the research is still going on to understand if there are some breeds more susceptible to jealousy than others or not. ",
      "Dealing with jealousy in dogs:",
      "There are certain things that you can do to help your dog deal with this negative emotion",
      "Professional Training:",
      " A dog that is properly trained, can control their emotions just like we humans do to live in society. If your dog hasn’t had any obedience training, it is never too late and you can book the <a href='https://petmojo.care/dog-training' target='_blank' rel='noopener noreferrer'>training session</a> now as well, no matter the age of the dog. ",
      "The Right Balance of Attention and Ignoring:",
      "If you give your jealous fur baby extra attention, it may soon turn into a tantrum. You need to maintain a balance and understand when is the time to give them attention and when to leave them alone and let them deal with their emotions themselves. ",
      "Keep the object of Jealousy Close:",
      "Keeping the person/dog/puppy or baby your dog is jealous of close to you will help them adjust to the new member and soon enough they will be cordial together. P.S. Remember, there might be a few fights initially but if you handle them well, all will be okay soon enough.",
    ],
    id: "do-dogs-get-jealous-97",
    title: "Do Dogs Get Jealous? - Petmojo",
    description:
      "Discover the truth about whether dogs get jealous and learn how to recognize and understand this complex emotion in our beloved canine companions. Learn more in this comprehensive blog post on 'Do Dogs get Jealous?",
    serial_no: 5,
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1741geg.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-96.webp",
    views: "1287",
    date: "27th January, 2023",
    heading: "Travel Anxiety in Dogs",
    full_content: [
      "Just like humans, each dog has a different personality. Some dogs are more travel friendly, while others are scared of the unpredictability involving travelling. Whether it's a road trip or a flight, dogs can experience a range of emotions from nervousness to full-blown panic. In this blog post, we will discuss the causes of travel anxiety in dogs, the signs to look out for, and ways to alleviate and prevent it. If you have a dog that gets anxious about going in a car or any other vehicle, here is a comprehensive guide to help you understand and help your fur baby.",
      "Factors for Travel Anxiety in Dogs:",
      "To relieve the anxiety in dogs, we need to understand what is the cause of it. We need to understand what are the factors that affect the psyche of our fur babies. Anxiety due to travelling in dogs can be caused by a variety of factors, including lack of experience, past traumatic experiences, and separation anxiety.",
      "Lack of Experience:",
      "Dogs that have not been exposed to travelling frequently or at all may not be used to the sensation of moving or the unfamiliar sounds and smells associated with travelling.",
      "Past Traumatic Experiences:",
      "Dogs that have had negative experiences while travelling in the past, such as getting carsick or being left alone in an unfamiliar place, may develop a fear of travelling.",
      "For example, initially whenever my dog used to go in a car, we ended up taking him to the vet. This created a negative association in his mind with cars. So, we started taking him around to other places as well and now he is comfortable in a car. In fact, he has now travelled from Delhi to Mumbai by train as well.",
      "Separation Anxiety:",
      "Some dogs may experience separation anxiety when away from their owners, which can be exacerbated by the unfamiliar surroundings of travelling.",
      "Again, using the same example as above, instead of taking him somewhere and leaving him there, give him some positive experiences where you go along with them and come back together.",
      "Signs of Travel Anxiety in Dogs:",
      "Dogs are one of the most expressive animals that are there. If they are getting anxious while going somewhere, they will exhibit many signs that can be caught by you and you can help them deal with their negative feelings.",
      "Trembling or shaking:",
      "Shivering or trembling is another sure sign that they are uncomfortable.",
      "Excessive drooling:",
      "Dogs may drool more than normal when they are anxious. This can be a sign that they are feeling uneasy or stressed.",
      "Whining or barking:",
      "Dogs may whine or bark excessively when they are anxious. This can be a sign that they are trying to communicate their distress to their owners.",
      "Refusing to get into the car:",
      "Dogs may refuse to get into the car. This can be a sign that they are not comfortable with the mode of transportation.",
      "Loss of appetite:",
      "If, at any time, due to any reason, dogs lose their appetite, it conveys distress and you, as a pet parent, need to make sure that you take care of it.",
      "Preventing Travel Anxiety in Dogs@",
      "There are numerous things you can do to prevent travel anxiety in dogs, even though it may not be possible to completely eliminate travel anxiety in dogs. Here are several ways to prevent it for your dog.",
      "Start Slowly:-",
      "Whenever you are trying to help your dog do something against their will, you always need to follow the motto ‘gradual and slow steps’. If you do things step by step, your dog will be more comfortable to get acclimated with everything. Gradually exposing your dog to the sights, sounds, and sensations associated with travelling can help them become more comfortable and less anxious. Start by taking short car rides around the block and gradually increasing the distance and duration of the rides.",
      "Training:",
      "For dogs that are extremely anxious about things, you must get them trained. Professional training can help them deal with their issues much better than we can teach them. Training your dog to associate travelling with positive experiences can help reduce their anxiety. This can be done by providing them with treats and positive reinforcement during car rides and flights.",
      "Medication:",
      "If your dog has severe anxiety issues, it is best that you visit the vet and get him some medication. In some cases, medication may be necessary to alleviate severe travel anxiety. Consult with your veterinarian for guidance on the best medication for your dog.",
      "Comfort Items:",
      "Make sure to carry some things that your dog loves while travelling in a car or any vehicle they are afraid of. For example, you can carry their favourite toys or treats to keep them occupied during the commute.",
      "Keep Familiar Routine:",
      "Dogs prosper in a routine life. They love routine and get anxious when things don’t go as normal. To reduce the uneasiness, maintain their regular sleep, eating and walking schedule, no matter where you are. Also, don’t forget to spend some quality time with them.",
      "Final Thoughts:",
      "Travel anxiety in dogs can be a difficult issue to deal with, but with the right approach, it is possible to reduce and prevent it. Gradual exposure, training, medication, comfort items and keeping a familiar routine can all be effective in reducing your dog's travel anxiety. Consult with a veterinarian if you have any concerns about your dog's travel anxiety. With patience and understanding, you and your furry companion can enjoy travelling together.",
    ],
    id: "travel-anxiety-in-dogs-96",
    title: "Travel Anxiety in Dogs - Petmojo",
    description:
      "Learn how to ease your furry friend's travel anxiety. Our blog post 'Travel Anxiety for Dogs' offers tips and tricks to make your next trip with your dog stress-free.",
    serial_no: 2,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1741.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-95.webp",
    views: "1287",
    date: "26th January, 2023",
    heading: "Pet Insurance: Is it Worth the Cost?",
    full_content: [
      "As new pet parents, we are often unaware of the hidden costs of having a pet, like illness or injury. There are in fact so many excess expenditures that occur when we have a pet and take care of them properly. A lot of new pet parents are now opting the option of pet insurance to secure their finances with respect to their pets.",
      "Although pet insurance is a growing industry in India, a lot of pet parents are unable to decide if this investment is worth it. In this blog, we will discuss all about pet insurance and we'll explore all aspects of getting a pet insurance including .",
      "What is Pet Insurance?@",
      "Pet insurance is a type of insurance policy that covers the medical expenses of your pet in the event of an unexpected illness or injury. It is similar to human health insurance, in that it can help you to defray the cost of unexpected veterinary care.",
      "Pet insurance policies cover a variety of things like general consultations, nutritional counselling, medical and surgical interventions, emergency care, geriatric advice, and more. Not just that, it also includes losses from theft or injuries.",
      "Which animals can be covered:",
      "It is already quite prevalent to insure animals like cows, buffaloes, cattle, and even elephants in India. This has been a standard for a long time. But recently, with more and more households getting pets like dogs, cats, birds, the insurance companies have started providing insurance to a lot of these as well. Currently, animals like dogs and cats can be insured when it comes to domestic animals. Some companies may also insure exotic birds.",
      "Who provides Pet Insurance?",
      "There are a lot of companies that offer Pet insurance in India as of today. All the major insurance companies provide this service. The most reliable companies providing this service are New India Assurance, United India, Oriental, and Bajaj Allianz General Insurance.",
      "What Do Most Pet Insurance Cover?",
      "There are many different policies out there which determine the cover  of your insurance. Although, each policy has different specifics, most of them cover these,",
      "- Illness:",
      "Coverage for the treatment of illnesses such as cancer, diabetes, and heart disease.",
      "- Injury:",
      "Coverage for the treatment of injuries such as broken bones, cuts, and burns.",
      "- Diagnostic tests:",
      "Coverage for diagnostic tests such as bloodwork and x-rays.",
      "- Surgery:",
      "Coverage for surgery, including emergency surgery.",
      "- Prescription medications:",
      "Coverage for prescription medications, such as antibiotics and pain medications.",
      "- Alternative therapies:",
      "Coverage for alternative therapies such as acupuncture and physical therapy.",
      "- Vaccinations:",
      "While most companies do provide annual vaccinations, It depends on your policy and company.",
      "What is not included in the policies?",
      "There are some illnesses or problems that are not covered in the policies. These include,",
      "- Self opted surgeries:",
      "If you have opted for a surgery that is not due to any illness or injury as such,it will not be covered in the insurance.",
      "- Deformities:",
      "If the pet is undergoing surgery due to deformities or congenital defects, it will not be covered.",
      "- It makes it easier to maintain the regular routine of the dog ads dogs prosper in routine.",
      "- Pregnancy:",
      "Surgeries due to pregnancy are not covered.",
      "- <a href='https://petmojo.care/grooming' target='_blank' rel='noopener noreferrer'>Grooming</a> Procedures:",
      "Any cosmetic procedures are not included.",
      "- Dental Procedures:",
      "All dental procedures are excluded from insurance cover. That is why it is very important to care for your pet’s <a href='https://petmojo.care/grooming' target='_blank' rel='noopener noreferrer'>dental health.</a>",
      "Different kinds of Policies:",
      "There are different kinds of policies available that you can opt for,",
      "- Where you cover your pet for a certain amount.",
      "- When you cover your pet for only certain illnesses or injuries.",
      "- Accident-only insurance (covers sudden accidents)",
      "- Lifetime Insurance (for the entire lifespan of your pet).",
      "How much does it cost?@",
      "It is difficult to give an exact price for the insurance as it depends on a lot of factors like your pet’s age, size, location, general health, and the policy you are opting for.",
      "On an average, companies can cover up to Rs 10,000 of hospital costs and up to Rs 50,000 in case you’re opting for some surgery.",
      "It is important to note, it increases based on the factors mentioned above. Also note, pets above a certain age are not applicable for insurance. Although it varies, mostly, dogs and cats can be insured from 8 weeks to 8 years. Post that, it will be highly burdensome and difficult to insure your pet.",
      "Is Pet Insurance Worth the Cost?",
      "When it comes to whether or not pet insurance is worth the cost, it really depends on your individual circumstances. Here are a few things to consider when deciding if pet insurance is the right choice for your pet:-",
      "- The cost of veterinary care in your area:",
      "If veterinary care in your area is relatively inexpensive, pet insurance may not be necessary.",
      "- Your pet's breed and age:",
      "If your pet is prone to certain medical conditions, pet insurance can help you to defray the cost of unexpected veterinary care.",
      "- Your financial situation:",
      "If you're able to set aside money for unexpected veterinary expenses, pet insurance may not be necessary.",
      "- Your peace of mind:",
      "For many pet owners, the peace of mind that comes with knowing that their pet is covered in the event of an unexpected illness or injury is worth the cost of the policy.",
      "Conclusion:",
      "In conclusion, pet insurance can be a valuable investment for pet owners in India, helping to defray the cost of unexpected veterinary care and providing peace of mind. However, it's important to weigh the costs and benefits of pet insurance and to consider your individual circumstances before making a decision.",
    ],
    id: "pet-insurance-is-it-worth-the-cost-95",
    title: "Pet Insurance: Is it Worth the Cost? - Petmojo",
    description:
      "Is Pet Insurance worth it? There are a lot of different opinions on it out there. Here are all the details you need to make your decision. Read to find more.",
    serial_no: 2,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1738.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-94.webp",
    views: "1287",
    date: "25th January, 2023",
    heading: "A Guide to Dog Care for New Pet Parents",
    full_content: [
      "If you’re planning to get a pet or have recently got one, you must know, it is a full time responsibility. It is not something one must do just for fun as it requires a lot of commitment. It is almost like taking care of a <a href='https://petmojo.care/blog/5-reasons-why-millennials-prefer-owning-pets-instead-of-parenthood-51' target='_blank' rel='noopener noreferrer'>kid.</a>",
      "With all these responsibilities, it can get tough but thanks to our little furry friends, their love makes it all so easy and worth it. Getting unconditional love from someone is all that we need from our doggos.",
      "As pet parents, we are responsible for their food, their exercise and even excretion. To make your life a little easier, here is a comprehensive guide for you to make sure you do it right!",
      "Health and Wellness:@",
      "We know you may have a lot of questions. One of the major concerns we have while taking care of a new pet is about their health and what is the right way to take care of the little pooches. There is a one by one guide following to help you make the right decisions.",
      "Vet Visits:",
      "It is important to establish right at the beginning the right number of times to visit the vet for regular checkups. These would help identify any underlying issue in your dog from time to time.",
      "In dogs that are not old, make sure to take them for a full body checkup once a year. If your dog has some health issues, you may take him more frequently. For example, older dogs or those with pre-existing health conditions may need to see the vet more often.",
      "Vaccinations:",
      "Dogs require a number of vaccines on a regular basis. Your Vet will guide you through all the vaccinations and due dates for them. Here are some important vaccines you cannot miss at any cost!",
      "- Canine Parvovirus#",
      "- Canine Distemper#",
      "- Hepatitis#",
      "- Rabies#",
      "- Leptospirosis#",
      "These are the ones you cannot miss at all…Other than these there are a few more vaccines you must consider for the safety of your dog.",
      "- Bordetella#",
      "- Canine Influenza (dog flu)#",
      "- Lyme vaccine#",
      "Spaying and Neutering:",
      "Spaying and Neutering is something a lot of dog owners get done for their fur babies. But before deciding on it, make sure that you find out all the pros as well as the cons of it.",
      "Spaying is the surgical removal of a female dog's ovaries and uterus, while neutering is the surgical removal of a male dog's testicles. These procedures are typically performed by a veterinarian, under general anaesthesia.",
      "There are many benefits like avoiding unwanted pregnancies, reducing the risk of certain types of cancers, reducing behavioral issues and reducing the risk of some diseases like pyometra.",
      "However, there are some negative consequences of spaying and neutering. It includes some serious conditions like osteosarcoma, hip dysplasia, and an increased risk of certain hormone-responsive cancers.",
      "This is a huge decision that you make for your dog. Make sure to discuss everything with a vet before finalizing your course of action.",
      "Activity and Workout:",
      "Regular exercise is essential for all dogs, not just for their physical well being but also their mental fitness. Every dog needs to workout to stimulate their mind and bodies but the amount of exercise totally depends on the breed, size and age of the dog.",
      "Some dogs, like the <a href='https://petmojo.care/blog/labradors-the-ultimate-guide-to-dog-breeds-26' target='_blank' rel='noopener noreferrer'>Labrador</a> are more prone to obesity so they require ample amounts of exercise. Others like <a href='https://petmojo.care/blog/shih-tzu-the-ultimate-guide-to-dog-breed' target='_blank' rel='noopener noreferrer'>Shih Tzu</a>, require much less workout due to their size. They tire easily.",
      "We need to make sure that we make their walks as fun as possible for them. It is a great opportunity for them to socialize with other dogs as well as people. As we have established many times, dogs are social creatures. You can also include some running, hiking and playing sessions to make the exercises more interesting.",
      "Nutrition:",
      "Proper nutrition is extremely important for canines as is for everyone but we need to understand their nutritional needs are not the same as ours. The type of food you feed your dog, as well as the amount and frequency, can have a significant impact on their overall well-being. When choosing a food for your dog, it is important to look for high-quality ingredients, such as meat, vegetables, and whole grains. Avoid foods that are high in fillers, such as corn and wheat, and preservatives.",
      "Some Indian households are pure vegetarian and even if they get a dog, they think a veg diet will suffice, but In reality, dogs need some meat. So, if you are a vegetarian and have a dog, you have a few options. Don’t worry, you don’t have to buy eggs and chicken for your house. One of the best options you have is to buy <a href='https://petmojo.care/blog/guide-to-pet-foods' target='_blank' rel='noopener noreferrer'>dog food.</a>",
      "A lot of us start feeding their dogs what we eat. I have seen so many people feeding roti and rice to their dogs and always wondered if it is the right thing to give them. So, I did some research and learnt all about the right <a href='https://petmojo.care/blog/can-my-dog-eat-human-food' target='_blank' rel='noopener noreferrer'>food for your dog!</a>",
      "Living Environment:",
      "Having a pet means you need to create a safe place for them. To make sure your dog is safe and feels at home, you must have enough space for your pet. If you have a <a href='https://petmojo.care/blog/best-dog-breeds-for-a-small-apartment-25' target='_blank' rel='noopener noreferrer'>small apartment</a>, it is best to go for a smaller breed.",
      "When a dog comes to live with you and you are responsible for their well being, you must make sure that your apartment is <a href='https://petmojo.care/blog/puppy-proofing-your-home-47' target='_blank' rel='noopener noreferrer'>puppy proofed.</a> It is important that all harmful things are kept at places which are out of reach for them.",
      "Final Thoughts:",
      "Being a dog owner is a big responsibility, but it is also one of the most rewarding experiences. By following everything you’ve learnt about pet care here, you can ensure that your dog is happy, healthy, and well-behaved.",
      "Remember to provide your dog with proper nutrition, regular exercise, regular vet check-ups, and a safe and comfortable living environment. And always keep an open line of communication with your vet, they can provide valuable advice and guidance on all aspects of pet care.",
    ],
    id: "a-guide-to-dog-care-for-new-pet-parents-94",
    title: "A Guide to Dog Care for New Pet Parents - Petmojo",
    description:
      "A comprehensive guide to dog care. Becoming new pet parents is nerve wrecking and overwhelming. Here is a guide that will help you glide through this journey. Read to find more.",
    serial_no: 5,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1735.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-93.webp",
    views: "1287",
    date: "25th January, 2023",
    heading: "Do Dogs Truly Love Their Owners?",
    full_content: [
      "As pet parents, we all love our little fur buddies. I am a proud pet parent of a Japanese Spitz that is almost 5 years old now. The exciting part about having a dog is in the love we have for them. We care about their walks, their food, boredom, sleep and everything else all day long. In return, we believe that our dogs love us unconditionally.",
      "A lot of pet parents these days prefer having <a href='https://petmojo.care/blog/5-reasons-why-millennials-prefer-owning-pets-instead-of-parenthood-51' target='_blank' rel='noopener noreferrer'>dogs instead of kids.</a> The reason they give for it is that either they are too busy or they feel it is less of a responsibility which is more manageable for them. Another reason is they believe that the kind of love they receive from kids is very similar to what they get from dogs. But, is it really true? Do they actually adore us as much as we believe they do? Let’s find out!",
      "What is Love?",
      "Love is an extremely complex emotion that most of us struggle to understand for most part of our lives. Some people say, to truly understand the meaning of love, get a dog and it will be crystal clear. In general, it is a feeling of strong connection and attachment that we get from spending time with someone we are close to. It can also be defined as the feeling we get for someone who when leaves, we miss them.",
      "Dog-Human Connection:@",
      "We have discussed the special bond that is shared between dogs and humans in detail in <a href='https://petmojo.care/blog/dogs-and-people-exploring-the-human-dog-connection-86' target='_blank' rel='noopener noreferrer'>another blog post.</a> The connection that dogs and humans share is centuries old. Dogs have a special role in our lives. They can sense our fears, our troubles, when we are sad and even when we are not feeling well. It is a give and take relationship.",
      "Studies have shown that dogs release the hormone oxytocin, which is associated with feelings of love and bonding, when they interact with their owners. Oxytocin levels also increase in the owner's brain when they interact with their dogs, indicating a mutual feeling of bonding.",
      "Signs of Attachment:",
      "The feeling of attachment leads to fulfilment, companionship and love. Dogs, genetically, are the animals who are social. They always, even if not around humans, form packs to navigate through life. This trait makes them so friendly with their families as well. When we get a dog at home, they start considering their human family as their pack. Once that happens, they definitely start loving and protecting their family like they do for their packs.",
      "What does Science tell us?",
      "Scientists have been at work trying to find if the reactions we see from dogs are actually love. A team of scientists from the Emory University in Atlanta, Georgia investigated the emotional state of dogs. They used MRI imaging to scan their brain while exposing them to different smells which included smells from their neighbourhood, some dogs, some humans and their own pet parent. The results show that on smelling the odour of their owner, the dog brain’s reward center was activated which is called the caudate nucleus. The caudate nucleus contains many dopamine receptors. Even in the human brain, the caudate nucleus is activated when we have any positive experiences, especially in experiences of love!",
      "In many scientific experiments, to find what love is and how one’s brain works in love, similar experiments are done with humans but instead of the smell of the person they know or like, the photos or voices of the people they like are shown or the sound is played.",
      "Similar scientific research has been done in Budapest where they discovered the activity in the brain when the owners talk to the pets and when they hear someone or something they dislike. The experiment showed that the connection between human and dog is deep and they feel the connection as well as we do.",
      "Why are smells used to detect affection in dogs?",
      "Dog’s noses are extremely sensitive. In fact, it is their most sensitive organ. We have 5 sense organs but none of them compare to the sensitivity of dog’s smells. They decide their friends, family, enemies, and everything else based on their odours. That is the reason, scientists used their nose sensitivity to conduct their experiment to determine if their brains react differently to the ones they love!",
      "Results of the Experiments:",
      "Science has helped us navigate through the minds of our dogs. They have come to the conclusion that they definitely feel the love for their owners. The happy parts of their brain lights up when they smell or hear someone close to them. This is a scientific breakthrough in dog psychology.",
      "Signs your Dog Loves you:",
      "Here are some ways to tell that your dog truly cares for you and loves you as much as you love them:-",
      "1. When you <a href='https://petmojo.care/blog/4-signs-your-dog-misses-you' target='_blank' rel='noopener noreferrer'>return</a> from somewhere and your dog was left behind, the welcome that they give you is heartwarming. This is a sure sign of affection they have for you.",
      "2. Except for food, they love you the most. For all animals, food is of foremost importance. They have the thought process of survival of the fittest. That is in their genes. But dogs often prioritize you over everything else. For example, my dog follows me around even if I’m just going to another room and he is sleeping.",
      "3. They look for opportunities to sleep right next to you. If you have trained your dog well, they might not do this but most logs love to cuddle and sleep with their people.",
      "4. Your dog looks at you with loving eyes. They have expressive eyes and you can figure it out if their direct eye contact can be interpreted as an aggressive action or the way they are looking at you is with a loving gaze.",
      "Conclusion:",
      "In conclusion, the bond between a dog and its owner can be quite similar to the bond between two human beings, and dogs have been known to show signs of attachment, affection, and caring towards their owners. Factors such as breed, individual personality, and early experiences can all play a role in shaping a dog's behavior and level of attachment to their owner.",
    ],
    id: "do-dogs-truly-love-their-owners-93",
    title: "Do Dogs Truly Love Their Owners - Petmojo",
    description:
      "Discover the truth about the love between dogs and humans. Learn about the signs of true love in dogs, and scientific research on the topic. Learn more on Petmojo.",
    serial_no: 2,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1729.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-92.webp",
    views: "1287",
    date: "24th January, 2023",
    heading: "Pet Hotel Vs Pet Sitting",
    full_content: [
      "Having a pet means every vacation becomes slightly stressful due to the fact that we either have to leave a piece of our heart behind or take them along, which is not always an option, as a lot of destinations do not have pet friendly facilities. Pet Hotel and sitting are two options for pet parents in such cases. Both these options have their fair set of pros and cons to consider while booking a service.",
      "What is Pet Hotel?@",
      "This is a service provided by many pet care service providers in India. Some services like Petmojo, pickup your pet from your home, take them to a safe boarding center where the pet can stay and be taken care of. You need to inform the boarders of your return and they will drop your pet to your place. This is a very convenient process for pet owners.",
      "Some other companies that offer boarding have certain boarding centers spread across the city. The pet owners need to drop their pet before leaving for their vacation and pick them up as soon as they return. If you opt for such services it might become too hectic for you to take care of packing, travelling and dropping and picking your pet to the center.",
      "What is Pet Sitting?@",
      "This is when you call a professional sitter to stay at your house and take care of your dog while you are away for a vacation or any other trip. This is a service predominantly popular in other countries. In India, this concept is not yet popular due to safety reasons. We, as a country, do not trust strangers in our home ever, let alone with us not being there.",
      "This only works if you already know and trust the sitter. Also, it is a little difficult in India to do this as neighbours might object to a stranger lining up in the area.",
      "Benefits of Pet Hotel:",
      "There are numerous benefits of choosing boarding as the right option for you. Some of the advantages are mentioned further.",
      "- One of the important things to keep in mind while boarding your pet is it is a more safe and secure environment without the pet owners around. When the dog parent is not around, sometimes they may get anxious in their own house, but staying in a center would keep them calmer.",
      "- They offer structured and stimulating activities to keep your pet mentally and physically active. Boarding centers make sure that your dog gets enough exercises and gets to play games with other pets. Some centers also have swimming pools and dedicated play areas.",
      "- They have state of the art centers that are perfect for dogs.",
      "- It can be more cost-effective than sitting.",
      "- It allows for more socialization opportunities for your pet as there are other dogs around to play and sometimes fight with.",
      "- It provides a more controlled environment to keep your pet safe.",
      "- They have an on call professional vet to take care of any health issues that your dog might have or develop during the stay",
      "Benefits of Pet Sitting:",
      "There are benefits of pet sitting even though it is not prevalent in India yet. Some of them are written below:-",
      "- The best thing about it is it allows your pet to stay in the comfort of their own home.",
      "- Minimizes stress and anxiety for pets who do not do well in unfamiliar environments.",
      "- Provides one on one attention and companionship for your pet.",
      "- It makes it easier to maintain the regular routine of the dog ads dogs prosper in routine.",
      "Disadvantages of Pet Hotel:",
      "Reading the benefits of both the services, you may have kind of made up your mind. But, it is important that you know the drawbacks as well. Here are some unfavourable points about it.",
      "- Some pets may experience stress and anxiety in unfamiliar environments.",
      "- Limited access to familiar surroundings and personal belongings.",
      "- Some boarding facilities may not provide as much individual attention as pet sitting.",
      "- Some facilities may have additional fees for certain services such as grooming or medication administration.",
      "Disadvantages of Pet Sitting:",
      "Some of the drawbacks of hiring a pet sitter are written here:-",
      "- Can be more expensive than boarding, especially if multiple visits per day are required.",
      "- Can be difficult to find a reliable and trustworthy pet sitter.",
      "- Some pet sitters may not have the necessary experience or qualifications to handle certain medical conditions.",
      "- Your pet may not have the opportunity to socialize with other animals.",
      "Which is better for India?",
      "In India, as referred earlier, Pet sitting is not a favourable service. Pet Hotel is a great option for pet owners who want to ensure that their pet is well taken care of while they are away. Even with all the advantages, it will be a few years before pet sitting becomes a common practice here in our country. Till then, the two options we have are to either leave our dogs with a boarder or take them along for a vacation. There are some <a href='https://petmojo.care/blog/top-pet-friendly-vacation-destinations-in-india-2023-70' target='_blank' rel='noopener noreferrer'>destinations</a> where you can take your pet along with you.",
      "Conclusion:",
      "Both Pet Hotel and pet sitting have their own set of pros and cons. Pet Hotel provides a safe and secure environment for your pet, as well as structured and stimulating activities. Pet sitting allows your pet to stay in the comfort of their own home and provides one-on-one attention. It's important to weigh the pros and cons of each option and choose the best one for your pet and your specific needs. It's also important to choose a reputable and reliable service provider whether it's a Pet Hotel or pet sitting.",
    ],
    id: "pet-boarding-vs-pet-sitting-92",
    title: "Pet Hotel Vs Pet Sitting - Petmojo",
    description:
      "Pet Hotel VS Pet Sitting - There is often a debate going on about boarding vs sitting. Finding the most suitable option for your pet may be stressful. Read to find the best option.",
    serial_no: 2,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-drtgtg.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-all-about-service-dogs-in-india.webp",
    views: "1287",
    date: "23rd January, 2023",
    heading: "All About Service Dogs in India",
    full_content: [
      "This Republic Day, let’s learn a little about our 4 legged protectors. Police and Army recruits dogs that are well <a href='https://petmojo.care/dog-training' target='_blank' rel='noopener noreferrer'>trained.</a> These dogs are then taken through some rigorous training and exercise sessions. Dogs are chosen as the perfect police or army partner due to their enhanced sense of smell which helps them do general protection or help find drugs, bodies, etc.",
      "How many dog units Indian Army has?",
      "The Indian Army has 25 dog units and 2 half units. For understanding, a full unit consists of 24 dogs and a half unit has 12 dogs. So, in total, 600 dogs are recruited for full units and 24 for half units.",
      "Dog breeds preferred as police or army dogs:",
      "Some of the most preferred dog breeds by the Indian police force or Army are Belgian Malinois, German shepherd, Labrador, <a href='https://en.wikipedia.org/wiki/Rajapalayam_dog' target='_blank' rel='noopener noreferrer'>Rajapalayam</a>, Indian Pariah dog, etc. These are preferred due to their intelligence and high trainable quotient.",
      "Duties of police/army dogs:",
      "A police or army dog can have humongous responsibilities. Their duties can include guard duty, patrolling, sniffing explosives including Improvised Explosive Devices (IEDs), mine detection, sniffing contraband items including drugs, assaulting potential targets, avalanche debris detection as well as participating in search operations to locate hiding fugitives and terrorists.",
      "Where does the training happen?",
      "They go through thorough training. All dog training occurs at Remount and Veterinary Corps Centre and School located in Meerut. Each dog has a dog handler who takes care of them and guides them through the training.",
      "Do they retire?:",
      "As per the Indian Government, dogs stay in service for eight years after which they retire.",
    ],
    id: "all-about-service-dogs-in-india-91",
    title: "All About Service Dogs in India - Petmojo",
    description:
      "This Republic Day, let’s learn a little about our 4 legged protectors. Special service dogs work extra hard to keep us safe. Here is everything you need to know about them.",
    serial_no: 5,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1726.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1728.webp",
    views: "1287",
    date: "21st January, 2023",
    heading: "Dachshund: Ultimate Guide to Dog Breed",
    full_content: [
      "Dachshunds, also known as 'sausage dogs' or 'wiener dogs,' are a beloved and popular dog breed known for their small size and distinctive long, low body shape. Here is an ultimate guide to the Dachshund breed, including information on their characteristics, personality, training needs, health and wellness, energy level, and lifespan.",
      "Characteristics:",
      "Dachshunds are a small breed that typically weigh between 16-32 pounds and stand 5-7 inches tall at the shoulder. They have a long, low body shape and short legs, which gives them their distinctive appearance. They have a short, smooth coat that comes in a variety of colors, including black, brown, and tan. They also have long, droopy ears and a long tail.",
      "Personality:",
      "Dachshunds are known for their playful and affectionate personalities. They are loyal and loving dogs that enjoy spending time with their human family. They are also known for being independent and stubborn, which can make them a bit difficult to train. They can be quite vocal and are known for their distinctive 'wiener dog' bark.",
      "Training Needs:",
      "Dachshunds are intelligent dogs, but they can be stubborn and independent, which can make training a challenge. They respond best to positive reinforcement and consistent training methods. They need an experienced and patient owner who can be firm and consistent in their training.",
      "Socialization is also important, as Dachshunds can be reserved with strangers and other animals if not exposed to them early on.",
      "Health and Wellness:",
      "Dachshunds are prone to certain health issues, such as back problems and obesity. Regular vet check-ups, good nutrition, and regular exercise can help maintain their overall health and wellness. Due to their long and low body shape, it's important to avoid activities that may put stress on their spine, such as jumping and carrying things on their backs.",
      "Energy Level:",
      "Dachshunds have a moderate energy level, they enjoy short walks and playtime. They are not as active as other breeds and don't require a lot of exercise, making them suitable for apartment living.",
      "Life Span:",
      "Dachshunds have a relatively long lifespan, typically living between 12-15 years..",
    ],
    id: "dachshund-ultimate-guide-to-dog-breed-90",
    title: "Dachshund: Ultimate Guide to Dog Breed at Petmojo",
    description:
      "Guide To Pet Parenting - Discover the ultimate guide to Dachshunds and expert tips on pet parenting. Your go-to resource for pet care in Delhi and Mumbai, only on Petmojo Blog.",
    serial_no: 2,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1723.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1725.webp",
    views: "1287",
    date: "20th January, 2023",
    heading: "Famous Indian Dogs in Television and Movies",
    full_content: [
      "India has a long history of dogs appearing in television and movies, with many breeds and individual dogs becoming famous in their own right. Some of the most well-known Indian dogs in television and movies include the Vodafone dog, Tuffy from Hum Aapke Hain Kaun, Entertainment from the movie entertainment and Pluto from Dil Dhadakne Do.",
      "Pug, the Vodafone Dog:",
      "One of the most famous Indian dogs in television advertisements is the <a href='https://petmojo.care/blog/pug-the-ultimate-guide-to-dog-breeds-54' target='_blank' rel='noopener noreferrer'>Pug</a> named 'Cheeka' who was used in the Vodafone commercials. Vodafone dog was an instant hit with the audience and became an instant household name. The dog was used in the advertisement as a representation of a loyal and reliable companion and was used to represent the brand values of Vodafone. Their memorable tagline was ‘you and I, in this beautiful world.",
      "Tuffy, Hum Aapke Hain Kaun:",
      "Another famous dog is the Indian Spitz named 'Tuffy' who appeared in the Bollywood movie 'Hum Aapke Hain Koun'. Tuffy played a key role in the movie and was known for his comic timing, his scenes were widely appreciated by the audiences. The movie is considered a cult classic and Tuffy's role in the movie is still remembered by the audiences. In fact, Tuffy had a major role in the climax to solve all the problems of the protagonists.",
      "Junior, Entertainment:",
      "A <a href='https://petmojo.care/blog/golden-retriever-the-ultimate-guide-to-dog-breed-36' target='_blank' rel='noopener noreferrer'>Golden Retriever</a> named 'Entertainment' appeared in the Bollywood movie 'Entertainment'. The dog played the role of an adorable pet and was widely appreciated by the audiences. Even though the movie did not do well commercially, it was loved by dog lovers.",
      "Pluto, Dil Dhadakna Do:",
      "A Bullmastiff  named 'Pluto Mehra' appeared in the Bollywood movie 'Dil Dhadakne Do'. Pluto played the role of a loyal and loving companion. Pluto was the one narrating the whole movie. He was a pivotal part of the film and was loved by everyone. He made a good movie even better!",
    ],
    id: "famous-indian-dogs-on-television-and-movies-89",
    title: "Famous Indian Dogs on Television and Movies - Petmojo",
    description:
      "Some dogs have become iconic due to their legendary roles in movies or advertisements. Vodafone dog is one such dog. Read to find the others.",
    serial_no: 3,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1720.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1722.webp",
    views: "1287",
    date: "19th January, 2023",
    heading: "Identify the types of barking in Dogs",
    full_content: [
      "Barking, in general, is a very natural behaviour of dogs. It is their way to communicate as they can’t speak the same way we do. They use barking to express emotions, communicate, call out other dogs, try to get attention from the owner, or to be territorial. But sometimes, dogs start barking excessively and there can be quite a few reasons for it.",
      "Excitement:",
      "When dogs are excited, they tend to bark. You can identify this type of bark by body language. A wagging tail with head held high along with barking may indicate excitement type of barking. It can happen when you return home after a while, when you reach for the <a href='https://petmojo.care/blog/how-do-i-calm-my-dog-when-its-time-to-put-on-the-leash-46' target='_blank' rel='noopener noreferrer'>leash</a> to take them for a walk. When excited, they may bark, howl, wag their tails and spin in circles.",
      "Boredom:",
      "Sometimes dogs bark just because they are bored. Boredom means they need some attention from their owner. They need some physical stimulation as well as mental stimulation to avoid boredom. Make sure that you give them enough <a href='https://petmojo.care/dog-running' target='_blank' rel='noopener noreferrer'>exercise</a> opportunities and some puzzle toys.",
      "Attention Seeking:",
      "When a dog wants your attention, they may bark at you. To analyse the bark, if the bark is a long string of single barks with pauses, it means they crave some attention. To remedy this, take them out for a walk or spend some time with them.",
      "Fear or Anxiety:",
      "Being defensive or fearful also leads to a barking dog. For anxious dogs, their body language will usually include a tail between the legs, hackles raised, and low head posture. Their tense body is an indicator of the fear they are feeling.",
      "Pain:",
      "If your dog is in pain, they may try to tell you that by barking. This type of barking is likely to be high pitched and trails off as the bark goes on. If you hear this type of bark, you may take the dog to the vet to get him checked.",
    ],
    id: "identify-the-types-of-barking-in-dogs-88",
    title: "Identify the types of barking in Dogs - Petmojo",
    description:
      "Barking is the way dogs communicate but sometimes they start barking excessively. As pet parents, it is important to identify the types of barking. Read to find more.",
    serial_no: 5,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1717.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1719.webp",
    views: "1287",
    date: "18th January, 2023",
    heading: "Importance of Regular Pet Check-Ups",
    full_content: [
      "Regular pet checkups are an essential part of maintaining your pet's health and well-being. These checkups are not only important for detecting and preventing potential health issues, but they also provide an opportunity for you to ask questions and get advice from your veterinarian. Here are some of the reasons why regular vet checkups are so important:-",

      "Early Detection of Serious Conditions:",
      "Regular checkups allow your veterinarian to detect potential health problems early on, when they are most treatable. During a checkup, your veterinarian will examine your pet, take their vital signs and check for any signs of illness or injury. This can include checking their ears, eyes, teeth, and skin, as well as listening to their heart and lungs.",
      "Preventive Care:",
      "Prevention is always better than a cure. During a checkup, your veterinarian will provide preventive care services, such as vaccinations, parasite control, and dental cleanings. These services help to protect your pet from serious health issues and keep them in the best possible condition.",
      "Behavioral Consultations:",
      "Regular checkups provide an opportunity for you to discuss any behavior issues you may be experiencing with your pet. Your veterinarian can help you identify the cause of the problem and provide advice and guidance on how to address it.",
      "Nutrition and Exercise:",
      "It is a good idea to ask for advice on nutrition and exercise for your pet. They can recommend the best diet for your pet based on their age, breed, and activity level, as well as provide guidance on how much exercise they need.",
      "Peace of Mind:",
      "Regular checkups give you peace of mind knowing that your pet is healthy and well taken care of. You can rest easy knowing that any potential health issues are being addressed early on, and that your pet is receiving the best possible care.",
    ],
    id: "importance-of-regular-pet-check-ups-87",
    title: "Importance of Regular Pet Check-Ups - Petmojo",
    description:
      "Regular checkups are a great way to make sure your little pooch is healthy and hearty. Read to find the various benefits of regular pet check ups.",
    serial_no: 2,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1714.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-74-desktop.png",
    views: "1287",
    date: "17th January, 2023",
    heading: "Dogs and People: Exploring the Human-Dog Connection",
    full_content: [
      "The bond between dogs and humans is one that has existed for thousands of years. Today, dogs are considered to be a part of the family by many people, especially young couples, some of whom are opting to be pet parents rather than parents. Dogs play an important role in our lives. The connection between dogs and humans is a complex and fascinating one that has been the subject of much research and study.",

      "Emotional Bond:",
      "One of the key factors in the human-dog connection is the emotional bond between dogs and their human caregivers. Research has shown that dogs are capable of understanding and responding to human emotions, and they are able to form deep and lasting emotional bonds with their human family. This emotional connection is thought to be due to the release of the hormone oxytocin, which is associated with feelings of love and bonding, in both dogs and humans.",
      "Role of Dogs in Our Lives:",
      "Another important aspect of the human-dog connection is the role that dogs play in our lives. They provide companionship, support, and protection for their human family. They can also provide a sense of purpose and meaning in our lives, which can be especially important for people who may be experiencing loneliness or isolation.",
      "Physical Health Benefits:",
      "The human-dog connection also has physical health benefits. Studies have found that people who have dogs tend to have lower blood pressure, heart rate and less stress than those without dogs. In addition, having a dog can help to increase physical activity and therefore improve overall health.",
      "Overall, the human-dog connection is a complex and fascinating one that is still not fully understood. However, what is clear is that dogs have a unique ability to understand and respond to human emotions and they play an important role in our lives.",
      "In summary, the human-dog connection is a complex bond that has existed for thousands of years. It is driven by emotional bond, the role that dogs play in our lives, and physical health benefits. This bond is unique and it's still not fully understood, but it's clear that dogs have a special ability to understand and respond to human emotions and they have a positive impact on our physical and emotional well-being.",
    ],
    id: "dogs-and-people-exploring-the-human-dog-connection-86",
    title: "Dogs and People: Exploring the Human-Dog Connection - Petmojo",
    description:
      "Discover the complex and fascinating bond between dogs and humans. Learn about the emotional connection between dogs and their human caregivers. Read to find more.",
    serial_no: 3,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1711.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1713.webp",
    views: "1287",
    date: "16th January, 2023",
    heading: "Tips to Live with an Older Dog",
    full_content: [
      "Older Dogs have a special quality about them. They seem more royal and calm once they age. When we pet a dog, we must realize that they will age much faster than we do. Once they are old, they need extra care and support from their owner. Here are some great tips for you if you have a senior dog.",

      "Visit the Vet regularly:",
      "Once your dog reaches their senior days, it is important to increase the frequency of regular Vet visits. Even if you feel they are healthy, make sure to see a vet and get regular checkups done twice in a year.",
      "Provide nutritional food:",
      "Older the age, more nutrition dense food is appropriate for your fur buddy. We need to make sure that they get all the necessary vitamins and minerals for their body to function properly.",
      "Reduce High Intensity Exercise:",
      "Your fur baby does not have the same energy that they used to have when they were younger. Make sure that you are considering their age when planning workout sessions.",
      "Be Patient:",
      "With age, your dog may become cranky, some might become dependent on some things. This is not an easy age for them so it is pertinent that you be extra patient and calm while handling them.",
      "Spend Quality Time together:",
      "Dogs do not have a very long life and it is of foremost importance that you spend as much quality time with them as possible. They are the most loyal and loving family members and deserve all your attention.",
    ],
    id: "tips-to-live-with-an-older-dog-85",
    title: "Tips to Live with an Older Dog - Petmojo",
    description:
      "Do you own a senior dog? It can be a daunting task to take care of an older dog. We have some tips to help you live with an older dog. Read to find more.",
    serial_no: 5,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1705.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1707.webp",
    views: "1287",
    date: "14th January, 2023",
    heading: "Siberian Husky: Ultimate guide to Dog Breeds",
    full_content: [
      "Huskies are generally scary looking with their ancestors from the wolf family, their eyes are grey in colour and make them look petrifying. Even with their spine chilling stares, they are one of the most friendly and loving dog breeds. They are known for their loyalty and mischievous nature. Here is a complete guide to their breed for someone willing to bring them home.",

      "Characteristics:",
      "With their silky fur in combination of colours grey, brown and white, they are one of the most dignified dog breeds. They need to burn high energy everyday which may include a long walk, some running, and playing games. They also love hiking due to their love for the outdoors. Huskies, in earlier times, used to carry weights for people over long distances. You can still use them to carry some weight for some time.",
      "Personality:",
      "Huskies love to socialize with other dogs of all breeds and also humans. This trait is passed on to them by their ancestors who loved being part of a pack. These mischievous and playful dogs are very intelligent and have great problem solving abilities. They can be great as a fun dog for your family, but don’t expect them to be a guard dog.",
      "Health & Wellness:",
      "The fur they have is beautiful and mostly do not require cleaning. It is important that you brush their fur often but they require less bath than most other dog breeds. They are prone to excessive shedding especially during monsoon season. Generally healthy, you need to take care of their nail grooming to avoid any foot problems.",
      "Training Needs:",
      "Due to their large and muscular body, and extremely playful nature, you may need to provide them with obedience training when they are a little puppy. They love to mingle with people and dogs so it is essential that you take them out to places where they have a lot of opportunity for socialization.",
      "Energy Level:",
      "Huskies love to play and run but due to their size and expressions, they are often feared by people. To make sure that this doesn’t create an issue, always walk/run them while on a leash. But make sure to provide them some time off leash in your personal garden or backyard.",
      "Life Span:",
      "Their life span ranges from 10 to 14 years.",
    ],
    id: "siberian-husky-ultimate-guide-to-dog-breeds-84",
    title: "Siberian Husky: Ultimate guide to Dog Breeds - Petmojo",
    description:
      "The Ultimate Guide to Siberian Husky Breed. Everything you need to know before you decide to pet them. Read to learn more.",
    serial_no: 2,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1692.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1694.webp",
    views: "1287",
    date: "13th January, 2023",
    heading: "What do dogs think when left alone at home",
    full_content: [
      "Dogs are so attached to their humans that it is unbelievable. They are emotionally involved with their humans and cannot imagine a life without their humans. Dogs are highly social animals and they thrive on companionship and interaction with their human family. When left alone at home, dogs can experience a range of emotions, from boredom to anxiety and fear.",

      "Without the stimulation and interaction provided by their human family, dogs may become restless and engage in destructive behaviors such as <a href='https://petmojo.care/blog/4-benefits-of-chew-toys-for-dogs' target='_blank' rel='noopener noreferrer'>chewing</a> on furniture or digging up the yard. This is often a sign that the dog is not getting enough exercise or mental stimulation while they are alone.",
      "Anxiety and fear are also common emotions experienced by dogs when left alone. Separation anxiety is a real condition that affects many dogs, and it can manifest in a number of ways. Some dogs may become destructive or vocal when left alone, while others may become withdrawn and lethargic. Dogs with <a href='https://petmojo.care/blog/pet-separation-anxiety' target='_blank' rel='noopener noreferrer'>separation anxiety</a> may also exhibit signs of distress such as panting, whining, or pacing. This kind of behavior is a sign that the dog is feeling anxious and stressed and needs help to overcome this.",

      "To help prevent boredom and anxiety, it is important to provide your dog with plenty of <a href='https://petmojo.care/dog-running'  target='_blank' rel='noopener noreferrer'>exercise</a> and mental stimulation before leaving them alone. This can include taking them for a walk, playing with them in the backyard, or providing them with interactive toys. It's also important to establish a consistent routine when leaving and returning home, so that your dog can anticipate when you will be back.",

      "In addition, crate training can be helpful for some dogs as it can provide a safe and secure space for them to relax while they are alone. It's important to make sure the crate is comfortable and that the dog has access to food, water, and a comfortable place to sleep.",
      "If your dog is experiencing separation anxiety, you may need to consult with a <a href='https://petmojo.care/dog-training'  target='_blank' rel='noopener noreferrer'>professional dog trainer</a> or behaviorist for help. These experts can provide you with strategies and techniques to help your dog overcome their anxiety and feel more comfortable when left alone.",
      "In summary, dogs can experience a range of emotions when left alone, from boredom to anxiety and fear. Providing plenty of exercise and mental stimulation, a consistent routine and crate training can help prevent these issues. If these methods do not work, seeking professional help is recommended.",
    ],
    id: "what-do-dogs-think-when-left-alone-at-home-83",
    title: "What do dogs think when left alone at home - Petmojo",
    description:
      "Dogs are overly attached to their humans and separation from them can make them anxious. Read to find what goes on in their mind.",
    serial_no: 3,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1708.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-weekly-training-schedule-for-puppies.webp",
    views: "1287",
    date: "12th January, 2023",
    heading: "Weekly Training Schedule for Puppies Part 2",
    full_content: [
      "In this blog post, we are continuing with the Weekly Training Schedule for Puppies. It is important to have a dedicated schedule for puppy training because they love a life with routine. It helps to speed up the process of training and have a confident puppy.",

      "Week 3:",
      "• Basic Socialization:",
      "3rd week of training can be used to take your dog to the pet store, play dress-up with them. Let them discover new scents and new surfaces during this week. Also, prepare a puppy socialization checklist that can be used in the weeks ahead.",
      "• Life Skills:",
      "Check if the puppy remembers everything he has been taught till week 2. Teach them to greet, play tug, alone training, crate training, and to sit at different locations.",
      "• Building the Bond:",
      "Start setting up the harness and keep brushing their hair for now. Also start <a href='https://petmojo.care/blog/how-to-brush-your-dog%E2%80%99s-teeth-60' target='_blank' rel='noopener noreferrer'>brushing their teeth</a> gently in week 3.",
      "Week 4:",
      "• Basic Socialization:",
      "This is the ideal week to start professional training for little pups. Take your puppy out to a bigger environment and do a meet and greet with a little more people.",
      "• Life Skills:",
      "Continue the alone training, crate training and toilet training. Also, start introducing lead walking, recall games, push command, drop command, etc.",
      "• Building the Bond:",
      " By this time, you will also learn to handle the puppy better. This is the time to learn more about the individual personality of your pup.",
      "Week 5:",
      "• Basic Socialization:",
      "In week 5 of training, you can introduce your dog to children. Also, pick your puppy socialization checklist and start with these one by one.",
      "• Life Skills:",
      "Continue the alone training, crate training and toilet training. Introduce retrieve games, settle on a mat in different locations, lead walking and push/drop/stick on known exercises.",
      "• Building the bond:",
      "Introduce them to nail clipping by doing pretend nail clipping in the beginning. When they are comfortable enough, do the actual nail clipping.",
    ],
    id: "weekly-training-schedule-for-puppies-part-2-82",
    title: "Weekly Training Schedule for Puppies Part 2 - Petmojo",
    description:
      "Training a puppy can be scary at times. There is too much to do in very little time. We have prepared a week by week schedule so that it is easier for you.",
    serial_no: 5,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1689.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1691.webp",
    views: "1287",
    date: "11th January, 2023",
    heading: "5 Most Loyal Dog Breeds That Will Always Be By Your Side",
    full_content: [
      "Dogs are man's best friend and the most loyal animals in the world. But, some breeds are more loyal than others. Some breeds are very protective and possessive of their human family while others are slightly indifferent. Here, we have listed the top 5 most loyal dog breeds that will always be by your side, no matter what.",

      "Labrador:",
      "<a href='https://petmojo.care/blog/labradors-the-ultimate-guide-to-dog-breeds-26' target='_blank' rel='noopener noreferrer'>Labradors</a> are a great choice for families especially ones with young kids as they are one of the most lovable and loyal dog breeds that there are. They are even better when <a href='https://petmojo.care/dog-training' target='_blank' rel='noopener noreferrer'>trained</a> well but even naturally, they are always there to support you.",
      "German Shepherd:",
      "They are one of the most dedicated and committed dogs. Their intelligence is the reason they are often used as service dogs. They have special physical abilities and a loyal nature. <a href='https://petmojo.care/blog/golden-retriever-the-ultimate-guide-to-dog-breed-36' target='_blank' rel='noopener noreferrer'>German Shepherds</a> are great for families too. Their loyalty lies with humans over other dogs.",
      "Rottweiler:",
      "These robust and muscular breed dogs may look aggressive but are not. They are quite opposite actually. They are as hard on the outside as they are soft on the inside. Rottweilers love their families deeply and truly. They are one of the most loyal ones out there.",
      "Golden Retriever:",
      "<a href='https://petmojo.care/blog/golden-retriever-the-ultimate-guide-to-dog-breed-36' target='_blank' rel='noopener noreferrer'>Golden Retriever</a> are at the top of our list as the most loyal dog breed. They are known for their outgoing and loving nature. They love their families incredibly. They love children and being friendly, cuddly, playful and dependable, they are great companions for children.",
      "Pug:",
      "<a href='https://petmojo.care/blog/pug-the-ultimate-guide-to-dog-breeds-54' target='_blank' rel='noopener noreferrer'>Pugs</a> are tiny, lovable and fantastic family dogs. They are happy and playful and not just that, they are so loyal to their family that they can do anything to protect them. They don’t care about anything in the world other than their families and especially their person.",
    ],
    id: "5-most-loyal-dog-breeds-that-will-always-be-by-your-side-81",
    title: "5 Most Loyal Dog Breeds That Will Always Be By Your Side - Petmojo",
    description:
      "Dogs are man’s best friend and the most loyal beings there are. Some breeds are more loyal than others. Read to find the 5 most loyal dog breeds.",
    serial_no: 2,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1686.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1688.webp",
    views: "1287",
    date: "10th January, 2023",
    heading: "Benefits of Board-and-Train Dog Training",
    full_content: [
      "As discussed in a previous blog post, board-and-train dog training programmes are now becoming popular amongst pet owners. Board-and-train dog training is a type of professional dog training in which the trainer takes in the dog for a set period of time. Here are some benefits of board-and-train dog training:-",

      "Consistency:",
      "One of the main benefits of board-and-train dog training is the consistency of the training. With a professional trainer working with the dog multiple times a day, the dog can receive a consistent and intensive training experience that may be difficult to achieve at home.",
      "Convenience:",
      "Board-and-train dog training is great when you have to go for a vacation or to your hometown. You can leave your dog with us and we’ll train them while taking care of them while you are away.",
      "Structured Environment:",
      "A professional training facility can provide a structured and controlled environment for training, which can be beneficial for dogs who may be easily distracted or who may benefit from a more structured routine.",
      "Behavioural Issues:",
      "For dogs with behavioural issues, such as aggression or separation anxiety, board-and-train dog training can be an effective way to address and improve these behaviours. With intensive training and a structured environment, dogs can learn new behaviours and skills that can help them cope with their issues.",
    ],
    id: "benefits-of-board-and-train-dog-training-80",
    title: "Benefits of Board-and-Train Dog Training - Petmojo",
    description:
      "Board-and-Train Dog Training programmes can be beneficial for your dog due to various reasons. Read to learn more about it.",
    serial_no: 3,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1685.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1685.webp",
    views: "1287",
    date: "9th January, 2023",
    heading: "Weekly Training Schedule for Puppies Part 1",
    full_content: [
      "Just like all other animals, puppies naturally learn their way in the world via their mommies. But, when we bring a pup home, they are separated from their mothers and have no way to learn unless we train them according to how we need them to behave all their life.",
      "Don’t get overwhelmed, and in case you find it all too much, you can book a <a href='https://petmojo.care/dog-training' target='_blank' rel='noopener noreferrer'>professional training service for puppy training</a> anytime. To make your life a little easier, we have prepared a weekly training schedule that you can use as a guide to train them.",

      "Week 1:",
      "The first week is crucial. You need to acclimatise the puppy to training schedules. Week 1 training must include the following:-",
      "• Basic Socialization:",
      "This would include letting them explore the house, items of different textures and backyard. Also, visit the vet in the first week.",
      "• Life Skills:",
      "Teach them their name in the first week. Get them familiar with the command ‘Sit’. It is also good to start getting them used to their crate or bed. Basics of toilet training need to start as soon as possible.",
      "• Building the Bond:",
      "To build the bond with the little pup, start brushing their fur and touching them gradually. Don’t get too touchy feely too soon. Let them adapt.",
      "Week 2:",
      "During the second week, one must increase the activities by a pinch and gradually make them stick to the things taught.",

      "• Basic Socialization:",
      "Make them familiar with new sounds like pressure cooker or vacuum cleaner. Sit in the balcony with them to let them observe the world outside. Introduce them to a couple of new people to help them understand the world more than you. This is also a great time to take them out for a drive.",

      "• Life Skills:",
      "Leave them alone in a room for a few minutes and gradually increase the time. Continue name calling, potty training and bed/crate training. You can now start playing ball and fetch with them.",
      "• Building the Bond:",
      "Now, you can start getting a bit closer to them. You can touch their paws, ears. You can groom them yourself or call a professional. Put a collar on them to let them get used to the extra weight.",
      "We will continue with the weekly training schedule for puppies which will be shared soon.",
    ],
    id: "weekly-training-schedule-for-puppies-part-1-79",
    title: "Weekly Training Schedule for Puppies Part 1 - Petmojo",
    description:
      "Training a puppy can be scary at times. There is too much to do in very little time. We have prepared a week by week schedule so that it is easier for you.",
    serial_no: 5,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1677vgu.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1682.webp",
    views: "1287",
    date: "7th January, 2023",
    heading: "Rottweiler: Ultimate guide to Dog Breeds",
    full_content: [
      "Rottweilers are those large dogs which can scare anybody out of their wits but at the same time they can be the best protectors and silly dogs for your family. These dependable and intelligent dog breeds are one of the oldest pet dogs ever, dating back to the Roman empire. Here is everything you need to know about these majestic breeds if you are planning to get them into your life.",

      "Characteristics:",
      "They are large in size. Their coat is <a href='https://petmojo.care/blog/4-black-dog-breeds-in-india-you'll-fall-in-love-with-57' target='_blank' rel='noopener noreferrer'>shiny black</a> in colour with spots of brown. They have short hairs on their coats. They are not the fastest dogs but they sure are strong due to their big bones and muscular structure.",
      "Personality:",
      "Rottweilers are people loving and possessive for their family. They are intelligent and obedient even when they are pups. Rottweilers may seem stubborn to some, but they just prefer to maintain a routine. Rottweilers can make great family pets, but they can also be reserved or aloof with strangers.",
      "Health & Wellness:",
      "If you have a Rottweiler, make sure to brush their coat and give them a bath weekly. Even though they are generally healthy, they may be prone to certain diseases like hip dysplasia and cancer. You must get their hip and elbow evaluations regularly. They need healthy food and <a href='https://petmojo.care/dog-running' target='_blank' rel='noopener noreferrer'>plenty of exercise</a> to maintain their health.",
      "Training Needs:",
      "As mentioned before, Rottweilers are intelligent but also stubborn. That is why, it is recommended that they are given <a href='https://petmojo.care/dog-training' target='_blank' rel='noopener noreferrer'>professional training</a> early in their life. While training them, consistency is key. You need to train them consistently till they are completely trained.",
      "Energy Level:",
      "With their size and physique, Rottweilers require an ample amount of exercise and walks. Apart from their regular walks, they are mostly calm at home so they won’t be a bother for you.",
      "Life Span:",
      "Rottweilers have a lifespan of 8-10 years on average.",
    ],
    id: "rottweiler-ultimate-guide-to-dog-breeds-78",
    title: "Rottweiler: Ultimate guide to Dog Breeds - Petmojo",
    description:
      "The Ultimate Guide to Rottweiler Breed. Everything you need to know before you decide to pet them. Read to learn more.",
    serial_no: 2,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1677.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1679.webp",
    views: "1287",
    date: "6th January, 2023",
    heading: "Top 5 Qualities of a Good Dog Trainer",
    full_content: [
      "When choosing a dog trainer for our pooches, it is essential to do an ample amount of research. But often we are not aware of what we should look for. A dog trainer requires a specific set of skills so that they can train the dog with love. Here are top 5 attributes that a good dog trainer must possess.",

      "Patience:",
      "One of the most important qualities a dog trainer must possess is patience. Training a dog is a repetitive process and it can take time for a dog to learn new behaviours. A good trainer needs to have patience to work with the dog and reinforce the training until the behaviour is learned.",
      "Empathy:",
      "Empathy is another preferred trait of a dog trainer as an empathetic person will be way more gentle with the dog. It will help the trainer understand the dog and in the long run, the dog will be trained better.",
      "Creativity:",
      "A creative approach to training can go a long way. A good trainer understands that every dog is different and has to be handled in a different way. There is no one formula that applies to dogs of all sizes and breeds. Creativity comes in handy for a dog trainer.",
      "Confidence:",
      "A good dog trainer should have confidence in their abilities and in their methods. This can help the trainer effectively communicate with the dog and can also help the trainer stay calm and focused during training sessions.",
      "Friendly:",
      "Dog trainers should not be intimidating, they have to be friendly and approachable for both, the dog as well as the pet owner. It helps the trainer to develop a good relationship with the dog and also helps the owner understand a better way to handle their dog.",
      "By choosing a trainer who is patient, empathetic, creative, confident, and friendly, you can ensure that you and your furry friend have a positive and successful training experience.",
    ],
    id: "top-5-qualities-of-a-good-dog-trainer-77",
    title: "Top 5 Qualities of a Good Dog Trainer - Petmojo",
    description:
      "Choosing a dog trainer is a crucial process. Here are the top 5 qualities to look for in a dog trainer. Read to find more.",
    serial_no: 3,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1674ygu.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1676.webp",
    views: "1287",
    date: "5th January, 2023",
    heading: "5 Most Common Winter Illnesses in Dogs",
    full_content: [
      "When the temperatures dip and we find it difficult to survive, imagine how harsh they are for our little fur buddies. A lot of people believe that their fur protects them from the cold but the question is, is it enough to protect them completely? No, they need extra care in this season as they are susceptible to a lot of illnesses due to cold. Here is a list of common winter illnesses in dogs.",

      "Hypothermia:",
      "Hypothermia is known as the temperature regulatory issue that occurs when the body temperature falls dangerously low. Hypothermia also occurs in humans in extreme weather. The symptoms are usually shivering, turning blue, weakness, tiredness. Make sure to not expose your 4 legged friend to extremely low temperatures and if you have to go somewhere, cover them in sweaters or blankets.",

      "Frost Bite:",
      "Frostbite is basically an after effect of hypothermia. When the body is exposed to low temperatures, ice crystals are formed in the tissues. This disrupts the blood circulation and creates blueness on the skin. Lethargy, shivering, unconsciousness, and drowsiness are some of the symptoms of frostbite.",
      "Cold:",
      "Our dogs are similar to us in a lot of ways. They can even get cold just like us. The cold they suffer from is also similar to ours. Runny nose, cough, watery eyes, and shivering are all symptoms they face when they have a cold.",
      "Flu:",
      "During the winter season, dogs can be susceptible to flu, fever, runny nose and a few other symptoms. In case your dog is showing these symptoms, do not delay going to the vet. It is absolutely essential that they get proper care.",
      "Arthritis:",
      "Arthritis is common in dogs, especially in old age. A dog that has arthritis often suffers more during the winter season. The joints start swelling and there is an immense amount of pain in their legs. It may also be a result of dog being overweight. Hence, it is very important to take them for <a href='https://petmojo.care/dog-running' target='_blank' rel='noopener noreferrer'>regular exercise</a>.",
      "Try to <a href='https://petmojo.care/blog/dog-care-in-winters-44' target='_blank' rel='noopener noreferrer'>care for your dog in this extremely cold weather</a>. In case, your dog is showing any of the symptoms as above, take them to the vet immediately.",
    ],
    id: "5-most-common-winter-illnesses-in-dogs-76",
    title: "5 Most Common Winter Illnesses in Dogs - Petmojo",
    description:
      "The most common ailments that affect pets during the winter season include frostbite, hypothermia, flu, arthritis, etc. Learn about the symptoms and more.",
    serial_no: 5,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1671.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1673.webp",
    views: "1287",
    date: "4th January, 2023",
    heading: "What is Board-and-Train Dog Training?",
    full_content: [
      "Training a dog, especially a dog who has aggression issues or anxiety issues, is a time consuming process. As a pet parent, if you are short on time, it may not be feasible that a trainer visits your place to train your dog. In such cases, board-and-train programmes come in handy. Another way to use this service is if you are going on a vacation and are looking for a place where your dog can stay, but also learn a few tricks in the meantime.",

      "What it entails?:",
      "Board-and-Train programmes differ by different companies. There are different approaches and specific techniques that can be adopted based on the trainer and dog.",

      "In the board-and-train program, the dog has to stay at the facility to be trained completely. At Petmojo, we believe that the best training can be done at your home, as the dog is most comfortable there.",

      "But, we have a great programme where you board your dog with us while you are away on vacation and train them in the meantime. It is a great way to utilize the time you are away from them.",
      "Overall, board-and-train dog training can be a useful tool for teaching new behaviors and skills to dogs, but it is important to carefully research different trainers and approaches to find the one that is right for you and your furry friend.",
    ],
    id: "what-is-board-and-train-dog-training-75",
    title: "What is Board-and-Train Dog Training? - Petmojo",
    description:
      "When we leave our dogs to board with a facility, we are always sceptical and worried. There are some board-and-train programmes that may be more suited for your dog.",
    serial_no: 2,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1665hbu.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1670.webp",
    views: "1287",
    date: "3rd January, 2023",
    heading: "Does your Dog require Obedience Training?",
    full_content: [
      "Some animals cannot be trained easily but dogs are trainable and it is highly recommended to train dogs at least some basic commands and to be obedient to their owner. Every dog can benefit from some training but some dogs especially require obedience training. We have listed 5 behaviours which show your dog is in desperate need of obedience training.",

      "Excessive Barking:",
      "If your dog is barking a lot, a lot more than normal, it may be a sign that they may need obedience training. <a href='https://petmojo.care/blog/excessive-barking-in-dogs-43' target='_blank' rel='noopener noreferrer'>Too much barking</a> can be annoying for you and your neighbours, and it can also be a sign that your dog is not properly trained. Obedience training can help teach your dog to bark only when necessary and to quiet down when commanded.",

      "Growls and Snarls:",
      "Growling and snarling are normal behaviours for dogs, but if your dog is growling or snarling excessively or inappropriately, it could be a sign of aggression or anxiety. Obedience training can help teach your dog appropriate behaviour and help prevent aggressive behaviours.",

      "Destructive Habits:",
      "If your dog is engaging in destructive behaviours, such as <a href='https://petmojo.care/blog/4-benefits-of-chew-toys-for-dogs' target='_blank' rel='noopener noreferrer'>chewing</a> on furniture or digging holes in the yard, it could be a sign of boredom or pent-up energy. Obedience training can help your dog learn appropriate outlets for their energy and can also help prevent destructive behaviours.",
      "Pulling on Leash:",
      "If your dog is constantly <a href='https://petmojo.care/blog/how-do-i-calm-my-dog-when-its-time-to-put-on-the-leash-46' target='_blank' rel='noopener noreferrer'>pulling on the leash</a> during walks, it can be frustrating and potentially dangerous. Obedience training can help teach your dog to walk politely on a leash and to follow your lead.",
      "Separation Anxiety:",
      "If your dog exhibits anxious or destructive behaviours when left alone, they may be suffering from <a href='https://petmojo.care/blog/pet-separation-anxiety' target='_blank' rel='noopener noreferrer'>separation anxiety</a>. Obedience training can help your dog learn to cope with being alone and can help reduce anxiety.",
    ],
    id: "does-your-dog-require-obedience-training-74",
    title: "Does your Dog require Obedience Training? - Petmojo",
    description:
      "Obedience training can be beneficial for dogs of all ages and can help improve behaviour and strengthen the bond between you and your furry friend. Read to find more.",
    serial_no: 3,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1665.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1667.webp",
    views: "1287",
    date: "2nd January, 2023",
    heading: "6 Easy Ways to Bond with your Dog",
    full_content: [
      "When we get a dog to our home, we want to bond with them in every way possible and include them in our family. Building a strong bond with your dog can bring joy and happiness to both you and your furry friend. Here are six easy steps you can follow to bond with your dog:-",

      "Spend quality time together:",
      "One of the best ways to bond with your dog is to spend quality time together. This can include activities like going for walks, playing fetch, or cuddling on the couch. The more time you spend together, the stronger your bond will become.",

      "Train together:",
      "Training is a great way to bond with your dog as it helps to build trust and communication between you. Training can be as simple as teaching your dog basic commands like 'sit' and 'stay,' or it can involve more <a href='https://petmojo.care/dog-training' target='_blank' rel='noopener noreferrer'>advanced training</a> like agility courses.",

      "Provide consistent care:",
      "Consistently meeting your dog's basic needs, such as feeding, grooming, and exercise, can help to strengthen the bond between you. By taking care of your dog, you are showing them that you are dependable and responsible, which can help to build trust and loyalty.",
      "Show affection:",
      "Showing affection to your dog can help to strengthen the bond between you. This can include simple gestures like petting, cuddling, and praising your dog.",
      "Play together:",
      "Playtime is an important part of bonding with your dog. Whether it's a game of fetch or a tug-of-war, playing together helps to strengthen the bond between you and your dog and can be a lot of fun for both of you.",
      "Provide for their needs:",
      "Taking care of your dog's basic needs, such as providing them with food, water, shelter, and medical care, is essential for building a strong bond. By meeting their needs, you are showing your dog that you care for them and are responsible for their well-being.",
      "By following these simple steps, you can build a strong and meaningful bond with your dog that will bring joy and happiness to both of you.",
    ],
    id: "6-easy-ways-to-bond-with-your-dog-73",
    title: "6 Easy Ways to Bond with your Dog - Petmojo",
    description:
      "Bonding with your dog is an important part of being a responsible and loving pet owner. Read to find 6 ways to bond with your dog.",
    serial_no: 5,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1662.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1664.webp",
    views: "1287",
    date: "31st December, 2022",
    heading: "Great Dane: Ultimate guide to dog breeds",
    full_content: [
      "The Great Dane is a breed of domestic dog known for its giant size and regal appearance. These gentle giants are known for their calm, loving nature and make excellent family pets.",

      "Characteristics:",
      "The Great Dane is a large breed of dog, with males standing at least 30 inches tall at the shoulder and females at least 28 inches. They can weigh anywhere from 100 to 200 pounds. They have a short, smooth coat that can come in a variety of colors including fawn, brindle, black, blue, and harlequin. Great Danes have long, floppy ears and a strong, muscular build.",

      "Personality:",
      "Great Danes are known for their calm and friendly personality. They are generally good-natured and patient, making them a great breed for families with children. Despite their size, Great Danes are gentle and affectionate with their loved ones and make excellent guard dogs. They are loyal and devoted to their owners and can be quite protective of their family.",

      "Health and Wellness:",
      "Great Danes are prone to a number of health issues including hip dysplasia and heart problems. It is important to keep them on a healthy diet and to have them checked by a veterinarian regularly to help prevent these issues. They also need regular exercise to maintain their muscle tone and to prevent obesity.",
      "Training Needs:",
      "Great Danes are intelligent and easily trained, but due to their size, it is important to start training and socialization early. They should be taught basic obedience commands and good manners to help them become well-behaved and well-rounded pets.",
      "Energy Level:",
      "Despite their size, Great Danes have a moderate energy level and do not require an excessive amount of exercise. They enjoy daily walks and playtime, but do not need long, strenuous exercise sessions.",
      "Life Span",
      "Great Danes have a lifespan of around 7 to 10 years, although some may live longer with proper care and nutrition.",
      "In conclusion, the Great Dane is a beautiful and majestic breed that is perfect for families looking for a gentle, affectionate companion. With proper care and training, these gentle giants can be a loving addition to any household.",
    ],
    id: "great-dane-ultimate-guide-to-dog-breeds-72",
    title: "Great Dane: Ultimate guide to dog breeds - Petmojo",
    description:
      "The Ultimate Guide to Great Dane Breed. Everything you need to know before you decide to pet them. Read to learn more.",
    serial_no: 2,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1659.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1661.webp",
    views: "1287",
    date: "30th December, 2022",
    heading: "5 Ways Dogs Communicate With Us",
    full_content: [
      "Dogs are one of the most expressive animals that there are and a lot of times pet parents understand what the dog is trying to communicate but sometimes it is a mystery. We are here to help you decipher some ways dogs communicate with us.",

      "Eye Contact:",
      "Dogs often use their eyes to communicate their feelings as well as what they want. If a dog maintains eye contact for a long time, that could mean he wants attention and affection. In case the dog is avoiding eye contact, it can show fear and submission.",

      "Posture:",
      "Posture is another great way to understand what your dog wants to tell you. The way they are holding up or down their ears can determine if they are confidant or scared. Their tail posture also helps them communicate their feelings. Slow wagging means they are being cautious while fast wagging means they are happy.",

      "Belly Exposure:",
      "When a dog exposes his belly, it is an indication that they are comfortable and trust the people around them completely. Sometimes, when they are carefree, they may sleep in this posture as well.",
      "Bringing things to you:",
      "When your dog brings a ball to you, or any other item, they are either inviting you to play with them or they are feeling the love towards you and want to convey it.",
      "Raising a Paw:",
      "This is their way of seeking attention when they feel that they are not being listened to or they feel misunderstood.",
    ],
    id: "5-ways-dogs-communicate-with-us-71",
    title: "5 Ways Dogs Communicate With Us - Petmojo",
    description:
      "Dogs cannot speak our language but we have to understand them through their body language. Here are 5 ways dogs communicate with us.",
    serial_no: 3,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1656.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1658.webp",
    views: "1287",
    date: "29th December, 2022",
    heading: "Top Pet-Friendly Vacation Destinations in India 2023",
    full_content: [
      "We understand it is heartbreaking to leave your pet behind while going for a vacation. It reduces the fun of the vacation and leaves us worried. You can leave your pet behind in a trustworthy <a href='https://www.petmojo.care/pet-boarding' target='_blank' rel='noopener noreferrer'>boarding center</a> but if you are planning a vacation with your furry friend in 2023, we have the most articulate list to help you find the top pet-friendly vacation destinations in India.",

      "Goa:",
      "Goa is the perfect vacation spot for you as well as your 4 legged companion. It is one of the most loved destinations in India. Goa is best known for its music, culture, beaches and seafood. There are a variety of pet friendly resorts, hotels and hostels in Goa like Blue Lagoon Cola Resort, Agonda Shell Beach Resort.",

      "Manali:",
      "Manali is a popular hill station in Himachal Pradesh that is known for its stunning natural beauty. There are several pet-friendly hotels and resorts in Manali like Village House, Orchards House, Spring House that welcome dogs. A great deal of cafes are also pet friendly in Manali.",

      "Puducherry:",
      "Puducherry is a great destination for vacations. It is also an ideal location for your fur buddy. There are many pet friendly places in Puducherry for stay like CocoLand Resort, Hotel La Villa, and many more. You can also find many pet friendly cafes in the city.",
      "Ooty:",
      "Also known as Udhagamandalam, is a popular hill station in Tamil Nadu that is known for its lakes, lush valleys and beautiful hills. There are several pet-friendly hotels and resorts in Ooty that welcome dogs. You can check out Blossoms Corner Inn, Clouds Bay Hotel and Wings Resort.",
      "Munnar:",
      "Munnar is a popular hill station in Kerala that is known for its stunning natural beauty. There are several pet-friendly hotels and resorts in Munnar like Hut Holidays, JJ Cottage and Elysium Garden Hill Resort. The town is surrounded by forests and trails that are perfect for exploring with your furry friend.",
    ],
    id: "top-pet-friendly-vacation-destinations-in-india-2023-70",
    title: "Top Pet-Friendly Vacation Destinations in India 2023 - Petmojo",
    description:
      "We have articulated the top destinations for a trip with your furry friend In India. Don’t wait anymore and plan a trip with your dog in 2023.",
    serial_no: 5,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1653.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1655.webp",
    views: "1287",
    date: "28th December, 2022",
    heading: "10 adorable pet photoshoot ideas for 2023",
    full_content: [
      "Which pet parent does not want cute pictures of their pooches? We are always looking for ideas to get more cute insta-worthy photos of our pets for keepsake! Here are some adorable pet photo shoot ideas that you can click for 2023.",

      "Holiday Theme:",
      "Dress up your pet in festive outfits and decorate the surroundings accordingly. Use a good quality camera to click some adorable pictures. Do not forget to add props to click amazing pictures.",

      "Floral Theme:",
      "A floral theme goes well with everything. A cute pet deserves a floral theme photoshoot. This theme goes great with small dogs or small pets like hamsters.",

      "Beach Day:",
      "When summer comes, make sure to use the time to click some cutesy pictures of the pet having fun in sun and sand.",
      "Matching Outfits:",
      "Get matching outfits for yourself and your pet. Coordinated outfits bring out so much warmth in the picture.",
      "Outdoor Adventure:",
      "Go for an adventure trip with your pet and click some photos outdoors. Photos clicked in the sun are the best. Capture some candid shots and you’re done!",
      "Baby Theme:",
      "If you have a baby or children, click some great pictures of your pet with them. You can combine other ideas with this one to click even more social media worthy pictures.",
      "Food Theme:",
      "Set up a food-themed photo shoot with your pet and some of their favorite treats. You can get some great videos, boomerangs and slow motion as well.",
      "Books Theme:",
      "Set up a cozy reading nook with your pet and some of your favorite books. Get glasses for your dog. This would give you some great nerdy pictures!",
      "Toy Theme:",
      "Arrange your pet’s favourite toys in a corner. Decorate the corner and click their pictures where they are happy.",
      "Holiday Lights:",
      "Set up a holiday light-themed photo shoot with your pet and some festive decorations.",
    ],
    id: "10-adorable-pet-photoshoot-ideas-for-2023-69",
    title: "10 adorable pet photoshoot ideas for 2023 - Petmojo",
    description:
      "Get inspired for a pet photo shoot with these ideas for 2023! Visit our blog for more creative ideas and tips for a successful photo shoot.",
    serial_no: 2,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1650.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1652.webp",
    views: "1287",
    date: "27th December, 2022",
    heading: "Most adorable dog breeds to pet in 2023",
    full_content: [
      "If you're looking for the most adorable dog breeds to pet in 2023, you're in luck! There are so many cute and fluffy breeds out there that it can be hard to choose just one. Here are some of the most adorable dog breeds that you might consider adding to your family in the coming year:-",

      "Pomeranians:",
      "These small, fluffy dogs are known for their thick mane of fur and fox-like appearance. They are energetic and friendly, making them great companions for individuals and families. We have a blog post dedicated to <a href='https://petmojo.care/blog/pomeranian-the-ultimate-guide-to-dog-breed-66'  target='_blank' rel='noopener noreferrer'>Pomeranians.</a>",

      "Bichon Frises:",
      "With their soft, fluffy white coats and playful personalities, Bichon Frises are sure to steal your heart. They are known for being intelligent and are easy to train. They make great family pets.",

      "Maltese:",
      "These small, white dogs are known for their silky coats and sweet, gentle personalities. They are great with children and make great lap dogs.",
      "Indian Spitz:",
      "The Indian Spitz is a medium-sized dog with a thick, white or cream-colored coat. They are known for their friendly and energetic personalities, and they make great family pets.",
      "Lhasa Apso:",
      "The Lhasa Apso is a small, fluffy dog with a long, thick coat. They are known for their playful and energetic personalities and make great companion dogs.",
      "No matter which breed you choose, it's important to do your research and make sure you are prepared to provide the necessary care and attention that any dog deserves. With love and care, any of these adorable breeds can make a great addition to your family.",
    ],
    id: "most-adorable-dog-breeds-to-pet-in-2023-68",
    title: "Most adorable dog breeds to pet in 2023 - Petmojo",
    description:
      "If you are planning to get a pet dog in 2023, we have curated a perfect list to help you decide which breed is best for your family.",
    serial_no: 3,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1647.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1649.webp",
    views: "1287",
    date: "26th December, 2022",
    heading: "5 Pet Pawsitive New Year Resolutions for 2023",
    full_content: [
      "2022 is almost over and a new year always comes with lots of hopes and dreams. We make so many resolutions every year for ourselves. Next year in 2023, add your pet resolutions for a healthier and happy life for your pet.",

      "Exercise them regularly:",
      "Our pet dogs, especially in urban areas, are facing obesity and other health issues related to it. We must make a new year resolution to make sure to give them plenty of outdoor time and <a href='https://petmojo.care/dog-running' target='_blank' rel='noopener noreferrer'>exercise everyday</a>.",

      "Give them measured food:",
      "Stop giving your pet food without measuring. In the long run, unmeasured food also leads to obesity.",

      "Learn about Pet First Aid:",
      "Having a pet is like having a child. As a pet parent, it is important to learn about first aid for your fur buddy to help them in times of crisis.",
      "Train them well:",
      "A dog that is not trained can get unruly and uncontrollable. As a member of your family, you are responsible for them. <a href='https://petmojo.care/dog-training' target='_blank' rel='noopener noreferrer'>Training</a> them will make it so much more worthwhile to have them in your life.",
      "Try a new activity every couple of months:",
      "Find dog friendly activities that you can do around your place. Make sure to keep the spark alive and make life interesting for them.",
      "Get regular checkups:",
      "It is important to get your dog checked every once in a while by a vet to see if everything is alright with them physically. We don’t want to see our pooches in pain and regular check ups are the best way to avoid all problems.",
      "Groom them regularly:",
      "<a href='https://petmojo.care/grooming' target='_blank' rel='noopener noreferrer'>Groom</a> your dog every month or two professionally to make sure they look rad and feel awesome!",
    ],
    id: "5-pet-pawsitive-new-year-resolutions-for-2023-67",
    title: "5 Pet Pawsitive New Year Resolutions for 2023 - Petmojo",
    description:
      "This New Year 2023, make some resolutions that benefit you as well as your dog. Make it an awesome year for both of you! Read to find more.",
    serial_no: 5,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1644.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1646.webp",
    views: "1287",
    date: "24th December, 2022",
    heading: "Pomeranian: The Ultimate Guide to Dog Breed",
    full_content: [
      "Pomeranians are one of the cutest dog breeds that exists. They are very apartment friendly due to their size and have been one of the favourite amongst Indians for a very long time!",

      "Characteristics:",
      "Pomeranians are small, fluffy dogs with a double coat of fur and a fox-like appearance. They are known for their abundant mane of fur, which can be found in a variety of colors including white, black, orange, and brown. Pomeranians have a small, triangular head and pointed ears, and they are generally very small in size, with adults weighing between 3 and 7 pounds.",

      "Personality:",
      "Pomeranians are energetic, friendly, and intelligent dogs. They are known for their playful and curious nature and make great companions for individuals and families. Pomeranians are also very protective of their owners and make excellent watchdogs. They may be prone to barking, so it is important to train them to be quiet on command.",

      "Health & Wellness:",
      "Pomeranians are generally healthy dogs, but they may be prone to certain health issues such as dental problems, luxating patella, and eye problems. It is important to keep their teeth clean and visit the veterinarian for regular check-ups to prevent dental issues. Pomeranians may also be prone to obesity, so it is important to monitor their diet and exercise to maintain a healthy weight.",
      "Training Needs:",
      "Pomeranians are intelligent and eager to please, so they are generally easy to train. They respond well to positive reinforcement and reward-based training methods. It is important to start training and socialization early to prevent behavior issues such as barking and aggression. Pomeranians may also benefit from obedience training and agility training to keep them mentally and physically stimulated.",
      "Energy Level:",
      "Pomeranians are energetic and playful dogs, but they do not require a lot of exercise due to their small size. They are well-suited for apartment living and do well with a few short walks or play sessions each day.",
      "Life Span:",
      "Pomeranians have a lifespan of 12-16 years. With proper care, including a healthy diet, regular exercise, and regular veterinary check-ups, they can live a long and healthy life.",
    ],
    id: "pomeranian-the-ultimate-guide-to-dog-breed-66",
    title: "Pomeranian: The Ultimate Guide to Dog Breed - Petmojo",
    description:
      "The Ultimate Guide to Pomeranian Breed. Everything you need to know before you decide to pet them. Read to learn more.",
    serial_no: 2,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1640.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1642.webp",
    views: "1287",
    date: "23rd December, 2022",
    heading: "7 ways to celebrate Christmas with your Dog",
    full_content: [
      "Tis the season to be jolly and why should our fur buddies be left out of the merriment! Some people feel having pets makes it harder to celebrate the holidays but we have some amazing ideas to make this season even more special with your doggos.",

      "Dress them up:",
      "It is a great idea to pick up something cute for the dog. I would love a red sweater, even your old red sweater can be customised for your fur buddy.",

      "Holiday Photo Shoot:",
      "Plan a holiday photo-shoot with your pooch. It is a great way to spend time not just with the family but also with your dog. Get matching clothes and create memories that would last a lifetime!",

      "Plan a Weekend away to dog-friendly hotel:",
      "If you have some days off from work, for a change of pace, you can take your dog to a dog-friendly resorts. These days, there are a lot of places that not just welcome dogs but also plan activities around them.",
      "Decorate their bed and make it cozy:",
      "Just like we decorate our homes, take some time to give a makeover to their bed and their space as well. <a href='https://petmojo.care/blog/dog-care-in-winters-44' target='_blank' rel='noopener noreferrer'>Winters</a> are tough on them, and a cozy bed will make it so much easier.",
      "Take them for a spa & styling session:",
      "Another way to pamper them is to take them to a professional dog spa and <a href='https://petmojo.care/styling' target='_blank' rel='noopener noreferrer'>professional dog styling</a> sessions.",
      "Bake some dog treats:",
      "This is the time for all of us to indulge in some baked goods. Why should our dogs not have the same? Bake some delicious baked goods for your dogs.",
      "Give them more time outdoors:",
      "Take them out to parks and play with them outdoors. That is, by far, their favourite activity, to spend time with their humans, outside.",
    ],
    id: "7-ways-to-celebrate-christmas-with-your-dog-65",
    title: "7 ways to celebrate Christmas with your Dog - Petmojo",
    description:
      "This is one of the most loved time of the year for most of us. Let us make it special for our fur buddies as well. Here are 7 ways to celebrate the holidays with your dog. ",
    serial_no: 3,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1635.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1637.webp",
    views: "1287",
    date: "22nd December, 2022",
    heading: "5 ways to tackle Dog’s Stress in the Holiday Season",
    full_content: [
      "As read in ‘5 Reasons your Dog gets Stressed During the Holidays’, our dogs get very stressed during this season due to so many changes in their regular life. They don’t like changes as much as we do and this causes them stress. We have listed down the top 5 ways to tackle dog’s stress in the holiday season!",

      "Maintain a consistent routine:",
      "Dogs thrive on routine, so try to stick to their regular feeding, exercise, and play times as much as possible. This can help them feel more secure and comfortable during the busy holiday season.",

      "Create a quiet space:",
      "With all the extra activity and visitors during the holidays, it's important to provide your dog with a quiet space where they can retreat to if they need a break. This could be a crate, a small room, or just a corner of the house where they can relax and feel safe.",

      "Keep an eye on your dog's diet:",
      "Changes in diet, especially during the holidays, can cause digestive issues and stress in dogs. Stick to their regular food as much as possible and avoid giving them any table scraps or treats that could upset their stomach.",
      "Exercise your dog:",
      "Regular exercise is important for maintaining your dog's physical and mental health. Make sure to take them for <a href='https://petmojo.care/dog-running' target='_blank' rel='noopener noreferrer'>regular walks</a> and play sessions to help them burn off excess energy and reduce stress.",
      "Monitor your dog's behaviour:",
      "Pay attention to your dog's body language and behaviour. If they seem anxious or <a href='https://petmojo.care/blog/signs-your-dog-is-stressed-30' target='_blank' rel='noopener noreferrer'>stressed</a>, try to identify the cause and take steps to alleviate it. This could involve providing them with extra attention, removing them from the situation, or using calming techniques such as massage or aromatherapy.",
      "Overall, the key to helping your dog cope with holiday stress is to pay attention to their needs and provide them with a sense of stability and security. With a little effort, you can ensure that your furry friend has a happy and stress-free holiday season.",
    ],
    id: "5-ways-to-tackle-dog’s-stress-in-the-holiday-season-64",
    title: "5 ways to tackle Dog’s Stress in the Holiday Season - Petmojo",
    description:
      "Dog’s can get stressed in the holiday season. Here are some great ways to tackle their stress and give them some stability and security.",
    serial_no: 5,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1632.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1634.webp",
    views: "1287",
    date: "21st December, 2022",
    heading: "5 most common small dog breed health issues",
    full_content: [
      "Small dog breeds, while adorable and popular as pets, can be prone to certain health issues. Here are the top five most common health issues that small dog breeds may face:-",

      "Dental problems:",
      "Small dog breeds, such as Chihuahua and Pomeranian, often have crowded teeth and small jaws, which can lead to dental problems such as tooth decay and gum disease. It is important to <a href='https://petmojo.care/blog/how-to-brush-your-dog%E2%80%99s-teeth-60' target='_blank' rel='noopener noreferrer'>regularly brush</a> your small dog's teeth and visit the veterinarian for regular dental check-ups to prevent these issues.",

      "Luxating patella:",
      "This is a condition where the kneecap becomes dislocated and can cause lameness and pain in the affected leg. Small dog breeds, such as Pomeranian and Pekingese, are more prone to luxating patella due to their small size and conformation. Treatment may include surgery or physical therapy.",

      "Eye problems:",
      "Small dog breeds, such as Pugs and Shih Tzus, are prone to eye problems such as cataracts and dry eyes. Regular eye exams by a veterinarian can help catch these issues early and prompt treatment can help prevent further damage to the eye.",
      "Skin allergies:",
      "Small dog breeds, such as Maltese and Bichon Frises, are prone to skin allergies, which can cause itching, redness, and hair loss. Allergies can be caused by a variety of things, including food, environmental factors, and grooming products. It is important to work with a veterinarian to identify the cause of the allergy and determine the best treatment plan.",
      "Obesity:",
      "Small dog breeds are prone to obesity due to their small size and tendency to be less active. It is important to monitor your small <a href='https://petmojo.care/blog/guide-to-pet-foods' target='_blank' rel='noopener noreferrer'>dog's diet</a> and <a href='https://petmojo.care/dog-running' target='_blank' rel='noopener noreferrer'>exercise</a> to prevent obesity, which can lead to other health issues such as diabetes and joint problems.",
    ],
    id: "5-most-common-small-dog-breed-health-issues-63",
    title: "5 most common small dog breed health issues - Petmojo",
    description:
      "Small dog breeds, though super cute, are prone to some  health issues due to their size. Here are 5 most common small dog breed health issues. ",
    serial_no: 2,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1629.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1631.webp",
    views: "1287",
    date: "20th December, 2022",
    heading: "5 Reasons your Dog gets Stressed During the Holidays",
    full_content: [
      "Holidays are the favourite time of the year for most of us. Family, celebrations, fun, joy, and a lot of laughter. But, in all the merriment, sometimes we forget our little pooches and what they feel during this time. More often than not, dogs get stressed during holidays. Let’s learn what keeps them stressed during the holiday season.",

      "Guests:",
      "Holidays mean a lot of guests coming over. Family as well as friends visit around this time. Although some people enjoy socializing with dogs, others do not. A lot of times we end up putting our dogs on leash because our guests aren’t comfortable with them.",

      "Unfamiliar objects:",
      "No matter how much we love our christmas tree, the beautiful decorations and everything christmasy, for our pooches, these are all unfamiliar items and they feel threatened and stressed around so many new things, all at once.",

      "No Routine:",
      "Dogs love to follow a routine. They generally have the same time to wake up, same time to eat and the same time to walk. The christmas hustle bustle often throws them off their routine and that makes them very anxious.",
      "Fireworks:",
      "Fireworks are always tough on our little fur buddies. We have a detailed blog post on calming them down during <a href='https://petmojo.care/blog/keeping-your-dog-calm-during-diwali-fireworks' target='_blank' rel='noopener noreferrer'>firecrackers.</a>",
      "Being Left Alone:",
      "We have so many commitments and events to attend during the holiday season, we leave the dogs alone at home more often than regular days. This is tough on their mental health because they do <a href='https://petmojo.care/blog/4-signs-your-dog-misses-you' target='_blank' rel='noopener noreferrer'>miss us</a> and get very stressed every time they are left alone.",
    ],
    id: "5-reasons-your-dog-gets-stressed-during-the-holidays-62",
    title: "5 Reasons your Dog gets Stressed During the Holidays - Petmojo",
    description:
      "Holiday Season can get very tough on our pooches. We have listed the top 5 reasons your dog gets stressed during the holidays. Read to find more.",
    serial_no: 3,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1626.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1628.webp",
    views: "1287",
    date: "19th December, 2022",
    heading: "Beagles: The Ultimate Guide to Dog Breed",
    full_content: [
      "Beagles are a small breed of hound that are known for their energetic and curious personalities. They are often used as hunting dogs due to their excellent sense of smell and ability to track scents. However, they are also popular as family pets due to their friendly and affectionate nature.",

      "Characteristics:",
      "Beagles have a distinctive appearance, with their long ears and short, sleek coat. They come in a variety of colors, including tri-color, red and white, and lemon. They typically stand around 13 inches tall at the shoulder and weigh between 20 and 30 pounds.",

      "Personality:",
      "Beagles are also known for their friendly and affectionate nature. They are great with children and love to play and cuddle with their family. They are also typically good with other dogs and pets, making them a good fit for households with multiple animals.",

      "Health & Wellness:",
      "Like all breeds, Beagles are prone to certain health issues. Some common health concerns for Beagles include hip dysplasia, eye problems, and thyroid issues. It is important for Beagle owners to keep up with regular vet visits and screenings to catch any potential health issues early on.",
      "Beagles also require regular exercise and a healthy diet to maintain their weight and overall wellness. They should be taken on daily walks or given the opportunity to run in a safe, enclosed area.",
      "Training Needs:",
      "Beagles are intelligent and trainable, but can be stubborn at times. With proper training and socialization, they can be well-behaved and obedient companions. It is important to start training and socialization early on in a Beagle's life to help them develop good habits and manners.",
      "Energy Level:",
      "Beagles are energetic and active dogs that require regular exercise. They enjoy going on walks and runs, and also love to play and explore their environment. It is important for Beagle owners to provide their pets with plenty of opportunities for physical activity to keep them healthy and happy.",
    ],
    id: "beagles-the-ultimate-guide-to-dog-breed-61",
    title: "Beagles: The Ultimate Guide to Dog Breed - Petmojo",
    description:
      "The Ultimate Guide to Beagles Breed. Everything you need to know before you decide to pet them. Read to learn more.",
    serial_no: 5,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1623.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1625.webp",
    views: "1287",
    date: "17th December, 2022",
    heading: "How to brush your dog’s teeth?",
    full_content: [
      "Maintaining good oral hygiene is important for all animals, including dogs. Not only does it keep their breath fresh and prevent bad odours, it also helps prevent dental problems such as gum disease and tooth loss. Here are some tips on how to brush your dog's teeth:-",

      "Start when they are young:",
      "It is much easier to get a puppy used to having their teeth brushed than it is to introduce the practice to an older dog. Try to get them used to having their mouth touched and handled while they are still young.",

      "Use a soft-bristled toothbrush:",
      "It is important to use a toothbrush specifically designed for dogs, as their teeth and gums are different from those of humans. Look for a toothbrush with soft, round-ended bristles to avoid damaging their gums.",

      "Use toothpaste specifically for dogs:",
      "Do not use human toothpaste on your dog, as it can contain ingredients that are toxic to them. Instead, use toothpaste specifically made for dogs, which is formulated to be safe for them to swallow.",
      "Brush in circular motions:",
      "Gently brush your dog's teeth in circular motions, making sure to reach all areas of their mouth. Pay special attention to the back molars, as these tend to build up the most tartar.",
      "Reward your dog:",
      "Make sure to reward your dog with treats or praise after brushing their teeth to reinforce the positive behaviour.",
      "It is recommended to brush your dog's teeth at least once a week, but daily brushing is even better. If you are unable to brush your dog's teeth, there are other options such as dental chews and water additives that can help maintain good oral hygiene.",
    ],
    id: "how-to-brush-your-dog’s-teeth-60",
    title: "How to brush your dog’s teeth? - Petmojo",
    description:
      "Taking care of a dog's dental health is important. A lot of pet parents are clueless how to go about it. Here is a guide on how to brush your dog’s teeth.",
    serial_no: 2,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-xyz.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1622.webp",
    views: "1287",
    date: "16th December, 2022",
    heading: "What to do if your dog eats chocolate?",
    full_content: [
      "Chocolate Poisoning is a real threat for dogs. To learn more about why chocolate is so bad for them, read our blog about it.",

      "Step 1:",
      "In case of accidental chocolate ingestion by the dog, contact your vet immediately. He will give you a list of symptoms to look for based on the size of the dog and amount of chocolate eaten by him. If he feels it is more dangerous, he might call you to the clinic.",

      "Step 2- Part 1:",
      "Firstly, the chocolate needs to be removed from the dog’s system. To do this, you may induce vomiting, if it was recently consumed, within an hour. Always do this under supervision of a Vet. Do not attempt to do it at home.",

      "Step 2- Part 2:",
      "In case it has been more than a few hours, then the vet may need to clear the toxic byproducts by administering activated charcoal or giving IV fluids.",
      "Step 3:",
      "If the dog is showing symptoms of chocolate poisoning, they are likely to be hospitalized and given intravenous fluids, some medications like anti seizure, anti vomit, anti nausea, etc.",
      "Based on the severity of the intoxication, the dog could be in the hospital from a day to a week or 10 days.",
      "The best way to avoid all of this is to keep chocolates way out of reach of your pooches and avoid all the hassle and worry.",
    ],
    id: "what-to-do-if-your-dog-eats-chocolate-59",
    title: "What to do if your dog eats chocolate? - Petmojo",
    description:
      "Chocolate is poisonous to dogs. But, what if your dog actually consumes some. Here are steps to follow if your dog eats chocolate.",
    serial_no: 3,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1614ytf.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1619.webp",
    views: "1287",
    date: "15th December, 2022",
    heading: "4 Tips to Keep Your Dog’s Hair Glossy and Healthy",
    full_content: [
      "Does your dog have dull hair? Dull hair is not just due to less care of their coat but also a reflection of their diet. Just like us humans have better hair when we eat healthy and take care of our hair by using good products. Here are 5 ways how you can keep your dog’s hair glossy and healthy.",

      "Bathe them regularly:",
      "Regular baths and good quality shampoo is a must to maintain their beautiful coats. By regular, we don’t mean give them a bath everyday! That may strip them of their natural oils. You can bathe them once every week to twice a month.",

      "Brush their fur:",
      "Brushing their fur removes any knots and grooms them well. We often skip brushing their coats as we believe it is not as important. This is a mistake you don’t want to make. Brushing not just removes knots, it also releases the natural oils from their skin to give their fur a glossy look. If your dog sheds a lot, get them a de-shedding brush.",

      "Get Professional Grooming every month:",
      "Even if you bathe them at home regularly, make sure to give them professional grooming once a month or in two months. It really brings out the best in them. <a href='https://petmojo.care/grooming' target='_blank' rel='noopener noreferrer'>Professional groomers</a> know exactly how to make you pooch look rad and make them feel awesome!",
      "Feed them healthy food:",
      "Having a glossy and shiny fur coat does not just come from grooming the dog. We need to give the dog <a href='https://petmojo.care/blog/guide-to-pet-foods' target='_blank' rel='noopener noreferrer'>healthy food</a> and treats to make sure they are healthy from inside which makes them look good from outside.For beautiful fur, their diet should be rich in Omega 3 and Omega 6 while low in fat. Do not feed them sugar or salt.",
    ],
    id: "4-tips-to-keep-your-dog’s-hair-glossy-and-healthy-58",
    title: "4 Tips to Keep Your Dog’s Hair Glossy and Healthy - Petmojo",
    description:
      "We all want our dogs to look beautiful and have glossy and shiny hair. Here are 4 tips to keep your Dog’s hair glossy and healthy.",
    serial_no: 5,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1614.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1616.webp",
    views: "1287",
    date: "14th December, 2022",
    heading: "4 Black Dog Breeds in India You’ll Fall in Love with",
    full_content: [
      "Honestly most of us don’t care about the color or size of dogs. Let’s face it, we find them all super cute and adorable. But, if you want a dog that’s gorgeous and looks royal, a dog with black fur is the way to go. We have listed the 5 most adorable black dog breeds that you can easily find in India and definitely fall in love with!",

      "Rottweiler:",
      "Rottweiler is an old German breed. They are active, confident and very intelligent. They are great to become service dogs and can be trained easily. They are gentle, calm and great with families. Their coat is smooth and short and is a mix of black, tan and mahogany colour.",

      "Black Labrador Retrievers:",
      "Labrador retrievers come in various colours like yellow, golden and black. Black ones look very royal. They are one of the most popular breeds to pet in India. If you have the space and bandwidth of getting a bigger dog, they are the best. To learn more about Labrador Retrievers, read our blog on them.",
      "Doberman Pinscher:",
      "They are the most alert, loyal, and fearless dogs. Just like Rottweilers, they are also great as watch dogs or service dogs. They are playful in nature, have a lot of energy, and are great for families. They can get aggressive to strangers if they sense any danger to their families.",
      "Pug:",
      "Pugs are great if you want a royal black dog but you also have some space constraints. They are small, cute but naturally have a royal attitude. They are also one of the most popular breeds around.",
    ],
    id: "4-black-dog-breeds-in-india-you'll-fall-in-love-with-57",
    title: "4 Black Dog Breeds in India You’ll Fall in Love with - Petmojo",
    description:
      "If you are someone who is looking for a black dog, this is the article for you! Read to find top 4 black dog breeds in India.",
    serial_no: 2,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1611.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1613.webp",
    views: "1287",
    date: "13th December, 2022",
    heading: "What is obedience training for Dogs?",
    full_content: [
      "Having a pet dog can be as challenging as it is fun. Obedience training is one we recommend to each and every dog to make your pet life easy and fun. Let us explain in detail, what is obedience training and what to expect from it.",

      "What is obedience training?",
      "Obedience, as per dictionary, means compliance with an order, request, or law or submission to another person’s authority. With respect to dog training, obedience means something similar. We give obedience training to dogs to make them obedient and follow commands. This makes them be trained more easily and effectively.",

      "How much does obedience training cost?",
      "Obedience training may cost somewhere between 900 to 1000 INR per session. It takes approximately 6 to 10 sessions to get them trained depending on the dog.",
      "How is obedience training different from other types of training?",
      "Other types of training are specific. They teach particular activities or commands whereas obedience training prepares a pooch for training. It teaches the fundamentals of obedience to dogs. It helps build the bond between a new pup and owner. It is ideal to start this training as soon as an 8 weeks old puppy.",
    ],
    id: "what-is-obedience-training-for-dogs-56",
    title: "What is obedience training for Dogs? - Petmojo",
    description:
      "Obedience training is the basic training that every dog owner should get for their dog. It teaches them the fundamentals of obedience. Read to know more.",
    serial_no: 3,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1608.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1610.webp",
    views: "1287",
    date: "12th December, 2022",
    heading: "How to Pick the Ideal Dog Boarding Facility",
    full_content: [
      "It’s the most magical time of the year! We all love to go on vacations during winters. But, as pet parents, whenever we plan to step without our little dogs, we are worried about where to leave them. There are so many things to consider. Listed below are 5 things to keep in mind to help you choose the ideal dog boarding facility.",

      "Research:",
      "First and foremost, do a good amount of research to find the best boarding facility in your area. You can search on the web or ask fellow pet parents in your neighbourhood if they have a good experience (or bad) with any particular service in your area.",

      "Find out their methods:",
      "Some boarding facilities offer home pickup and drop, others don’t. You must find out about this beforehand. Another thing to inquire about is how they take care of the pets. What are their boarders trained to do?",
      "Ask about special services:",
      "It is important to find out if the boarders provide any special services. For example, we at Petmojo provide an add on service of basic training while a dog boards with us. Just imagine, you leave your dog for a few days, and when you return, they have learnt some new tricks. Imagine how cool that would be.",

      "Find out if the dogs are chained:",
      "A lot of boarding facilities have the dogs chained and this leads to an unhappy dog. Of course different dogs have different reactions to this treatment. Some may become aggressive, others may be too sad and stop eating or drinking which could lead to bad side effects on their health. Make sure you choose a boarding facility where dogs are left free.",

      "Find out about food/water:",
      "Find out if the food and water being provided is hygienic and safe for your little pooch. They should also have an on-call Vet available in case of any emergency.",
    ],
    id: "how-to-pick-the-ideal-dog-boarding-facility-55",
    title: "How to Pick the Ideal Dog Boarding Facility - Petmojo",
    description:
      "Leaving your dog with anyone is a nightmare. We have listed down 5 things to look for when you pick the ideal dog boarding facility. Continue reading",
    serial_no: 5,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1604.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1606.webp",
    views: "1287",
    date: "10th December, 2022",
    heading: "Pug: The Ultimate Guide to Dog Breeds",
    full_content: [
      "<a href='https://google.com' target='_blank' rel='noopener noreferrer'>Pugs</a>  are known for their loving nature and royal attitude. In India, they are often referred to as the Vodafone dog. They are very friendly and love to be in the company of humans. They are great pets, especially if you have a small apartment. We are here to give you facts about these little pooches that would help you decide if they are good for you.",

      "Characteristics:",
      "They have short hairs on their fur coats and are mostly brown in colour. Some of them are also black or silver. They have large eyes and a wrinkled brow. They have a lovable appearance that makes them loved by almost everyone.",

      "Personality:",
      "Pugs can sometimes be lazy and greedy. They love to eat and tend to overeat more often than not. If you get a pug, you need to do the portion control for them.",
      "Health & Wellness:",
      "Being over-eaters and slightly lazy dogs, you need to give them ample amounts of exercise from the very beginning. They do not need frequent grooming sessions as they have a short coat but they need regular nail clipping. They are prone to eye issues.",
      "Training Needs:",
      "Pugs want humans to love them. They are people pleasers. That makes it easy to train them. The best method to train them is positive reinforcement.",
      "Energy Level:",
      "As mentioned earlier, pugs are kinda lazy. They love to snuggle with their humans in bed. They have much lower energy than most dog breeds. But, it is their owner’s responsibility to make sure to train them from the very beginning to walk and exercise well and avoid obesity.",
      "Life Span:",
      "Pugs live between 13 to 15 years.",
    ],
    id: "pug-the-ultimate-guide-to-dog-breeds-54",
    title: "Pug: The Ultimate Guide to Dog Breeds - Petmojo",
    description:
      "The Ultimate Guide to Pug Breed. Everything you need to know before you decide to pet them. Read to learn more.",
    serial_no: 2,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1601ytcytg.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1603.webp",
    views: "1287",
    date: "9th December, 2022",
    heading: "Why is Chocolate bad for Dogs?",
    full_content: [
      "Chocolate is very bad for dogs and if consumed in excess quantity, it can be fatal. No matter how much we enjoy chocolates, they are just not good for our pooches. The type of chocolate determines the level of fatality in dogs. Let us understand in detail why chocolate is bad for dogs & what happens if they mistakenly consume some.",

      "Why is chocolate harmful:",
      "Chocolate contains theobromine and a bit of caffeine. Dogs cannot digest theobromine easily. It is very heavy food for canines and takes a long time to digest. Due to this, toxic compounds build in their system.",

      "Chocolate toxicity symptoms:",
      "When consumed in small amounts, a dog may feel nauseated or diarrhoea. The symptoms will be similar to an upset stomach. But, if consumed in large quantities, they may get seizures, tremors or heart arrhythmia.",

      "It is safe to keep chocolates way out of reach from our little fur buddies. Always make sure that you store chocolates in shelves that are high up, far from the reach of your pooch.",
    ],
    id: "why-is-chocolate-bad-for-dogs-53",
    title: "Why is Chocolate bad for Dogs? - Petmojo",
    description:
      "It is widely known that chocolate is harmful for dogs. But do you ever wonder why is it so? How can a treat for humans be toxic for dogs? Read to find why chocolate is bad for dogs?",
    serial_no: 3,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1598trtut.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1600.webp",
    views: "1287",
    date: "8th December, 2022",
    heading: "5 Most Kid Friendly Pets (Other than Dogs & Cats)",
    full_content: [
      "If you have a kid, then you know what a handful they can be. Thankfully, there are plenty of pets that will keep them busy and are not very time consuming for you. Here's a list of the most kid friendly pets to add to your home or give as gifts for both boys and girls.",

      "Fishes:",
      "Fishes are one of the most common kid friendly pets. They are easy to maintain, clean and feed. A child can easily take care of them without any problems. Fishes also come in different sizes and shapes which makes it easier for children to choose their own pet fish.",

      "Birds:",
      "Birds are great pets for kids because they don't require any special care or attention from them. They need to be fed once a day but that's pretty much it! They come in different shapes, sizes and colors so children can choose the one that suits their personality best.",

      "Hamsters:",
      "Hamsters are another kid friendly pet that doesn't require too much effort from kids who want to keep it as a pet. It's easy to maintain, clean and feed hamsters so if you're looking for a simple pet for your child, then this could be the option for you! It is also an affordable option!",
      "Turtles:",
      "Turtles are another great choice for young children since they don't require a lot of attention or cleaning up after them. Turtles also don't require socialization with other animals.",
      "Rabbits:",
      "Rabbits are some of the most popular pet animals around the world. They are easy to care for and can be very friendly and playful. They are also fun to play with, especially by children. Rabbits make great pets for kids because they are small, easy to handle and have a lot of personality.",
    ],
    id: "5-most-kid-friendly-pets-other-than-dogs-and-cats-52",
    title: "5 Most Kid Friendly Pets Other than Dogs & Cats - Petmojo",
    description:
      "If you're looking for a kid friendly pet that's less high-maintenance than a dog or cat, consider some of these other domesticated animals. Read to find more.",
    serial_no: 5,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1585tyr.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1593.webp",
    views: "1287",
    date: "7th December, 2022",
    heading:
      "5 Reasons why Millennials prefer Owning Pets instead of Parenthood",
    full_content: [
      "Recently, a lot of young couples are opting to be pet parents instead of parenthood. They are not just getting pets, they treat them exactly how one treats their children. Millennials are ditching the traditional lifestyles and doing what they really want to. Here are some top reasons why millennials prefer owning pets instead of parenthood.",

      "Finances:",
      "With prices of everything increasing at an exponential rate, a lot of people opt for pets. Even though there are expenses of pets, it is definitely much less than kids.",

      "Busy Lifestyle:",
      "In most millennial households, both husbands and wives work and have a career. This makes it difficult to manage time for children. Also, both of them do not want to halt their careers in order to raise a child.",

      "For Better Mental Health:",
      "It is a known fact that pets improve mental health. In the hustle bustle of life, we all tend to lose our mental health. A lot of young couples like to get pets like dogs or cats to improve their mental health as well as physical health.",
      "Regular exercise for better shape:",
      "Getting a pet, especially a dog, forces you to go for walks everyday. In the long run, it really improves your health. On the other hand, having a kid leaves you no time to workout. It is one of the reasons why people prefer pets over babies these days.",
      "Not lonely:",
      "When couples decide not to have kids, they may get lonely after a few years. To avoid this, they often opt for getting pets to reduce their loneliness.",
    ],
    id: "5-reasons-why-millennials-prefer-owning-pets-instead-of-parenthood-51",
    title:
      "5 Reasons why Millennials prefer Owning Pets instead of Parenthood - Petmojo",
    description:
      "Millennials are preferring to own pets rather than having children. These pets are like their own children and cared for in the same way. Read to find out why it may be a good idea!",
    serial_no: 2,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_15825r.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1587.webp",
    views: "1287",
    date: "6th December, 2022",
    heading: "How to find a Dog Trainer",
    full_content: [
      "Finding a reliable and skilled dog trainer is not as easy as it sounds. Dog Training is a very personal experience which may differ for everyone. Consider it as a long term investment to ensure a lifetime of happy and harmonious living with a well-behaved dog. Here are some ways to find a good dog trainer in your area.",

      "Internet:",
      "We all use Google to find anything and everything these days. Although it is easy to find a list on the internet. But, when you are trying to find a dog trainer, you want someone that is compassionate and empathetic.",

      "Friends & Family:",
      "If anyone in your friends, family or neighbours has used some dog training service and are happy with it, get the details from there. Getting first hand reviews from people you know is one of the best ways to find the perfect trainer for your dog.",

      "Dogs Parks:",
      "If you take your dog to nearby dog parks, you may be meeting a lot of fellow pet parents. They may be able to guide you in finding good dog trainers.",
      "Research:",
      "No matter which method you decide to use to find a dog trainer for your dog, make sure to do ample amount of research about the person you choose to train your dog. A background check is absolutely essential.",
      "At Petmojo, we provide dog training services. Our trainers are not just great at their job, they are animal lovers and extremely compassionate. We believe a happy dog is a quick learner. Book our service here.",
    ],
    id: "how-to-find-a-dog-trainer-50",
    title: "How to find a Dog Trainer - Petmojo",
    description:
      "Finding a dog trainer that is good, professional and also able to make a connection is not easy. We have shared a few ways you can find a Dog Trainer. Continue reading to know more.",
    serial_no: 3,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1582rtyu.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1584.webp",
    views: "1287",
    date: "5th December, 2022",
    heading: "Top 5 Benefits of Dog Boarding",
    full_content: [
      "Sometimes, as pet parents, we believe that no one can take better care of our poodles than us. Although it is true that nobody can love them as much, professional pet boarders can care for their well being better than we can. Here are some scientifically proven health benefits of Dog Boarding so that you can leave them without worrying.",

      "Proper Nutrition:",
      "A professional dog boarder is aware of proper nutrition requirements for all breeds of dogs. Most dog boarding centers have a variety of dog food available which includes dry food as well as wet food.",

      "Personality:",
      "They are curious and very intelligent. They are also very social and loyal. These qualities possessed by German Shepherd makes them great guard dogs or police dogs.",

      "Focused Medical Attention:",
      "Since boarders are professionally trained to take care of dogs, they are able to recognize any health issues in canines and get in touch with doctors. Also, they take care of any existing medications. They also make sure that your dog does not get infested by ticks.",
      "Safety:",
      "Dog Boarding centers are safe for your pet. When dog’s are separated from their owners, they often want to leave in search of their family. Dog boarders make sure the dogs are safe and do not leave their premises.",
      "Adequate Exercise:",
      "Exercise is an important factor in the health and overall wellness of dogs. Dog boarders make sure they get adequate amounts of exercise. All breeds have different requirements when it comes to working out. Some small breeds like Shih Tzu need minimal exercise while bigger breeds like Golden Retrievers require a lot of workout.",
      "Basic Training:",
      "If you book our special dog boarding service, our boarders will also provide some training to your dog. Imagine when you come back from your vacation.",
    ],
    id: "top-5-benefits-of-dog-boarding-49",
    title: "Top 5 Benefits of Dog Boarding - Petmojo",
    description:
      "Dog boarding can be worrying for pet parents. Here are top 5 benefits of Dog Boarding to convince you to leave them with boarders!",
    serial_no: 5,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1576gh.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1581.webp",
    views: "1287",
    date: "3rd December, 2022",
    heading: "German Shepherd: The Ultimate Guide to Dog Breed",
    full_content: [
      "German Shepherd Dogs can be a great pet for you. But, before jumping into getting them as pets you need to do your research on German Shepherds. They are used for security, as guide dogs, and as police and military working dogs. This article will provide you with a comprehensive guide to this breed.",

      "Characteristics:",
      "They are large and muscular. Commonly, they are a mix of brown and black in colour but there are other varieties as well. They have a medium coat. German Shepherds are friendly and very affectionate. They are great with children and other pets.",

      "Personality:",
      "They are curious and very intelligent. They are also very social and loyal. These qualities possessed by German Shepherd makes them great guard dogs or police dogs.",

      "Health & Wellness:",
      "German Shepherds have a dense double coat which has a water-resistant outer layer. They’re great pets but they are big time shedders. These dogs typically shed once or twice per year. It is best to groom them at least once weekly and brush their fur daily.",
      "Training Needs:",
      "As they are extremely intelligent and obedient, it is easier to train them. When trained well, they can do things that are unimaginable. In fact, they are well known for their train-ability. If you plan on getting a German Shepherd, make sure to get them trained at the advanced level from professional trainers.",
      "Energy Level:",
      "They need an ample amount of exercise to stay fit. You need to take them for regular walks and runs. German Shepherds also enjoy occasional hiking. In short, they love the outdoors and want to go out and burn calories as much as possible.",
      "Life Span:",
      "They have a life span of 10 to 14 years.",
    ],
    id: "german-shepherd-the-ultimate-guide-to-dog-breed-48",
    title: "German Shepherd: The Ultimate Guide to Dog Breed - Petmojo",
    description:
      "The Ultimate Guide to German Shepherd Breed. Everything you need to know before you decide to pet them. Read to learn more.",
    serial_no: 2,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1573knk.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1578.webp",
    views: "1287",
    date: "2nd December, 2022",
    heading: "Puppy proofing your home",
    full_content: [
      "Are you getting a cute new puppy in your house? Getting a pup is an addition to the family and we can’t be more excited. But, we are also worried about so many things like taking care of them, what to feed them, how to make them comfortable and most importantly, how to keep them safe. Here are some great ideas to puppy proof your apartment and keep them safe.",

      "Keep toxic things on high shelves:",
      "When puppies are young, they tend to be curious and touch everything in their vicinity. It is best to keep toxic things like cleaning supplies in shelves which are out of reach for them.",

      "Close the toilet lids:",
      "Toilets can be hazardous for little pups. They can fall in the bowl. It is important to close the lid to keep them safe.",

      "Keep electric cords out of reach:",
      "Make sure that all the electric cords that are lying around in your house are kept high. These are chewing risks for little pups especially when they are teething.",
      "Close electric sockets using covers:",
      "Puppies end up touching everything they find interesting. Sockets are one such thing that they are often attracted to. To protect them, you can buy socket covers that are available in the market.",
    ],
    id: "puppy-proofing-your-home-47",
    title: "Puppy proofing your home - Petmojo",
    description:
      "Bringing a puppy into your home and life can be so exciting but also scary. Taking care of another soul. We have a list of things to do to puppy proof your home.",
    serial_no: 3,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1573.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1575.webp",
    views: "1287",
    date: "1st December, 2022",
    heading: "How do I calm my dog when it's time to put on the leash?",
    full_content: [
      "A lot of dogs get overexcited which can be dangerous as well, when we start the process of putting the leash on them to go for a walk. They start jumping, biting, barking and pull on the leash which makes the whole process very difficult and annoying. There is some basic training that you can do yourself to solve this habit.",

      "Train to not jump:",
      "First, we must train our dog to stay and not start jumping whenever we go to the area where the leash is stored. To do this, make them sit and give them treats while slowly moving towards the leash area.",

      "Train to stay when you pick and put the leash:",
      "Taking yourKeep giving your dog treats while he keeps sitting and you pick the leash. If the dog stands or starts getting restless, stop giving the treat. This will make them realize that they get the treat when they are calm and quiet. After a while, they will be calm every time this process happens.",

      "Hire a Professional Trainer:",
      "The best way to control the hyper dog is to get him trained professionally. A professional trainer will work at the root of the problem. This would generally make your dog calmer and happier.",
    ],
    id: "how-do-i-calm-my-dog-when-its-time-to-put-on-the-leash-46",
    title: "How do I calm my dog when it's time to put on the leash? - Petmojo",
    description:
      "Most dogs get over excited when it is time to go out. Here are some tips to calm your dog when it’s time to put on the leash. Read to know more.",
    serial_no: 5,
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1570.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1572.webp",
    views: "76",
    date: "30th November, 2022",
    heading: "How to Potty Train your Puppy",
    full_content: [
      "Potty training is the most essential thing to teach your pooch for your and your dog’s happy life. It can be a tedious and frustrating process to potty train your puppy. It requires a lot of patience. We have collated some tips to help you bathroom train your dog easily.",
      "Paper training:",
      "Initially, when your puppy is too small to take them out for walks, you can lay down newspapers and teach your pup to use the newspaper to relieve themselves. This can be tricky as once the pup is used to newspapers, you will have to re-train them to go outside for bathroom use.",
      "Train to go outside:",
      "Taking your puppies out often is an effective method to make them understand the right place for potty. It is their natural instinct to relieve in mud so it is easier to teach them.",
      "Practice positive reinforcement:",
      "Make sure that you practice positive reinforcement when you teach anything to your dog. It is by far the most ethical method of training. Give treats every time the puppy does something right.",
      "Hire professional trainers:",
      "If you feel it is difficult to train the puppy yourself, there are various options available for professional training. It will make your life easier and your puppy will be happy as well.",
    ],
    id: "how-to-potty-train-your-puppy-45",
    title: "How to Potty Train your Puppy - Petmojo",
    description:
      "Train your puppy to relieve themselves outside. Potty training is very important for your and your dog’s well being. Read to know more.",
    serial_no: 2,
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1567.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1569.webp",
    views: "144",
    date: "29th November, 2022",
    heading: "Dog Care in Winters",
    full_content: [
      "Winters can be tough for dogs. If you have a pet, it is necessary to know the ways we can keep them warm and cozy. Some people believe that due to the fur coat of dogs, they don’t feel as cold. This is a myth and dogs do feel as cold as we do.",
      "Cover them with woollen clothes:",
      "To keep your dogs warm, buy some woollen clothes for them. If you do not want to buy new clothes for them, you can also reuse your old sweaters and sweatshirts. But it is important to keep them warm.",
      "Keep them indoor:",
      "Keep your pooch indoor for most part of the day during winters. Keeping them indoors will maintain their body temperature. You can also use room heaters to keep the room warm. But, if you do use room heaters, make sure to keep it out of reach of the canine.",
      "Cozy Bed:",
      "Make sure to buy or DIY a cozy bed for your dog. A space where they can stay warm and cozy. It won’t just keep them cozy but also make them feel comfortable and get a sense of belonging.",
      "Give them plenty of exercise:",
      "A great way to keep them warm is to give them plenty of exercise. You can book Petmojo’s walking/exercise service to make sure your poodle gets an ample amount of workout.",
    ],
    id: "dog-care-in-winters-44",
    title: "Dog Care in Winters - Petmojo",
    description:
      "Winters are here! We cover ourselves in so many layers of clothes. But, what about our dogs? Here are some tips to keep them warm during this season.",
    serial_no: 3,
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1564.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1566.webp",
    views: "178",
    date: "28th November, 2022",
    heading: "Excessive Barking in Dogs",
    full_content: [
      "Barking for dogs is like talking for humans. It is natural for them to bark every once in a while. But, sometimes dogs start to bark excessively which can lead to disturbance and annoyance for pet parents as well as neighbours. To remedy this, it is important to know the reason for their barking. To treat excessive barking, following measures can be taken.",
      "Be Calm:",
      "When your dog is barking, and you start shouting at them as well, it makes them think it is okay to shout or be loud. Instead of shouting, in a calm but firm voice say, ‘Quiet’. It would have more impact than shouting.",
      "Teach the command ‘Quiet’ beforehand:",
      "It is always better to teach your dog the command ‘quiet’ beforehand. It will make your job later very easy. This command can be taught by saying quiet in a soft tone. As soon as the dog quiets down, give them a treat. After doing this a few times, they will learn.",
      "Tire them out:",
      "One of the best ways to stop a dog from barking is to tire them out or distract them one way or another. Some ways to do that is give them a DIY toy or take them out for a walk.",
    ],
    id: "excessive-barking-in-dogs-43",
    title: "Excessive Barking in Dogs - Petmojo",
    description:
      "Does your dog bark excessively? Read to find how to control excessive barking in dogs.",
    serial_no: 5,
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1561.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/bb42.png",
    views: "163",
    date: "26th November, 2022",
    heading: "Over-Excitement and Aggression",
    full_content: [
      "Over-excited dogs are often the beginning phase of an aggressive dog. Both, over-excitement and Aggression are inter-related but mean something very different. This article will help you understand the difference between the two and how to identify them in your dog.",
      "What is Over-Excitement?",
      "For humans, over-excitement means extreme happiness and fun while in dogs it borders anxiety and agitation. When a dog is excited, their body produces stress hormones, cortisol and serotonin.",
      "Why is over-excitement dangerous?",
      "An excited dog doesn’t always show signs of aggression like stiffening of the body but can suddenly bite which is very dangerous. This makes the canine behaviour unpredictable.",
      "How to control over-excitement in dogs?",
      "To control a dog that is too excited, never encourage this behaviour. There are a few ways which will help you control them.",
      "Make sure they get ample amounts of exercise. When they work out well, they are calmer and happier.",
      "Find positive outlets for your dog. It can include games, running, and socializing with other dogs. Make sure you put a stop to games if the dog starts getting too over-excited.",
      "Another great way to calm your dog is to use aromatherapy. Use some essential oils to keep them calm.",
    ],
    id: "over-excitement-and-aggression-42",
    title: "Over-Excitement and Aggression - Petmojo",
    description:
      "It is not always true that only aggressive dogs can cause harm. Over-excitement can cause aggression in dogs. Read to find more about it.",
    serial_no: 2,
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1558wd.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1560.webp",
    views: "77",
    date: "25th November, 2022",
    heading: "Do Dogs Sweat?",
    full_content: [
      "Sweating is the process of excreting liquid through skin to cool the body down in hot weather. That is how humans understand sweating. In dogs, however, sweat is little different.",
      "Dogs have 2 types of sweat glands,",
      "Merocrine Gland:",
      "This gland is located in the paw pads and releases sweat. It helps to cool their paws down while they walk on hot surfaces. These glands secrete sweat which is similar to human sweat.",
      "Apocrine Gland:",
      "It is present on the entire body of the dog but it doesn’t secrete sweat in the same way as humans. Along with sweat, it also releases pheromones which helps them to identify each other.",
      "Keeping dogs cool is very important as they are prone to heat strokes. To make sure your little one is cool, keep clean water for them at all times so they can hydrate themselves. If you’re in a car, keep the windows open and never leave the dog alone in the car. While indoors, try to control the temperature of the house.",
      "If you use these tips, it will help keep them cool and healthy, no matter the weather in your location.",
    ],
    id: "do-dogs-sweat-41",
    title: "Do Dogs Sweat? - Petmojo",
    description:
      "Sweating is a natural process that helps us keep our body cool during hot seasons. Let us find if dogs sweat the same way as us.",
    serial_no: 3,
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1551wd.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1556.webp",
    views: "1287",
    date: "24th November, 2022",
    heading: "Methods to Train a Dog Part 2",
    full_content: [
      "Training dogs can be done using various methods, some are considered more ethical than others. We were discussing the methods of training in part 1 of this blog, continuing here with more methods of training used by professional trainers.",
      "Electronic Training:",
      "In electronic training, an electric collar is tied to a dog and this collar can shock the dog when needed. These are not the most ethical, per se, but they can be used in order to give strict signals to the dog for things that are totally off limits. This type of training is not recommended.",
      "Mirror Training:",
      "In this type of training, believing that a dog mirrors the one they like or adore, is based on the concept of portraying good behaviour will make them follow. For this, you can assign a model and praise them or give them gifts for accomplishing the tasks. The dog will follow their lead.",
      "Dominance:",
      "Dogs have a pack mentality. A pet dog considers their family as their pack. They would definitely follow what the family is doing. For example, if you make sure that all members of a family sit at a little distance before their meals, they would surely follow the lead. But this training method might be more difficult for dogs to understand and may take much longer than other training methods.",
    ],
    id: "methods-to-train-a-dog-part-2-40",
    title: "Methods to Train a Dog Part 2 - Petmojo",
    description:
      "There are various methods that are used by dog trainers. Some are more ethical than others. Read to find the best training method for your pooch.",
    serial_no: 5,
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/b39.png",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/bb39.png",
    views: "98",
    date: "23rd November, 2022",
    heading: "Keep your dog safe from ticks",
    full_content: [
      "We have already discussed ticks and guides to removing ticks. Let us now discuss preventive measures that should be taken in order to keep your dog safe from ticks.",
      "Dog Shoes:",
      "One of the most common places where dogs pickup ticks is the park where they go for a walk. There are special dog shoes available in the market to protect your dog’s feet from infections and ticks.",
      "Anti Tick Spray or Injection:",
      "Your vet can help you prevent ticks very successfully. There are a variety of products available in different price ranges that can prevent ticks. Some of them are in the form of injection, others in the form of spray. Your vet will guide you with the right choice.",
      "Regular checking:",
      "The most important preventive measure is to keep checking your dog for signs and symptoms of ticks. This will ensure that your dog is tick free and even if they catch ticks, it will be caught early without damage.",
      "Sanitize the area:",
      "If your dog caught ticks in the past, make sure you sanitize not only the dog but also the surroundings, your clothes to make sure the ticks don’t return any time soon. ",
    ],
    id: "keep-your-dog-safe-from-ticks-39",
    title:
      "Keep your dog safe from ticks - Preventive measures for ticks - Petmojo",
    description:
      "Prevention is always better than cure. Here are some tips to keep your dog safe from ticks. Read to find out more. ",
    serial_no: 2,
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1548wd.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1550.webp",
    views: "156",
    date: "22nd November, 2022",
    heading: "Why does my dog eat grass?",
    full_content: [
      "Eating grass is more common than you would believe. Most dogs, at some point or another, eat grass. Even though it is common, there may be varied reasons why dogs eat grass.",
      "Low nutrient diet:",
      "If a dog doesn’t get enough nutrients in his diet, there are chances he might eat grass to look for those nutrients. If your dog eats grass, reassess their diet and if they are getting the required nutrients, talk to their vet to check for other reasons.",
      "Boredom:",
      "If your dog eats grass but not always, it may simply be boredom. Sometimes when dogs are bored, they may eat grass.",
      "Require fiber:",
      "By eating grass, dogs get their fiber. In case of constipation, fiber helps them to pass stool. A change in diet that is high in fiber can help in this case.",
      "Like the taste:",
      "Another reason is the more obvious one. Sometimes, dogs just enjoy the fresh taste of grass. This is especially true for fresh grass that is growing. To avoid this, keep fresh cool water for them and add fresh fruits or veggies in their diet.",
      "Stomach upset:",
      "When the dog’s stomach is upset, they like to eat grass to get some extra fiber in their diet.",
    ],
    id: "why-does-my-dog-eat-grass-38",
    title: "Why does my dog eat grass?- Petmojo",
    description:
      "Dog eating grass, even though normal, can be worrying for pet parents. Read to find what is normal and what is not.",
    serial_no: 3,
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1539wdx.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1547.webp",
    views: "89",
    date: "21st November, 2022",
    heading: "Signs of an Aggressive Dog",
    full_content: [
      "All pet parents adore their little ones but some dogs can get really aggressive and that can be a problem. These behavioural issues are difficult to handle or even understand. We are here to tell you the major signs to look for to determine if your dog is aggressive.",
      "Body Language:",
      "If you look closely, a dog’s body language can tell much more than we give credit. Most of the time, people ignore what their body language is telling us. To look for aggression, you must see if their ears are pinned back and if their body is getting stiff especially if someone is touching them. It may be time to leave them alone.",
      "Growling:",
      "This is quite a clear indicator of a dog getting aggressive but still ignored some times. A growling dog can attack at any time.",
      "Showing teeth:",
      "Some dogs are more expressive than others and it is easier to know when they are getting aggressive as they show their teeth. This is a clear sign of aggression. This sign of aggression can be noted to understand the patterns.",
      "Biting:",
      "Biting of various intensities are all signs of aggression in Dogs. Depending on the temperament of your canine, the bite may be just a light snipping or a full blown puncturing bite.",
    ],
    id: "signs-of-an-aggressive-dog-37",
    title: "Signs of an Aggressive Dog - Petmojo",
    description:
      "An aggressive dog is tough to handle. It is important to know the signs of an aggressive dog. Read to know more.",
    serial_no: 5,
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1536sd.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/bb35.png",
    views: "138",
    date: "19th November, 2022",
    heading: "Common Dog Myths Part 2",
    full_content: [
      "We already have a blog discussing common dog myths that are believed by pet parents but are actually not true. Since that blog post was loved by you all, we are sharing a continuation of the same.",
      "Dogs can swim:",
      "Most of us believe that all dogs can swim and it is a talent they have by birth. Unfortunately, this is a rumour. Although a lot of breeds can swim, they require a little bit of training. We have a blog about this topic that you can read.",
      "Dogs have clean mouths:",
      "It is widely believed that dog’s mouths are cleaner than human mouths. This is untrue. Their mouths have a lot of bacteria and microbes.",
      "It is impossible to train an older dog:",
      "Training is not a skill based on age. Yes, a young dog is easier to train but dogs can be trained at any age. Just like it is said, babies learn new languages much quicker than adults but that does not mean that adults cannot learn a new language. At Petmojo, we train dogs of all ages or breeds.",
      "Wagging tail means a friendly dog:",
      "Another myth is that anytime a dog’s tail wags, it means he is happy. Also, a dog with a wagging tail is considered friendly. Most of the time when dogs wag their tails, they are happy but they also wag their tails when scared or angry.",
    ],
    id: "common-dog-myths-part-2-35",
    title: "Common Dog Myths Part 2 - Petmojo",
    description:
      "Common Dog Myths that should be busted in order to take better care of our pooches. We are here to help you understand your dog better. Read to find more.",
    serial_no: 2,
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1539.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1541.webp",
    views: "224",
    date: "17th November, 2022",
    heading: "Golden Retriever: The Ultimate Guide to Dog Breed",
    full_content: [
      "Golden Retrievers are the sweetest, playful and loyal.  We have curated a detailed list with everything you need to know if you are planning to get a Golden Retriever home.",
      "Characteristics:",
      "Golden Retrievers are known for their jolly nature. They are active & strong. Physically, they have a grand looking golden coat. Some retrievers can also be of white, off white or red colour. Most commonly found are the ones with gold fur.",
      "Personality:",
      "They are one of the most loved dog breeds especially if you want an active and happy dog. They are high energy dogs who have a very puppy-like energy for most of their life. They are very loyal to their family and are amazing with kids.",
      "Health & Wellness:",
      "Generally healthy but you need to do regular checks of their ears for infection. Moreover, good dental hygiene is a must! They are not heavy shedders but they do shed frequently. They are pretty much self cleaning breed and require much less bath than other breeds.",
      "Training Needs:",
      "The first thing to do when you have a golden retriever puppy is socialize them till 4 months. They are energetic and friendly, so they love meeting new people and dogs. Positive reinforcement training works best for them due to their eager nature.",
      "Energy Level:",
      "They need a lot of exercise. At least 2 hours of active time is required for them. They also enjoy high intensity activities like hiking or running.",
      "Life Span:",
      "Golden Retrievers live up to 10 to 12 years on an average.",
    ],
    id: "golden-retriever-the-ultimate-guide-to-dog-breed-36",
    title: "Golden Retriever: The Ultimate Guide to Dog Breed - Petmojo",
    description:
      "The Ultimate Guide to Golden Retriever Breed. Everything you need to know before you decide to pet them. Read to learn more.",
    serial_no: 3,
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/b34.png",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/bb34.png",
    views: "68",
    date: "16th November, 2022",
    heading: "Methods to Train a Dog Part 1",
    full_content: [
      "There are multiple ways to train a dog and as a pet parent it can be a daunting task to decide which way to go. Some methods are very effective but not ethical and others are not effective. Let’s discuss the most powerful and ethical methods.",
      "Positive Reinforcement:",
      "This method is by far the most ethical out there. At Petmojo, we provide positive reinforcement training. To use this method, we need to associate good behaviour with rewards. Punishments are not used to enforce the behaviour. The reward must be given immediately for them to make associations.",
      "Clicker Training:",
      "In this method, the dog is conditioned to follow the commands and the clicker sound is made on following the command correctly. Clicker training can be used in combination with positive reinforcement for more powerful training. For the dog, the click acts as an appreciation, so clicker training is a subset of positive reinforcement.",
      "Scientific Training:",
      "With new scientific discoveries, the scientific approach is changing quickly. Scientists are working on dog psychology constantly to learn more about them. To implement scientific training, one needs to be up to date with the latest studies.",
    ],
    id: "methods-to-train-a-dog-1-34",
    title: "Methods to Train a Dog Part 1 - Petmojo",
    description:
      "There are various methods that are used by dog trainers. Some are more ethical than others. Read to find the best training method for your pooch.",
    serial_no: 5,
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1533.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-rectangle_1535.webp",
    views: "132",
    date: "15th November, 2022",
    heading: "Guide to remove ticks from your Dog",
    full_content: [
      "Ticks are parasites that stick to dog’s skin and feed on them. There are various signs you can look for in your dog to know if they have ticks. Preventing ticks is the best but if your dog is already infested with ticks, here is a guide to remove them.",
      "Professional Help:",
      "The best way to go about this is to contact a professional groomer. Removing ticks on your own can be a tedious process while the groomers have special tools and products to deal with them.",
      "Rubbing alcohol:",
      "If there is a tick which hasn’t bitten yet, drop a small amount of rubbing alcohol there. This will kill the tick. This method can be used to kill ticks that are removed from the body of the dog.",
      "Heat:",
      "Heat kills ticks as well so make sure whenever you have worked with removing ticks from your dog’s body, wash your clothes with hot water to get rid of the remainder.",
      "Garlic:",
      "Garlic has a very strong smell as well as a tick repellent. Add garlic to the diet of your dog and use garlic to remove ticks from their body.",
    ],
    id: "guide-to-remove-ticks-from-your-dog",
    title: "Guide to remove ticks from your Dog - Petmojo",
    description:
      "Ticks can infest our little doggos and cause real harm. Here is a Guide to Remove Ticks from your Dog. Read to know more. ",
    serial_no: 2,
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1529.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/bb32.png",
    views: "45",
    date: "14th November, 2022",
    heading: "Find out your Dog’s Chew Personality",
    full_content: [
      "All dogs love to chew but they have various kinds of chew personalities. Why is knowing their chew personality important? The reason is it will help you decide the right chew toy for them.",
      "Eaters:",
      "The dogs that not just chew around but also swallow what they chew are the eaters. You need to be very careful with the chew toy for them. Make sure that the toy is not made of toxic material. Try and buy larger toys for them so that they can’t swallow.",
      "Tugger:",
      "They don’t chew as much as they tug everything in sight. It is just like a massage for their gums. The best toys for such chewers are rope toys or tug toys.",
      "Shredder:",
      "Shredders are slightly destructive. They shred everything like newspaper, clothes, carpets, etc to small pieces.The best materials of toys for shredders is rubber. Rubber toys will be fine for a longer time.",
      "Nibbler:",
      "Nibblers are the ones that like to chew slightly on things like slippers or wires. They are the most destructive type of chewers.",
    ],
    id: "find-out-your-dogs-chew-personality",
    title: "Find out your Dog’s Chew Personality - Petmojo",
    description:
      "Learn your Dog’s chew personality and buy the right chew toy for them. Read to know more. ",
    serial_no: 3,
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1526.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-shih-tzu.webp",
    views: "129",
    date: "11th November, 2022",
    heading: "Shih Tzu: The Ultimate Guide to Dog Breed",
    full_content: [
      "Shih Tzu, also known as ‘lion dog’ are very cute, small sized dogs which are perfect for apartments. They are one of the most royal dog breeds. Here are some things about Shih Tzu you should know before getting one.",
      "Size:",
      "Shih Tzus are known for their small size. Their height is between 9-10 inches while their weight is 9-16 pounds.",
      "Personality:",
      "They are friendly and affectionate. They are very kid friendly. They are not very energetic and do not require long exercises and are likely to spend more time at home.",
      "Grooming needs:",
      "Shih Tsus do not shed a lot but their coat requires an ample amount of care. They require a bath at least once every month. Due to their long fur, they require regular haircuts as their long fur coat makes them very hot especially in summers.",
      "Training:",
      "Shih Tzus are so cute that often as a pup, their owners give them whatever they want. That is why it is extremely important that they get puppy training professionally.",
      "Life Span:",
      "Did you know that small dogs have a longer lifespan than big dogs. Shih Tzus are very small and have a life span of 10 to 18 years.",
    ],
    id: "shih-tzu-the-ultimate-guide-to-dog-breed",
    title: "Shih Tzu: The Ultimate Guide to Dog Breed - Petmojo",
    description:
      "Are you planning to get a Shih Tzu as a companion? Here is everything you need to know about them.",
    serial_no: 5,
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1518.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/bb30.png",
    views: "168",
    date: "10th November, 2022",
    heading: "Common Dog Myths Part 1",
    full_content: [
      "Dogs are man's best friend. We want to know everything about our doggos but there are so many misconceptions making the rounds about our little buddies. Let’s bust some of the common dog myths.",
      "Dogs eat grass when they are ill:",
      "It is very common that <a href='https://petmojo.care/blog/why-does-my-dog-eat-grass-38' target='_blank' rel='noopener noreferrer'>dogs eat grass.</a> We always presume that they do so because their stomach is upset but it is not always the reason. Sometimes, they just eat grass as they find it tasty or they are bored. There may be times when they eat grass due to lack of nutrients in their food.",
      "Dogs are color blind:",
      "As often believed, dogs can see colors other than black and white. Even though they can see other colors, their vision is not like ours. You can read more about their <a href='https://petmojo.care/blog/how-do-dogs-see-the-world' target='_blank' rel='noopener noreferrer'>vision here.</a>",
      "One dog year is equal to seven human years:",
      "This is a generalization and calculating your dog’s age is not as straightforward as multiplying 7. Their age depends on breed, size, genetics, diet and exercise.",
      "My dog can feel guilty:",
      "Pet owners feel that their pets display certain behaviour which indicates guilt but in reality, it is not really true. To learn more, <a href='https://petmojo.care/blog/do-dogs-feel-guilty' target='_blank' rel='noopener noreferrer'>read here</a>.",
    ],
    id: "common-dog-myths-part-1-30",
    title: "Common Dog Myths Part 1 - Petmojo",
    description:
      "Common Dog Myths that should be busted in order to take better care of our pooches. We are here to help you understand your dog better. Read to find more.",
    serial_no: 2,
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1486.webp",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/bb1.png",
    views: "132",
    date: "9th November, 2022",
    heading: "Signs your Dog is Stressed",
    full_content: [
      "Did you know our fur buddies are just as susceptible to stress as us. Generally, the symptoms of a stressed dog are quite clear. We have listed down some signs that show your dog is stressed.",
      "Body Language:",
      "A stressed dog may shift his weight on his rear legs, tuck his ears or tail, yawn more often, etc. He may also avoid direct eye contact. Body language of a stressed dog is quite similar to a guilty dog.",
      "Aggression:",
      "An agitated dog may be one of the first signs of a dog that is stressed. Especially so if your dog is getting aggressive while their body language displays fear, he is definitely stressed.",
      "Panting:",
      "There are three reasons that your dog may be panting, either it is hot, or they are very excited or they are stressed.",
      "Pacing:",
      "Sometimes, when dogs are stressed, they pace back and forth. Mostly this happens for a short time, and then it is not a big deal but if this happens for a long time or very frequently, then your dog is stressed.",
      "Excessive Sleeping:",
      "Just like us humans, increased sleep is not a good sign with respect to mental illnesses. Similarly, in dogs, excessive sleeping can be a symptom of stress.",
    ],
    id: "signs-your-dog-is-stressed-30",
    title: "Signs your Dog is Stressed - Petmojo",
    description:
      "Just like us, our dogs are prone to stress. It is important to know the signs your dog is stressed so you can remedy it. Read to find more about it. ",
    serial_no: 3,
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1484.webp",
    views: "179",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/bb2.png",
    date: "8th November, 2022",
    heading: "Reasons you should groom your Dog",
    full_content: [
      "<a href='https://www.petmojo.care/grooming' target='_blank' rel='noopener noreferrer'>Grooming</a> not only maintains their cleanliness, it also keeps them free of infections, good looking and great smelling. That is the reason why dogs have to be groomed regularly. Let us discuss in detail",
      "Skin Repair:",
      "Regular grooming removes dead skin and dead hair cells from the skin of dogs.",
      "Remove knots:",
      "With regular brushing, the knots in the fur can be removed. This will make sure their fur is tamed and beautiful.",
      "Regulate grease level:",
      "Their skin regularly secretes grease. Too much grease blocks pores and that leads to hair fall. While grooming, these natural oils are distributed evenly on their skin.",
      "Remove dirt:",
      "Dogs like to play outside. They often play in dirt and have lots of grass seeds or other things stuck on their coat which can only be removed by bathing.",
      "Entertaining Activity:",
      "Most dogs love to be brushed. When brushed, they feel amazing as it reduces the itch. This activity is also a great bonding experience for you and your dog.",
    ],
    id: "reasons-you-should-groom-your-dog-29",
    title: "Reasons you should groom your Dog - Petmojo",
    description:
      "Apart from cleanliness, there are many more reasons why you should groom your dog. Read to find more about it.",
    serial_no: 5,
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1482.webp",
    views: "138",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/bb3.png",
    date: "7th November, 2022",
    heading: "4 Signs that your Dog has ticks",
    full_content: [
      "Ticks are those parasites that can cause serious problems in dogs. They attach to the body of our dog, feed off their blood and make them itch a lot. Tick infested dogs may exhibit some of these signs.",
      "Excessive Scratching and Itching:",
      "The first telltale sign of ticks on your dog is excessive scratching and itching. The reason why having ticks makes them itchier is because when ticks bite, it releases a protein in their saliva. This protein creates an allergic reaction which makes them itch.",
      "Always tired:",
      "If your dog looks fatigued and his or her energy levels are constantly low, they may have ticks and you must take him to the vet.",
      "Loss of appetite:",
      "Another sign that your pooch has ticks is if he is not eating as much. Another effect that ticks have on dogs is loss of appetite.",
      "Hairless spots/Red Spots on skin:",
      "If you notice some spots on your dog where the fur is not there or there is a red spot, it might indicate a tick on their body. This is due to excessive itching caused by ticks.",
    ],
    id: "4-signs-your-dog-has-ticks",
    title: "4 Signs that your Dog has Ticks - Petmojo",
    description:
      "Find out what are the signs that you can look for in your dog to know if he has ticks. Read to learn more.",
    serial_no: 2,
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1480.webp",
    views: "169",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/bb4.png",
    date: "6th November, 2022",
    heading: "4 Benefits of Chew Toys for Dogs",
    full_content: [
      "All pet parents have been through the phase when their Dogs chew everything in sight. Even though you think this is negative behaviour, there are actually some benefits of Chew Toys for Dogs. So, it is better to buy a chew toy for them instead of them chewing everything they see.",
      "Good for gums:",
      "While teething, our little pups have a lot of pain. Chewing makes that pain bearable for them.",
      "Stress Relief:",
      "You know how a stress ball is calming for you, that is exactly what a chew toy does for your fur baby. Chewing makes them happy and calm.",
      "Better Dental Health:",
      "Chewing makes their teeth strong and clean and these days there are special dental chews for dogs which are great for dental health. They are amazing to scrape plaque and remove tartar buildup. It is highly advisable to use dental chews for dogs.",
      "Avoid Boredom:",
      "If you feel that your dog is chewing a lot especially when he is alone or just plain bored. That just might be the reason. If they are chewing all of your precious items due to boredom, it may indicate that they require more <a href='https://www.petmojo.care/dog-running' target='_blank' rel='noopener noreferrer'>exercise</a>.",
    ],
    category: "suggestion",
    id: "4-benefits-of-chew-toys-for-dogs",
    title: "4 Benefits of Chew Toys for Dogs - Petmojo",
    description:
      "Chew toys for dogs help them to be calm and relieve stress. Read to find more about the benefits for your pooch.",
    serial_no: 3,
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1478.webp",
    views: "201",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/bb5.png",
    date: "4th November, 2022",
    heading: "Labradors: The Ultimate Guide to Dog Breed",
    full_content: [
      "Labradors are friendly and energetic dogs that are perfect for almost any family. They’re great hunting dogs and they make amazing pets for families as well. We have created a comprehensive guide about Labs as pets for you!",
      "Very Energetic:",
      "It is often believed that Labs are lazy but the truth is they are extremely energetic. The problem is most of the time they do not get the kind of  <a href='https://www.petmojo.care/dog-running' target='_blank' rel='noopener noreferrer'>exercise</a> they require in an urban setup, so they end up becoming lazy. Labradors require ample amount of exercise barring which they may become obese.",
      "Friendly by Nature:",
      "Labradors are even tempered with everyone they meet. They love their family and have protective instincts towards them. They are great with kids as well. They have a good relationship with other dogs as well.",
      "Basic Grooming Requirement:",
      "Labs have short and flat hair which shed minimally. Booking groomers every month and <a href='https://www.petmojo.care/grooming' target='_blank' rel='noopener noreferrer'>grooming</a> them at home twice or thrice every week will keep them rad looking. Keeping them clean will also reduce any possibilities of infections.",
      "Easy to Train:",
      "It is good to get your Labrador some <a href='https://www.petmojo.care/dog-training' target='_blank' rel='noopener noreferrer'>obedience training</a> as they have an exuberant personality. They are very intelligent so it is easier to train them.",
    ],
    category: "suggestion",
    id: "labradors-the-ultimate-guide-to-dog-breeds-26",
    title: "Labrador Breed Guide - The ultimate guide to labradors - Petmojo",
    description:
      "Labrador Breed Guide - Labradors are one of the most loved dog breeds to pet in India. Learn more about them.",
    serial_no: 5,
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1476.webp",
    views: "223",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/bb6.png",
    date: "3rd November, 2022",
    heading: "Best Dog Breeds for a Small Apartment",
    full_content: [
      "If you have a small space but you can’t wait to be a pet parent, you are in the right place. We will tell you the best dog breeds that you can pet even if you have a small apartment.",
      "Pug:",
      "Due to their small size and low maintenance, pugs make great pets for small houses. They require minimal walks and exercise. They are very friendly and like to stay indoors with their family.",
      "Beagle:",
      "Beagles are small and compact dogs, so they don’t need large spaces to be happy. They love being active, so an apartment with space downstairs to run around would be perfect for them.",
      "Shih Tzu:",
      "These cute & small dogs are perfect for people who live in small apartments as they don't take up much space. They are not very active and have a tendency to be lazy. Even though they have beautiful fur, their shedding is minimal.",
      "Chihuahua:",
      "Although Chihuahuas are small, their energy levels are very high. They would require proper <a href='https://www.petmojo.care/dog-running' target='_blank' rel='noopener noreferrer'>walking</a> but their grooming needs and shedding is quite less. With their energy, it is better to get them trained well.",
      "Dachshund:",
      "These long and low to the ground cuties are perfect for an apartment. They are very energetic, so there will never be a dull moment. The only downside is that they bark very loudly which may be a problem with your neighbour.",
    ],
    category: "suggestion",
    id: "best-dog-breeds-for-a-small-apartment-25",
    title: "Best Dog Breeds for a Small Apartment - Petmojo",
    description:
      "Small apartment owners, find the best dog breeds that you can pet. Here is a list of top 5 dogs that are suitable even if you have a small flat. ",
    serial_no: 2,
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1474.webp",
    views: "262",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/bb7.png",
    date: "2nd November, 2022",
    heading: "Why You Need to Brush Your Dog's Teeth",
    full_content: [
      "Brushing your dog's teeth is a great way to maintain their dental health. They are prone to dental problems. The best time to brush your dog's teeth is after they have eaten or drank something because the bacteria in their mouth will be less concentrated right after eating.",
      "It is paramount to brush your dog's teeth with toothpaste made for dogs because it has a higher concentration of fluoride that can help prevent tooth decay.",
      "3 Reasons Why You Should Brush Your Dog's Teeth:",
      "1) It helps to prevent tooth decay and gum disease.",
      "2) It prevents plaque buildup on their teeth that could lead to bad breath or tooth loss.",
      "3) Dogs with dental problems cannot eat properly because they're in pain or just don't have the ability to chew food properly anymore. But if you brush a dog's teeth regularly, they'll be able to eat without any trouble!",
      "How often should we brush our Dog’s teeth:",
      "You can brush their teeth everyday but if you are unable to, at least once or twice in a week is a must. If you are brushing once or twice a week, don’t forget to add some dental chews in their routine to maintain dental health.",
    ],
    category: "suggestion",
    id: "why-do-you-need-to-brush-your-dogs-teeth",
    title: "Why You Need to Brush Your Dog's Teeth - Petmojo",
    description:
      "It is essential to brush your dog’s teeth regularly. We often ignore our pooch’s dental health. Read to find why it’s important.",
    serial_no: 3,
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1472.webp",
    views: "221",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/bb8.png",
    serial_no: 5,
    date: "1st November, 2022",
    heading: "Can Dogs Swim?",
    full_content: [
      "Although, it is widely believed that all dogs swim, unfortunately, this is a myth. It is only partially true that dogs can swim for survival but they might need some training.",
      "What determines their swimming ability?",
      "A dog's swimming ability depends on the shape of its body, the shape of its legs and paws, and its fur.",
      "How to teach them to swim?",
      "Make their experience with water safe. If you directly throw them in deep waters, they will likely struggle and may come close to drowning",
      "Which breeds are natural swimmers?",
      "Some breeds are better swimmers than others. Breeds like hounds and Labrador retrievers were meant for hunting so they are better swimmers.",
      "No breed of dog is completely incapable of swimming but there are some dog breeds that are more likely to sink than they are to swim. So, better be sure about the breed of your dog before trying to get them to swim.",
    ],
    category: "suggestion",
    id: "can-dogs-swim",
    title: "Can Dogs Swim? - Petmojo",
    description:
      "Dogs can swim or not? Dogs can swim for survival but they might need some training. Read to find more",
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1470.webp",
    views: "359",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/bb9.png",
    serial_no: 2,
    date: "26th October, 2022",
    heading: "4 Signs your Dog Misses You While You’re Gone",
    full_content: [
      "Every time you, as pet owners, leave your little dogs at home, it feels like leaving a piece of heart behind.Their expressions when you leave and the way they welcome you on return makes us think that the dog misses you as well.",
      "Let's find out sure shot signs that your dog misses you.",
      "Cuddle your belongings:",
      "Do you find your dog with your personal items when you return? That is a sign that he misses you and your things remind him of you. They may also spend all that time you were away in your room. Some dogs also stay at the door while their human is away. ",
      "Overexcited on your Return:",
      "If you pooch gets over-excited when you return, it is a sign that they missed you. They may run around the house and want you to pat and love them.",
      "Don’t eat while you are out:",
      "Some dogs do not eat when either they are in an unfamiliar environment or their owner is not around. It is their way of expressing their sadness by not eating.",
      "Follow you around:",
      "Once you return from anywhere, your little one follows you around everywhere you go. Some of them also start following the owner when they sense that they are about to leave. They just don’t want to let you out of sight.",
    ],
    category: "suggestion",
    id: "4-signs-your-dog-misses-you",
    title: "4 Signs your Dog Misses You While You’re Gone - Petmojo",
    description:
      "We think that your Dog misses you as much as you miss them while you're away. We have listed down some sure shot signs that they miss you.",
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1466.webp",
    views: "621",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-ideas-for-diy-dog-toys.webp",
    serial_no: 3,
    date: "25th August, 2022",
    heading: "Ideas for DIY Dog Toys",
    full_content: [
      "Have you ever wondered why dog toys are so expensive? Here is something about our little fur babies, they really don’t care about the price of the toys you buy them. What they really want is just something to play with. Here are a few DIY dog toys you can make for your Doggo.",
      "Using a Plastic Bottle:",
      "1. Take any plastic bottle. The sound of a plastic bottle is loved by most dogs. Cover it with a sock or any cloth. That’s a pretty good toy. :D ",
      "2. Another great one is a simple plastic bottle. Fill it with some of the favourite treats. The dog will keep trying till he gets every one of the treats out and will tire himself out. (Make sure your dog is not a chewer and won’t swallow the plastic) ",
      "Using Old T-shirts:",
      "Take an old cloth (T-shirts are great for this) Cut the cloth in strips and tie  it all together at the end. Start braiding the cloth like hairs and it’s done. ",
      "These are some of the simplest DIY toys for your dogs which will make them happy and tired, which in turn will calm them down.  ",
    ],
    category: "suggestion",
    id: "ideas-for-diy-dog-toys",
    title: "DIY Dog Toys Ideas using simple things at home - Petmojo",
    description:
      "Make your own DIY Dog Toys at home using basic things easily available in every household. Read to find how to make them yourself.",
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-how-do-dogs-see-the-world.webp",
    views: "2155",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-how-do-dogs-see-the-world.webp",
    serial_no: 5,
    date: "21st August, 2022",
    heading: "How Do Dogs See the World?",
    full_content: [
      "Until a few years back, it was believed that Dogs see only in black and white. Recent research suggests they can see and differentiate between many colours.",
      " ",
      "Colour Range in Dog Vision:",
      "Dogs are not colour blind, they have a different range of colours that they see. Dogs can see blues and yellows, but not reds and greens.",
      " ",
      "Vision Quality:",
      "Another interesting fact is that dogs also can suffer from near and farsightedness. Dogs also have a blurry vision as compared to us. When looking at an object from the same distance, the object may appear sharp to us, but blurred to our dogs.",
      " ",
      "Vision Range:",
      "Their eyes are placed towards the side of the head. This placement gives them a much broader vision than ours.",
      " ",
      "Making use of their vision to train them better:",
      "Whenever you are buying a toy or a training tool, make sure you buy yellow or green ones and not red ones. Although red toys are very common for dogs, it's mostly to attract their human parents.",
      " ",
      "How their vision helps them:",
      "The colours that canines can see makes their night vision great & they can detect movement easily. This, in turn, helps them to survive in the wild.",
    ],
    category: "suggestion",
    id: "how-do-dogs-see-the-world",
    title: "Dog Vision - How do dogs see the world? - Petmojo",
    description:
      "Have you ever wondered what your dog's vision is like? They see the world quite differently than we do due to the position of their eyes, colours they can see, etc. Read to find more about what they can and cannot see. ",
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1524.webp",
    views: "786",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/bb12.png",
    serial_no: 2,
    date: "23rd August, 2022",
    heading: "5 Common mistakes by first time Pet Parents",
    full_content: [
      "Are you a first-time pet parent? Let’s discuss the top 5 common mistakes new pet parents make that should be avoided.",
      "Getting Physically Too Close:",
      " We often indulge in hugging our puppy but most of them do not enjoy it. They feel constrained in such situations.",
      "Providing Inappropriate Food:",
      "Giving food which is not the best for dogs. We need to understand that they are different from us. Give them meat, eggs, veggies, fruits and pet food. Avoid dairy and chapatis. ",
      " Not Giving them Space:",
      "When we get a new puppy home, we have to help them settle in a 	relaxed way. They need to feel comfortable in the new place. Try and understand their body language and leave them alone if they seem uncomfortable. ",
      "Not Socializing them:",
      "Not getting them socialized properly. This has to be a gradual process. Don’t force your pup into uncomfortable situations. ",
      " Not Training Them:",
      "Not getting them trained professionally. Training is an essential part of a dog’s development. With the right training, their life as well as yours becomes so much easier. ",
    ],
    category: "suggestion",
    id: "5-common-mistakes-by-first-time-pet-parents",
    title: "5 common mistakes new Pet Parents make - Petmojo",
    description:
      "Common Mistakes New Pet Parents make. Having a new pet at home is like having a baby. There are so many questions new pet parents have and we are here to do our bit to help you. Read to find more.",
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/b1.jpeg",
    views: "1287",
    serial_no: 3,
    date: "12th September, 2022",
    heading: "How to help your Dog Socialize?",
    full_content: [
      "Socialization means giving your dogs experiences by meeting new people or animals. Here are some tips to help your dogs socialise. It is very important for their overall development.",
      "Introduction:",
      "Introduce them to someone gradually. Don’t be in a hurry. ",
      "Protection:",
      "When they meet someone new, human or another pet, protect them by being in front of them till they get comfortable. They need to feel protected.",
      "Investigation:",
      "Let them investigate by smelling as that is their strongest sense.",
      "Engagement:",
      "Ask the person to engage with the dog in a way your dog likes. For example, they may play ball.",
    ],
    category: "suggestion",
    id: "how-to-help-your-dog-socialize",
    title: "Dog Socialization Tips - How to help your dog socialize?",
    description:
      "Socialization of Dogs is very important from the beginning to make sure they are friendly. We, at Petmojo, are here to help you with some great tips to follow to increase social skills in your dog. ",
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1441.webp",
    views: "3421",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/keeping-your-dog-calm-during-diwali-fireworks",
    serial_no: 3,
    date: "22nd September, 2022",
    heading: "Keeping your Dog calm during Diwali fireworks",
    full_content: [
      "We all know the loud firecrackers are very uncomfortable for our Dogs. With Diwali approaching, we need to prepare our dogs to deal with loud noises.",
      "Safe Place:",
      "Get them acclimated to pet carriers or dog crates which can be their safe place.",
      "Minimise Noise:",
      "Shutdown windows and minimise noise inside your house.",
      "Distract Them:",
      "Keep them distracted by giving their favourite toys and treats.",
      "Soothing Essential Oils:",
      "There are some essential oils which can help calm them down. You can research the ones which are not harmful for dogs.",
      "Help from your Vet:",
      "If nothing else works, you can get anxiety medicine from the Veterinary to keep them calm.",
    ],
    category: "suggestion",
    id: "keeping-your-dog-calm-during-diwali-fireworks",
    title:
      "Dogs are terrified of firecrackers and Diwali, here are some tips to keep them calm - Petmojo",
    description:
      "For dogs, Diwali is not as fun as it is for us. The loud and unexpected sounds of firecrackers scares them. Here are a few tips that can help your dogs relax during this time. ",
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1123_4x.webp",
    views: "989",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/bb14.png",
    serial_no: 5,
    date: "9th December, 2021",
    heading: "Dogs can dream, just like us",
    full_content: [
      "Did you ever wonder if your dog dreams?",
      "Well, according to research, they do go through the same stages of sleep like us. During their REM sleep, they may twitch their noses or move their mouth.",
      "Dogs do dream but there is not enough evidence to realize the kind they see (visual or not), if they know the difference in dream & reality or if they remember them in the morning. Small dogs tend to dream more than bigger ones.",
    ],
    category: "suggestion",
    id: "dogs-can-dream-just-like-us",
    title: "Dogs have the ability to dream. Learn more on Petmojo",
    description:
      "Did you know that Dogs can actually see dreams. In fact, it is also said that dogs may dream like us, with visuals. Click to read more.",
  },
  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1440.webp",
    views: "3213",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/bb15.png",
    serial_no: 2,
    date: "14th December, 2021",
    heading: "Do Dogs feel guilty?",
    full_content: [
      "As pet-parents, we often feel that when our dogs do something unpleasant like chew a shoe, they demonstrate behaviour which shows guilt or shame. Such conduct includes avoiding eye contact, low walking posture, etc.",
      "As per research, dogs are incapable of complex feelings like guilt or shame. So, why do they show guilt-like behaviour?",
      "Researchers believe that dogs display such behaviour as a response to the body language of an angry owner or they have experienced that such actions reduces their punishment or avoids the punishment altogether.",
    ],
    category: "suggestion",
    id: "do-dogs-feel-guilty",
    title: "Do dogs feel guilt? - Dog Behaviour Psychology - Petmojo",
    description:
      " What is guilt? It is a complex emotion. Guilt is realizing your mistake and feeling shame for it. The question is, can dogs feel such complex emotions. Read on to find out. ",
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/b5.png",
    views: "263",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/bb16.png",
    serial_no: 3,
    date: "17th October, 2022",
    heading: "Road Tripping With Your Dog - What to Pack?",
    full_content: [
      "What’s more fun than going on a vacay? Going on one with your beloved dog of course! I wouldn’t dream about going anywhere for a rip-roarin’ good time without my best buddy.",
      " However, the first time planning for a trip can be a harrowing experience. Not only do you have to figure out the logistics of the hotel room, but you’ll also have to pack for two.",
      "There is nothing worse than arriving at an exciting new dog-friendly city and realizing that you forgot your dog’s bed or something equally likely to have your precious pooch glaring at you with those accusing eyes. ",
      "Researchers believe that dogs display such behaviour as a response to the body language of an angry owner or they have experienced that such actions reduces their punishment or avoids the punishment altogether.",
    ],
    category: "suggestion",
    id: "road-tripping-with-your-dog",
    title: "Essential tips while going on a Road Trip with your Dog - Petmojo",
    description:
      "Planning a trip with your pet can be quite exhilerating. We need to be prepared for everything that may come across our way during the vacation. We have listed some things to take care of while planning.",
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/b6.png",
    views: "1228",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/bb17.png",
    serial_no: 5,
    date: "5th October, 2022",
    heading: "Positive Pet Rewards",
    full_content: [
      "Food rewards are what most people think of when they think of positive reinforcement. Treats are quick and easy to give and are a great way to reward behavior immediately. If you use clicker training, it's easy to make sure your dog gets a treat the moment he performs a certain behavior",
      " Your dog may have separation anxiety.",
      "What is it? Separation anxiety occurs when a dog that is hyper-attached to its owner gets super stressed when left alone. It's more than just a little whine when you leave or a bit of mischievousness when you're out and about. It is a serious condition and one of the main reasons why owners get frustrated with their dogs and give them up. But there are lots of things you can do to help.",
    ],
    category: "suggestion",
    id: "positive-pet-rewards",
    title:
      "List of Rewards for Positive Reinforcement Training of your Dogs - Petmojo",
    description:
      " Wondering what rewards to give to your Dog while training? Here are some great examples of positive rewards that will help train your little ones better. ",
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/b7.png",
    views: "378",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/bb18.png",
    serial_no: 2,
    date: "10th October, 2022",
    heading: "Pet Separation Anxiety",
    full_content: [
      "Does your dog get nervous when he sees you about to leave the house? Will he go crazy with joy when you come home? Did they ruin your shoes, claw at the door, or bite the corner of the table while you were away? ",
      " Your dog may have separation anxiety.",
      "What is it? Separation anxiety occurs when a dog that is hyper-attached to its owner gets super stressed when left alone. It's more than just a little whine when you leave or a bit of mischievousness when you're out and about. It is a serious condition and one of the main reasons why owners get frustrated with their dogs and give them up. But there are lots of things you can do to help.",
    ],
    category: "suggestion",
    id: "pet-separation-anxiety",
    title:
      "Does your Dog have seperation anxiety? Read to learn more about dealing with it - Petmojo",
    description:
      "Dogs are social beings and depend on their pet parent completely. So, when we leave them alone at home, they often face anxiety issues commonly known as Seperation Anxiety. Here are a few tips to help your dogs.",
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/b8.png",
    views: "456",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/bb19.png",
    serial_no: 3,
    date: "2nd October, 2022",
    heading: "Guide to Pet Foods",
    full_content: [
      "Most people feed their dogs dry kibble or wet canned food. These processed foods may not be appealing to us, but they contain all the nutrients dogs need to stay healthy. Quality commercial dog food is strictly regulated and has undergone rigorous testing by veterinary specialists. So what exactly is in these dog foods?",
      "Dogs, unlike cats, are not strict carnivores. While meat makes up the majority of their diet, domestic dogs can also get their nutrients from grains, fruits and vegetables. These non-meat foods are not just fillers, but can be a valuable source of essential vitamins, minerals and fiber. A good dog food will contain meat, vegetables, grains and fruits. The best dog foods contain high-quality versions of these ingredients that are suitable for your dog's digestive system.",
    ],
    category: "suggestion",
    id: "guide-to-pet-foods",
    title: "A guide on feeding your Dogs just the right things - Petmojo",
    description:
      "We are often unaware of the right food we should feed our Dogs and feed them what we eat. That's not always the right way to go. Here are some food items that you can feed them without worrying about it.",
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/b9.png",
    views: "765",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/bb20.png",
    serial_no: 5,
    date: "25th September, 2022",
    heading: "Puppy and Teething",
    full_content: [
      "When a puppy is about 3 to 4 months old, its teeth begin to fall out to make room for 42 adult teeth. (That's about 10 more teeth than humans have.)",
      "This process can be very painful for your puppy – their gums will hurt. When teething occurs, you may notice that your puppy is drooling or see small spots of blood on his toys, although if you notice anything unusual, talk to your vet as both of these symptoms can also be related to a more serious problem.",
      "Once your puppy is 6 months old, his baby teeth should fall out. According to AKC Chief Veterinary Officer “You may find puppy teeth on the floor, although the puppy will probably swallow most of the teeth. If you find that some won't fall out, be sure to tell your vet. They may need to be removed by a veterinary professional.'",
    ],
    category: "suggestion",
    id: "puppy-and-teething",
    title:
      "Do you have concerns about your puppy's teething? Learn more to know what to expect - Petmojo",
    description:
      "Teething can be painful for your puppy and scary for you. What you should expect while your puppy is teething and at what point you should visit the vet. ",
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/b10.png",
    views: "147",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/bb21.png",
    serial_no: 2,
    date: "12th September, 2022",
    heading: "Beginners Guide to Neutering and Spaying",
    full_content: [
      "Most pets in western world are neutered. Neutering is the best way to prevent unwanted pregnancies. Some owners may be anxious about making this decision on behalf of their pets. They may be concerned about possible behaviour changes, weight gain or other health issues of their pet.",
      "Difference between neutering, spaying and castrating? Spaying refers to the removal of ovaries and uterus from a female animal, castration refers to the removal of testicles from a male animal and neutering can apply to either! ",
      "Benefits of neutering your dog, cat or rabbit? Some of many advantages are prevention of mammary cancer, prostate cancer, perineal hernias, tumours, endometriosis. Neutered pets live significantly longer than entire ones on average.",
      "Best time to neuter your pet? It is recommend to discuss this with your vet because each individual case varies. Generally, dogs and cats can be neutered from as early as 16 weeks.",
    ],
    category: "suggestion",
    id: "beginners-guide-to-neutering-and-spaying",
    title:
      "Are you conflicted about neutering your pet? We can help you decide - Petmojo",
    description:
      "Neutering your pet is a huge decision and can often get very confusing. Let us help you to make the decision by letting you know the right time and way. Read to find out more. ",
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/b11.png",
    views: "1678",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/bb22.png",
    serial_no: 3,
    date: "26th August, 2022",
    heading: "Can my dog eat human food? A guide to feeding human food to dog",
    full_content: [
      "Whether you believe in giving a raw diet to your pet or simply giving them the commercial dog food from the supermarket, it is necessary for you to know which food your fur-babies can eat from your plate.",
      " ",
      "Eggs- Eggs are highly beneficial to your dog's health. They have amino acids and calcium.",
      "Chicken- Chicken has omega 6 Fatty Acids and will ensure that your pooch has a healthy coat.",
      "Green beans- These veggies are perfect for overweight dogs. They have Vitamin A, K and many more nutrients.",
      "Yogurt- This treat, packed with calcium, keeps them healthy.",
      "Peanut butter- This is a good source of Niacin and Vitamin E. Make sure the peanut butter is sugar free.",
      "Cottage cheese- A fantastic treat for your dog.",
      "Carrots, peas, pumpkin, broccoli- Chewing on the veggie is good for your dog. They have a number of nutrients and are very healthy.Serve without seasonings.",
      "Rice-It can be given to dogs on a daily basis. It is a good source of carbohydrates, fats, cholesterol or sodium.",
      "Bananas, Apples- Low-calorie treat for dogs. They're high in potassium, vitamins, biotin, fibre, and copper.",
      "Toxic Foods for your Dog: Chocolate, Coffee, Soft drinks, Avocados ",
    ],
    category: "suggestion",
    id: "can-my-dog-eat-human-food",
    title:
      "Is human food appropriate for your Dog? Find more about it - Petmojo",
    description:
      "A lot of new Pet Parents are unsure about the right food for Dogs. Their body is not like ours so is their food. We need to curate the meals according to their requirement. ",
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/b12.png",
    views: "465",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/bb23.png",
    serial_no: 5,
    date: "24th August, 2022",
    heading: "Home Alone - What to do about separation anxiety?",
    full_content: [
      "Pet parents fear leaving their little ones alone at home while they go out. Some pets develop separation anxiety.",
      "Signs of Separation Anxiety in Dogs - Barking, howling, whimpering, clinginess, abnormal drooling, Chewing, digging, or destroying household items, Urination or defecation.",
      "How can I help manage my pet’s separation anxiety?",
      "Separation anxiety is very treatable, firstly, you should consult your veterinarian. In addition, follow the methods to help pets with separation anxiety:",
      "Make your departures and returns low-key.",
      "Give your pet a special treat or toy. This will make him associate your leaving with something good. Do not greet them while leaving.",
      "Teach independence. ",
      "Train them to lay in a specific spot. Don't pay attention to them if they stand up and follow you.",
      "You need patience to help your dog with separation anxiety. You can leave them alone for small periods of time and work your way up. Keep the change gradual to make them feel safe.",
    ],
    category: "suggestion",
    id: "home-alone-what-to-do-about-pet-separation-anxiety",
    title:
      "Find out how to help your dog cope with separation anxiety - Petmojo",
    description:
      "To understand separation anxiety in dogs, we must first understand what separation anxiety is and why our pets behave the way they do. Petmojo gives you an insight of different causes of separation anxiety and ways to prevent it.",
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/b13.png",
    views: "876",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/bb24.png",
    serial_no: 2,
    date: "22nd August, 2022",
    heading: "How many treaties are too many treaties? Dog treat Help!",
    full_content: [
      "Dog treats are great for training your buddy. Treats should be only 10% of your dog's daily calories.",
      "Calories are in Dog Food: Most dry and wet dog food have caloric information at the back of the bag. Highly energetic or pregnant dogs require more calories. Use this formula as a guide on food and treats. [Body weight (in kilograms) x 30] + 70 = Resting energy",
      "Choosing Treats: When it comes to giving treats, your dog's preference holds the ultimate hand. Some dogs prefer vegetables/fruits as treats others prefer the commercial counterparts.",
      "Homemade Treats: Peanut Butter, Bananas,Apple Slices,Carrots, Broccoli, Green Beans, Cucumber, Cooked Sweet Potatoes, Watermelon, Strawberries",
      "Fighting Dog Obesity: You must take calorie intake of your dog seriously even if you’re tempted to feed your dog. Obesity leads to health problems. We can reduce these problems by limiting their food intake.",
      "Remember treats shouldn't replace your dog's usual balanced diet. Always give treats in moderation even if they are healthy.",
    ],
    category: "suggestion",
    id: "how-many-treaties-are-too-many-treaties",
    title: "Are Dog Treats good or bad? Learn about them on Petmojo",
    description:
      "The use of commercial dog treats has been growing rapidly in the past few years. Dog Treats are a convenient way to reward your faithful canine friend for a job well done! ",
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/b14.png",
    views: "2412",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/bb25.png",
    serial_no: 3,
    date: "20th August, 2022",
    heading: "What is positive pet-rewarding training and why use it?",
    full_content: [
      "Using positive reinforcement is a great technique to train your pet without being strict or harsh. This technique means you reward the behaviors you like and ignore the behaviors you do not like. Positive reinforcement training is the best way to shape your pet's future behavior.",
      "1. Instantly Praise and Reward Desired Behaviour: Pets live in the moment, so your response should be instant. When your pet does something that you want them to do, you should reward them.",
      "2. Keep it Short and Fun : You should make training brief, fun, and positive - ideally finishing on a positive note so that they realize that good things happen on obeying you.",
      "3. Stock Up on Plenty of Treats: Treats are a great way to motivate most pets. Go for soft treats you can easily break into small, pea-sized nibbles.",
      "4. Make Sure Your Pet Is Enjoying Training: You can tell if pets are enjoying skill-building sessions by reading their cues. If they’re paying attention,then keep teaching. If they seem disinterested, try another time.",
      "5. Create Trust and Understanding with Your Pet: Treat the pet with love and care. Enjoy the process. Motivate them. Stay the course.",
    ],
    category: "suggestion",
    id: "what-is-positive-pet-rewarding-training",
    title:
      "Read to find out more about positive reinforcement training - Petmojo",
    description:
      "Positive training is a force-free and motivational approach to dog training. Dogs are rewarded with treats, play, toys and praise for practicing desired behaviours. ",
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/b15.png",
    views: "2344",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-portrait-114.webp",
    serial_no: 5,
    date: "18th August, 2022",
    heading: "What should I feed my dog-Wet food or Dry Food?",
    full_content: [
      "    Pet parents go through the dilemma of choosing between dry and wet food. The right kind of food determines a dog's health and happiness. ",
      "Wet and dry foods meet the basic standards for nutrient levels. Wet food is made by grinding meat and grain and added in rich gravy. Wet food has much higher moisture content. Dry food has around 10% moisture while wet food has almost 78% moisture.",
      "The ingredients are similar in dry food, the meat combination is pulverized to form a uniform dough. The dough is baked and then extruded through holes. After drying, its sprayed with fats, oils, vitamins, and minerals.",
      "Dental Health: Some dry foods have substances added to help minimize tartar build-up on the teeth.",
      "Mixing both types of food? There are times when feeding either one may be the choice for you or your dog. However, there are times when mixing the two together can be tasty. It is very important to ensure you are still providing the proper daily feeding amount.",
      "Wet foods are usually more nutritious. Both wet and dry dog diets have significant advantages and disadvantages, so deciding between the two is often a matter of dog's dietary needs.",
    ],
    category: "suggestion",
    id: "what-should-i-feed-my-dog",
    title:
      "Are you confused what you should feed your dog? Read to find more - Petmojo",
    description:
      "Your dog is part of family and it needs the best possible food for a healthy life. Let's discuss about the merits and demerits of wet food or dry food for your pet along with the right quantity to be fed. ",
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/b16.png",
    views: "3210",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/bb27.png",
    serial_no: 5,
    date: "17th August, 2022",
    heading: "Your Puppy and Teething - Explained",
    full_content: [
      "Puppy’s baby teeth fall out just like humans. Most puppies are born without teeth and go through a process called puppy teething.",
      "Puppy Training Timeline",
      "Till 2 Weeks- No Teeth",
      "2 to 4 Weeks- Incisors, premolars, molars and canines emerge.",
      "5 to 8 Weeks- All 28 baby teeth are in place.",
      "8 Weeks- Milk teeth start falling out.",
      "12 to 16 Weeks- Adult teeth come in pushing baby teeth out.",
      "6 months and older- All 42 permanent teeth should be in place",
      " ",
      "Although there is a slight variation in breeds, you can expect traditional growth of your puppy's teeth.",
      "What can you do as a pet-parent? Getting Chew Toys- Buy chew toys according to your puppy’s size. They should also not be rock hard. Start a teeth-cleaning routine- Start handling your puppy's mouth while it's young so you can regularly check for any potential tooth problems. Divert attention- If your puppy won't stop biting give them a chew toy or train them. Teach them tricks. Don't ignore- If they bite your hand, end the play and walk away. Repeat the process till they understand that biting means no play.",
      "Happy Parenting!",
    ],
    category: "suggestion",
    id: "your-puppy-and-teething-explained",
    title: "How to take care of teething puppies - Petmojo",
    description:
      "Troubleshoot your dog's teething and puppyhood woes. We have gathered useful information like symptoms, treatments and how to deal with a pooch that just won't stop chewing!",
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1446.webp",
    views: "1282",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/bb28.png",
    serial_no: 3,
    date: "19th September, 2022",
    heading: "How often should you bathe your dog?",
    full_content: [
      "The answer to this question is not direct, it depends on various factors. Let us discuss in detail,",
      "Fur Length:",
      "Dogs with medium to long hairs or curly hairs need more <a href='https://www.petmojo.care/grooming' target='_blank' rel='noopener noreferrer'>frequent grooming</a>, once every 4 to 6 weeks. If they have small hairs, you can bathe them in 3 months.",
      "Activity Level:",
      "If your little one spends a lot of time outside, in the dirt, they need more frequent cleaning.",
      "Skin Health:",
      "Considering your pooch’s skin health, you can use medicated shampoo and decide how often the dog should be bathed.",
      "It can also differ based on the breed. You must ask your Groomer to suggest as per the breed.",
    ],
    category: "suggestion",
    id: "how-often-should-you-bathe-your-dog",
    title:
      "How often should you bathe your Dog? We can help you decide - Petmojo",
    description:
      "Every dog is different and has different needs. We must bathe our dogs but the frequency depends on various factors because too little or too much is not good for our fur babies. Read to find out more.",
  },

  {
    src: "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/blog-landscape-rectangle_1444.webp",
    views: "3214",
    src_desktop:
      "https://websitefilesbucket.s3.ap-south-1.amazonaws.com/Petmojo-Media-Collection/bb29.png",
    serial_no: 3,
    date: "19th August, 2022",
    heading: "Controlling a Hyperactive Dog",
    full_content: [
      "Sometimes having a hyperactive dog can be quite exhausting. If you have an over excited dog, we have a few tips for you.",
      "Be Calm:",
      "First and foremost, to control a hyperactive dog, you need to be calm. Using a calm voice while comforting your dogs can do wonders.",
      "Tiring Them Out:",
      "Making sure that your pet has exercised well. If your little one is tired, they are far less likely to be over excited while meeting someone.",
      "Socialize Them:",
      "Another great way is to socialize him with more dogs. When canines are in company of other dogs, they play, run, fight which in turn utilizes their excess energy. It is difficult for us to match their energy level, so let them tire each other out. Learn more here (link to socializing dog blogs). ",
      "Interactive Toys",
      "Mental stimulation using interactive toys, puzzle toys are another great way to keep them busy while you are away. You can make some <a href='https://www.petmojo.care/blog/ideas-for-diy-dog-toys' target='_blank' rel='noopener noreferrer'>DIY games</a> for them as well.",
    ],
    category: "suggestion",
    id: "controlling-a-hyperactive-dog",
    title:
      "Do you have a hyperactive Dog? Read and get tips to handle overexcited canines - Petmojo",
    description:
      "Some dogs are so hyperactive that it gets difficult to control them. We have 4 tips for you to control and calm them down while making sure their energy is well-utilized.",
  },

  
];

const SuggestionBlogs = [];

export { AllBlogs, SuggestionBlogs };
