import React, { useEffect, useState } from 'react'
import NavigationBar from '../Navigation/navigationBar/NavigationBar'
import NavigationDesktopBlogs from "../../components-desktop/Navigation/NavigationDesktopBlogs";
import "./refundsPage.css";
import imgs from "./Good team-pana.svg"

const RefundPolicy = () => {
    const [windowInnerWidth, setWindowInnerWidth] = useState("");

    useEffect(() => {
        setWindowInnerWidth(window.innerWidth);
        window &&
            window.addEventListener("resize", (data) => {
                setWindowInnerWidth(data.target.screen.width);
            });
    }, []);

    return (
        <div>
            {windowInnerWidth > 1150 ? (
                <NavigationDesktopBlogs
                    submissionType={"none"}
                    submissionText={"Download Our App"}
                />
            ) : (
                <NavigationBar />
            )}
            {windowInnerWidth < 1150 &&
                <div className='flex-1 flex justify-center'>
                    <img src={imgs} alt="background" className='h-[300px] w-[300px]' />
                </div>
            }
            <div className="refunds_text_wrapper">
                <div className='w-full flex justify-between'>
                    <div className='flex-1'>
                        <div className="refunds_div ">
                            <h1 className="refunds_heading">CANCELLATION, REPLACEMENT, AND REFUND POLICY FOR PETMOJO</h1>
                            <div className="space-y-5">
                                <p className="refunds_text">
                                    Welcome to PetMojo! We value your trust in choosing us for your pet care needs. This policy outlines the terms governing cancellations, replacements, and refunds for services booked via the PetMojo app and website.
                                </p>
                                <p className="refunds_text">
                                    Please read this policy carefully. By making a booking with PetMojo, you acknowledge that you have understood and agreed to these terms.
                                </p>
                            </div>
                            {/* <div className="refunds_points">
                        <div className="refunds_dot"></div>
                        <p className="refunds_text">
                            The first 24 sessions are non refundable in all services. E.g., if A Service opted for 48 sessions where 24 sessions have been delivered, the refund will be provided only for the remaining 24 sessions.
                        </p>
                    </div>
                    <div className="refunds_points">
                        <div className="refunds_dot"></div>
                        <p className="refunds_text">
                            The refund amount will be made via Bank Transfer to the payee only
                        </p>
                    </div>
                    <div className="refunds_points">
                        <div className="refunds_dot"></div>
                        <p className="refunds_text">
                            Petmojo employees may verify your refund claims/ perform an internal check before the refund is sanctioned
                        </p>
                    </div> */}
                            {/* <div className="refunds_points">
            <div className="refunds_dot"></div>
            <p className="refunds_text">
              Petmojo employees may verify your refund claims/ perform an
              internal check before the refund is sanctioned
            </p>
          </div> */}
                            {/* <div className="refunds_points">
                        <div className="refunds_dot"></div>
                        <p className="refunds_text">
                            Once a refund request is raised it will take 14-21 days to process
                            & sanction your refund claim
                        </p>
                    </div> */}
                        </div>
                    </div>
                    {windowInnerWidth > 1150 &&
                        <div className='flex-1 flex justify-center'>
                            <img src={imgs} alt="background" className='h-[300px] w-[300px]' />
                        </div>
                    }
                </div>
                <div className="refunds_div space-y-6">
                    <h1 className="refunds_heading" style={{ marginBottom: "15px" }}>
                        GENERAL POLICY
                    </h1>
                    <p className="refunds_text">
                        At PetMojo, we aim to offer seamless and reliable pet care services. To maintain service quality and ensure operational efficiency, the following general policies govern cancellations, replacements, and refunds :
                    </p>
                    <ul className="list-disc list-inside">
                        <li className='refunds_text'>
                            All bookings are final. Once a service is confirmed, no refunds will be provided under normal circumstances.
                        </li>
                        <li className='refunds_text'>
                            Refunds will only be granted on reasonable grounds, as outlined in this policy, and subject to verification by PetMojo and after comply with the steps herein.
                        </li>
                        <li className='refunds_text'>
                            Clients are responsible for ensuring that they book the correct service, provide accurate information, and are available at the scheduled time.
                        </li>
                        <li className='refunds_text'>
                            PetMojo discourages frivolous cancellations. Frequent or unreasonable cancellations will lead to account warnings, restrictions, or suspension to protect our service providers.
                        </li>
                        <li className='refunds_text'>
                            No partial refunds or prorated credits will be given if a service is discontinued or not fully utilized by the client after booking.
                        </li>
                        <li className='refunds_text'>
                            Users are expected to plan responsibly, keeping in mind their schedule and the needs of their pets, before making a booking.
                        </li>
                        <li className='refunds_text'>
                            By confirming a booking, clients agree to abide by these policies. PetMojo reserves the right to update or modify this policy at any time to ensure fairness and operational sustainability.
                        </li>
                    </ul>
                </div>
                <div className="refunds_div space-y-6">
                    <h1 className="refunds_heading" style={{ marginBottom: "15px" }}>
                        CANCELLATION POLICY
                    </h1>
                    <p className="refunds_text">
                        At PetMojo, we strive to maintain high service standards while balancing the availability of our partners. As cancellations impact both operations and service providers, the following rules will apply:
                    </p>
                    <div className='space-y-5'>
                        <h2 className='refunds_heading_2'>2.1 Cancellations Immediately After Booking</h2>
                        <ul className="list-disc list-inside pl-6">
                            <li className='refunds_text'>
                                Any cancellation made within 48 hours of booking would not qualify for a refund unless an error in the booking system occurred (e.g., double bookings).
                            </li>
                            <li className='refunds_text'>
                                Frequent cancellations immediately after booking may lead to account warnings or restrictions on future bookings.
                            </li>
                        </ul>
                    </div>
                    <div className='space-y-5'>
                        <h2 className='refunds_heading_2'>2.2 Cancellations within 72 Hours of Booking</h2>
                        <ul className="list-disc list-inside pl-6">
                            <li className='refunds_text'>
                                No refunds or credits will be issued for cancellations made within the first 72 hours of booking. This rule applies regardless of the reason for the cancellation.
                            </li>
                            <li className='refunds_text'>
                                Repeated cancellations within 72 hours of booking, even with different reasons, will be flagged. PetMojo may temporarily suspend or restrict the user's ability to book services.
                            </li>
                        </ul>
                    </div>
                    <div className='space-y-5'>
                        <h2 className='refunds_heading_2'>2.3 Cancellations within 72 Hours of the Scheduled Service Time</h2>
                        <ul className="list-disc list-inside pl-6">
                            <li className='refunds_text'>
                                No refunds will be provided for cancellations made within 24 hours of the scheduled service start time, as service providers have already committed their time and resources.</li>
                            <li className='refunds_text'>
                                Users may opt to reschedule or request replacement services, subject to availability and PetMojo’s discretion.</li>
                        </ul>
                    </div>
                    <div className='space-y-5'>
                        <h2 className='refunds_heading_2'>2.4 No-Show Policy</h2>
                        <ul className="list-disc list-inside pl-6">
                            <li className='refunds_text'>
                                If the client does not show up or fails to make their pet available at the scheduled time without prior cancellation, the booking will be marked as a no-show, and no refund or credit will be issued.
                            </li>
                            <li className='refunds_text'>
                                Repeated no-shows may result in account deactivation or restrictions on future bookings.
                            </li>
                        </ul>
                    </div>
                    <div className='space-y-5'>
                        <h2 className='refunds_heading_2'>2.5 Cancellation due to van services in grooming </h2>
                        <ul className="list-disc list-inside pl-6">
                            <li className='refunds_text'>
                                It is is mentioned on our website that we provide doorstep services only and not van services - this is clearly mentioned and the same cannot be refunded though we could send the best available groomers in the area who could provide similar kind of experience at home
                            </li>
                        </ul>
                    </div>
                    <div className='space-y-5'>
                        <h2 className='refunds_heading_2'>2.6 Exceptional Circumstances for Cancellations with Refunds</h2>
                        <p className='refunds_text'>PetMojo recognizes that certain emergencies may necessitate cancellations. Refunds will only be considered for cancellations under the following verified scenarios:</p>
                        <ul className="list-decimal list-inside pl-6">
                            <li className='refunds_text'>
                                Critical Illness or Death of Pet
                                <ul className='list-disc list-inside pl-6'>
                                    <li>If the pet falls seriously ill or passes away before the scheduled service.</li>
                                    <li>A vet's certificate confirming the condition or loss is required from a reputed veterinary.</li>
                                    <li>If a user provides false information or fraudulent reasons for cancellation, PetMojo reserves the right to deny refunds and permanently suspend the user's account.</li>
                                    <li>Legal action may also be initiated for deliberate misuse of the cancellation policy.</li>
                                </ul>
                            </li>
                            <li className='refunds_text'>
                                Natural Calamities or Government Restrictions
                                <ul className='list-disc list-inside pl-6'>
                                    <li>If a natural disaster, government lockdown, or curfew affects the scheduled service, preventing the user or the service provider from participating.</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div className='space-y-5'>
                        <h2 className='refunds_heading_2'>2.7 Fraudulent or Misleading Cancellation Requests</h2>
                        <ul className="list-disc list-inside pl-6">
                            <li className='refunds_text'>
                                If a user provides false information or fraudulent reasons for cancellation, PetMojo reserves the right to deny refunds and permanently suspend the user's account.
                            </li>
                            <li className='refunds_text'>
                                Legal action may also be initiated for deliberate misuse of the cancellation policy.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="refunds_div space-y-6">
                    <h1 className="refunds_heading" style={{ marginBottom: "15px" }}>
                        REPLACEMENT OF SERVICES
                    </h1>
                    <p className="refunds_text">
                        At PetMojo, we understand that unforeseen circumstances may affect your ability to avail of scheduled services. While we do not offer refunds in most cases, replacements are provided as an alternative solution at PetMojo’s discretion. This ensures our clients can still benefit from our services while minimizing disruptions.
                    </p>
                    <div className='space-y-5'>
                        <h2 className='refunds_heading_2'>Eligibility for Replacement Services</h2>
                        <ul className="list-disc list-inside pl-8">
                            <li className='refunds_text'>
                                <span className='font-bold'>Service Provider Unavailability:</span> If the assigned service provider is unable to fulfill the booking due to operational issues or emergencies.
                            </li>
                            <li className='refunds_text'>
                                <span className='font-bold'>Service Provider Quality Issues:</span> At Pemtojo, we are committed to delivering exceptional dog training services. However, if a customer feels that the training provided does not meet their expectations, they can request a quality audit. Upon receiving the request:
                                <ul className='list-decimal list-inside pl-6'>
                                    <li className='refunds_text'>
                                        Audit Process: An experienced auditor will thoroughly review the sessions, evaluate progress, and gather feedback from the customer.
                                    </li>
                                    <li className='refunds_text'>
                                        Service Replacement: If the auditor finds the training unsatisfactory, the service partner may be replaced with a more suitable trainer to ensure optimal results
                                    </li>
                                    <li className='refunds_text'>
                                        Extended Sessions: If necessary, the auditor may recommend up to 3 additional sessions at no extra cost to cover any missed areas or to refine specific aspects of the training.
                                    </li>
                                    <li className='refunds_text'>
                                        No refund is applicable in case service quality issues BEFORE 2 replacements by the company.
                                    </li>
                                    <li className='refunds_text'>
                                        Audit could be conducted within 7 working days from date of request from the customer.
                                    </li>
                                </ul>
                            </li>
                            <li className='refunds_text'>
                                <span className='font-bold'>Verified Client Emergencies:</span> In cases where clients experience verified emergencies such as a medical issue of the pet (subject to documentation).
                            </li>
                            <li className='refunds_text'>
                                <span className='font-bold'>Natural Calamities or Government Restrictions:</span> If unforeseen external events, such as a lockdown or natural disaster, prevent the service from being delivered.
                            </li>
                        </ul>
                    </div>
                    <div className='space-y-5'>
                        <h2 className='refunds_heading_2'>Terms and Conditions for Replacement Services</h2>
                        <ul className="list-disc list-inside pl-6">
                            <li className='refunds_text'>
                                Replacement services are subject to availability. If the original service or provider is unavailable, PetMojo will offer a similar service or an alternate date based on availability.

                            </li>
                            <li className='refunds_text'>
                                The replacement of the service/service partner may take up to 5 working days from the request.
                            </li>
                            <li className='refunds_text'>
                                In certain cases, PetMojo may issue a voucher or credit for future bookings, valid for 365 days from the original service date.
                            </li>
                            <li className='refunds_text'>
                                A replacement will only be offered thrice per booking. If the client cancels or fails to utilize the replacement, no further replacement or refund will be provided.
                            </li>
                        </ul>
                    </div>
                    <div className='space-y-5'>
                        <h2 className='refunds_heading_2'>Process for Requesting a Replacement</h2>
                        <p className='refunds_text'>If you believe your case qualifies for a replacement, follow these steps:</p>
                        <ul className="list-decimal list-inside pl-6">
                            <li className='refunds_text'>
                                Email us at customercare@petmojo.com with your booking details and reason for requesting a replacement.
                            </li>
                            <li className='refunds_text'>
                                Connect with customer support over the phone 8889991800.
                            </li>
                            <li className='refunds_text'>
                                Adhere to the instructions provided during your conversation to proceed with the replacement process.
                            </li>
                            <li className='refunds_text'>
                                Documentation: Provide any necessary documents (e.g., medical reports, vet certificates) to support your request.
                            </li>
                        </ul>
                        <p className='refunds_text'>PetMojo will review the request and respond within 24 hours of your request.</p>
                    </div>
                    <div className='space-y-5'>
                        <h2 className='refunds_heading_2'>Non-Eligible Scenarios for Replacement</h2>
                        <p className='refunds_text'>A replacement will not be provided under the following circumstances:</p>
                        <ul className="list-disc list-inside pl-6">
                            <li className='refunds_text'>
                                Client’s Change of Mind: If the client no longer needs the service or changes plans without valid reason.
                            </li>
                            <li className='refunds_text'>
                                Incorrect Information: If the booking was made with inaccurate details (e.g., wrong dates or service types).
                            </li>
                            <li className='refunds_text'>
                                Client No-Show: If the client fails to show up for the scheduled service without prior notice.
                            </li>
                            <li className='refunds_text'>
                                Behavioral Issues: If undisclosed pet behavior problems cause the service to be canceled.
                            </li>
                        </ul>
                    </div>
                    <div className='space-y-5'>
                        <h2 className='refunds_heading_2'>Final Decision and Availability Limitations</h2>
                        <ul className="list-disc list-inside pl-6">
                            <li className='refunds_text'>
                                PetMojo reserves the right to approve or deny any replacement request at its sole discretion.
                            </li>
                            <li className='refunds_text'>
                                Alternate arrangements or credits may be offered when replacement services are not feasible. However, these will be limited to the options PetMojo can reasonably provide.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="refunds_div space-y-6">
                    <h1 className="refunds_heading" style={{ marginBottom: "15px" }}>
                        REFUND POLICY
                    </h1>
                    <p className="refunds_text">
                        At PetMojo, we are committed to providing reliable pet care services. However, to ensure fair business practices and operational stability,<span className='font-bold'>refunds will only be issued in specific circumstances</span> outlined in this policy. Please read carefully to understand when refunds may be applicable and the conditions governing them.
                    </p>
                    <div className='space-y-5'>
                        <h2 className='refunds_heading_2'>General Refund Policy</h2>
                        <ul className="list-disc list-inside pl-8">
                            <li className='refunds_text'>
                                All bookings are final, and refunds are generally not provided unless the request meets the specific eligibility criteria outlined below.
                            </li>
                            <li className='refunds_text'>
                                There is no refund if client relocates or shifts outside of available service zones but credits could be transferred to some other pets.
                            </li>
                            <li className='refunds_text'>
                                PetMojo reserves the right to investigate all refund requests to ensure they meet our policy conditions.
                            </li>
                            <li className='refunds_text'>
                                Refunds will only be processed if the client submits the request within 3 days from the scheduled service date.
                            </li>
                        </ul>
                    </div>
                    <div className='space-y-5'>
                        <h2 className='refunds_heading_2'>Valid Scenarios for Refunds</h2>
                        <p className="refunds_text">
                            Refunds will only be considered under the following <span className='font-bold'> exceptional and verified circumstances</span>: Refund is always on pro rata basis the number of sessions completed regardless of anything.
                        </p>
                        <ul className="list-disc list-inside pl-6">
                            <li className='refunds_text '>
                                <span className='font-bold'>Service Provider Unavailability or Cancellation</span>
                                <ul className='list-none list-inside pl-6'>
                                    <li className='refunds_text list-[circle]'>
                                        If the assigned service provider is unable to fulfill the booking and no replacement is available, a pro-rata-based refund (unused amount) will be issued.
                                    </li>
                                    <li className='refunds_text list-[circle]'>
                                        For training Services upto 24 sessions, petmojo shall try to provide at least 2 replacements before client could ask for refund. Before replacement, refund is not at all applicable.
                                    </li>
                                    <li className='refunds_text list-[circle]'>
                                        For walking services considering one one-month period, petmojo shall try to provide at least 1 replacement before client could ask for refund. Before replacement, refund is not at all applicable.
                                    </li>
                                    <li className='refunds_text list-[circle]'>
                                        For training services of 24 to 144 sessions, petmojo shall try to provide at least 3 replacements before client could ask for refund. Before replacement, refund is not at all applicable.
                                    </li>
                                    <li className='refunds_text list-[circle]'>
                                        For grooming service, if you are not satisfied with the service partner – then we may provide the replacement of the service partner withn 24 hours before the refund can be requested. Without replacement, refund is not at all applicable.
                                    </li>
                                </ul>
                            </li>
                            <li className='refunds_text'>
                                <span className='font-bold'>Medical Emergencies (Pet)</span>
                                <ul className='list-none list-inside pl-6'>
                                    <li className='refunds_text list-[circle]'>
                                        If  the pet suffers a medical emergency preventing the service, a refund of up to 50% of the unused funds of the booking fee may be granted.
                                    </li>
                                    <li className='refunds_text list-[circle]'>
                                        Supporting documents such as hospital admission slips or a reputed vet certificate must be provided for verification.
                                    </li>
                                    <li className='refunds_text list-[circle]'>
                                        PetMojo will attempt to arrange a reschedule first. Refunds will only apply if a reschedule cannot be happen from the PetMojo’s end. If the user denies the reschedule then the booking shall be treated as cancelled.
                                    </li>
                                </ul>
                            </li>
                            <li className='refunds_text'>
                                <span className='font-bold'>Natural Disasters or Government Restrictions</span>
                                <ul className='list-none list-inside pl-6'>
                                    <li className='refunds_text list-[circle]'>
                                        If the service cannot be fulfilled due to natural disasters, lockdowns, curfews, or government-imposed restrictions, a pro-rata based refund or service credit will be provided.
                                    </li>
                                    <li className='refunds_text list-[circle]'>
                                        Refunds under this category are processed only if rescheduling is not feasible or the client declines an alternative service date.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div className='space-y-5'>
                        <h2 className='refunds_heading_2'>Non-Refundable Cases</h2>
                        <p className='refunds_text'>Refunds will not be granted under the following circumstances:</p>
                        <ul className="list-disc list-inside pl-6">
                            <li className='refunds_text'>
                                Client’s Change of Mind: If the user no longer needs the service or cancels for personal reasons.
                            </li>
                            <li className='refunds_text'>
                                Incorrect Booking Details: If the booking was made with incorrect information (e.g., wrong dates or service type).
                            </li>
                            <li className='refunds_text'>
                                Pet Behavioral Issues: If the service cannot be completed due to undisclosed pet behavior problems.
                            </li>
                            <li className='refunds_text'>
                                Late Cancellations: If the cancellation is made within 72 hours of the scheduled service time.
                            </li>
                            <li className='refunds_text'>
                                No-Shows: If the client fails to make the pet available or misses the appointment without prior cancellation.
                            </li>
                            <li className='refunds_text'>
                                Fraudulent Requests: If the refund request is based on false or misleading information.
                            </li>
                        </ul>
                    </div>
                    <div className='space-y-5'>
                        <h2 className='refunds_heading_2'>Refund Process</h2>
                        <p className='refunds_text'>If you believe you are eligible for a refund, follow these steps:</p>
                        <ul className="list-decimal list-inside pl-6">
                            <li className='refunds_text'>
                                <span className='font-bold'>Submit a Refund Request</span>
                                <ul className='list-none list-inside pl-6'>
                                    <li className='refunds_text list-[circle]'>
                                        Via Email: Send an email to customercare@petmojo.in with your booking details and reason for refund.
                                    </li>
                                    <li className='refunds_text list-[circle]'>
                                        Connect with customer support over the phone 8889991800.
                                    </li>
                                    <li className='refunds_text list-[circle]'>
                                        Adhere to the instructions provided during your conversation to proceed with the replacement process.
                                    </li>
                                </ul>
                            </li>
                            <li className='refunds_text'>
                                <span className='font-bold'>Provide Supporting Documentation</span>
                                <ul className='list-none list-inside pl-6'>
                                    <li className='refunds_text list-[circle]'>
                                        If your refund request is based on a medical emergency or pet death, attach the necessary documents (e.g., vet certificate, hospital report).
                                    </li>
                                </ul>
                            </li>
                            <li className='refunds_text'>
                                <span className='font-bold'>Review and Verification</span>
                                <ul className='list-none list-inside pl-6'>
                                    <li className='refunds_text list-[circle]'>
                                        PetMojo will review the request within 5-7 business days and may contact you for additional information.
                                    </li>
                                </ul>
                            </li>
                            <li className='refunds_text'>
                                <span className='font-bold'>Refund Approval and Processing</span>
                                <ul className='list-none list-inside pl-6'>
                                    <li className='refunds_text list-[circle]'>
                                        If approved, refunds will be processed within 10 business days to the preferred payment method used during booking.
                                    </li>
                                    <li className='refunds_text list-[circle]'>
                                        If declined, the client will be notified via email with the reason for the denial.
                                    </li>
                                </ul>
                            </li>
                            <li className='refunds_text'>
                                <span className='font-bold'>Service Credits as an Alternative to Refunds</span>
                                <ul className='list-none list-inside pl-6'>
                                    <li className='refunds_text list-[circle]'>
                                        In certain cases, PetMojo may offer service credits or vouchers instead of refunds. These credits must be used within 365 days of issuance.
                                    </li>
                                    <li className='refunds_text list-[circle]'>
                                        Service credits are non-transferable and non-refundable once issued.
                                    </li>
                                </ul>
                            </li>
                            <li className='refunds_text'>
                                <span className='font-bold'>Multiple Refund Requests and Abuse of Policy</span>
                                <ul className='list-none list-inside pl-6'>
                                    <li className='refunds_text list-[circle]'>
                                        Multiple refund requests from the same user for different bookings will be flagged for review.
                                    </li>
                                    <li className='refunds_text list-[circle]'>
                                        Users attempting to abuse the refund policy through frequent cancellations or false claims may face account restrictions or permanent suspension.
                                    </li>
                                </ul>
                            </li>
                            <li className='refunds_text'>
                                <span className='font-bold'>Dispute Resolution</span>
                                <ul className='list-none list-inside pl-6'>
                                    <li className='refunds_text list-[circle]'>
                                        If the client is dissatisfied with the refund decision, they may escalate the matter by contacting customercare@petmojo.in for further review.
                                    </li>
                                    <li className='refunds_text list-[circle]'>
                                        PetMojo's final decision on refunds will be binding and non-negotiable.
                                    </li>
                                </ul>
                            </li>

                        </ul>
                    </div>
                </div>
                <div className="refunds_div space-y-6">
                    <h1 className="refunds_heading" style={{ marginBottom: "15px" }}>
                        PETMOJO’S RIGHT TO MODIFY SERVICES
                    </h1>
                    <p className="refunds_text">
                        PetMojo reserves the right to modify, adjust, or cancel its services, offerings, and policies to ensure continuous improvement, operational efficiency, and customer satisfaction. These modifications may apply to individual services, pricing, features, or availability. By using PetMojo’s platform, clients agree to these potential changes as part of their service agreement. PetMojo may implement the following types of changes:
                    </p>
                    <div className='space-y-5'>
                        <ul className="list-decimal list-inside pl-6">
                            <li className='refunds_text'>
                                <span className='font-bold'>Service Structure Adjustments:</span> Changes to the scope, duration, or nature of specific services (e.g., altering grooming package inclusions).
                            </li>
                            <li className='refunds_text'>
                                <span className='font-bold'>Pricing Changes:</span> Adjustments to service fees, discounts, or offers based on market conditions or operational needs.
                            </li>
                            <li className='refunds_text'>
                                <span className='font-bold'>Availability Adjustments:</span> Temporary or permanent withdrawal of services in certain regions or during specific times.
                            </li>
                            <li className='refunds_text'>
                                <span className='font-bold'>Provider Replacement:</span> Reassignment of service providers in cases of unavailability, without affecting the quality of the service.
                            </li>
                            <li className='refunds_text'>
                                <span className='font-bold'>Platform Updates:</span>  Changes to app or website features, booking processes, and policies to enhance user experience.
                            </li>
                        </ul>
                        <p className='refunds_text'>PetMojo will not be liable for any inconvenience, loss, or damages arising from modifications made to its services, especially when such changes are necessary to comply with external factors (e.g., regulatory changes, natural disasters). PetMojo’s responsibility is limited to providing an alternate solution or rescheduling options whenever feasible.</p>
                    </div>
                    <div className='space-y-5'>
                        <h2 className='refunds_heading_2'>Emergency Situations and Service Interruptions</h2>
                        <ul className="list-disc list-inside pl-6">
                            <li className='refunds_text '>
                                In cases where unforeseen circumstances (e.g., pandemic restrictions, natural disasters) require the suspension or alteration of services, PetMojo will act in the best interest of clients by offering rescheduling, credits, or other solutions.
                            </li>
                            <li className='refunds_text '>
                                Clients acknowledge that PetMojo may need to modify or discontinue services without prior notice during emergencies to ensure safety and compliance.
                            </li>
                        </ul>
                    </div>
                    <div className='space-y-5'>
                        <h2 className='refunds_heading_2'>Right to Amend Terms and Policies</h2>
                        <ul className="list-disc list-inside pl-6">
                            <li className='refunds_text '>
                                PetMojo retains the right to revise service policies and terms (including this policy) as necessary.
                            </li>
                            <li className='refunds_text '>
                                Changes to terms and conditions will be communicated through the app, website, or email. Continued use of PetMojo’s platform and services following any policy change constitutes acceptance of the revised terms.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="refunds_div space-y-6">
                    <p className="refunds_text">
                        Thank you for trusting PetMojo with your pet care needs! We are committed to providing the best possible experience for you and your furry companions. For further inquiries, please contact us at customercare@petmojo.in or call us at 8889991800.
                    </p>
                </div>

            </div>
        </div>
    )
}

export default RefundPolicy